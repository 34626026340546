import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Loading from "../../components/Loading";
import { IAuthState } from "../../redux/reducers/authReducer";
import { IChecklistState } from "../../redux/reducers/checklistReducer";
import { RootState } from "../../redux/store/reducer";
import { IUsersState } from "../../redux/reducers/usersReducer";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface Props {
  user?: any;
  open: boolean;
  onClose: () => void;
  onAccept: (values: any, projects: any) => void;
  title?: string;
  type?: string;
}

export const ChecklistEdit: React.FC<Props> = React.memo(
  ({ user, open, onAccept, onClose, title = "", type = "new" }) => {
    const dispatch = useDispatch();
    const auth = useSelector<RootState, IAuthState>(
      (state) => state.authReducer
    );
    const { recintos, isLoading } = useSelector<RootState, IChecklistState>(
      (state) => state.checklistReducer
    );
    const {
      dialog: { selectedChecklist },
    } = useSelector<RootState, IChecklistState>(
      (state) => state.checklistReducer
    );
    const { users } = useSelector<RootState, IUsersState>(
      (state) => state.usersReducer
    );

    const [project, setProject] = React.useState<any[]>([]);
    const [checklist, setChecklist] = React.useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [tipoUsuario, setTipoUsuario] = useState<string | null>(null);
    const [editTipoUsuario, setEditTipoUsuario] = useState(true);

    const validateAvaiableUsers = (data: any, value: boolean) => {
      if (value) {
        return false;
      } else {
        if (data.UsuariosUsados < data.LimiteUsuarios) {
          return false;
        } else {
          return true;
        }
      }
    };

    const {
      handleSubmit,
      handleChange,
      values,
      touched,
      errors,
      setValues,
      setFieldValue,
      resetForm,
    } = useFormik({
      initialValues: {
        Nombre: "",
      },
      onSubmit: (values) => {
        setLoading(true);
        onAccept(values.Nombre, checklist);
        setChecklist([]);
        resetForm();
        setLoading(false);
        onClose();
      },
      validationSchema: yup.object({
        Nombre: yup.string().required("Nombre requerido"),
      }),
    });

    useEffect(() => {
      if (auth.user?.tipoUsuario !== "Administrador") {
        setEditTipoUsuario(false);
      }
      setTipoUsuario(null);
      if (type === "edit") {
        if (user) {
          var isRevisiones = false;
          var isChecklist = false;
          var isEntregas = false;
          if (user.Revisiones) {
            isRevisiones = user.Revisiones;
          }
          if (user.Checklist) {
            isChecklist = user.Checklist;
          }
          if (user.Entregas) {
            isEntregas = user.Entregas;
          }
        }
      } else if (type === "new") {
        resetForm();
      }
    }, [user, type]);

    useEffect(() => {
      setValues({
        Nombre: selectedChecklist?.Nombre,
      });
      setChecklist(selectedChecklist?.ItemsRecintos)
    }, [selectedChecklist]);

    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              onClose();
            }
          }}
          aria-labelledby="add-and-update-dialog-title"
        >
          <DialogTitle id="add-and-update-dialog-title">{title}</DialogTitle>

          <form noValidate onSubmit={handleSubmit}>
            <DialogContent style={{ minHeight: 300, minWidth: 600 }}>
              {!loading ? (
                <Grid container spacing={3} justify="space-between">
                  <Grid style={{ marginBottom: 20 }} item sm={6} xs={12}>
                    <TextField
                      autoFocus
                      id="Nombre"
                      label="Nombre"
                      type="text"
                      value={values?.Nombre}
                      name="Nombre"
                      onChange={handleChange}
                      error={touched.Nombre && Boolean(errors.Nombre)}
                      helperText={touched.Nombre && errors.Nombre}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      value={recintos?.filter(function (obj: any) {
                        return checklist?.find(function (obj2: any) {
                          return obj.id === obj2.id;
                        });
                      })}
                      options={recintos}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.Nombre}
                      onChange={(_, value: any) => {
                        setChecklist(value);
                      }}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.Nombre}
                          {option.Responsable &&
                            " - [" + option.Responsable?.NombreCompleto + "]"}
                        </React.Fragment>
                      )}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Editar Recintos"
                          placeholder="Recintos"
                          disabled
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Loading open={loading} />
              )}
            </DialogContent>
            <DialogActions>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Box>
                  <Button onClick={onClose} color="primary">
                    Cancelar
                  </Button>
                  <Button type="submit" color="primary">
                    Guardar
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }
);
