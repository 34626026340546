import React from "react";
import styled from "styled-components/macro";

import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;
interface ILoadingProps {
  open: boolean;
}

function Loading(open: ILoadingProps) {
  return (
    <Root>
      <CircularProgress color="secondary" />
    </Root>
  );
}

export default Loading;
