import {
	CircularProgress,
	IconButton,
	ListItem,
	MenuItemProps,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { FC } from "react";
import { Trash2, Edit } from "react-feather";
import { useOutsideAlerter } from "src/utils/hooks/useOutsideAlerter";
import * as Yup from "yup";

type Props = {
	placeName: string;
	quantity?: number;
	onValueChange?: (ev: any, value: number) => void;
	onNameChange?: (name: string) => void;
	onDelete?: (name: string) => void;
	valueEditable?: boolean;
	nameEditable?: boolean;
	numberEditable?: boolean;
	deletable?: boolean;
	loading?: boolean;
} & MenuItemProps;

export const NeoPlaceItem: FC<Props> = ({
	quantity: value = 0,
	valueEditable = true,
	placeName = "Lugar sin título",
	nameEditable = false,
	numberEditable = true,
	deletable = false,
	onValueChange,
	onNameChange,
	onDelete,
	button,
	loading,
	...props
}) => {
	const ref = useOutsideAlerter(() =>
		setFieldValue("editMode", false)
	);

	const {
		values,
		errors,
		handleChange,
		handleSubmit,
		setFieldValue,
	} = useFormik({
		enableReinitialize: true,
		initialValues: {
			nombre: placeName ?? "",
			editMode: false,
		},
		onSubmit: async (
			values,
			{ resetForm, setFieldValue }
		) => {
			setFieldValue("editMode", false);
			if (values.nombre === placeName) return;
			await onNameChange?.(values.nombre);
			resetForm();
		},
		validationSchema: Yup.object({
			nombre: Yup.string().required(
				"No puede ir en blanco."
			),
		}),
	});

	return (
		<div ref={ref as any}>
			<ListItem
				id={`revision-${placeName}`}
				{...props}
				style={{
					...props.style,
					display: "flex",
					gap: 10,
					justifyItems: "center",
				}}
			>
				{values.editMode ? (
					<>
						<TextField
							onKeyDown={(ev) =>
								ev.key === "Enter" && handleSubmit()
							}
							style={{ flexGrow: 1 }}
							label="Nombre de la revisión"
							size="small"
							value={values.nombre}
							error={!!errors.nombre}
							helperText={
								errors.nombre ??
								"Presiona ENTER para guardar."
							}
							id="nombre"
							onChange={handleChange}
							autoComplete="off"
							autoFocus
						/>
					</>
				) : (
					<Typography style={{ flexGrow: 1 }}>
						{placeName}
					</Typography>
				)}

				{numberEditable && (
					<TextField
						style={{ width: 50 }}
						size="small"
						label="Cant."
						type="number"
						inputProps={{ min: 0 }}
						disabled={!valueEditable}
						value={value}
						onChange={(ev) =>
							onValueChange?.(ev, parseInt(ev.target.value))
						}
					/>
				)}

				{nameEditable && (
					<Tooltip
						title="Renombrar"
						arrow
						onClick={() =>
							values.editMode
								? handleSubmit()
								: setFieldValue("editMode", true)
						}
					>
						<span>
							<IconButton
								size="small"
								disabled={loading}
								color="primary"
							>
								{loading ? (
									<CircularProgress size={24} />
								) : (
									<Edit />
								)}
							</IconButton>
						</span>
					</Tooltip>
				)}

				{deletable && (
					<Tooltip
						title="Quitar"
						arrow
						onClick={() => onDelete?.(placeName)}
					>
						<span>
							<IconButton size="small" disabled={loading}>
								<Trash2 color="red" />
							</IconButton>
						</span>
					</Tooltip>
				)}
			</ListItem>
		</div>
	);
};
