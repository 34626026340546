import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
	Breadcrumbs as MuiBreadcrumbs,
	Card as MuiCard,
	CardContent,
	Divider as MuiDivider,
	Fade,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { format, isAfter, isBefore } from "date-fns";
import { useStyles } from "../../theme/useStyles";
import { firestore } from "../../firebase/firebase";
import { Observacion } from "../../models/Observacion";
import { useTable } from "../../hooks/useTable";
import { RangeDate } from "./Filters";
import { generateLegacyExcel } from "../../redux/actions/observacionesActions";
import { LegacyFilters } from "./LegacyFilters";
import { TABLE_LIMITS } from "../../constants";

import { HelperIcon } from "./HelperIcon";
import {
	LinkEmpresas,
	LinkProyectos,
} from "../CheckPro/Links";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useDispatch, useSelector } from "src/redux/store";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const enumerateObservations = async (
	arr: Observacion[]
): Promise<Observacion[]> => {
	const groupedByListado = arr.reduce((acc, obs) => {
		const listado = obs.NombreListado;
		if (!acc[listado]) {
			acc[listado] = [];
		}
		acc[listado].push(obs);
		return acc;
	}, {} as { [key: string]: Observacion[] });

	return Object.values(groupedByListado)
		.map((listadoArray) =>
			listadoArray.map((o, i) => ({ ...o, Numero: i + 1 }))
		)
		.flat();
};

const ContentCard = () => {
	const classes = useStyles();
	const { getExcel } = useSelector(
		(state) => state.observacionesChecklistReducer
	);
	const { projectId } = useParams() as any;
	const dispatch = useDispatch();

	const [observaciones, setObservaciones] = useState<
		Observacion[]
	>([]);

	// Conseguir observaciones
	useMemo(async () => {
		const snap = await firestore
			.collectionGroup("Observaciones")
			.where("ProyectoId", "==", projectId)
			.orderBy("CreatedAt", "asc")
			.get();

		setObservaciones(
			await enumerateObservations(
				snap.docs.map((doc) => doc.data() as Observacion)
			)
		);
	}, [projectId]);

	const {
		limit,
		page,
		handleLimitChange,
		handlePageChange,
	} = useTable({
		limit: 20,
	});
	const [filterData, setFilterData] = useState({});

	const filteredObservations = observaciones.filter(
		(obs) => {
			const arrayOfTests: [string, string | RangeDate][] =
				Object.entries(filterData);
			if (arrayOfTests.length === 0) return true;

			return arrayOfTests
				.map((arrayOfTestsEntry) => {
					const key = arrayOfTestsEntry[0];
					const value = arrayOfTestsEntry[1];
					if (typeof value === "string") {
						return (obs as any)[key] === value;
					} else {
						return (
							isAfter(obs.CreatedAt.toDate(), value[0]) &&
							isBefore(obs.CreatedAt.toDate(), value[1])
						);
					}
				})
				.every((e) => e);
		}
	);

	const handleGenerateExcel = () => {
		dispatch(generateLegacyExcel(filteredObservations));
	};

	return (
		<Card mb={6}>
			<LegacyFilters
				observations={observaciones}
				onChangeFilter={(value, filterName) => {
					const actualFilters: any = { ...filterData };
					if (value === "todo") {
						delete actualFilters[filterName];
					} else {
						actualFilters[filterName] = value;
					}
					setFilterData(actualFilters);
				}}
				onClickGenerateExcel={handleGenerateExcel}
				isLoadingExcel={getExcel.state === "IsSubmitting"}
			/>
			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Revisión</TableCell>
								<TableCell>Listado</TableCell>
								<TableCell>Sector</TableCell>
								<TableCell>Recinto</TableCell>
								<TableCell>Subcontrato</TableCell>
								<TableCell>Error tipo</TableCell>
								<TableCell>Prioridad</TableCell>
								<TableCell>Orden</TableCell>
								<TableCell>Estado</TableCell>
								<TableCell>Comentario</TableCell>
								{/* <TableCell>Foto</TableCell> */}
								<TableCell>Observada</TableCell>
								<TableCell>Vence</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredObservations
								.slice(page * limit, page * limit + limit)
								.map((arr, i) => (
									<Fade key={`obs-${i}`} in={true}>
										<TableRow
											hover
											className={classes.styledRow}
										>
											<TableCell>
												{arr.NombreRevision}
											</TableCell>
											<TableCell>
												{arr.NombreListado}
											</TableCell>
											<TableCell
												style={{ whiteSpace: "nowrap" }}
											>
												{arr.Sector}
											</TableCell>
											<TableCell
												style={{ whiteSpace: "nowrap" }}
											>
												{arr.Recinto}
											</TableCell>
											<TableCell>
												{arr.Subcontrato}
											</TableCell>
											<TableCell>{arr.ErrorTipo}</TableCell>
											<TableCell>{arr.Prioridad}</TableCell>
											<TableCell>
												{arr.Numero || "-"}
											</TableCell>
											<TableCell
												style={{ whiteSpace: "nowrap" }}
											>
												{arr.Estado === "Vencida"
													? "Por Resolver"
													: arr.Estado}
											</TableCell>
											<TableCell
												align="center"
												style={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													maxWidth: 150,
												}}
											>
												<HelperIcon
													title={arr.Comentario}
												/>
											</TableCell>
											{/* <TableCell>
												<CeldaImagen observacion={arr} />
											</TableCell> */}
											<TableCell>
												{format(
													arr.CreatedAt.toDate(),
													"dd/MM/yyyy"
												)}
											</TableCell>
											<TableCell>
												{arr.Vencida
													? format(
															arr.Vencida.toDate(),
															"dd/MM/yyyy"
													  )
													: "-"}
											</TableCell>
										</TableRow>
									</Fade>
								))}

							{false ? (
								<Fade in={true}>
									<TableRow>
										<TableCell align={"center"} colSpan={3}>
											<img
												alt="Nada imagen"
												src="/static/no_data.svg"
												height="100"
											/>
											<Typography variant="h5">
												Nada Aun
											</Typography>
										</TableCell>
									</TableRow>
								</Fade>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={filteredObservations.length || 0}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
			</CardContent>
		</Card>
	);
};

export const RevisionObservationsExcelPage = () => {
	const { selectedBusiness } = useBusiness();

	const { selectedRevision } = useSelector(
		(s) => s.revisionsReducer
	);

	return (
		<>
			<Helmet title="Observaciones" />
			<Breadcrumbs>
				<Typography
					variant="h3"
					gutterBottom
					display="inline"
				>
					{selectedBusiness?.Nombre ?? "Empresa"}
				</Typography>
				<Typography
					variant="h3"
					gutterBottom
					display="inline"
				>
					{selectedRevision?.NombreProyecto ?? "Proyecto"}
				</Typography>
				<Typography
					variant="h3"
					gutterBottom
					display="inline"
				>
					Observaciones
				</Typography>
			</Breadcrumbs>

			<Breadcrumbs aria-label="Breadcrumb" mt={2}>
				<LinkEmpresas />
				<LinkProyectos />
				<Typography>Observaciones</Typography>
			</Breadcrumbs>

			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard />
				</Grid>
			</Grid>
		</>
	);
};
