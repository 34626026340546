import {
	Breadcrumbs,
	Divider,
	Tab,
	Tabs,
	Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { LinkProjectsOT } from "src/components/CheckPro/Links/NeoProjects";
import { RejectedByGraphOT } from "src/components/ProjectsOT/Graph/RejectedBy";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

export const DeviationByChecklistGraphPageOT = () => {
	const { selectedBusiness } = useBusiness();
	const { selected, project, get } = useProjectsOT();

	const [type, setType] = useState<
		"Obra Gruesa" | "Terminaciones"
	>("Obra Gruesa");

	useEffect(() => {
		if (!selected.project) return;
		get.project.checklists(type);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected.project, type]);

	useEffect(() => {
		if (!project.checklists) return;
		get.report.deviationByParameter({
			checklistType: type,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project.checklists]);

	return (
		<>
			<Helmet title="Checklist" />

			<Breadcrumbs style={{ fontSize: 20 }}>
				<Typography variant="h3" style={{ color: "black" }}>
					{selectedBusiness?.Nombre ?? "Empresa"}
				</Typography>
				<Typography variant="h3" style={{ color: "black" }}>
					{selected.project?.Nombre ?? "Proyecto"}
				</Typography>
			</Breadcrumbs>

			<Breadcrumbs>
				<LinkEmpresas />
				<LinkProjectsOT />
				<Typography>
					Desviación por listas de chequeo
				</Typography>
			</Breadcrumbs>

			<Divider
				style={{ marginTop: 10, marginBottom: 10 }}
			/>

			<Tabs
				value={type}
				onChange={(e, v) => setType(v)}
				variant="fullWidth"
			>
				<Tab label="Obra Gruesa" value="Obra Gruesa" />
				<Tab label="Terminaciones" value="Terminaciones" />
			</Tabs>

			<RejectedByGraphOT />
		</>
	);
};
