import {
	Box,
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	Chip,
	Collapse,
	Dialog,
	Divider as MuiDivider,
	Fade,
	Grid,
	Grow,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	ExpandLess,
	ExpandMore,
	KeyboardArrowDown,
	KeyboardArrowUp,
} from "@material-ui/icons";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { FC, useEffect, useMemo, useState } from "react";
import {
	Edit as EditIcon,
	Minus as MinusIcon,
	MinusSquare as X,
	Search,
	X as CloseIcon,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { TableSkeleton } from "../components/TableSkeleton";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import { useTable } from "../hooks/useTable";
import { getBusiness } from "../redux/actions/businessActions";
import { getList } from "../redux/actions/listsActions";
import { getObservations } from "../redux/actions/observationsActions";
import { getPlacesList } from "../redux/actions/placesActions";
import { getOneProject } from "../redux/actions/projectsActions";
import { getUser } from "../redux/actions/usersActions";
import { useStyles } from "../theme/useStyles";
import { conseguirImagenObservacion } from "../services/observaciones-revision";
import {
	useDispatch,
	useSelector,
} from "../redux/store/index";
import {
	timesStampToDateFormatted,
	timesStampToDateFormattedDate,
} from "../utils/utils";
import { getStatusRevision } from "src/utils/checkpro";
import { Observacion } from "../models/Observacion";
import { EditRevisionResponseDialog } from "src/components/Revisiones/Responses/Edit";
import {
	LinkEmpresas,
	LinkListados,
	LinkProyectos,
	LinkRevisiones,
} from "src/components/CheckPro/Links";
import {
	Subcontrato,
	ToggableProyecto,
} from "src/models/Empresa";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface RowsProps {
	data: Observacion;
	itemsOpen: ItemOpen[];
	setItemsOpen: any;
	openDialog: (value: string) => void;
	openEditDialog: (arg: any) => void;
}

const Rows: FC<RowsProps> = ({
	data,
	itemsOpen,
	setItemsOpen,
	openDialog,
	openEditDialog,
}) => {
	const classes = useStyles();

	//abrir dialogo al cliquear imagen
	const handleClickImage = (img?: string) => {
		if (!img) return;
		openDialog(img);
	};
	// lógica de open
	const open: boolean =
		itemsOpen.find((x) => x.id === data.id)?.open ?? false;

	const setOpen = (open: boolean) => {
		// const index = itemsOpen.findIndex((x) => x.id === data.id)
		setItemsOpen((itemsOpen: any) =>
			itemsOpen.map((x: any) => {
				if (x.id === data.id)
					return {
						...x,
						open,
					};
				return x;
			})
		);
	};

	const { status, backgroundColor, color } = useMemo(
		() => getStatusRevision(data),
		[data]
	);

	const [imageData, setImageData] = useState<
		string | undefined
	>();
	// get imagen
	useEffect(() => {
		(async () => {
			setImageData(await conseguirImagenObservacion(data));
		})();
	}, [data]);

	return (
		<>
			<TableRow hover className={classes.styledRow2}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? (
							<KeyboardArrowUp />
						) : (
							<KeyboardArrowDown />
						)}
					</IconButton>
				</TableCell>
				<TableCell>{data?.Numero}</TableCell>
				<TableCell>
					<Chip
						style={{ backgroundColor, color, width: 100 }}
						label={
							status === "Vencida" ? "Por Resolver" : status
						}
					/>
				</TableCell>
				<TableCell>{data.Prioridad}</TableCell>
				<TableCell>
					{timesStampToDateFormatted(data.CreatedAt)}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={10}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Box display="flex" flexWrap="wrap">
								<Box order={1} mt={3.5}>
									<Typography variant="h5" component="div">
										Detalle
									</Typography>
								</Box>
								<Box order={2}>
									<IconButton
										color="secondary"
										onClick={() => openEditDialog(data)}
									>
										<EditIcon />
									</IconButton>
								</Box>
							</Box>

							<Typography
								variant="subtitle1"
								gutterBottom
								component="div"
							>
								{`${data.Recinto} - ${data.Subcontrato}${
									data.ErrorTipo
										? " - " + data.ErrorTipo
										: " - Sin error tipo"
								}`}
							</Typography>

							<Grid container spacing={2}>
								<Grid
									container
									item
									xs={12}
									sm={6}
									justify="center"
									direction="column"
								>
									{[
										{
											title: "Observada",
											value: data.CreatedAt
												? timesStampToDateFormattedDate(
														data.CreatedAt
												  )
												: " - ",
										},
										{
											title: "Por Vencer",
											value: timesStampToDateFormattedDate(
												data.PorVencer
											),
										},
										{
											title: "Por resolver",
											value: timesStampToDateFormattedDate(
												data.Vencida
											),
										},
										{
											title: "Resuelta",
											value: data.Resuelta
												? timesStampToDateFormattedDate(
														data.Resuelta
												  )
												: " - ",
										},
										{
											title: "Sector",
											value: data.Sector,
										},
										{
											title: "Comentarios",
											value: data.Comentario,
										},
									].map((obj) => {
										return (
											<Box
												key={obj.title}
												display="flex"
												flexWrap="wrap"
											>
												<Box
													order={1}
													style={{ width: 125 }}
												>
													<Typography
														component="div"
														variant="body1"
														style={{ fontWeight: "bold" }}
													>
														{obj.title}
													</Typography>
												</Box>
												<Box order={2}>
													<Typography
														component="div"
														variant="body1"
														style={{ fontWeight: "bold" }}
													>
														:
													</Typography>
												</Box>
												<Box order={3}>
													<Typography
														component="div"
														variant="body1"
													>
														&nbsp;{obj.value}
													</Typography>
												</Box>
											</Box>
										);
									})}
								</Grid>
								<Grid
									container
									item
									xs={12}
									sm={6}
									justify="center"
								>
									{/*/// sin imagen*/}
									<Grid item xs={6}>
										<Box
											display="flex"
											justifyContent="flex-start"
										>
											<Grow in={open} timeout={600}>
												<img
													onKeyDown={() => {}}
													src={imageData}
													alt="Imagen Observacion 1"
													height={120}
													onClick={() =>
														imageData &&
														handleClickImage(imageData)
													}
													style={{ cursor: "pointer" }}
												/>
											</Grow>
										</Box>
									</Grid>
									{data.Imagen2 && (
										<Grid item xs={6}>
											<Box
												display="flex"
												justifyContent="flex-start"
											>
												<Grow
													in={open}
													{...(open
														? { timeout: 1000 }
														: {})}
												>
													<img
														onKeyDown={() => {}}
														src={`data:image/png;base64, ${data.Imagen2}`}
														alt="Imagen Observacion 2"
														height={120}
														onClick={() =>
															handleClickImage(data.Imagen2)
														}
														style={{ cursor: "pointer" }}
													/>
												</Grow>
											</Box>
											<Box
												display="flex"
												justifyContent="flex-start"
											>
												<Typography>Imagen 2</Typography>
											</Box>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

interface ItemOpen {
	open: boolean;
	id: string;
}

interface Props {
	array: any[];
	listData: any;
}

const ContentCard: FC<Props> = () => {
	const [valueWidth, setValueWidth] = useState<number>(
		window.innerWidth
	);
	const widthBreakPoint = 1415;
	function updateDimensions() {
		setValueWidth(window.innerWidth);
	}
	window.addEventListener("resize", updateDimensions);
	const classes = useStyles();
	const dispatch = useDispatch();
	const { projectId, revisionId, listId } =
		useParams<any>();

	const { totalDocs, observations, isLoading } =
		useSelector((state) => state.observationsReducer);

	const {
		limit,
		handleLimitChange,
		handlePageChange,
		page,
		handleResetPage,
	} = useTable({
		limit: TABLE_LIMIT_DEFAULT || 5,
		onLimitChange: (e, newLimit) => {
			// if (newLimit > observations.length) {
			// 	if (Object.keys(filtersList).length === 0) {
			// 		dispatch(getObservations(listId));
			// 	} else {
			// 		dispatch(
			// 			searchObservations(
			// 				filtersList,
			// 				projectId,
			// 				revisionId,
			// 				listId,
			// 				newLimit
			// 			)
			// 		);
			// 	}
			// }
		},
		onPageChange: (newPage, oldPage, limit) => {
			// if (
			// 	observations.length < totalDocs &&
			// 	newPage > oldPage
			// ) {
			// 	if (Object.keys(filtersList).length === 0) {
			// 		dispatch(
			// 			getMoreObservations(
			// 				projectId,
			// 				revisionId,
			// 				listId,
			// 				limit
			// 			)
			// 		);
			// 	} else {
			// 		dispatch(
			// 			searchMoreObservations(
			// 				filtersList,
			// 				projectId,
			// 				revisionId,
			// 				listId,
			// 				limit
			// 			)
			// 		);
			// 	}
			// }
		},
	});

	//Array for excel download

	///////////////////////////

	const [itemsOpen, setItemsOpen] = useState<ItemOpen[]>(
		[]
	);

	useEffect(() => {
		setItemsOpen(
			observations.map((x) => ({
				open: false,
				id: x.id!,
			}))
		);
	}, [observations]);

	const [selectedImage, setSelectedImage] = useState<
		string | null
	>(null);

	const [selectedObservation, setSelectedObservation] =
		useState<null | Observacion>(null);

	const [openDialogImage, setOpenDialogImage] =
		useState(false);

	const [openDialogEdit, setOpenDialogEdit] =
		useState(false);

	const [openFilter, setOpenFilter] = useState(false);

	const {
		edit: { selectedBusiness },
	} = useSelector((state) => state.businessReducer);

	const { selectedList } = useSelector(
		(state) => state.listsReducer
	);

	useEffect(() => {
		if (Object.keys(selectedList).length === 0) {
			dispatch(getList(projectId, revisionId, listId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedList, projectId, revisionId, listId]);

	useEffect(() => {
		if (selectedList.Responsable) {
			dispatch(
				getUser(selectedList.Responsable.FirebaseId)
			);
		}
	}, [dispatch, selectedList.Responsable]);

	useEffect(() => {
		dispatch(getPlacesList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/*
  // Filter constants
  */
	const [filtersList, setFiltersList] = useState<any>({});
	const [valueRecinto, setValueRecinto] =
		useState<string>("");
	const [valuePrioridad, setValuePrioridad] =
		useState<ToggableProyecto | null>(null);
	const optionsEstados = [
		"Resuelta",
		"Por Resolver",
		"Por vencer",
		"Observada",
	];
	const [valueEstado, setValueEstado] =
		useState<string>("");
	const [valueSector, setValueSector] =
		useState<string>("");
	const [valueSubcontrato, setValueSubcontrato] =
		useState<Subcontrato | null>(null);
	const [valueErrorTipo, setValueErrorTipo] =
		useState<string>("");
	const [valueCreacionStart, setValueCreacionStart] =
		useState("");
	const [valueCreacionEnd, setValueCreacionEnd] =
		useState("");

	const [showObservations, setShowObservations] =
		useState<boolean>(true);
	/*
  //End filter constants
  */

	const filtered = useMemo(() => {
		let filtered = [...observations];

		if (valueRecinto)
			filtered = filtered.filter(
				(x) => x.Recinto === valueRecinto
			);

		console.log(valuePrioridad);
		console.log(filtered);
		if (valuePrioridad)
			filtered = filtered.filter(
				(x) => x.Prioridad === valuePrioridad.Nombre
			);
		if (valueEstado)
			filtered = filtered.filter(
				(x) => x.Estado === valueEstado
			);
		if (valueSector)
			filtered = filtered.filter(
				(x) => x.Sector === valueSector
			);
		if (valueSubcontrato)
			filtered = filtered.filter(
				(x) => x.Subcontrato === valueSubcontrato.Nombre
			);
		if (valueErrorTipo)
			filtered = filtered.filter(
				(x) => x.ErrorTipo === valueErrorTipo
			);
		if (valueCreacionStart)
			filtered = filtered.filter(
				(x) =>
					x.CreatedAt &&
					x.CreatedAt.toDate().getMilliseconds() >=
						new Date(valueCreacionStart).getTime()
			);
		if (valueCreacionEnd)
			filtered = filtered.filter(
				(x) =>
					x.CreatedAt &&
					x.CreatedAt.toDate().getMilliseconds() <=
						new Date(valueCreacionEnd).getTime()
			);

		return filtered;
	}, [
		observations,
		valueRecinto,
		valuePrioridad,
		valueEstado,
		valueSector,
		valueSubcontrato,
		valueErrorTipo,
		valueCreacionStart,
		valueCreacionEnd,
	]);
	// const handleFilterChange = () => {
	// 	handleResetPage();
	// 	if (Object.keys(filtersList).length !== 0) {
	// 		dispatch(
	// 			searchObservations(
	// 				filtersList,
	// 				projectId,
	// 				revisionId,
	// 				listId
	// 			)
	// 		);
	// 	}
	// };

	useEffect(() => {
		if (Object.keys(filtersList).length === 0) {
			handleResetPage();
			dispatch(getObservations(listId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersList, projectId, revisionId, listId]);

	return (
		<Card mb={6}>
			{/*
      // Start Filters
      */}
			<CardContent>
				<Grid container justify="space-between">
					<Box mb={2}>
						<Button
							size="large"
							onClick={() => setOpenFilter(!openFilter)}
						>
							Filtros{" "}
							{openFilter ? <ExpandLess /> : <ExpandMore />}
						</Button>
					</Box>
				</Grid>
				<Collapse
					in={openFilter}
					timeout="auto"
					unmountOnExit
				>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={2}>
							<Autocomplete
								id="sectores-filtro"
								value={valueSector}
								options={selectedBusiness?.Sectores ?? []}
								getOptionLabel={(option) => option.Nombre}
								filterOptions={() =>
									selectedBusiness?.Sectores.filter(
										(x: any) => x.Activado
									) ?? []
								}
								getOptionDisabled={(option: any) =>
									!option.Activado
								}
								fullWidth
								onChange={(_, value) =>
									setValueSector(value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Todos sectores"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Autocomplete
								id="recintos-filtro"
								value={valueRecinto}
								options={selectedBusiness?.Recintos ?? []}
								getOptionLabel={(option: any) =>
									option.Nombre
								}
								filterOptions={() =>
									selectedBusiness?.Recintos.filter(
										(x: any) => x.Activado
									) ?? []
								}
								getOptionDisabled={(option: any) =>
									!option.Activado
								}
								fullWidth
								onChange={(_, value) =>
									setValueRecinto(value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Todos recintos"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Autocomplete
								id="subcontratos-filtro"
								value={valueSubcontrato}
								options={
									selectedBusiness?.Subcontratos ?? []
								}
								getOptionLabel={(option) => option.Nombre}
								filterOptions={() =>
									selectedBusiness?.Subcontratos.filter(
										(x: any) => x.Activado
									) ?? []
								}
								getOptionDisabled={(option) =>
									!option.Activado
								}
								fullWidth
								onChange={(_, value) =>
									setValueSubcontrato(value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Todos subcontrato"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Autocomplete
								id="errotipo-filtro"
								value={valueErrorTipo}
								options={
									valueSubcontrato?.ErroresTipo ?? []
								}
								getOptionLabel={(option: any) =>
									option.Nombre
								}
								fullWidth
								onChange={(_, value) =>
									setValueErrorTipo(value ?? "")
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Todos error Tipo"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Autocomplete
								id="prioridad-filtro"
								value={valuePrioridad}
								options={
									selectedBusiness?.Prioridades ?? []
								}
								getOptionLabel={(option) => option.Nombre}
								filterOptions={() =>
									selectedBusiness?.Prioridades.filter(
										(x: any) => x.Activado
									) ?? []
								}
								getOptionDisabled={(option: any) =>
									!option.Activado
								}
								fullWidth
								onChange={(_, value) =>
									setValuePrioridad(value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Todos prioridades"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Autocomplete
								id="estado-filtro"
								value={valueEstado}
								options={optionsEstados}
								fullWidth
								getOptionLabel={(option) => {
									return option === "Vencida"
										? "Por Resolver"
										: option;
								}}
								onChange={(_, value) =>
									setValueEstado(value ?? "")
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Todos estados z"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid
							item
							xs={12}
							sm={valueWidth > widthBreakPoint ? 2 : 3}
						>
							<Box mt={2}>
								<TextField
									id="creacion-start"
									label="Fecha Creación: Inicio"
									type="datetime-local"
									value={valueCreacionStart}
									defaultValue={valueCreacionStart}
									onChange={(event) => {
										setValueCreacionStart(
											event.target.value
										);
										filtersList["dateStart"] =
											event.target.value;
									}}
									style={{ width: 170 }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={valueWidth > widthBreakPoint ? 2 : 3}
						>
							<Box display="flex">
								<Box mt={2} order={1}>
									<TextField
										id="creacion-end"
										label="Fecha Creación: Final"
										type="datetime-local"
										value={valueCreacionEnd}
										defaultValue={valueCreacionEnd}
										onChange={(event) => {
											setValueCreacionEnd(
												event.target.value
											);
											filtersList["dateEnd"] =
												event.target.value;
										}}
										style={{ width: 170 }}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Box>
								<Box mt={5} order={2}>
									<IconButton
										size="small"
										aria-label="Filtrar"
										onClick={() => {
											if (
												valueCreacionStart !== "" &&
												valueCreacionEnd !== ""
											) {
												// dispatch(
												// 	searchObservations(
												// 		filtersList,
												// 		projectId,
												// 		revisionId,
												// 		listId
												// 	)
												// );
											}
										}}
									>
										<Search />
									</IconButton>
								</Box>
								<Box mt={5} order={3}>
									<IconButton
										size="small"
										aria-label="Borrar filtro"
										onClick={() => {
											setFiltersList({});
											dispatch(getObservations(listId));
											setValueCreacionStart("");
											setValueCreacionEnd("");
											setValueRecinto("");
											setValuePrioridad(null);
											setValueEstado("");
											setValueSector("");
											setValueSubcontrato(null);
											setValueErrorTipo("");
										}}
									>
										<X />
									</IconButton>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={2}></Grid>
					</Grid>
				</Collapse>
			</CardContent>
			{/*
      // End Filters
      */}
			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>
									<IconButton
										size="small"
										onClick={() => {
											setShowObservations(
												(showObservations) =>
													!showObservations
											);
											setItemsOpen((items) =>
												items.map((i) => ({
													...i,
													open: showObservations,
												}))
											);
										}}
									>
										<MinusIcon size={20} />
									</IconButton>
								</TableCell>
								<TableCell>N°</TableCell>
								<TableCell>Estado</TableCell>
								<TableCell>Prioridad</TableCell>
								<TableCell>Fecha de Creación</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!isLoading ? (
								filtered
									.slice(page * limit, page * limit + limit)
									.map((data: any, i: any) => (
										<Fade key={i} in={true}>
											<Rows
												key={data.id}
												data={data}
												itemsOpen={itemsOpen}
												setItemsOpen={setItemsOpen}
												openDialog={(selected) => {
													setSelectedImage(selected);
													setOpenDialogImage(true);
												}}
												openEditDialog={(selected) => {
													setSelectedObservation(selected);
													setOpenDialogEdit(true);
												}}
											/>
										</Fade>
									))
							) : (
								<TableSkeleton colSpan={5} limit={limit} />
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={totalDocs}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
			</CardContent>

			{/* diálogo imagen */}
			<Dialog
				open={openDialogImage}
				onClose={() => setOpenDialogImage(false)}
			>
				<IconButton
					color="primary"
					size="small"
					style={{
						position: "absolute",
						right: 0,
						background: "white",
						margin: 5,
					}}
					onClick={() => setOpenDialogImage(false)}
				>
					<CloseIcon />
				</IconButton>

				<img
					src={selectedImage ?? ""}
					alt="Imagen observada"
				/>
			</Dialog>

			<EditRevisionResponseDialog
				open={openDialogEdit}
				selected={selectedObservation}
				onClose={() => setOpenDialogEdit(false)}
			/>
		</Card>
	);
};

export const ListadosScreen = () => {
	const { businessId, projectId, revisionId, listId } =
		useParams<any>();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const isFromBusiness = pathname.startsWith(
		"/administrador/empresas/"
	);

	const { observations } = useSelector(
		(state) => state.observationsReducer
	);

	const {
		edit: { selectedBusiness },
	} = useSelector((state) => state.businessReducer);

	const {
		revisions: { selectedProject },
	} = useSelector((state) => state.projectsReducer);

	const { selectedList } = useSelector(
		(state) => state.listsReducer
	);

	const { selectedRevision } = useSelector(
		(state) => state.revisionsReducer
	);

	useEffect(() => {
		if (!observations) {
			dispatch(getObservations(listId));
		}
		if (isFromBusiness && !selectedBusiness) {
			dispatch(getBusiness(businessId));
		}
		if (selectedProject === null) {
			dispatch(getOneProject(projectId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		observations,
		businessId,
		projectId,
		revisionId,
		selectedBusiness,
		isFromBusiness,
		listId,
		selectedProject,
	]);

	return (
		<>
			<Helmet title="Observaciones" />
			<Grid justify="space-between" container>
				<Grid item>
					{!selectedList ? (
						<Skeleton variant="text" />
					) : (
						<Box>
							<Typography
								variant="h3"
								gutterBottom
								display="inline"
							>
								{/** @ts-ignore */}
								{`${selectedBusiness?.Nombre} / ${selectedProject?.NombreProyecto} / ${selectedRevision?.Nombre} / ${selectedList?.Nombre}`}
							</Typography>
						</Box>
					)}

					<Breadcrumbs aria-label="Breadcrumb" mt={2}>
						<LinkEmpresas />
						<LinkProyectos />
						<LinkRevisiones />
						<LinkListados />
						<Typography>Observaciones</Typography>
					</Breadcrumbs>
				</Grid>
			</Grid>
			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard
						array={observations}
						listData={selectedList}
					/>
				</Grid>
			</Grid>
		</>
	);
};
