/// @ts-nocheck

import { Link } from "@material-ui/core";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useCheckproLinks } from "./hooks/useCheckproLinks";

export const LinkProyectos = () => {
	const { isFromBusiness, auth, isCheckList, idEmpresa } =
		useCheckproLinks();

	const nextLink = useMemo(() => {
		const tipoUsuario = auth.user?.tipoUsuario;

		if (isCheckList) {
			if (tipoUsuario === "Administrador")
				return `/administrador/empresas/${idEmpresa}/checklist-proyectos`;
			else if (tipoUsuario === "AdministradorEmpresa")
				return `/administrador/checklist-proyectos`;
			else return `/administrador/checklist-proyectos`;
		} else {
			if (tipoUsuario === "Administrador")
				return `/administrador/empresas/${idEmpresa}/proyectos`;
			else if (tipoUsuario === "AdministradorEmpresa")
				return `/administrador/empresa/proyectos`;
			else return `/administrador/user/proyectos`;
		}
	}, [auth.user?.tipoUsuario, idEmpresa, isCheckList]);

	return (
		<Link component={NavLink} exact to={nextLink}>
			Proyectos (
			{isCheckList ? "Checklist Revisiones" : "Revisiones"})
		</Link>
	);
};
