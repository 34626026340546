import React, { FC, useEffect, useState } from "react";
import {
	NavLink,
	useLocation,
	useParams,
} from "react-router-dom";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
	Avatar,
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	Divider as MuiDivider,
	Fade,
	Grid,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { format, isAfter, isBefore } from "date-fns";
import { Filters, RangeDate } from "./Filters";
import { TABLE_LIMITS } from "../../constants";
import { useTable } from "../../hooks/useTable";
import {
	generateExcel,
	getObservations,
} from "../../redux/actions/observacionesActions";
import { ObservacionChecklistState } from "../../redux/reducers/observacionesChecklistReducer";
import { RootState } from "../../redux/store/reducer";
import { useStyles } from "../../theme/useStyles";
import { HelperIcon } from "./HelperIcon";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useDispatch, useSelector } from "src/redux/store";
import {
	LinkEmpresas,
	LinkProyectos,
} from "../CheckPro/Links";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type ContentCardProps = {
	openImgModal: (url: string) => void;
	closeImgModal: VoidFunction;
};

const ContentCard: FC<ContentCardProps> = ({
	closeImgModal,
	openImgModal,
}) => {
	const location = useLocation();
	const classes = useStyles();
	const { observations, getExcel } = useSelector(
		(state) => state.observacionesChecklistReducer
	);
	const { projectId } = useParams() as any;
	const dispatch = useDispatch();

	useEffect(() => {
		projectId && dispatch(getObservations(projectId));
	}, [projectId]);

	const {
		limit,
		page,
		handleLimitChange,
		handlePageChange,
	} = useTable({
		limit: 20,
	});
	const [filterData, setFilterData] = useState({});

	const filteredObservations = observations.filter(
		(obs) => {
			const arrayOfTests: [string, string | RangeDate][] =
				Object.entries(filterData);
			if (arrayOfTests.length === 0) return true;

			return arrayOfTests
				.map((arrayOfTestsEntry) => {
					const key = arrayOfTestsEntry[0];
					const value = arrayOfTestsEntry[1];
					if (typeof value === "string") {
						return (obs as any)[key] === value;
					} else {
						return (
							isAfter(
								obs.FechaCreacion.toDate(),
								value[0]
							) &&
							isBefore(obs.FechaCreacion.toDate(), value[1])
						);
					}
				})
				.every((e) => e);
		}
	);

	const handleGenerateExcel = () => {
		dispatch(
			generateExcel(
				(location.state as any).NombreProyecto,
				filteredObservations
			)
		);
	};
	return (
		<Card mb={6}>
			<Filters
				observations={observations}
				onChangeFilter={(value, filterName) => {
					const actualFilters: any = { ...filterData };
					if (value === "todo") {
						delete actualFilters[filterName];
					} else {
						actualFilters[filterName] = value;
					}
					setFilterData(actualFilters);
				}}
				onClickGenerateExcel={handleGenerateExcel}
				isLoadingExcel={getExcel.state === "IsSubmitting"}
			/>
			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Revision</TableCell>
								<TableCell>Listado</TableCell>
								<TableCell>Recinto</TableCell>
								<TableCell>Subcontrato</TableCell>
								<TableCell>Error Tipo</TableCell>
								<TableCell>Orden</TableCell>
								<TableCell>Estado</TableCell>
								<TableCell>Comentario</TableCell>
								<TableCell>Foto</TableCell>
								<TableCell>Observada</TableCell>
								<TableCell>Resuelta</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredObservations
								.slice(page * limit, page * limit + limit)
								.map((arr, i) => (
									<Fade key={`obs-${i}`} in={true}>
										<TableRow
											hover
											className={classes.styledRow}
										>
											<TableCell>
												{arr.NombreRevision}
											</TableCell>
											<TableCell>
												{arr.NombreListado}
											</TableCell>
											<TableCell
												style={{ whiteSpace: "nowrap" }}
											>
												{arr.NombreRecinto}
											</TableCell>
											<TableCell>
												{arr.NombreSubcontrato}
											</TableCell>
											<TableCell>
												{arr.NombreErrorTipo}
											</TableCell>
											<TableCell>
												{arr.Order || "-"}
											</TableCell>
											<TableCell
												style={{ whiteSpace: "nowrap" }}
											>
												{arr.Estado ===
												"Criterios Propietario"
													? "Criterio Propietario"
													: arr.Estado}
											</TableCell>
											<TableCell
												align="center"
												style={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													maxWidth: 150,
												}}
											>
												<HelperIcon
													title={arr.Comentario}
												/>
											</TableCell>
											<TableCell>
												<Avatar
													style={{ cursor: "pointer" }}
													src={arr.Imagen}
													onClick={() =>
														openImgModal(arr.Imagen)
													}
												/>
											</TableCell>
											<TableCell>
												{format(
													arr.FechaCreacion.toDate(),
													"dd/MM/yyyy"
												)}
											</TableCell>
											<TableCell>
												{arr.FechaResuelta
													? format(
															arr.FechaResuelta.toDate(),
															"dd/MM/yyyy"
													  )
													: "-"}
											</TableCell>
										</TableRow>
									</Fade>
								))}

							{false ? (
								<Fade in={true}>
									<TableRow>
										<TableCell align={"center"} colSpan={3}>
											<img
												alt="Nada imagen"
												src="/static/no_data.svg"
												height="100"
											/>
											<Typography variant="h5">
												Nada Aun
											</Typography>
										</TableCell>
									</TableRow>
								</Fade>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={filteredObservations.length || 0}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
			</CardContent>
		</Card>
	);
};

export const ObservacionesSummary = () => {
	const [imgModal, setImgModal] = useState({
		open: false,
		url: "",
	});
	const openImgModal = (url: string) =>
		setImgModal({ open: true, url });
	const closeImgModal = () =>
		setImgModal((d) => ({ ...d, open: false }));

	const { selectedBusiness } = useBusiness();

	return (
		<React.Fragment>
			<Helmet title="Observaciones" />
			<Typography
				variant="h3"
				gutterBottom
				display="inline"
			>
				Observaciones
			</Typography>

			<Breadcrumbs aria-label="Breadcrumb" mt={2}>
				<LinkEmpresas />
				<LinkProyectos />
				<Typography>Observaciones</Typography>
			</Breadcrumbs>

			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard
						{...{ openImgModal, closeImgModal }}
					/>
				</Grid>
			</Grid>

			<Dialog open={imgModal.open} onClose={closeImgModal}>
				<DialogContent>
					<img
						src={imgModal.url}
						style={{ width: "100%" }}
						alt="Imágen de la observación."
					/>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={closeImgModal}>
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};
