import { Box, Typography } from "@material-ui/core";
import { FC } from "react";
import { HomeWork, PeopleAlt } from "@material-ui/icons";

interface Props {
	activated: boolean;
	limitUser: number;
	usedUser: number;
	usedType: number;
	limitType: number;
}

export const BusinessModuleCell: FC<Props> = ({
	activated,
	limitUser,
	usedUser,
	usedType,
	limitType,
}) => {
	return (
		<>
			<Box
				style={{
					borderRadius: 6,
					padding: "5px 8px",
				}}
			>
				{activated ? (
					<>
						<div
							style={{
								height: 17,

								marginTop: -20,
							}}
						>
							<Box display="flex" ml={10}>
								<Box order={1} mr={1}>
									<HomeWork
										fontSize="small"
										color="primary"
									/>
								</Box>
								<Box order={2}>
									<Typography
										component="div"
										align="center"
										variant="caption"
										style={{ whiteSpace: "nowrap" }}
									>
										{`${usedType} / ${limitType}`}
									</Typography>
								</Box>
							</Box>
							<Box display="flex" ml={10}>
								<Box order={3} mr={1}>
									<PeopleAlt
										fontSize="small"
										color="primary"
									/>
								</Box>
								<Box order={4}>
									<Typography
										component="div"
										align="center"
										variant="caption"
										style={{ whiteSpace: "nowrap" }}
									>
										{`${usedUser} / ${limitUser}`}
									</Typography>
								</Box>
							</Box>
						</div>
					</>
				) : (
					<Typography
						component="div"
						align="center"
						variant="caption"
						style={{ fontWeight: "bold" }}
					>
						Desactivado
					</Typography>
				)}
			</Box>
		</>
	);
};
