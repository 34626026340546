import {
	ReactNode,
	FC,
	useState,
	useMemo,
	Children,
	useCallback,
} from "react";
import {
	Box,
	BoxProps,
	Collapse,
	Tab,
	TabProps,
	Tabs,
	TabsProps,
} from "@material-ui/core";

type TabGroupProps = {
	tabs: (string | ReactNode)[];
	tabProps?: (index: number) => TabProps;
	display?: BoxProps;
	disabled?: boolean | boolean[];
	hidden?: boolean[];
	defaultSelected?: number;
} & Omit<TabsProps, "disabled" | "hidden">;

export const TabGroup: FC<TabGroupProps> = ({
	children,
	tabProps,
	tabs: tabList,
	onChange,
	value,
	display,
	disabled,
	hidden,
	defaultSelected,
	...tabs
}) => {
	const [selected, setSelected] = useState(
		defaultSelected ?? 0
	);

	const handleChange = (ev: any, value: number) => {
		onChange?.(ev, value);
		setSelected(value);
	};

	const child = useMemo(
		() => Children.toArray(children),
		[children]
	);

	const isDisabled = useCallback(
		(index: number) => {
			if (Array.isArray(disabled))
				return disabled[index ?? 0];
			else if (typeof disabled === "boolean")
				return disabled;
			else return false;
		},
		[disabled]
	);

	const isHidden = useCallback(
		(index: number) => {
			if (Array.isArray(hidden)) return !!hidden[index];
			else if (typeof hidden === "boolean") return hidden;
			else return false;
		},
		[hidden]
	);

	return (
		<>
			<Tabs
				textColor="primary"
				scrollButtons="on"
				variant="fullWidth"
				{...tabs}
				value={value ?? selected}
				onChange={handleChange}
			>
				{tabList.map(
					(tab, i) =>
						!isHidden(i) && (
							<Tab
								disabled={isDisabled(i)}
								label={tab}
								{...tabProps?.(i)}
								value={i}
								key={`tab-${i}`}
							/>
						)
				)}
			</Tabs>

			{/* display */}
			{!isHidden(selected) && (
				<Collapse in={selected > -1}>
					<Box {...display}>{child[selected]}</Box>
				</Collapse>
			)}
		</>
	);
};
