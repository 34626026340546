import {
	Breadcrumbs,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { FC, useMemo } from "react";
import * as Yup from "yup";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { cleanString } from "src/utils/utils";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { ChecklistOT } from "src/models/OT/Checklist";

type Props = {
	baseChecklist?: ChecklistOT | null;
} & DialogProps;

export const NeoChecklistsAssignDialog: FC<Props> = ({
	baseChecklist,
	fullScreen,
	...props
}) => {
	const { create, selected } = useProjectsOT();

	const { selectedBusiness } = useBusiness();

	const {
		values,
		touched,
		handleSubmit,
		dirty,
		errors,
		isSubmitting,
		setFieldValue,
	} = useFormik({
		enableReinitialize: true,
		initialValues: {
			MetaRevision: 0,
			Unidad: "",
		},
		validationSchema: Yup.object({
			MetaRevision: Yup.number()
				.min(1, "Mínimo 1%")
				.max(100, "Máximo 100%")
				.required("Este dato es importante."),
			Unidad: Yup.string().required(
				"Este dato es importante."
			),
		}),
		onSubmit: async (
			{ MetaRevision, Unidad },
			{ resetForm }
		) => {
			if (!baseChecklist) return;

			const data: Pick<
				ChecklistOT,
				"MetaRevision" | "Unidad"
			> = {
				MetaRevision,
				Unidad,
			};

			await create.project.checklist(baseChecklist, data);

			resetForm();
			props.onClose?.({}, "escapeKeyDown");
		},
	});

	const sortedUnits = useMemo(() => {
		if (!selectedBusiness?.UnidadesOT) return [];
		return selectedBusiness.UnidadesOT.sort((a, b) =>
			cleanString(a).localeCompare(cleanString(b))
		);
	}, [selectedBusiness]);

	return (
		<Dialog {...props} fullScreen={fullScreen}>
			<DialogTitle
				bigText={"Asignar lista de chequeo"}
				smallText={
					<Breadcrumbs>
						<Typography>
							{selected.project?.Nombre}
						</Typography>
						<Typography>{baseChecklist?.Nombre}</Typography>
					</Breadcrumbs>
				}
			/>

			<DialogContent
				style={{
					display: "flex",
					gap: 10,
					flexDirection: "column",
					height: fullScreen ? "100%" : "auto",
				}}
			>
				<TextField
					type="number"
					inputProps={{
						min: 1,
						max: 100,
					}}
					label="Meta de revisión"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								%
							</InputAdornment>
						),
					}}
					variant="outlined"
					id="MetaRevision"
					size="small"
					value={values.MetaRevision}
					onChange={(ev) =>
						setFieldValue("MetaRevision", +ev.target.value)
					}
					error={
						touched.MetaRevision && !!errors.MetaRevision
					}
					helperText={
						touched.MetaRevision && errors.MetaRevision
					}
				/>

				<FormControl variant="outlined" size="small">
					<InputLabel>Unidad</InputLabel>
					<Select
						label="Unidad"
						value={values.Unidad}
						id="Unidad"
						onChange={(ev) =>
							setFieldValue("Unidad", ev.target.value)
						}
					>
						{sortedUnits.map((u) => (
							<MenuItem value={u} key={u}>
								{u}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>

			<DialogActions>
				<Button
					disabled={isSubmitting}
					onClick={() =>
						props.onClose?.({}, "escapeKeyDown")
					}
				>
					Cancelar
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => handleSubmit()}
					disabled={!dirty || isSubmitting}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
