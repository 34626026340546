import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import * as Yup from "yup";
import { NeoPlaceItem } from "../PlacesOT/Item";
import { PlacesToolbar } from "../Misc/TableToolbar";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { firestore } from "src/firebase/firebase";
import { WithRef } from "src/utils/firebase";
import { useDispatch, useSelector } from "src/redux/store";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useBusiness } from "src/hooks/redux/useBusiness";
import {
	PROJECT_OT_EDIFICIOS,
	ChecklistProject,
} from "src/models/OT/Projects";
import { PROJECT_OT_CASAS } from "../../../models/OT/Projects";
import { getUsers } from "src/redux/actions/usersActions";

export const NeoProjectDialog: FC<DialogProps> = ({
	fullScreen,
	...props
}) => {
	const dispatch = useDispatch();
	const {
		updateProject,
		createProject,
		selected: { project: selectedProject },
	} = useProjectsOT();

	const { selectedBusiness } = useBusiness();

	const {
		values,
		touched,
		handleSubmit,
		handleChange,
		dirty,
		errors,
		isSubmitting,
		setFieldValue,
		resetForm,
	} = useFormik({
		enableReinitialize: true,
		initialValues: {
			nombre: selectedProject?.Nombre ?? "",
			responsable: selectedProject?.Responsable.id ?? "",
			tipo: selectedProject?.Tipo ?? "Edificios",
			espacios: (selectedProject?.Espacios ??
				PROJECT_OT_EDIFICIOS) as { [key: string]: number },
		},
		validationSchema: Yup.object({
			nombre: Yup.string().required("Campo requerido"),
			responsable: Yup.string().required("Campo requerido"),
			tipo: Yup.string().oneOf(
				["Edificios", "Casas", "Otro"],
				"Selección inválida"
			),
		}),
		onSubmit: async (values, { resetForm }) => {
			const responsibleRef = firestore.doc(
				`Usuarios/${
					users?.find((u) => u.id === values.responsable)
						?.id
				}`
			);

			if (selectedProject) {
				const data: WithRef<Partial<ChecklistProject>> = {
					_ref: selectedProject._ref!,
				};
				if (values.nombre) data.Nombre = values.nombre;
				if (values.responsable)
					data.Responsable = responsibleRef;

				await updateProject(data);
			} else
				await createProject({
					Nombre: values.nombre,
					Responsable: responsibleRef,
					Espacios: values.espacios,
					Responsables: [],
					Tipo: values.tipo,
				});

			resetForm();
			props.onClose?.({}, "escapeKeyDown");
		},
	});

	const { users } = useSelector((s) => s.usersReducer);

	/**
	 * Setter de los espacios del proyecto
	 */
	useEffect(() => {
		if (selectedProject) return;
		setFieldValue(
			"espacios",
			(() => {
				switch (values.tipo) {
					case "Edificios":
						return { ...PROJECT_OT_EDIFICIOS };
					case "Casas":
						return { ...PROJECT_OT_CASAS };
					default:
						return {};
				}
			})()
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.tipo]);

	const addPlace = async (name: string) => {
		if (values.espacios[name]) return;

		const espacios = {
			...values.espacios,
			[name]: 0,
		};
		setFieldValue("espacios", espacios);
	};

	const removePlace = async (name: string) => {
		const places = { ...values.espacios };
		delete places[name];
		setFieldValue("espacios", places);
	};

	const renamePlace = (
		oldName: string,
		newName: string
	) => {
		if (oldName === newName) return;
		const places = { ...values.espacios };
		places[newName] = places[oldName];
		delete places[oldName];
		setFieldValue("espacios", places);
	};

	const setPlaceValue = (name: string, value: number) => {
		const places = { ...values.espacios };
		places[name] = value;
		setFieldValue("espacios", places);
	};

	useEffect(() => {
		if (!props.open) return;
		dispatch(getUsers());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open]);

	return (
		<Dialog {...props} fullScreen={fullScreen}>
			<DialogTitle
				bigText={
					selectedProject
						? "Editar proyecto"
						: "Crear proyecto"
				}
				smallText={selectedBusiness?.Nombre}
			/>

			<DialogContent
				style={{
					display: "flex",
					gap: 10,
					flexDirection: "column",
					height: fullScreen ? "100%" : "auto",
				}}
			>
				<TextField
					label="Nombre del proyecto"
					variant="outlined"
					id="nombre"
					size="small"
					value={values.nombre}
					onChange={handleChange}
					error={touched.nombre && !!errors.nombre}
					helperText={touched.nombre && errors.nombre}
				/>

				<Autocomplete
					onEmptied={() => setFieldValue("responsable", "")}
					options={
						users ? ["", ...users.map((u) => u.id)] : []
					}
					value={values.responsable}
					getOptionLabel={(id) => {
						const found = users?.find((u) => u.id === id);

						return `${found?.Nombre ?? ""} ${
							found?.Apellido ?? ""
						}`;
					}}
					onChange={(e, value) =>
						setFieldValue("responsable", value ?? "")
					}
					renderInput={(props) => (
						<TextField
							{...props}
							size="small"
							variant="outlined"
							label="Responsable"
							error={
								touched.responsable && !!errors.responsable
							}
							helperText={
								touched.responsable && errors.responsable
							}
						/>
					)}
				/>

				{!selectedProject && (
					<>
						<FormControl variant="outlined" size="small">
							<InputLabel>Tipo de proyecto</InputLabel>
							<Select
								disabled={!!selectedProject}
								label="Tipo de proyecto"
								value={values.tipo}
								onChange={(ev) =>
									setFieldValue("tipo", ev.target.value)
								}
								error={touched.tipo && !!errors.tipo}
							>
								<MenuItem value="Edificios">
									Edificios
								</MenuItem>
								<MenuItem value="Casas">Casas</MenuItem>
								<MenuItem value="Otro">Otro</MenuItem>
							</Select>
						</FormControl>

						<Paper
							elevation={6}
							style={{
								width: "100%",
								minWidth: 500,
								height: fullScreen ? "100%" : 300,
								overflow: "auto",
							}}
						>
							<Box width={50} marginLeft={3.6}>
								<Typography
									variant="caption"
									style={{ width: 50 }}
								>
									Espacios
								</Typography>
							</Box>

							<Collapse
								in={
									values.tipo === "Otro" ||
									!!selectedProject
								}
							>
								<PlacesToolbar
									onSubmit={addPlace}
									style={{ flexGrow: 1 }}
									containerProps={{
										style: {
											width: "100%",
											display: "flex",
											gap: 5,
											paddingLeft: 5,
											paddingRight: 5,
											paddingBottom: 5,
										},
									}}
								/>
							</Collapse>

							<Divider />

							{Object.entries(values.espacios)
								.sort(([nameA], [nameB]) =>
									nameA.localeCompare(nameB)
								)
								.map(([name, value]) => (
									<NeoPlaceItem
										nameEditable={values.tipo === "Otro"}
										deletable={values.tipo === "Otro"}
										onValueChange={(_, value) =>
											setPlaceValue(name, value)
										}
										key={name}
										placeName={name}
										quantity={value}
										onDelete={removePlace}
										onNameChange={(newName) =>
											renamePlace(name, newName)
										}
									/>
								))}
						</Paper>
					</>
				)}
			</DialogContent>

			<DialogActions>
				<Button
					disabled={isSubmitting}
					onClick={() => {
						resetForm();
						props.onClose?.({}, "escapeKeyDown");
					}}
				>
					Cancelar
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => handleSubmit()}
					disabled={!dirty || isSubmitting}
				>
					{isSubmitting ? (
						<CircularProgress size={24} />
					) : (
						"Guardar"
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
