import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { FC, useEffect, useMemo } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useCheckproLinks } from "src/components/CheckPro/Links/hooks/useCheckproLinks";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { Observacion } from "src/models/Observacion";
import { updateObservation } from "src/redux/actions/observationsActions";
import { useDispatch } from "src/redux/store";
import * as Yup from "yup";

type Props = {
	selected: Observacion | null;
} & DialogProps;

export const EditRevisionResponseDialog: FC<Props> = ({
	selected,
	...dialog
}) => {
	const { selectedBusiness } = useBusiness();
	const dispatch = useDispatch();

	const { idProyecto, idRevision, idListado } =
		useCheckproLinks();

	console.log(selected);

	const {
		id = "",
		Subcontrato = "",
		Sector = "",
		ErrorTipo = "",
		Comentario = "",
		Recinto = "",
		Prioridad = "",
	} = selected ?? {};

	const {
		handleSubmit,
		values,
		handleChange,
		touched,
		errors,
		setFieldValue,
	} = useFormik({
		enableReinitialize: true,
		initialValues: {
			id,
			subcontrato: Subcontrato,
			sector: Sector,
			errorTipo: ErrorTipo,
			comentario: Comentario,
			recinto: Recinto,
			prioridad: Prioridad,
		},
		onSubmit: (values) => {
			dispatch(
				updateObservation(
					values,
					idProyecto!,
					idRevision!,
					idListado!
				)
			);

			dialog.onClose?.({}, "backdropClick");
		},
		validationSchema: Yup.object({
			sector: Yup.string().required("Sector es requerido"),
		}),
	});

	const subcontracts = useMemo(() => {
		const businessSubcontracts =
			selectedBusiness?.Subcontratos ?? [];

		const allSubcontracts = [...businessSubcontracts];
		// remove repeated
		const uniqueSubcontracts = allSubcontracts.filter(
			(s, i, a) =>
				a.findIndex((t) => t.Nombre === s.Nombre) === i
		);

		return uniqueSubcontracts;
	}, [selectedBusiness]);

	const typeErrors = useMemo(() => {
		const found = subcontracts.find(
			(s) => s.Nombre === values.subcontrato
		);

		return found?.ErroresTipo.map((e) => e.Nombre) ?? [];
	}, [subcontracts, values.subcontrato]);

	const sectors = useMemo(() => {
		const businessSectors =
			selectedBusiness?.Sectores ?? [];

		const allSectors = [...businessSectors].map(
			(s) => s.Nombre
		);
		const sectorSet = new Set(allSectors);

		return [...sectorSet];
	}, [selectedBusiness]);

	const priorities = useMemo(() => {
		const businessPriorities =
			selectedBusiness?.Prioridades ?? [];

		const prioritySet = new Set(
			businessPriorities.map((p) => p.Nombre)
		);

		return [...prioritySet];
	}, [selectedBusiness]);

	const precincts = useMemo(() => {
		const businessPrecincts =
			selectedBusiness?.Recintos ?? [];
		const allPrecincts = [...businessPrecincts].map(
			(p) => p.Nombre
		);
		const precinctSet = new Set(allPrecincts);

		return [...precinctSet];
	}, [selectedBusiness]);

	useEffect(() => {
		setFieldValue("errorTipo", "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.subcontrato]);

	return (
		<Dialog {...dialog}>
			<form onSubmit={handleSubmit}>
				<DialogTitle
					bigText="Editar respuesta"
					onClose={dialog.onClose}
				/>

				<DialogContent
					style={{
						display: "grid",
						gap: 20,
						gridTemplateColumns: "1fr 1fr",
						width: 500,
					}}
				>
					<Autocomplete
						value={values.sector}
						options={sectors}
						onChange={(_, value) => {
							setFieldValue("sector", value ?? "");
						}}
						fullWidth
						renderInput={(params) => (
							<TextField
								{...params}
								error={touched.sector && !!errors.sector}
								helperText={touched.sector && errors.sector}
								label="Sectores"
								variant="outlined"
							/>
						)}
					/>

					<Autocomplete
						value={values.prioridad}
						options={priorities}
						onChange={(_, value) => {
							setFieldValue("prioridad", value ?? "");
						}}
						fullWidth
						renderInput={(params) => (
							<TextField
								{...params}
								error={touched.sector && !!errors.sector}
								helperText={touched.sector && errors.sector}
								label="Prioridad"
								variant="outlined"
							/>
						)}
					/>

					<Autocomplete
						id="recinto"
						value={values.recinto}
						options={precincts}
						onChange={(_, value) => {
							setFieldValue("recinto", value ?? "");
						}}
						fullWidth
						renderInput={(params) => (
							<TextField
								{...params}
								error={touched.sector && !!errors.recinto}
								helperText={
									touched.sector && errors.recinto
								}
								name="recinto"
								label="Recinto"
								variant="outlined"
							/>
						)}
					/>

					<Autocomplete
						id="subcontrato"
						value={subcontracts.find(
							(s) => s.Nombre === values.subcontrato
						)}
						options={subcontracts}
						getOptionLabel={(option) => option.Nombre}
						onChange={(_, value) => {
							setFieldValue(
								"subcontrato",
								value?.Nombre ?? ""
							);
						}}
						fullWidth
						renderInput={(params) => (
							<TextField
								{...params}
								name="subcontrato"
								label={"Subcontrato"}
								variant="outlined"
							/>
						)}
					/>

					<Autocomplete
						value={values.errorTipo}
						options={typeErrors}
						onChange={(_, value) => {
							setFieldValue("errorTipo", value ?? "");
						}}
						fullWidth
						renderInput={(params) => (
							<TextField
								{...params}
								label="Error Tipo"
								variant="outlined"
							/>
						)}
					/>
					<TextField
						fullWidth
						multiline
						rows={3}
						id="comentario"
						name="comentario"
						label="Comentario"
						variant="outlined"
						value={values.comentario}
						onChange={handleChange}
					/>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={() =>
							dialog.onClose?.({}, "escapeKeyDown")
						}
						color="primary"
					>
						Cancel
					</Button>

					<Button
						variant="contained"
						color="primary"
						type="submit"
					>
						Guardar
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
