import { createAsyncThunk } from "@reduxjs/toolkit";
import { firestore } from "src/firebase/firebase";
import Firebase from "firebase";
import { Empresa } from "src/models/Empresa";
import { SnackState } from "src/models/snack-state";
import { openSnack } from "src/redux/actions/uiActions";
import { RootState } from "src/redux/store/reducer";
import { cleanString } from "src/utils/utils";

type ParamsOT = {
	param: string;
	type: keyof Pick<
		Empresa,
		| "UnidadesOT"
		| "SectoresOT"
		| "PasillosOT"
		| "RecintosOT"
		| "OtrosOT"
		| "ElementosOT"
	>;
	business?: Empresa;
};

export const addParameterToBusiness = createAsyncThunk(
	"businesses/neo-parameters/add",
	async (params: ParamsOT, { getState, dispatch }) => {
		try {
			const {
				param,
				type,
				business = (getState() as RootState).businessReducer
					.edit.selectedBusiness,
			} = params;

			if (!business)
				throw Error("No hay una empresa seleccionada.");

			if (
				business[type]
					?.map(cleanString)
					.includes(cleanString(param))
			)
				throw Error(
					`El parámetro ${param} ya existe en ${business.Nombre}.`
				);

			const businessRef = firestore
				.collection("Empresas")
				.doc(business?.id);

			await businessRef.update({
				[type]:
					Firebase.firestore.FieldValue.arrayUnion(param),
			});

			dispatch(
				openSnack(
					`Hemos agregado ${param} a ${business.Nombre}.`,
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(
					error.message || "Ha ocurrido un error.",
					SnackState.ERROR
				) as any
			);
		}
	}
);

export const removeParameterFromBusiness = createAsyncThunk(
	"businesses/neo-parameters/remove",
	async (params: ParamsOT, { getState, dispatch }) => {
		try {
			const {
				param,
				type,
				business = (getState() as RootState).businessReducer
					.edit.selectedBusiness,
			} = params;

			if (!business)
				throw Error("No hay una empresa seleccionada.");

			const businessRef = firestore
				.collection("Empresas")
				.doc(business?.id);

			await businessRef.update({
				[type]:
					Firebase.firestore.FieldValue.arrayRemove(param),
			});

			dispatch(
				openSnack(
					`Hemos quitado ${param} de ${business.Nombre}.`,
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(
					error.message || "Ha ocurrido un error.",
					SnackState.ERROR
				) as any
			);
		}
	}
);

export const updateParameterFromBusiness = createAsyncThunk(
	"businesses/neo-parameters/update",
	async (
		params: ParamsOT & { index: number },
		{ getState, dispatch }
	) => {
		try {
			const {
				param,
				type,
				index,
				business = (getState() as RootState).businessReducer
					.edit.selectedBusiness,
			} = params;

			if (!business)
				throw Error("No hay una empresa seleccionada.");

			if (
				business[type]
					?.map(cleanString)
					.filter((p) => p !== business[type]?.[index])
					.includes(cleanString(param))
			)
				throw Error(
					`El parámetro ${param} ya existe en ${business.Nombre}.`
				);

			const updatedParams = [...(business[type] ?? [])];
			if (updatedParams) updatedParams[index] = param;

			const businessRef = firestore
				.collection("Empresas")
				.doc(business?.id);

			await businessRef.update({
				[type]: updatedParams,
			});

			dispatch(
				openSnack(
					`Hemos actualizado ${param} de ${business.Nombre}.`,
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(
					error.message || "Ha ocurrido un error.",
					SnackState.ERROR
				) as any
			);
		}
	}
);
