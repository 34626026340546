import {
	Box,
	Button,
	CircularProgress,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FC } from "react";
import { UserValidator } from "./validator";

export const UserData: FC<UserValidator> = ({
	formik,
	selectedBusiness,
	directorsCount,
	selectedUser,
	handleReset,
}) => {
	const {
		handleChange,
		values,
		touched,
		errors,
		setFieldValue,
		isSubmitting,
	} = formik;

	return (
		<>
			<Box
				style={{
					padding: "10px 0px 10px 0px",
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gap: 10,
				}}
			>
				<TextField
					autoFocus
					id="Nombre"
					label="Nombre"
					type="text"
					value={values?.Nombre}
					name="Nombre"
					onChange={handleChange}
					error={touched.Nombre && Boolean(errors.Nombre)}
					helperText={touched.Nombre && errors.Nombre}
					fullWidth
					variant="outlined"
					autoComplete="off"
				/>
				<TextField
					id="Apellido"
					label="Apellido"
					type="text"
					value={values?.Apellido}
					name="Apellido"
					onChange={handleChange}
					error={
						touched.Apellido && Boolean(errors.Apellido)
					}
					helperText={touched.Apellido && errors.Apellido}
					fullWidth
					variant="outlined"
					autoComplete="off"
				/>
				<TextField
					id="Email"
					label="Email"
					type="Email"
					value={values?.Email}
					name="Email"
					disabled={!!selectedUser}
					onChange={handleChange}
					error={touched.Email && Boolean(errors.Email)}
					helperText={touched.Email && errors.Email}
					fullWidth
					variant="outlined"
					autoComplete="off"
				/>
				{!selectedUser && (
					<TextField
						id="VerifyEmail"
						label="Confirmar Email"
						type="Email"
						value={values?.VerifyEmail}
						name="VerifyEmail"
						onChange={handleChange}
						error={
							touched.VerifyEmail &&
							Boolean(errors.VerifyEmail)
						}
						helperText={
							touched.VerifyEmail && errors.VerifyEmail
						}
						fullWidth
						variant="outlined"
						autoComplete="off"
					/>
				)}
				<Autocomplete
					id="combo-box-cargos"
					value={values.Cargo}
					options={selectedBusiness?.Cargos!}
					filterOptions={(option: any) =>
						option.filter((opt: any) => opt.Activado)
					}
					getOptionLabel={(option: any) =>
						typeof option === "string"
							? option
							: option.Nombre
					}
					onChange={(_, newValue: any) => {
						if (newValue) {
							setFieldValue("Cargo", newValue.Nombre);
						}
					}}
					renderInput={(params: any) => (
						<TextField
							{...params}
							label="Cargo"
							variant="outlined"
							error={touched.Cargo && Boolean(errors.Cargo)}
							helperText={touched.Cargo && errors.Cargo}
						/>
					)}
				/>

				<Autocomplete<
					| "AdministradorEmpresa"
					| "UsuarioEmpresa"
					| "Administrador"
				>
					id="combo-box-demo"
					value={values.TipoUsuario}
					disabled={values.TipoUsuario === "Administrador"}
					options={[
						"AdministradorEmpresa",
						"UsuarioEmpresa",
					]}
					getOptionLabel={(option) => {
						switch (option) {
							case "AdministradorEmpresa":
								return "Director";
							case "UsuarioEmpresa":
								return "Encargado";
							default:
								return "SuperAdmin";
						}
					}}
					getOptionDisabled={(option) =>
						directorsCount >= 1 &&
						option === "AdministradorEmpresa"
					}
					onChange={(_, newValue) => {
						setFieldValue("TipoUsuario", newValue);
					}}
					renderInput={(params: any) => (
						<TextField
							{...params}
							label="Tipo Usuario"
							variant="outlined"
							error={
								touched.TipoUsuario &&
								Boolean(errors.TipoUsuario)
							}
						/>
					)}
				/>

				<TextField
					id="Telefono"
					label="Teléfono"
					placeholder="XXXXXXXX"
					value={values.Telefono}
					name="Telefono"
					onChange={handleChange}
					error={
						touched.Telefono && Boolean(errors.Telefono)
					}
					helperText={touched.Telefono && errors.Telefono}
					fullWidth
					variant="outlined"
					autoComplete="off"
				/>
			</Box>

			<Box
				display="flex"
				justifyContent="flex-end"
				gridGap={10}
			>
				<Button onClick={handleReset} color="primary">
					Cancelar
				</Button>
				<Button
					type="submit"
					color="primary"
					variant="contained"
					disabled={isSubmitting}
					startIcon={
						isSubmitting && <CircularProgress size={16} />
					}
				>
					Guardar
				</Button>
			</Box>
		</>
	);
};
