import { Link } from "@material-ui/core";
import { FC, useMemo } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useCheckproLinks } from "./hooks/useCheckproLinks";
import { UrlParams } from ".";

export const LinkNeoPlaces: FC = () => {
	const { auth } = useCheckproLinks();

	const { businessId, neoProjectId, neoChecklistId } =
		useParams<UrlParams>();

	const url = useMemo(() => {
		const userType = auth.user?.tipoUsuario;

		if (userType === "Administrador")
			return `/administrador/empresas/${businessId}/neo-projects/${neoProjectId}/${neoChecklistId}`;
		else
			return `/administrador/empresas/neo-projects/${neoProjectId}/${neoChecklistId}`;
	}, [auth.user, businessId, neoChecklistId, neoProjectId]);

	return (
		<Link component={NavLink} exact to={url}>
			Espacios
		</Link>
	);
};
