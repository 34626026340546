import {
	Box,
	Button,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import { LinkProyectos } from "../../components/CheckPro/Links/Proyectos";
import { LinkRevisiones } from "../../components/CheckPro/Links/Revisiones";
import { LoaderModal } from "../../components/LoaderModal";
import { NewConfirmDialog } from "../../components/NewConfirmDialog";
import { TableListados } from "../../components/TableListados";
import { TABLE_LIMIT_DEFAULT } from "../../constants";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useNewEditDialog } from "../../hooks/useNewEditDialog";
import { getBusiness } from "../../redux/actions/businessActions";
import {
	addList,
	deleteList,
	getLists,
	getRecintosProject,
	getTotalDocs,
	setSelectedList,
	updateList,
} from "../../redux/actions/listsActions";
import { getOneProject } from "../../redux/actions/projectsActions";
import { getOneRevision } from "../../redux/actions/revisionsActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { IBusinessState } from "../../redux/reducers/businessReducer";
import { IListsState } from "../../redux/reducers/listsReducer";
import { IProjectsState } from "../../redux/reducers/projectsReducer";
import { IRevisionsState } from "../../redux/reducers/revisionsReducer";
import { RootState } from "../../redux/store/reducer";
import { DialogNewEdit } from "./components/DialogNewEdit";
import { LinkEmpresas } from "../../components/CheckPro/Links/Empresas";
import { Breadcrumbs } from "../../components/CheckPro/Breadcrumbs";

export const ListProjectChecklistPage = () => {
	const { projectId, revisionId } = useParams<any>();
	const { pathname } = useLocation();
	const history = useHistory();
	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);

	const businessId = auth.user?.empresaReference.id;
	const dispatch = useDispatch();
	const isFromBusiness =
		pathname.indexOf("/administrador/empresas/") === 0;

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const {
		revisions: {
			selectedProject,
			isLoading: projectIsLoading,
		},
	} = useSelector<RootState, IProjectsState>(
		(state) => state.projectsReducer
	);

	const { selectedRevision } = useSelector<
		RootState,
		IRevisionsState
	>((state) => state.revisionsReducer);

	const {
		lists,
		isLoading,
		addChecklistState,
		totalDocs,
		updateLoading,
	} = useSelector<RootState, IListsState>(
		(state) => state.listsReducer
	);

	useEffect(() => {
		if (selectedProject === null) {
			dispatch(getOneProject(projectId));
		}
		if (isFromBusiness && !selectedBusiness) {
			// dispatch(getOneBusiness(businessId));
		}
	}, [
		dispatch,
		businessId,
		projectId,
		revisionId,
		selectedProject,
		selectedRevision,
		selectedBusiness,
		isFromBusiness,
		lists,
	]);

	useEffect(() => {
		if (!businessId) return;
		if (!selectedBusiness) {
			dispatch(getBusiness(businessId));
		}
	}, [businessId, dispatch, selectedBusiness]);

	useEffect(() => {
		try {
			dispatch(
				getLists(
					projectId,
					revisionId,
					TABLE_LIMIT_DEFAULT,
					true
				)
			);
		} catch (error) {
			console.error("Toy re loco.");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const {
		open,
		openEditDialog,
		openNewDialog,
		closeDialog,
		onAccept,
		action,
		selected,
		setIsLoading: setIsLoadingNewEditDialog,
	} = useNewEditDialog<any>({
		onAccept: (values, action) => {
			if (action === "New") {
				dispatch(addList(values));
			} else if (action === "Edit") {
				dispatch(
					updateList(values, selected.id, revisionId, true)
				);
			}
		},
	});

	const {
		open: openConfirmDialog,
		setIsLoading: setIsLoadingConfirmDialog,
		dialogConfirmHandleClose,
		dialogConfirmHandleConfirm,
		openDialog,
		selected: confirmDialogSelected,
	} = useConfirmDialog({
		onConfirm: (selected) => {
			dispatch(deleteList(selected.id));
		},
	});

	useEffect(() => {
		setIsLoadingNewEditDialog(updateLoading);
		setIsLoadingConfirmDialog(updateLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLoading]);

	// noinspection ConstantConditionalExpressionJS
	useEffect(() => {
		if (!selectedRevision) {
			dispatch(getOneRevision(projectId, revisionId, true));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRevision, projectId, revisionId]);

	return (
		<React.Fragment>
			<Helmet title="Listados" />
			<Grid justify="space-between" container>
				<Grid item>
					{!selectedProject ? (
						<Skeleton variant="text" />
					) : (
						<Box>
							<Typography
								variant="h3"
								gutterBottom
								display="inline"
							>
								{`${selectedBusiness?.Nombre} / ${selectedProject?.NombreProyecto} / ${selectedRevision?.Nombre}`}
							</Typography>
						</Box>
						// <Typography>{selectedRevision?.Nombre}</Typography>
					)}

					<Breadcrumbs>
						<LinkEmpresas />
						<LinkProyectos />
						<LinkRevisiones />
						<Typography>Listados</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={() => openNewDialog()}
					>
						<AddIcon />
						Nuevo Listado
					</Button>
				</Grid>
			</Grid>
			<Divider />
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<TableListados
						handleOpenEditDialog={openEditDialog}
						handleOpenConfirmDialog={openDialog}
						isLoading={isLoading}
						totalDocs={totalDocs}
						projects={lists}
						onLimitChange={(e, newLimit) => {
							if (newLimit > lists.length) {
								// dispatch(getProjectsTypeChecklist( newLimit));
							}
						}}
						onPageChange={(
							newPage: number,
							oldPage: number
						) => {}}
						handleChecksButton={(selected: any) => {
							dispatch(
								getRecintosProject(
									projectId,
									revisionId,
									selected.id
								)
							);
							dispatch(setSelectedList(selected));
							history.push(
								`/administrador/checklist-proyectos/${projectId}/revisiones/${revisionId}/listados/${selected.id}/recintos`
							);
						}}
						handleOpenResponsiblesDialog={() => {}}
					/>
				</Grid>
			</Grid>

			{!projectIsLoading && (
				<>
					<DialogNewEdit
						open={open}
						onClose={closeDialog}
						onAccept={onAccept}
						isLoading={updateLoading}
						action={action}
						title="Lista"
						selected={selected}
						project={selectedProject}
					/>
					<NewConfirmDialog
						open={openConfirmDialog}
						title={`¿Desea borrar la lista ${confirmDialogSelected?.Nombre}?`}
						content={
							<Alert severity="warning">
								Se borrarán los Recintos, Subcontratos y
								Observaciones que contiene esta Revisión.
								Esta acción no se puede deshacer.
							</Alert>
						}
						onClose={dialogConfirmHandleClose}
						isLoading={updateLoading}
						onConfirm={dialogConfirmHandleConfirm}
						onConfirmText="Borrar"
					/>
				</>
			)}

			<LoaderModal
				open={addChecklistState === "Submiting"}
			/>
		</React.Fragment>
	);
};
