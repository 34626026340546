import {
	IconButton,
	Tab,
	Tabs,
	Tooltip,
	Paper,
	Box,
	Typography,
} from "@material-ui/core";
import { Table } from "src/utils/components/Table";
import { Settings } from "@material-ui/icons";
import { ChevronRight, Edit, Trash2 } from "react-feather";
import { useCallback, useEffect, useState } from "react";
import { colorsCheckPro } from "src/theme/variants";
import { useResponsiveQuery } from "src/utils/hooks/useResponsiveQuery";
import { PercentageCell } from "../Misc/PercentageCell";
import { NeoChecklistSearchBar } from "./SearchBar";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { DropdownMenu } from "../../Generic/DropdownMenu";
import { Opener } from "../../CheckPro/Opener";
import { useHistory } from "react-router-dom";
import { getRef } from "src/utils/firebase";
import { ChecklistOT } from "src/models/OT/Checklist";
import { NeoChecklistDeleteDialog } from "./Dialog/Delete";
import { NeoChecklistEditDialog } from "./Dialog/Edit";
import { NeoChecklistPlacesDialog } from "./Dialog/Places";
import { useSelector } from "src/redux/store";

export const ChecklistTableOT = () => {
	const { user } = useSelector((s) => s.authReducer);

	const isSmall = useResponsiveQuery("down", "xs");

	const history = useHistory();

	const [dialog, setDialog] = useState(0);

	const { set, project, get, selected, toggle } =
		useProjectsOT();

	const [selectedType, setSelectedType] =
		useState<ChecklistOT["Tipo"]>("Obra Gruesa");

	const [checklistName, setChecklistName] = useState("");

	useEffect(() => {
		if (!selected.project) return;
		get.project.checklists(selectedType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected.project, selectedType]);

	const handleRedirectToPlaces = (
		checklist: ChecklistOT
	) => {
		const checklistRef = getRef(checklist);
		const projectRef = getRef(selected.project);

		set.checklist(checklist);
		history.push(`${projectRef.id}/${checklistRef.id}`);
	};

	const getPercentage = (checklist: ChecklistOT) => {
		const { CantidadCompletada = 0, Cantidad = 1 } =
			checklist;

		return Math.round(
			(CantidadCompletada * 100) / (Cantidad || 1)
		);
	};

	const getMenuOptions = useCallback(
		(value: ChecklistOT) => [
			{
				label: "Editar",
				icon: <Edit color={colorsCheckPro.primary} />,
				disabled: user?.tipoUsuario === "UsuarioEmpresa",
				onClick: () => {
					set.checklist(value);
					setDialog(2);
				},
			},
			{
				label: "Eliminar",
				disabled: user?.tipoUsuario === "UsuarioEmpresa",
				icon: <Trash2 color="red" />,
				onClick: () => {
					set.checklist(value);
					setDialog(1);
				},
			},
			{
				label: "Asignar espacios",
				disabled: user?.tipoUsuario === "UsuarioEmpresa",
				icon: (
					<Settings htmlColor={colorsCheckPro.primary} />
				),
				onClick: () => {
					set.checklist(value);
					setDialog(3);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[project.checklists]
	);

	return (
		<>
			<Paper elevation={3}>
				<Tabs
					style={{ alignSelf: "center", marginBottom: 10 }}
					value={selectedType}
					onChange={(_, v) => setSelectedType(v)}
				>
					<Tab
						label="OBRA GRUESA"
						value="Obra Gruesa"
						style={{ width: 200 }}
					/>
					<Tab
						label="TERMINACIONES"
						value="Terminaciones"
						style={{ width: 200 }}
					/>
				</Tabs>
			</Paper>

			<Paper elevation={4} style={{ flexGrow: 0 }}>
				<Table
					emptyMessage="Asigne al menos una lista de chequeo"
					stripped
					loading={!project.checklists}
					elementsProps={{
						TableBodyProps: {},
						TablePaginationProps: {
							backIconButtonProps: { size: "small" },
							nextIconButtonProps: { size: "small" },
						} as any,
						TableProps: {
							size: "small",
							style: { height: "100%" },
						},
						TableHeadProps: {
							style: {
								backgroundColor: colorsCheckPro.primary,
							},
						},
					}}
					disableExporting
					list={project.checklists ?? []}
					actions={
						!selected.project?.Eliminado && (
							<NeoChecklistSearchBar
								value={checklistName}
								onChange={(val) => setChecklistName(val)}
								type={selectedType}
								// onSubmit={addRevision}
							/>
						)
					}
					columns={[
						{
							header: "Nombre",
							Cell: ({ value }) => <>{value.Nombre}</>,
						},
						{
							align: "center",
							header: "Cantidad",
							Cell: ({ value }) => <>{value.Cantidad}</>,
						},
						{
							align: "center",
							header: "Unidad",
							Cell: ({ value }) => <>{value.Unidad}</>,
						},
						{
							align: "center",
							header: "Progreso",
							Cell: ({ value }) => (
								<Tooltip
									title={
										<Typography>
											<ul>
												<li>
													Menor o igual del 25%: rojo{" "}
												</li>
												<li>
													Sobre 25% y menos 50%: naranjo
												</li>
												<li>
													Sobre 50% y menos al 75%: amarillo
												</li>
												<li>
													Sobre 75% y menos a 99%: azul
												</li>
												<li>Sobre el 99%: verde</li>
											</ul>
											Si la meta es 50%, y has completado un
											51%, se vería en verde porque
											superaría el 99% RESPECTO A LA META.
										</Typography>
									}
								>
									<Box>
										<PercentageCell
											value={getPercentage(value)}
											aim={value.MetaRevision}
										/>
									</Box>
								</Tooltip>
							),
						},

						{
							align: "right",
							Cell: ({ value }) => (
								<>
									{!selected.project?.Eliminado && (
										<DropdownMenu
											Opener={Opener}
											options={getMenuOptions(value)}
										/>
									)}

									<Tooltip title="Ver espacios">
										<IconButton
											size="small"
											color="primary"
											onClick={() =>
												handleRedirectToPlaces(value)
											}
										>
											<ChevronRight />
										</IconButton>
									</Tooltip>
								</>
							),
						},
					]}
				/>
			</Paper>

			<NeoChecklistDeleteDialog
				open={dialog === 1}
				onClose={() => {
					setDialog(0);
					set.checklist(null);
				}}
				fullScreen={isSmall}
			/>

			<NeoChecklistEditDialog
				open={dialog === 2}
				onClose={() => {
					setDialog(0);
					set.checklist(null);
				}}
			/>

			<NeoChecklistPlacesDialog
				fullScreen
				open={dialog === 3}
				onClose={() => {
					setDialog(0);
					set.checklist(null);
					toggle.assignedPlace([]);
					toggle.place([]);
				}}
			/>
		</>
	);
};
