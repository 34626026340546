import {
	Table as MuiTable,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { FC, useMemo } from "react";
import { useTableContext } from "./Context";
import { TablePaginator } from "./Paginator";
import { ActionRow } from "./ActionRow";
import { TableBody } from "./TableBody";

export const Table: FC = () => {
	const {
		elementsProps,
		columns,
		actions,
		disableExporting,
		disablePagination,
	} = useTableContext();
	const {
		TableContainerProps,
		TableHeadProps,
		TableProps,
		TableRowProps,
		TableFooterProps,
	} = elementsProps || {};

	const header = useMemo(
		() =>
			columns?.map(({ title, header, ...props }, i) => (
				<TableCell key={`cool-cell-${i}`} {...props}>
					{header || title || ""}
				</TableCell>
			)),
		[columns]
	);

	return (
		<TableContainer {...TableContainerProps}>
			<MuiTable {...TableProps}>
				<TableHead {...TableHeadProps}>
					{!(disableExporting && !actions) && <ActionRow />}
					<TableRow {...TableRowProps}>{header}</TableRow>
				</TableHead>

				<TableBody />

				{!disablePagination && (
					<TableFooter {...TableFooterProps}>
						<TableRow>
							<TablePaginator />
						</TableRow>
					</TableFooter>
				)}
			</MuiTable>
		</TableContainer>
	);
};
