import {
	Button,
	Checkbox,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	FormControlLabel,
	TextField,
	Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "src/redux/store";
import * as yup from "yup";
import * as rutUtils from "rut.js";
import { addBusiness } from "src/redux/actions/businessActions";
import { ModuleLimitForm } from "../ModuleLimitForm";

export const BusinessAddDialog: FC<DialogProps> = (
	props
) => {
	const dispatch = useDispatch();

	const {
		edit: { isLoading },
	} = useSelector((state) => state.businessReducer);

	const formik = useFormik({
		initialValues: {
			Nombre: "",
			Rut: "",
			LimiteFotos: 1,
			Revisiones: {
				Activado: false,
				LimiteRevisiones: 5,
				RevisionesUsados: 0,
				LimiteUsuarios: 5,
				UsuariosUsados: 0,
			},
			ChecklistsOT: {
				Activado: false,
				LimiteProyectos: 5,
				ProyectosUsados: 0,
				LimiteUsuarios: 5,
				UsuariosUsados: 0,
			},
			CheckList: {
				Activado: false,
				LimiteCheckList: 5,
				CheckListUsados: 0,
				LimiteUsuarios: 5,
				UsuariosUsados: 0,
			},
		},
		validationSchema: yup.object().shape({
			Nombre: yup
				.string()
				.required("Nombre es requerido")
				.trim(),
			Rut: yup
				.string()
				.min(11)
				.required("Rut es requerido")
				.test({
					name: "Rut",
					message: "Rut no válido",
					test: (value) => {
						if (!value) return false;
						return rutUtils.validate(value);
					},
				}),
		}),
		onSubmit: (values) => {
			dispatch(addBusiness(values));
			setStatus("Submitting");
		},
	});

	const {
		values,
		handleSubmit,
		handleChange,
		touched,
		errors,
		status,
		setStatus,
		resetForm,
	} = formik;

	useEffect(() => {
		if (status === "Submitting" && !isLoading) {
			props.onClose?.({}, "escapeKeyDown");
			resetForm();
			setStatus("Initial");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, setStatus, resetForm, isLoading]);

	return (
		<Dialog {...props}>
			<form onSubmit={handleSubmit}>
				<DialogTitle>Agregar Empresa</DialogTitle>
				<DialogContent
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<TextField
						// autoFocus
						id="Nombre"
						label="Nombre"
						type="text"
						autoComplete="off"
						value={values.Nombre}
						name="Nombre"
						onChange={handleChange}
						error={touched.Nombre && Boolean(errors.Nombre)}
						helperText={touched.Nombre && errors.Nombre}
						fullWidth
					/>
					<TextField
						// autoFocus
						id="Rut"
						label="Rut Empresa"
						type="text"
						autoComplete="off"
						value={values.Rut}
						name="Rut"
						onChange={(e) => {
							e.target.value = rutUtils.format(
								e.target.value
							);
							handleChange(e);
						}}
						error={touched.Rut && Boolean(errors.Rut)}
						helperText={touched.Rut && errors.Rut}
						fullWidth
					/>
					<TextField
						id="LimiteFotos"
						label="Limite de fotografías"
						type="number"
						autoComplete="off"
						value={values.LimiteFotos}
						inputProps={{
							min: 1,
							max: 2,
						}}
						name="LimiteFotos"
						onChange={handleChange}
						error={
							touched.LimiteFotos && !!errors.LimiteFotos
						}
						helperText={
							touched.LimiteFotos && errors.LimiteFotos
						}
						fullWidth
					/>

					<Typography align="center" variant="h4">
						Módulos
					</Typography>

					<FormControlLabel
						control={
							<Checkbox
								checked={values.Revisiones.Activado}
								onChange={handleChange}
								name="Revisiones.Activado"
								color="primary"
							/>
						}
						label="Revisiones"
					/>

					<Collapse in={values.Revisiones.Activado}>
						<ModuleLimitForm
							formik={formik as any}
							module="Revisiones"
						/>
					</Collapse>

					<FormControlLabel
						control={
							<Checkbox
								checked={values.CheckList.Activado}
								onChange={handleChange}
								name="CheckList.Activado"
								color="primary"
							/>
						}
						label="Checklist Revisiones"
					/>

					<Collapse in={values.CheckList.Activado}>
						<ModuleLimitForm
							formik={formik as any}
							module="CheckList"
						/>
					</Collapse>

					<FormControlLabel
						control={
							<Checkbox
								checked={values.ChecklistsOT.Activado}
								onChange={handleChange}
								name="ChecklistsOT.Activado"
								color="primary"
							/>
						}
						label="Checklists"
					/>

					<Collapse in={values.ChecklistsOT.Activado}>
						<ModuleLimitForm
							formik={formik as any}
							module="ChecklistsOT"
						/>
					</Collapse>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							resetForm();
							props.onClose?.({}, "escapeKeyDown");
						}}
						color="primary"
					>
						Cancelar
					</Button>
					<Button
						type="submit"
						color="primary"
						disabled={isLoading}
					>
						Agregar
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
