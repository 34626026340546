import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useState } from "react";
import Loading from "../../components/Loading";
import {
	IChecklistState,
	checklistReducer,
} from "../../redux/reducers/checklistReducer";
import { RootState } from "../../redux/store/reducer";
import { useSelector } from "src/redux/store";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface Props {
	user?: any;
	open: boolean;
	onClose: () => void;
	onAccept: (subcontracts: any) => void;
	title?: string;
	type?: string;
}

export const AutocompleteSubcontratos: React.FC<Props> =
	React.memo(({ open, onAccept, onClose, title = "" }) => {
		const { subcontratos } = useSelector(
			(state) => state.checklistReducer
		);

		const selectedRecinto = useSelector(
			(s) => s.checklistReducer.selectedRecinto
		);

		const [checklist, setChecklist] = React.useState<any[]>(
			[]
		);
		const [loading, setLoading] = useState(false);

		const { handleSubmit, resetForm } = useFormik({
			initialValues: {},
			onSubmit: () => {
				setLoading(true);
				onAccept(checklist);
				setChecklist([]);
				resetForm();
				setLoading(false);
				onClose();
			},
		});

		const dynamicSort = (property: string) => {
			let sortOrder = 1;
			if (property.startsWith("-")) {
				sortOrder = -1;
				property = property.substr(1);
			}
			return function (a: any, b: any) {
				/* next line works with strings and numbers,
				 * and you may want to customize it to your needs
				 */
				let result;
				if (a[property] < b[property]) result = -1;
				else if (a[property] > b[property]) result = 1;
				else result = 0;

				return result * sortOrder;
			};
		};

		const orderedSubcontracts = [...subcontratos].sort(
			dynamicSort("Nombre")
		);
		return (
			<Dialog
				open={open}
				onClose={(_, reason) => {
					if (reason !== "backdropClick") {
						onClose();
					}
				}}
				aria-labelledby="add-and-update-dialog-title"
			>
				<DialogTitle id="add-and-update-dialog-title">
					{title}
				</DialogTitle>

				<form noValidate onSubmit={handleSubmit}>
					<DialogContent
						style={{ minHeight: 300, minWidth: 600 }}
					>
						{!loading ? (
							<Grid
								container
								spacing={3}
								justify="space-between"
							>
								<Grid item sm={12} xs={12}>
									<Autocomplete
										multiple
										id="checkboxes-tags-demo"
										value={subcontratos.filter(function (
											obj: any
										) {
											return checklist.find(function (
												obj2: any
											) {
												return obj === obj2;
											});
										})}
										options={orderedSubcontracts}
										disableCloseOnSelect
										getOptionLabel={(option) =>
											option.Nombre
										}
										onChange={(_, value: any) => {
											setChecklist(value);
										}}
										renderOption={(
											option,
											{ selected }
										) => (
											<>
												<Checkbox
													style={{ marginRight: 8 }}
													checked={selected}
												/>
												{option.Nombre}
											</>
										)}
										fullWidth
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Agregar Subcontratos"
												placeholder="Subcontratos"
												disabled
											/>
										)}
									/>
								</Grid>
							</Grid>
						) : (
							<Loading open={loading} />
						)}
					</DialogContent>
					<DialogActions>
						<Box
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								width: "100%",
							}}
						>
							<Box>
								<Button onClick={onClose} color="primary">
									Cancelar
								</Button>
								<Button type="submit" color="primary">
									Guardar
								</Button>
							</Box>
						</Box>
					</DialogActions>
				</form>
			</Dialog>
		);
	});
