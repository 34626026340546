//v2
import { configureStore } from "@reduxjs/toolkit";
import { reducer } from "./reducer";
import {
	useDispatch as useReduxDispatch,
	useSelector as useReduxSelector,
	TypedUseSelectorHook,
} from "react-redux";

const store = configureStore({
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}),
	reducer,
});

type AppDispatch = typeof store.dispatch;

export const useDispatch: () => AppDispatch =
	useReduxDispatch;

export const useSelector: TypedUseSelectorHook<
	ReturnType<typeof reducer>
> = useReduxSelector;

export default store;
