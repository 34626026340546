import Firebase from "firebase";

export type FireUpdate<T> = {
	[K in keyof T]: T[K] | Firebase.firestore.FieldValue;
};

export type WithRef<T = {}> = T & {
	_ref: Firebase.firestore.DocumentReference;
};

export const hasRef = <T = any>(
	model: T
): model is WithRef<T> => {
	if (!model) return false;
	if (typeof model !== "object") return false;
	if (!("_ref" in model)) return false;

	const { _ref } = model;
	return (
		_ref instanceof Firebase.firestore.DocumentReference
	);
};

/**
 * @throws Error if `model` has no reference.
 */
export const getRef = <T>(model: T) => {
	if (hasRef(model)) return model._ref;
	else throw Error("Model has no reference.");
};
