import { Empresa } from "src/models/Empresa";
import { isChecklistRevision, AnyProject } from "src/models/Proyecto";
import { Usuario } from "src/models/Usuario";
import { cleanString } from "src/utils/utils";
import { firestore } from "../../firebase/firebase";
import Firebase from "firebase";

type _Revision = Pick<AnyProject, "id" | "Tipo"> &
	Partial<AnyProject>;

/**
 * Solo para revisiones. Si esta función no se lleva a cabo
 * después de actualizar nombre del proyecto, o una de sus
 * revisiones o listados, estos cambios no se verán reflejados
 * en los PDFs generados.
 *
 * @param idProyecto
 * @param nombre Nuevo nombre a definir
 */
export const indexarObservaciones = async (
	campo:
		| "NombreProyecto"
		| "NombreRevision"
		| "NombreListado",
	projectId: string,
	newName: string
) => {
	const querySnap = await firestore
		.collectionGroup("Observaciones")
		.where(
			campo === "NombreProyecto"
				? "ProyectoId"
				: campo === "NombreRevision"
				? "RevisionId"
				: "ListadonId",
			"==",
			projectId
		)
		.get();

	if (querySnap.empty) return;

	const docs = [...querySnap.docs];

	while (docs.length > 0) {
		const batch = firestore.batch();
		const spliced = docs.splice(0, 500);

		spliced.forEach((doc) => {
			batch.update(doc.ref, {
				[campo]: newName,
			});
		});

		await batch.commit();
	}
};

export const sePuedeAgregarRevision = async (
	revision: AnyProject
): Promise<boolean> => {
	const { EmpresaReference } = revision;

	const snap = await firestore
		.doc(EmpresaReference.path)
		.get();

	const { CheckList, Revisiones } = snap.data() as Empresa;

	if (isChecklistRevision(revision)) {
		const { CheckListUsados, LimiteCheckList } = CheckList;
		return CheckListUsados + 1 <= LimiteCheckList;
	} else {
		const { RevisionesUsados, LimiteRevisiones } =
			Revisiones;
		return RevisionesUsados + 1 <= LimiteRevisiones;
	}
};

export const actualizarRevision = async (
	r: Partial<AnyProject>
) => {
	const { id, Tipo, ...data } = r;

	if (!id || typeof Tipo !== "string")
		throw Error(
			"Not enough data to determine reference. Needs 'id' and 'Tipo'."
		);

	await firestore
		.collection(
			Tipo === "CheckList"
				? "ProyectosChecklist"
				: "Proyectos"
		)
		.doc(id)
		.update(data);
};

export const actualizarResponsableRevisiones = async (
	{ Nombre, Apellido, Cargo, id: userId }: Usuario,
	...revisiones: _Revision[]
) => {
	const r = revisiones.map(({ id, Tipo }) => ({
		id,
		Tipo,
		Responsable: {
			Cargo,
			Fecha: Firebase.firestore.Timestamp.now(),
			NombreCompleto: `${Nombre} ${Apellido}`,
			NombreCompleto_lower: cleanString(
				`${Nombre} ${Apellido}`
			),
			FirebaseId: userId,
		},
	}));

	while (r.length > 0) {
		const spliced = r.splice(0, 500);
		const batch = firestore.batch();
		for (const revision of spliced) {
			const { id, Tipo, ...data } = revision;
			const collection =
				Tipo === "CheckList"
					? "ProyectosChecklist"
					: "Proyectos";
			const ref = firestore.doc(`${collection}/${id}`);
			batch.update(ref, data);
		}
		await batch.commit();
	}
};
