import {
	Breadcrumbs,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { FC } from "react";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import Firebase from "firebase";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { WithRef } from "src/utils/firebase";
import * as Yup from "yup";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { ChecklistOT } from "src/models/OT/Checklist";

export const NeoChecklistEditDialog: FC<DialogProps> = ({
	...props
}) => {
	const { selected, update } = useProjectsOT();

	const { selectedBusiness } = useBusiness();

	const { MetaRevision, Unidad } = selected.checklist ?? {};

	const {
		values,
		touched,
		errors,
		setFieldValue,
		handleSubmit,
		isSubmitting,
	} = useFormik({
		enableReinitialize: true,
		initialValues: {
			reviewGoal: MetaRevision ?? 1,
			unit: Unidad ?? "",

			quantity: 0,
			selectedPlaces:
				[] as Firebase.firestore.DocumentReference[],
			selectedReviewers:
				[] as (Firebase.firestore.DocumentReference | null)[],
		},

		validationSchema: Yup.object({
			reviewGoal: Yup.number()
				.min(1, "Mínimo 1%.")
				.max(100, "Máximo 100%.")
				.required("Este dato es importante."),
			unit: Yup.string().required(
				"Este dato es importante."
			),
		}),

		onSubmit: async ({ reviewGoal, unit }) => {
			const dataToUpdate: WithRef<Partial<ChecklistOT>> = {
				_ref: selected.checklist!._ref!,
				MetaRevision: reviewGoal,
				Unidad: unit,
			};

			await update.project.checklist(dataToUpdate);
			props.onClose?.({}, "escapeKeyDown");
		},
	});

	return (
		<>
			{/* main dialog */}
			<Dialog {...props}>
				<DialogTitle
					bigText="Editar lista de chequeo (Proyecto)"
					smallText={
						<Breadcrumbs>
							<Typography>
								{selected.project?.Nombre}
							</Typography>
						</Breadcrumbs>
					}
				/>

				<DialogContent
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
						height: "100%",
					}}
				>
					<TextField
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Typography>%</Typography>
								</InputAdornment>
							),
						}}
						variant="outlined"
						size="small"
						label="Meta de revisión"
						error={
							touched.reviewGoal && !!errors.reviewGoal
						}
						helperText={
							touched.reviewGoal && errors.reviewGoal
						}
						value={values.reviewGoal}
						onChange={(ev) => {
							const parsed = parseInt(ev.target.value);
							if (isNaN(parsed)) return;
							setFieldValue("reviewGoal", parsed);
						}}
						id="reviewGoal"
					/>

					<FormControl
						variant="outlined"
						size="small"
						error={touched.unit && !!errors.unit}
					>
						<InputLabel>Unidad</InputLabel>
						<Select
							label="Unidad"
							value={values.unit}
							onChange={(ev) =>
								setFieldValue("unit", ev.target.value)
							}
						>
							{selectedBusiness?.UnidadesOT?.map((unit) => (
								<MenuItem key={unit} value={unit}>
									{unit}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</DialogContent>

				<DialogActions>
					<Button
						disabled={isSubmitting}
						onClick={(ev) =>
							props.onClose?.(ev, "escapeKeyDown")
						}
					>
						Cancelar
					</Button>

					<Button
						disabled={isSubmitting}
						onClick={() => handleSubmit()}
						variant="contained"
						color="primary"
					>
						{isSubmitting ? (
							<CircularProgress size={24} />
						) : (
							"Guardar"
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
