import {
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider as MuiDivider,
	Fade,
	FormControlLabel as MuiFormControlLabel,
	Grid,
	IconButton,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import { es } from "yup-locales";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { SimpleCrudProjectChecklist } from "../components/SimpleCrudProjectChecklist";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import { getBusiness } from "../redux/actions/businessActions";
import { removeSubcontrato } from "../redux/actions/checklistActions";
import {
	getAnswersErrorTipo,
	getErrorTipoProject,
	getOneList,
	getRecintoFromProject,
	getSubcontratosProject,
	setSelectedErrorType,
	setSelectedErrorTipoProject,
	setSelectedSubcontratoProject,
} from "../redux/actions/listsActions";
import { getOneProject } from "../redux/actions/projectsActions";
import { getOneRevision } from "../redux/actions/revisionsActions";
import { IAuthState } from "../redux/reducers/authReducer";
import { IBusinessState } from "../redux/reducers/businessReducer";
import { IChecklistState } from "../redux/reducers/checklistReducer";
import { IListsState } from "../redux/reducers/listsReducer";
import { IProjectsState } from "../redux/reducers/projectsReducer";
import { IRevisionsState } from "../redux/reducers/revisionsReducer";
import { RootState } from "../redux/store/reducer";
import { useStyles } from "../theme/useStyles";
import {
	LinkEmpresas,
	LinkListados,
	LinkProyectos,
	LinkRecintos,
	LinkRevisiones,
} from "src/components/CheckPro/Links";

yup.setLocale(es);

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(
	spacing
);

function ContentCard() {
	const {
		dialog: { selectedChecklist },
	} = useSelector<RootState, IChecklistState>(
		(state) => state.checklistReducer
	);
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { projectId, revisionId, listadoId, recintoId } =
		useParams<any>();
	const [openDialogCreate, setOpenDialogCreate] =
		useState(false);
	const [editTarget, setEditTarget] = useState<any | null>(
		null
	);
	const [limit, setLimit] = useState(TABLE_LIMIT_DEFAULT);
	const [page, setPage] = useState(0);
	const [nombreSelected, setNombreSelected] = useState("");
	const [idSelected, setIdSelected] = useState("");

	const { pathname } = useLocation();

	const { subcontractProject, errorsTypetProject } =
		useSelector<RootState, IListsState>(
			(state) => state.listsReducer
		);

	const handleLimitChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handlePageChange = (_: any, newPage: number) => {
		setPage(newPage);
	};

	// Confirm Dialog
	const [dialogState, setDialogState] = useState({
		open: false,
		title: "",
		subContract: {},
	});

	const handleTypeErrorButton = (subContract: any) => {
		dispatch(setSelectedSubcontratoProject(subContract));
		dispatch(
			getErrorTipoProject(
				projectId,
				revisionId,
				listadoId,
				recintoId,
				subContract.id,
				true
			)
		);
	};
	const handleGoToViewAnswers = (arr: any) => {
		dispatch(setSelectedErrorTipoProject(arr));
		dispatch(getAnswersErrorTipo(arr.id));
		history.push(
			`/administrador/checklist-proyectos/${projectId}/revisiones/${revisionId}/listados/${listadoId}/recintos/${recintoId}/subcontratos/${idSelected}/respuestas/${arr.id}`
		);
	};
	useEffect(() => {
		if (!subcontractProject) {
			dispatch(
				getSubcontratosProject(
					projectId,
					revisionId,
					listadoId,
					recintoId
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		subcontractProject,
		projectId,
		revisionId,
		listadoId,
		recintoId,
	]);

	useEffect(() => {
		return () => {
			dispatch(setSelectedErrorType([]));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container spacing={4}>
			<Grid item xs={6}>
				<Card mb={6}>
					<CardHeader title="Subcontratos" />
					<Divider />
					<CardContent>
						<TableContainer
							className={classes.tableContainer}
						>
							<Table size="small" stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>Nombre</TableCell>
										<TableCell>Errores Tipo</TableCell>
										{pathname.includes(
											"checklist-proyectos"
										) && (
											<TableCell>
												{"Observaciones"}
											</TableCell>
										)}
										<TableCell align="center">
											Acciones
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{subcontractProject !== null
										? subcontractProject
												?.sort((a: any, b: any) =>
													a.Nombre.localeCompare(b.Nombre)
												)
												.map(
													(
														subContract: any,
														index: any
													) => (
														<Fade key={index} in={true}>
															<TableRow
																hover
																className={
																	classes.styledRow
																}
															>
																<TableCell>
																	{subContract?.Nombre}
																</TableCell>
																<TableCell align="left">
																	{subContract?.TotalErroresTipo
																		? subContract?.TotalErroresTipo
																		: 0}
																</TableCell>
																{pathname.includes(
																	"checklist-proyectos"
																) && (
																	<TableCell>
																		{(subContract?.Resueltas ||
																			"0") +
																			" de " +
																			(subContract?.TotalRespuestas ||
																				"0")}
																	</TableCell>
																)}
																<TableCell
																	style={{
																		whiteSpace: "nowrap",
																	}}
																	align="center"
																>
																	<Tooltip title="Errores Tipo">
																		<span>
																			<IconButton
																				onClick={() => {
																					handleTypeErrorButton(
																						subContract
																					);
																					setNombreSelected(
																						subContract.Nombre
																					);
																					setIdSelected(
																						subContract.id
																					);
																				}}
																				color="primary"
																			>
																				<ChevronRight />
																			</IconButton>
																		</span>
																	</Tooltip>
																</TableCell>
															</TableRow>
														</Fade>
													)
												)
										: new Array(limit)
												.fill(0)
												.map((x, i) => (
													<TableRow key={i}>
														<TableCell colSpan={5}>
															<Skeleton />
														</TableCell>
													</TableRow>
												))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							component="div"
							count={subcontractProject?.length}
							onChangePage={handlePageChange}
							onChangeRowsPerPage={handleLimitChange}
							page={page}
							rowsPerPage={limit}
							rowsPerPageOptions={TABLE_LIMITS}
						/>
					</CardContent>
					<ConfirmDialog
						open={dialogState.open}
						onClose={() =>
							setDialogState((dialogState) => ({
								...dialogState,
								open: false,
							}))
						}
						onConfirm={() => {
							dispatch(
								removeSubcontrato(selectedChecklist, {})
							);
						}}
						title={dialogState.title}
					/>
				</Card>
			</Grid>
			<Grid item xs={6}>
				{errorsTypetProject ? (
					<SimpleCrudProjectChecklist
						key={"ErroresTipo"}
						array={
							errorsTypetProject.sort(
								(
									a: { Nombre: string },
									b: { Nombre: any }
								) => a.Nombre.localeCompare(b.Nombre)
							) || []
						}
						isLoading={false}
						name="ErroresTipo"
						onAcceptNewEditDialog={() => {}}
						onConfirmDeleteDialog={() => {}}
						cardTitle={"Errores Tipo - " + nombreSelected}
						goToAnotherPage={(arr) => {
							handleGoToViewAnswers(arr);
						}}
					/>
				) : (
					<Card style={{ minHeight: 250 }}>
						<Grid
							container
							direction="column"
							alignItems="center"
							justify="center"
						>
							<Grid item>
								<img
									style={{
										marginTop: 20,
										marginBottom: 20,
									}}
									alt="Imagen vacía"
									src="/static/void.svg"
									height="150"
								/>
							</Grid>
							<Grid item>
								<Typography variant="h5">
									Ningún subcontrato seleccionado
								</Typography>
							</Grid>
						</Grid>
					</Card>
				)}
			</Grid>
			<DialogAddSubContractSimple
				open={openDialogCreate}
				onClose={() => {
					setEditTarget(null);
					setOpenDialogCreate(false);
				}}
				onSave={() => {}}
				selected={editTarget}
			/>
		</Grid>
	);
}

interface SimpleDialogProps {
	open: boolean;
	onClose: () => void;
	onSave: (data: any) => void;
	selected?: any;
}

const DialogAddSubContractSimple: React.FC<SimpleDialogProps> =
	({ open, onClose, onSave, selected = null }) => {
		const {
			handleSubmit,
			values,
			handleChange,
			touched,
			errors,
			setFieldValue,
			resetForm,
		} = useFormik({
			initialValues: {
				Nombre: "",
				Activado: true,
			},
			onSubmit: (values) => {
				onSave(values);
			},
			validationSchema: yup.object({
				Nombre: yup.string().required("Nombre requerido"),
			}),
		});

		useEffect(() => {
			if (selected) {
				setFieldValue("Nombre", selected?.Nombre || "");
				setFieldValue(
					"Activado",
					selected?.Activado || false
				);
			} else {
				resetForm();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selected]);

		return (
			<React.Fragment>
				<Dialog
					open={open}
					onClose={() => onClose()}
					aria-labelledby="add-and-update-dialog-title"
				>
					<DialogTitle id="add-and-update-dialog-title">
						{selected
							? "Editar Subcontrato"
							: "Agregar Subcontrato"}
					</DialogTitle>

					<form noValidate onSubmit={handleSubmit}>
						<DialogContent>
							<TextField
								autoFocus
								id="Nombre"
								label="Nombre Subcontrato"
								type="text"
								value={values.Nombre}
								name="Nombre"
								onChange={handleChange}
								error={
									touched.Nombre && Boolean(errors.Nombre)
								}
								helperText={touched.Nombre && errors.Nombre}
								fullWidth
							/>
							<FormControlLabel
								control={
									<Switch
										checked={values.Activado}
										onChange={handleChange}
										name="Activado"
										color="primary"
									/>
								}
								my={4}
								label="Activado"
							/>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => onClose()}
								color="primary"
							>
								Cancelar
							</Button>
							<Button type="submit" color="primary">
								{selected ? "Guardar" : "Agregar"}
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</React.Fragment>
		);
	};

export const SubcontratosProject = () => {
	const dispatch = useDispatch();
	const { projectId, revisionId, listadoId, recintoId } =
		useParams<any>();
	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const { selectedRevision } = useSelector<
		RootState,
		IRevisionsState
	>((state) => state.revisionsReducer);
	const businessId = auth.user?.empresaReference.id;
	const { selectedList, selectedRecinto } = useSelector<
		RootState,
		IListsState
	>((state) => state.listsReducer);

	const {
		revisions: {
			isLoading: projectIsLoading,
			selectedProject,
		},
	} = useSelector<RootState, IProjectsState>(
		(state) => state.projectsReducer
	);
	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);
	useEffect(() => {
		if (selectedProject === null) {
			dispatch(getOneProject(projectId));
		}
	}, [
		dispatch,
		businessId,
		projectId,
		revisionId,
		selectedProject,
		selectedBusiness,
	]);

	useEffect(() => {
		if (selectedBusiness === null && businessId) {
			dispatch(getBusiness(businessId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBusiness]);
	useEffect(() => {
		if (!selectedRecinto) {
			dispatch(getOneRevision(projectId, revisionId));
			dispatch(
				getOneList(projectId, revisionId, listadoId)
			);
			dispatch(
				getRecintoFromProject(
					projectId,
					revisionId,
					listadoId,
					recintoId
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedRecinto,
		projectId,
		revisionId,
		listadoId,
		recintoId,
	]);
	return (
		<React.Fragment>
			<Helmet title="Subcontratos" />
			<Grid justify="space-between" container>
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						{projectIsLoading && !selectedProject ? (
							<Skeleton variant="text" />
						) : (
							<Typography
								variant="h3"
								gutterBottom
								display="inline"
							>
								{`${selectedBusiness?.Nombre} / ${selectedProject?.NombreProyecto} / ${selectedRevision?.Nombre} / ${selectedList?.Nombre} / ${selectedRecinto?.Nombre}`}
							</Typography>
						)}
					</Typography>

					<Breadcrumbs aria-label="Breadcrumb" mt={2}>
						<LinkEmpresas />
						<LinkProyectos />
						<LinkRevisiones />
						<LinkListados />
						<LinkRecintos />
						<Typography>Subcontratos</Typography>
					</Breadcrumbs>
				</Grid>
			</Grid>
			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard />
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
