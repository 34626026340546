import {
	TableCell,
	TableRow,
	Typography,
} from "@material-ui/core";
import { FC } from "react";

type Props = {
	colSpan: number;
	message?: string;
};

export const TableEmpty: FC<Props> = ({
	colSpan,
	message = "No encontamos datos.",
}) => {
	return (
		<TableRow>
			<TableCell colSpan={colSpan}>
				<Typography
					variant="h5"
					style={{ margin: "5rem", textAlign: "center" }}
				>
					{message}
				</Typography>
			</TableCell>
		</TableRow>
	);
};
