import {
	Checkbox,
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import EventIcon from "@material-ui/icons/Event";
import * as React from "react";
import { useEffect, useState } from "react";
import BorderColorIcon from "@material-ui/icons/BorderColor";

import { useDispatch, useSelector } from "react-redux";
import {
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import {
	addDateTotList,
	addRealDateTotList,
	getPdfDocument,
	updateDateTotList,
	updateRealDate,
} from "../../redux/actions/listsActions";
import { restoreProject } from "../../redux/actions/projectsActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { IBusinessState } from "../../redux/reducers/businessReducer";
import { IListsState } from "../../redux/reducers/listsReducer";
import { RootState } from "../../redux/store/reducer";
import { useStyles } from "../../theme/useStyles";
import { DateSelected } from "../Dateselected";
import { RealDateSelected } from "../RealDateSelected";
import { PdfListadoDialog } from "../PdfListadoDialog";
import { LoaderModal } from "../LoaderModal";
import { ChevronRight, PieChart } from "@material-ui/icons";
import { formatDate } from "../../utils/utils";
import {
	DropdownOption,
	DropdownMenu,
} from "../Generic/DropdownMenu";
import {
	AlertTriangle,
	Edit,
	RotateCcw,
	Trash2,
} from "react-feather";
import { Opener } from "../CheckPro/Opener";

interface RowProps {
	project: any;
	checkedBox: boolean;
	handleOpenEditDialog: (value: any) => void;
	handleOpenConfirmDialog: (value: any) => void;
	handleOpenResponsiblesDialog: (value: any) => void;
	handleChecksButton: (value: any) => void;
	handleCheckedAdd: (
		project: any,
		checkedStatus: boolean
	) => void;
	handleStatsButton: (value: any) => void;
	selectedListados: any[];
}

export const DataRow = (props: RowProps) => {
	const {
		project,
		checkedBox,
		handleOpenEditDialog,
		handleOpenConfirmDialog,
		handleOpenResponsiblesDialog,
		handleChecksButton,
		handleCheckedAdd,
		selectedListados,
	} = props;
	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);
	const { addChecklistState, PdfisLoading } = useSelector<
		RootState,
		IListsState
	>((state) => state.listsReducer);
	const dispatch = useDispatch();
	const theme = useTheme();
	const classes = useStyles();
	const [showDate, setShowDate] = useState(false);
	const [myChecked, setMyChecked] = React.useState(false);
	const { projectId, revisionId, listadoId } =
		useParams<any>();
	const [idList, setIdList] = useState("");
	const [edit, setEdit] = useState(false);
	const [isedit, setIsdit] = useState(false);
	const [showRealDate, setShowRealDate] = useState(false);
	const [showPdf, setShowPdf] = useState(false);
	const [projectSelected, setProjectSelected] = useState(
		[]
	);
	const [dateSelected, setDateSelected] =
		React.useState("");
	const [dateReal, setDateReal] = React.useState("");
	const { pathname } = useLocation();
	const history = useHistory();
	const realDate = () => {
		setShowRealDate(!showRealDate);
		setIdList("");
	};

	const openClose = () => {
		setShowDate(!showDate);
		setIdList("");
	};
	const abrirCerrarPdf = () => {
		setShowPdf(!showPdf);
	};
	const handleAcepDate = (value: any) => {
		// idList tiene  listado seleccionado
		const selectedId = idList;
		setIdList("");
		if (edit === true) {
			dispatch(
				updateDateTotList(
					value,
					projectId,
					revisionId,
					idList,
					true
				)
			);
		} else {
			dispatch(
				addDateTotList(
					value,
					projectId,
					revisionId,
					idList,
					true
				)
			);
			setShowDate(false);
		}
	};
	const handleRealDate = (value: any) => {
		// idList tiene  listado seleccionado
		const selectedId = idList;
		setIdList("");
		if (edit === true) {
			dispatch(
				updateRealDate(
					value,
					projectId,
					revisionId,
					idList,
					true
				)
			);
		} else {
			dispatch(
				addRealDateTotList(
					value,
					projectId,
					revisionId,
					idList,
					true
				)
			);
			setShowDate(false);
		}
	};

	useEffect(() => {
		if (addChecklistState === "Success") {
			setMyChecked(false);
		}
	}, [addChecklistState]);

	const redirectToCharts = () =>
		history.push(
			`/administrador/checklist-proyectos/estadisticas/${projectId}/${revisionId}/${project.id}`
		);

	const getMenuOptions = (
		project: any
	): DropdownOption[] => {
		const tipoUsuario = auth.user?.tipoUsuario ?? "";
		const { Estado } = project;

		const options: DropdownOption[] = [];

		// Estas opciones son solo para Administradores.
		if (
			tipoUsuario !== "UsuarioEmpresa" &&
			Estado !== "Eliminado"
		)
			options.push(
				{
					label: "Editar",
					icon: (
						<Edit
							size={22}
							color={theme.palette.primary.main}
						/>
					),
					onClick: () => handleOpenEditDialog(project),
				},
				{
					label: "Eliminar",
					icon: <Trash2 color="red" size={22} />,
					onClick: () => handleOpenConfirmDialog(project),
				}
			);
		// Estas opciones son globales
		options.push(
			{
				label: "Estadísticas",
				icon: <PieChart color="primary" />,
				onClick: () => redirectToCharts(),
			},
			{
				label: "Solicitar informe",
				icon: <PictureAsPdfIcon color="error" />,
				onClick: () => {
					abrirCerrarPdf();
					setProjectSelected(project);
				},
			}
		);
		return options;
	};

	return (
		<>
			<TableRow
				hover
				className={classes.styledRow}
				style={{
					backgroundColor: project.AlertaEliminacion
						? "#fff8e1"
						: "",
				}}
			>
				{auth.user?.tipoUsuario !== "UsuarioEmpresa" &&
					(project.Estado !== "Eliminado" ? (
						<TableCell>
							<Tooltip title="Asignar Checklist">
								<Checkbox
									id={project.id}
									checked={
										!!selectedListados.find(
											(l) => l.id === project.id
										)
									}
									onChange={() => {
										setMyChecked(!myChecked);
										let myVar = document.getElementById(
											project.id
										) as HTMLInputElement;

										handleCheckedAdd(
											project,
											myVar.checked
										);
									}}
									color="primary"
									disabled={
										project.Estado === "Eliminado"
											? true
											: false
									}
									inputProps={{
										"aria-label": "secondary checkbox",
									}}
								/>
							</Tooltip>
						</TableCell>
					) : project.AlertaEliminacion ? (
						<TableCell>
							<Tooltip title="El Proyecto será eliminado definitivamente">
								<AlertTriangle
									color="#ff5722"
									style={{
										paddingTop: "2px",
										marginBottom: "-4px",
									}}
								/>
							</Tooltip>
							Dias para ser eliminado:{" "}
							{project?.DiasEliminacion}
						</TableCell>
					) : (
						""
					))}

				<TableCell>{project.Nombre}</TableCell>
				<TableCell>
					{project?.ChecklistName
						? project?.ChecklistName
						: "--"}
				</TableCell>
				{pathname.includes("checklist-proyectos") && (
					<TableCell>
						{(project?.Resueltas || "0") +
							" de " +
							(project?.TotalRespuestas || "0")}
					</TableCell>
				)}
				<TableCell>
					{project.Responsable
						? project.Responsable.NombreCompleto
						: "Sin Responsable"}
				</TableCell>
				<TableCell align="center">
					{project?.FechaPropuesta ? (
						<Typography>
							{formatDate(new Date(project.FechaPropuesta))}{" "}
							<Tooltip title="Editar Fecha Propuesta">
								<IconButton
									onClick={() => {
										openClose();
										setEdit(true);
										setIdList(project?.id);
										setDateSelected(
											formatDate(
												new Date(project.FechaPropuesta)
											)
										);
									}}
									color="inherit"
									size="small"
								>
									<BorderColorIcon
										style={{ fontSize: 16 }}
										color="primary"
									/>
								</IconButton>
							</Tooltip>
						</Typography>
					) : (
						<Tooltip title="Asignar Fecha Propuesta">
							<IconButton
								size="small"
								color="primary"
								onClick={() => {
									openClose();
									setEdit(false);
									setIdList(project?.id);
								}}
							>
								<EventIcon />
							</IconButton>
						</Tooltip>
					)}
				</TableCell>
				<TableCell align="center">
					{project?.FechaReal ? (
						<Typography>
							{formatDate(new Date(project.FechaReal))}{" "}
							<Tooltip title="Editar Fecha Real">
								<IconButton
									onClick={() => {
										realDate();
										setIsdit(true);
										setIdList(project?.id);
										setDateReal(project?.FechaReal);
									}}
									color="inherit"
									size="small"
								>
									<BorderColorIcon
										style={{ fontSize: 16 }}
										color="primary"
									/>
								</IconButton>
							</Tooltip>
						</Typography>
					) : (
						<Tooltip title="Asignar Fecha Real">
							<IconButton
								size="small"
								color="primary"
								onClick={() => {
									realDate();
									setIsdit(false);
									setIdList(project?.id);
								}}
							>
								<EventIcon />
							</IconButton>
						</Tooltip>
					)}
				</TableCell>

				{/* Celda de acciones */}
				<TableCell style={{ whiteSpace: "nowrap" }}>
					<DropdownMenu
						Opener={Opener}
						options={getMenuOptions(project)}
					/>
					{project.Estado !== "Eliminado" ? (
						<Tooltip title="Recintos">
							<IconButton
								size="small"
								color="primary"
								onClick={() => handleChecksButton(project)}
							>
								<ChevronRight />
							</IconButton>
						</Tooltip>
					) : (
						""
					)}
					{project.Estado === "Eliminado" ? (
						<Tooltip title="Restaurar">
							<span>
								<IconButton
									size="small"
									disabled={
										selectedBusiness?.Revisiones
											.RevisionesUsados! >=
										selectedBusiness?.Revisiones
											.LimiteRevisiones!
									}
									onClick={() => {
										dispatch(restoreProject(project));
									}}
								>
									<RotateCcw
										color={
											selectedBusiness?.Revisiones
												.RevisionesUsados! <
											selectedBusiness?.Revisiones
												.LimiteRevisiones!
												? "#00e676"
												: "#808080"
										}
									/>
								</IconButton>
							</span>
						</Tooltip>
					) : (
						""
					)}
				</TableCell>
			</TableRow>
			<DateSelected
				open={showDate}
				onClose={openClose}
				onAccept={(value) => handleAcepDate(value)}
				value={dateSelected}
			/>

			<RealDateSelected
				open={showRealDate}
				onClose={realDate}
				onAccept={(value) => handleRealDate(value)}
				value={dateReal}
			/>

			<PdfListadoDialog
				open={showPdf}
				onClose={abrirCerrarPdf}
				onAcceptRecinto={async () => {
					await dispatch(
						getPdfDocument(projectSelected, true)
					);
				}}
				onAcceptSubcontrato={async () => {
					await dispatch(
						getPdfDocument(projectSelected, false)
					);
				}}
			/>

			<LoaderModal open={PdfisLoading} />
		</>
	);
};
