import * as types from "../../constants";
import { AppThunk } from "../../models/app-thunk";
import {
	resetPassword as authResetPassword,
	signUp as authSignUp,
} from "../../services/authService";
import { auth, firestore } from "../../firebase/firebase";
import { openSnackLogin } from "./uiActions";
import { SnackState } from "../../models/snack-state";
import { Usuario } from "src/models/Usuario";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";

interface Credentials {
	email: string;
	password: string;
	name?: string;
}

export function signIn(credentials: Credentials): AppThunk {
	return async (dispatch) => {
		dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
		try {
			await auth
				.signInWithEmailAndPassword(
					credentials.email,
					credentials.password
				)
				.catch((errorLogin) => {
					console.error(errorLogin.code);
					if (
						errorLogin.code === "auth/too-many-requests"
					) {
						dispatch(
							openSnackLogin(
								"¡Demasiados Intentos. Recuperar Contraseña!",
								SnackState.ERROR
							)
						);
					} else if (
						errorLogin.code === "auth/user-not-found"
					) {
						dispatch(
							openSnackLogin(
								"¡Credenciales incorrectas!",
								SnackState.ERROR
							)
						);
					} else if (
						errorLogin.code === "auth/wrong-password" ||
						errorLogin.code === "auth/wrong-email"
					) {
						dispatch(
							openSnackLogin(
								"Contraseña incorrecta!",
								SnackState.ERROR
							)
						);
					} else {
						dispatch(
							openSnackLogin(
								"Hubo un problema.",
								SnackState.ERROR
							)
						);
					}
				});

			// if (response.user) {
			//   dispatch({
			//     type: types.AUTH_SIGN_IN_SUCCESS,
			//     id: response.user.uid,
			//     email: response.user.email,
			//     name: response.user.displayName,
			//   });
			// } else {
			//   throw new Error("Error desconocido");
			// }
		} catch (error) {
			dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
		}
	};
}

export function validateSession(): AppThunk {
	return (dispatch) => {
		dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
		auth.onAuthStateChanged(async (user) => {
			if (user) {
				const token = await user.getIdToken(false);
				const userSnap = await firestore
					.collection("Usuarios")
					.where("Email", "==", user.email)
					.get();

				const userDB = {
					...userSnap.docs[0].data(),
					id: userSnap.docs[0].id,
				} as Usuario;

				if (userDB.Activado === false) {
					dispatch({
						type: types.AUTH_SIGN_OUT,
					});
				} else {
					dispatch({
						og: userDB,
						type: types.AUTH_SIGN_IN_SUCCESS,
						id: user.uid,
						localId: userDB.id,
						email: user.email,
						name: `${userDB.Nombre} ${userDB.Apellido}`,
						image: user.photoURL,
						token,
						tipoUsuario: userDB.TipoUsuario,
						empresaReference: userDB.EmpresaReference,
						activado: userDB.Activado,
						modulos: {
							Revisiones: !!userDB?.Revisiones,
							ChecklistRevisiones: !!userDB?.Checklist,
							Checklists: !!userDB?.ChecklistsOT,
						},
					});
				}
			} else {
				dispatch({
					type: types.AUTH_SIGN_OUT,
				});
			}
		});
	};
}

export function signUp(credentials: Credentials): AppThunk {
	return async (dispatch) => {
		dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

		try {
			const response =
				await auth.createUserWithEmailAndPassword(
					credentials.email,
					credentials.password
				);

			await response.user?.updateProfile({
				displayName: credentials.name,
			});
		} catch (error) {
			dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
		}

		return authSignUp(credentials)
			.then((response) => {
				dispatch({
					type: types.AUTH_SIGN_UP_SUCCESS,
					id: response.id,
					email: response.email,
					name: response.name,
				});
			})
			.catch((error) => {
				dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
				throw error;
			});
	};
}

export function signOut(): AppThunk {
	return async () => {
		FirebaseListener.closeAll();
		await auth.signOut();
	};
}

export function resetPassword(
	credentials: Credentials
): AppThunk {
	return async (dispatch) => {
		dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

		return authResetPassword(credentials)
			.then((response) => {
				dispatch({
					type: types.AUTH_RESET_PASSWORD_SUCCESS,
					email: response.email,
				});
			})
			.catch((error) => {
				dispatch({
					type: types.AUTH_RESET_PASSWORD_FAILURE,
				});
				throw error;
			});
	};
}
