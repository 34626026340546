import { Usuario } from "src/models/Usuario";
import Firebase from "firebase";
import firebase from "firebase/app";
import {
	AnyProject,
	BaseProjectLegacy,
	isChecklist,
	isChecklistRevision,
	isRevision,
} from "src/models/Proyecto";
import { firestore } from "src/firebase/firebase";

export const updateBusinessCounters = (
	user: Pick<
		Usuario,
		"Revisiones" | "Checklist" | "ChecklistsOT"
	>,
	previousUser: Usuario
) => {
	const counters: {
		"Revisiones.UsuariosUsados"?: Firebase.firestore.FieldValue;
		"CheckList.UsuariosUsados"?: Firebase.firestore.FieldValue;
		"ChecklistsOT.UsuariosUsados"?: Firebase.firestore.FieldValue;
	} = {};

	const {
		Revisiones: hadRevisions,
		Checklist: hadChecklists,
		ChecklistsOT: hadNeoChecklists,
	} = previousUser ?? {};

	const {
		Revisiones: hasRevisions,
		Checklist: hasChecklists,
		ChecklistsOT: hasNeoChecklists,
	} = user;

	if (hadRevisions && !hasRevisions)
		counters["Revisiones.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(-1);
	else if (!hadRevisions && hasRevisions)
		counters["Revisiones.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(1);

	if (hadChecklists && !hasChecklists)
		counters["CheckList.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(-1);
	else if (!hadChecklists && hasChecklists)
		counters["CheckList.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(1);

	if (hadNeoChecklists && !hasNeoChecklists)
		counters["ChecklistsOT.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(-1);
	else if (!hadNeoChecklists && hasNeoChecklists)
		counters["ChecklistsOT.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(1);

	return counters;
};

export const updateProjectAssignees = (
	userId: string,
	projects?: {
		toAssign?: AnyProject[];
		toUnassign?: AnyProject[];
	}
) => {
	const { toAssign, toUnassign } = projects ?? {};

	const projectsToUpdate: {
		data: Partial<BaseProjectLegacy>;
		ref: Firebase.firestore.DocumentReference;
	}[] = [];

	// quitar responsables de proyectos que se desasignaron
	toUnassign?.forEach((project) =>
		projectsToUpdate.push({
			ref: firestore.doc(
				`${getCollectionName(project)}/${project.id}`
			),
			data: {
				Responsables:
					firebase.firestore.FieldValue.arrayRemove(
						userId
					) as any,
			},
		})
	);

	// asignar responsables de proyectos que se asignaron
	toAssign?.forEach((project) =>
		projectsToUpdate.push({
			ref: firestore.doc(
				`${getCollectionName(project)}/${project.id}`
			),
			data: {
				Responsables:
					firebase.firestore.FieldValue.arrayUnion(
						userId
					) as any,
			},
		})
	);

	return projectsToUpdate;
};

export const decrementBusinessCounters = (
	user: Usuario
) => {
	const {
		Revisiones,
		Checklist,
		ChecklistsOT: NeoChecklists,
	} = user;

	const counters: {
		"Revisiones.UsuariosUsados"?: Firebase.firestore.FieldValue;
		"CheckList.UsuariosUsados"?: Firebase.firestore.FieldValue;
		"NeoChecklists.UsuariosUsados"?: Firebase.firestore.FieldValue;
	} = {};

	if (Revisiones)
		counters["Revisiones.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(-1);

	if (Checklist)
		counters["CheckList.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(-1);

	if (NeoChecklists)
		counters["NeoChecklists.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(-1);

	return counters;
};

export const getAssignedProjects = async (
	from: Usuario
) => {
	const { EmpresaReference } = from;

	const [revisions, revisionChecklists, checklists] =
		await Promise.all([
			firestore
				.collection("Proyectos")
				.where("Responsables", "array-contains", from.id)
				.get(),

			firestore
				.collection("ProyectosChecklist")
				.where("Responsables", "array-contains", from.id)
				.get(),

			EmpresaReference.collection("ProyectosOT")
				.where("Responsables", "array-contains", from.id)
				.get(),
		]);

	return { revisions, revisionChecklists, checklists };
};

export const addAssigneesToProject = (
	userId: string,
	projects: AnyProject[]
) => {
	const projectsToUpdate: {
		data: Partial<BaseProjectLegacy>;
		ref: Firebase.firestore.DocumentReference;
	}[] = [];

	projects?.forEach((project) =>
		projectsToUpdate.push({
			ref: firestore.doc(
				`${getCollectionName(project)}/${project.id}`
			),
			data: {
				Responsables:
					firebase.firestore.FieldValue.arrayUnion(
						userId
					) as any,
			},
		})
	);

	return projectsToUpdate;
};

export const incrementBusinessCounters = (
	user: Pick<
		Usuario,
		"Revisiones" | "Checklist" | "ChecklistsOT"
	>
) => {
	const { Revisiones, Checklist, ChecklistsOT } = user;
	const counters: {
		"Revisiones.UsuariosUsados"?: Firebase.firestore.FieldValue;
		"CheckList.UsuariosUsados"?: Firebase.firestore.FieldValue;
		"ChecklistsOT.UsuariosUsados"?: Firebase.firestore.FieldValue;
	} = {};

	if (Revisiones)
		counters["Revisiones.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(1);

	if (Checklist)
		counters["CheckList.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(1);

	if (ChecklistsOT)
		counters["ChecklistsOT.UsuariosUsados"] =
			firebase.firestore.FieldValue.increment(1);

	return counters;
};

const getCollectionName = (p: AnyProject) => {
	if (isRevision(p)) return "Proyectos" as const;
	else if (isChecklistRevision(p))
		return "ProyectosChecklist" as const;
	else if (isChecklist(p))
		return `${p.EmpresaReference.path}/ProyectosOT` as const;
	else throw Error("Modulo no soportado.");
};
