import React from "react";
import { TablePagination } from "@material-ui/core";
import { useTableContext } from "./Context";

export const TablePaginator = () => {
	const {
		elementsProps,
		list,
		// Paginación
		page,
		onPageChange,
		rowsPerPage,
		rowsPerPageOptions,
		onRowsPerPageChange,
		columns,
		count,
	} = useTableContext();
	const { TablePaginationProps } = elementsProps;

	return (
		<TablePagination
			align="right"
			{...TablePaginationProps}
			page={page}
			onChangePage={onPageChange}
			rowsPerPage={rowsPerPage}
			rowsPerPageOptions={rowsPerPageOptions}
			onChangeRowsPerPage={onRowsPerPageChange}
			count={count ?? list.length}
			colSpan={columns.length}
		/>
	);
};
