import { Box, Button, DialogActions, DialogTitle } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 150,
    },
  })
);

interface Iprops {
  open: boolean;
  onClose: () => void;
  onAccept: (value: any) => void;
  value: string;
}
export const RealDateSelected: React.FC<Iprops> = ({
  open,
  onClose,
  onAccept,
  value: defaultValue,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>Fecha Real</DialogTitle>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField
          style={{ padding: 10, paddingBottom: 20 }}
          id="date"
          type="date"
          value={value}
          onChange={(e: any) => {
            setValue(e.target.value);
          }}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <DialogActions>
        {value === "" ? (
          ""
        ) : (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => onAccept(value)}
          >
            Agregar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
