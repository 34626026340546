import {
	createSlice,
	PayloadAction,
} from "@reduxjs/toolkit";
import { ChecklistOT } from "src/models/OT/Checklist";
import {
	AssignedPlaceOT,
	PlaceOT,
} from "src/models/OT/Place";
import { ChecklistProject } from "src/models/OT/Projects";
import {
	AnswerOT,
	SheetAnswerTuple,
	SheetAnswerGroupOT,
	SheetOT,
} from "src/models/OT/Sheet";
import { getRef } from "src/utils/firebase";
import { assigner } from "src/utils/redux/reducers";

type Slice = typeof initialState;

const initialState = {
	projects: null as ChecklistProject[] | null,

	/**
	 * Selected project data
	 */
	project: {
		checklists: null as ChecklistOT[] | null,
		places: null as PlaceOT[] | null,
	},

	checklist: {
		places: null as AssignedPlaceOT[] | null,
		responses: null as SheetOT[] | null,
		graphData: null as { [key: string]: number } | null,
		availableSectors: null as string[] | null,
	},

	sheets: {
		total: 0,
	},

	selected: {
		project: null as ChecklistProject | null,
		checklist: null as ChecklistOT | null,
		place: null as PlaceOT | null,
		assignedPlace: null as AssignedPlaceOT | null,

		sheet: null as SheetOT | null,
		responseGroup: null as SheetAnswerGroupOT | null,
		responseAnswer: null as AnswerOT | null,
		responseTuple: null as SheetAnswerTuple | null,

		places: [] as PlaceOT[],
		assignedPlaces: [] as AssignedPlaceOT[],
	},
	loading: false,
};

const { reducer: otReducer, actions: otActions } =
	createSlice({
		name: "checklists-ot",
		initialState,
		reducers: {
			assignChecklist:
				assigner<Slice["checklist"]>("checklist"),

			assignSelected:
				assigner<Slice["selected"]>("selected"),

			assignSheets: assigner<Slice["sheets"]>("sheets"),

			setProjects: (
				state,
				{
					payload,
				}: PayloadAction<ChecklistProject[] | null>
			) => {
				state.projects = payload;
			},

			setSelectedProject: (
				state,
				{ payload }: PayloadAction<ChecklistProject | null>
			) => {
				state.selected.project = payload;
			},

			setProjectChecklists: (
				state,
				{ payload }: PayloadAction<ChecklistOT[] | null>
			) => {
				state.project.checklists = payload;
			},

			setSelectedChecklist: (
				state,
				{ payload }: PayloadAction<ChecklistOT | null>
			) => {
				state.selected.checklist = payload;
			},

			setProjectPlaces: (
				state,
				{ payload }: PayloadAction<PlaceOT[] | null>
			) => {
				state.project.places = payload;
			},

			setSelectedPlace: (
				state,
				{ payload }: PayloadAction<PlaceOT | null>
			) => {
				state.selected.place = payload;
			},

			setSelectedAssignedPlace: (
				state,
				{ payload }: PayloadAction<AssignedPlaceOT | null>
			) => {
				state.selected.assignedPlace = payload;
			},

			togglePlace: (
				state,
				{ payload }: PayloadAction<PlaceOT[] | PlaceOT>
			) => {
				if (Array.isArray(payload))
					state.selected.places = payload;
				else {
					const places = [...state.selected.places];
					const index = places.findIndex(
						(p) => getRef(p).id === getRef(payload).id
					);
					if (index === -1) places.push(payload);
					else places.splice(index, 1);
					state.selected.places = places;
				}
			},

			toggleAssignedPlace: (
				state,
				{
					payload,
				}: PayloadAction<
					AssignedPlaceOT[] | AssignedPlaceOT
				>
			) => {
				if (Array.isArray(payload))
					state.selected.assignedPlaces = payload;
				else {
					const places = [...state.selected.assignedPlaces];
					const index = places.findIndex(
						(p) => getRef(p).id === getRef(payload).id
					);
					if (index === -1) places.push(payload);
					else places.splice(index, 1);
					state.selected.assignedPlaces = places;
				}
			},

			setLoading: (
				state,
				{ payload }: PayloadAction<boolean>
			) => {
				state.loading = payload;
			},
		},
	});

export { otReducer, otActions };
