import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";

interface ConfirmDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  title: string;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onConfirm,
  onClose,
  title,
}) => {
  const [activated, setActivated] = useState(false);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="confirm-dialog-title"
      >
        <DialogTitle aria-labelledby="confirm-dialog-title">
          {title}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={() => {
              onConfirm();
              setActivated(true);
              setTimeout(() => {
                onClose();
                setActivated(false);
              }, 400);
            }}
            disabled={activated}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
