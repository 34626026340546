import { PayloadAction } from "@reduxjs/toolkit";
import { get, set } from "lodash";

export type Payload<Action> = PayloadAction<Action>;

/**
 * Creates a reducer that assigns an `object` to a property of the state.
 * You can use dots to indicate the path to the property.
 *
 * Example:
 * ```ts
 * type Thing ={
 *   a: number;
 *   b: number;
 *   c: number;
 * }
 *
 * const reducer = createReducer({
 *   name: 'my-reducer',
 *   initialState: {
 *     thing: {
 *       a: 1,
 *       b: 2,
 *       c: 3,
 *       innerThing: {
 *         a: 1,
 *         b: 2,
 *         c: 3
 *       }
 *     }
 *   },
 *   reducers: {
 *     assignThing: assigner<Thing>('thing'),
 *     assignInnerThing: assigner<Thing>('thing.innerThing')
 *   }
 * });
 *
 * dispatch(reducer.actions.assignThing({ a: 4, b: 5, c: 6 }));
 * dispatch(reducer.actions.assignThing({ a: 4, c: 6 }));
 * dispatch(reducer.actions.assignInnerThing({ a: 4, b: 5, c: 6 }));
 * dispatch(reducer.actions.assignInnerThing({  b: 5 }));
 * ```
 */
export const assigner =
	<T extends Object, State extends Object = {}>(
		name: string
	) =>
	(state: State, { payload }: Payload<Partial<T>>) => {
		let section = get(state, name);

		if (!section)
			throw new Error(
				`The section "${name}" does not exist in your state, thus it cannot be assigned.`
			);

		if (typeof section !== "object")
			throw new Error(
				`The section "${name}" in your state is not an object, thus it cannot be assigned.`
			);

		section = { ...section, ...payload };
		set(state, name, section);
	};

/**
 * Creates a reducer that toggles a `boolean` value in the state.
 * You can use dots to indicate the path to the property.
 * The reducer may receive a `boolean` to set the property, or `undefined` to toggle it.
 *
 * Example:
 * ```ts
 * const reducer = createReducer({
 *   name: 'my-reducer',
 *   initialState: {
 *     a: true,
 *     thing: {
 *      b: true,
 *       innerThing: {
 *         c: false
 *      }
 *   },
 *   reducers: {
 *     toggle: toggler('a'),
 *     toggleThing: toggler('thing.b'),
 *     toggleInnerThing: toggler('thing.innerThing.c')
 *   }
 * });
 *
 * dispatch(reducer.actions.toggle());
 * dispatch(reducer.actions.toggle(false));
 * dispatch(reducer.actions.toggleThing());
 * dispatch(reducer.actions.toggleThing(false));
 * dispatch(reducer.actions.toggleInnerThing());
 * dispatch(reducer.actions.toggleInnerThing(false));
 *```
 */
export const toggler =
	(name: string) =>
	(
		state: any,
		{ payload }: Payload<boolean | undefined>
	) => {
		const section = get(state, name);

		if (typeof section !== "boolean")
			throw new Error(
				`The section "${name}" in your state is not a boolean, thus it cannot be toggled.`
			);

		set(
			state,
			name,
			typeof payload === "boolean" ? payload : !section
		);
	};

/**
 * Creates a reducer that sets `any` value in the state.
 * You can use dots to indicate the path to the property.
 * The reducer may receive a `boolean` to set the property, or `undefined` to toggle it.
 *
 * Example:
 * ```ts
 * const reducer = createReducer({
 *   name: 'my-reducer',
 *   initialState: {
 *     a: true,
 *     thing: {
 *      b: true,
 *       innerThing: {
 *         c: false
 *      }
 *   },
 *   reducers: {
 *     toggle: toggler('a'),
 *     toggleThing: toggler('thing.b'),
 *     toggleInnerThing: toggler('thing.innerThing.c')
 *   }
 * });
 *
 * dispatch(reducer.actions.toggle());
 * dispatch(reducer.actions.toggle(false));
 * dispatch(reducer.actions.toggleThing());
 * dispatch(reducer.actions.toggleThing(false));
 * dispatch(reducer.actions.toggleInnerThing());
 * dispatch(reducer.actions.toggleInnerThing(false));
 *```
 */
export const setter =
	<T = any, State extends Object = {}>(name: string) =>
	(state: State, { payload }: Payload<T>) => {
		const section = get(state, name);

		if (section === undefined)
			throw Error(
				`The section "${name}" does not exist in your state, thus it cannot be set.`
			);

		set(state, name, payload);
	};
