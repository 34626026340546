import {
	Breadcrumbs,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	FormControlLabel,
	InputAdornment,
	Switch,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { FC, useEffect, useMemo } from "react";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { useUsers } from "src/hooks/redux/useUsers";
import * as Yup from "yup";
import Firebase from "firebase";
import { getRef } from "src/utils/firebase";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useDispatch } from "src/redux/store";
import { getUsers } from "src/redux/actions/usersActions";

type Props = DialogProps & {
	mode: "assign" | "edit";
};

export const NeoPlaceAssignDialog: FC<Props> = ({
	mode,
	...props
}) => {
	const dispatch = useDispatch();
	const { selected, set, create, update, loading, toggle } =
		useProjectsOT();
	const { users } = useUsers();

	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleSubmit,
		resetForm,
	} = useFormik({
		enableReinitialize: true,
		initialValues: {
			assigned: true,
			quantity: 0,
			reviewers:
				[] as (Firebase.firestore.DocumentReference | null)[],
		},

		validationSchema: Yup.object({
			quantity: Yup.number()
				.min(1, "Debe ser mayor que 0.")
				.required("Este dato es importante."),
			selectedPlaces: Yup.array().min(1),
			reviewers:
				mode === "assign"
					? Yup.array()
							.transform(
								(
									value: (Firebase.firestore.DocumentReference | null)[]
								) =>
									value
										.filter((r) => r !== null)
										.map((r) => r!)
							)
							.min(
								1,
								"Debes seleccionar al menos un revisor."
							)
							.max(
								6,
								"Sobrepasaste el límite de revisores."
							)
					: Yup.array(),
		}),

		onSubmit: async ({ assigned, quantity, reviewers }) => {
			if (mode === "assign") {
				if (selected.places.length === 0) return;
				const _reviewers = reviewers
					.filter((r) => r !== null)
					.map((r) => r!);

				if (_reviewers.length === 0) return;

				await create.checklist.places(
					...selected.places.map((p) => ({
						basePlace: p,
						assignedPlace: {
							Asignado: assigned,
							Cantidad: quantity,
							Revisores: _reviewers,
						},
					}))
				);
				toggle.place([]);
			} else {
				if (selected.assignedPlaces.length === 0) return;
				await update.checklist.places(
					...selected.assignedPlaces.map((p) => ({
						_ref: getRef(p),
						Asignado: assigned,
						Cantidad: quantity,
						Revisores: reviewers,
					}))
				);
				toggle.assignedPlace([]);
			}

			props.onClose?.({}, "escapeKeyDown");
		},
	});

	// -----
	// Reviewers logic
	// -----
	const availableReviewers = useMemo(() => {
		return (
			users?.filter((u) =>
				values.reviewers.every((r) => r?.id !== u._ref?.id)
			) ?? []
		);
	}, [users, values.reviewers]);

	const setReviewer = (
		index: number,
		reviewerRef: Firebase.firestore.DocumentReference | null
	) => {
		const reviewers = [...values.reviewers];

		if (reviewerRef === null)
			reviewers.splice(index, 6, reviewerRef);
		else reviewers[index] = reviewerRef;

		setFieldValue("reviewers", reviewers);
	};
	// -----

	/**
	 * This logic sets the initial values of the form
	 * in case of one value is selected to edit.
	 */
	useEffect(() => {
		if (
			selected.assignedPlaces.length === 1 &&
			mode === "edit"
		) {
			setFieldValue(
				"quantity",
				selected.assignedPlaces[0].Cantidad
			);
			setFieldValue(
				"assigned",
				selected.assignedPlaces[0].Asignado
			);
			setFieldValue(
				"reviewers",
				selected.assignedPlaces[0].Revisores
			);
		} else resetForm();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode, selected.assignedPlaces]);

	/**
	 * Get business users
	 */
	useEffect(() => {
		if (!props.open) return;
		dispatch(getUsers());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open]);

	return (
		<Dialog {...props} fullWidth>
			<DialogTitle
				bigText={
					mode === "edit"
						? "Editar espacio"
						: "Completar espacio"
				}
				smallText={
					<Breadcrumbs>
						<Typography>
							{selected.assignedPlaces[0]?.Nombre}
						</Typography>
						<Typography>
							{selected.checklist?.Nombre}
						</Typography>
					</Breadcrumbs>
				}
			/>

			<DialogContent
				style={{
					display: "flex",
					flexDirection: "column",
					gap: 10,
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					label="Cantidad"
					id="quantity"
					type="number"
					error={!!(touched.quantity && errors.quantity)}
					helperText={touched.quantity && errors.quantity}
					value={values.quantity}
					onChange={handleChange}
					inputProps={{ min: 1 }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Typography>
									{selected.checklist?.Unidad}
								</Typography>
							</InputAdornment>
						),
					}}
				/>

				<FormControlLabel
					control={
						<Switch
							checked={values.assigned}
							onChange={(_, checked) =>
								setFieldValue("assigned", checked)
							}
						/>
					}
					label="Asignado"
				/>

				{Array(6)
					.fill(0)
					.map((_, i) => (
						<Autocomplete
							disabled={
								i !== 0 && !values.reviewers.at(i - 1)
							}
							size="small"
							value={
								users?.find(
									(u) =>
										u._ref!.id === values.reviewers[i]?.id
								) ?? null
							}
							getOptionSelected={(option) =>
								option._ref!.id === values.reviewers[i]?.id
							}
							onChange={(_, value) =>
								setReviewer(i, value?._ref ?? null)
							}
							key={`reviewer-${i}`}
							options={availableReviewers}
							getOptionLabel={(option) =>
								`${option.Nombre} ${option.Apellido} (${
									option.Cargo ?? "Sin cargo"
								})`
							}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label={`Revisor ${i + 1}`}
									size="small"
									error={
										i === 0 &&
										touched.reviewers &&
										!!errors.reviewers
									}
									helperText={
										i === 0 &&
										touched.reviewers &&
										errors.reviewers
									}
								/>
							)}
						/>
					))}
			</DialogContent>

			<DialogActions>
				<Button
					disabled={loading}
					onClick={() => {
						props.onClose?.({}, "backdropClick");
						selected.assignedPlace && set.place(null);
					}}
				>
					Cancelar
				</Button>

				<Button
					disabled={loading}
					onClick={() => handleSubmit()}
					color="primary"
					variant="contained"
				>
					{loading ? (
						<CircularProgress size={24} />
					) : (
						"Guardar"
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
