import { Box, Typography } from "@material-ui/core";

export const PendingIcon = () => (
	<Box
		bgcolor="purple"
		height={19}
		width={19}
		borderRadius={19}
		display="flex"
		alignItems="center"
		justifyContent="center"
	>
		<Typography
			style={{
				userSelect: "none",
				color: "white",
				fontSize: 12,
				textAlign: "center",
				paddingTop: 1,
			}}
		>
			<b>P</b>
		</Typography>
	</Box>
);
