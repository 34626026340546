import { useDispatch, useSelector } from "src/redux/store";
import { getUsers } from "../../redux/actions/usersActions";
import { Empresa } from "src/models/Empresa";

export const useUsers = () => {
	const dispatch = useDispatch();
	const { users } = useSelector((s) => s.usersReducer);

	return {
		users,
		getUsers: (ofBusiness?: Empresa) =>
			dispatch(getUsers(ofBusiness)),
	};
};
