import { useState } from "react";

export const useSelectRows = (limit?: number) => {
  const [selected, setSelected] = useState<string[]>([]);

  const handleClickSelect = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (limit && newSelected.length - 1 >= limit) {
      return;
    }
    setSelected(newSelected);
  };
  const reset = () => setSelected([]);
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  return {
    isSelected,
    handleClickSelect,
    reset,
    selected,
  };
};
