import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage"

export const firebaseConfig = {
	apiKey: "AIzaSyDhK59mUV5BAU32d0QnWacB0mb9gLSaCXY",
	authDomain: "checkpro-3a90a.firebaseapp.com",
	projectId: "checkpro-3a90a",
	storageBucket: "checkpro-3a90a.appspot.com",
	messagingSenderId: "925834999640",
	appId: "1:925834999640:web:eb37567b902aeb3d67d72d",
	measurementId: "G-ZFCLPKKTRG",
};

export const app = firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();
export const firestore = firebase.firestore();
// firebase.setLogLevel("debug");
// firestore
//   .enablePersistence()
export const auth = firebase.auth();
export const storage = firebase.storage();

export const DocumentReference = firebase.firestore.DocumentReference;
export const Timestamp = firebase.firestore.Timestamp;
