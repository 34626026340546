/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

type Props = {
	loading?: boolean;
};

export const RejectedByGraphOT: FC<Props> = ({
	loading,
}) => {
	const { checklist } = useProjectsOT();

	if (loading || !checklist.graphData)
		return <Skeleton variant="rect" height={400} />;

	return (
		<Box display="flex" flexDirection="column" width="100%">
			<List>
				{Object.entries(checklist.graphData).map(
					([key, value]) => (
						<ListItem key={key} divider>
							<ListItemText primary={key} />
							<ListItemIcon>: {value}</ListItemIcon>
						</ListItem>
					)
				)}
			</List>

			<Bar
				options={{
					legend: {
						display: false,
					},
					scales: {
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
									max: Math.ceil(
										(Math.max(
											...Object.values(checklist.graphData)
										) || 1) * 1.15
									),
								},
							},
						],
					},
					plugins: {
						labels: [
							{
								render: (col: any) => col.value,
								position: "outside",
								precision: 2,
							},
						],
					},
				}}
				data={{
					labels: Object.keys(checklist.graphData),
					datasets: [
						{
							label: "Rechazadas",
							data: Object.values(checklist.graphData),
							backgroundColor: "red",
						},
					],
				}}
			/>
		</Box>
	);
};
