import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Typography,
} from "@material-ui/core";
import { FC } from "react";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { hasRef } from "src/utils/firebase";

export const NeoChecklistDeleteDialog: FC<DialogProps> = (
	props
) => {
	const { remove, selected, set, loading } =
		useProjectsOT();

	const handleRemove = async () => {
		if (!selected.checklist || !hasRef(selected.checklist))
			return;
		await remove.project.checklist(selected.checklist);
		props.onClose?.({}, "escapeKeyDown");
		set.checklist(null);
	};

	return (
		<Dialog {...props}>
			<DialogTitle>Eliminar lista de chequeo</DialogTitle>

			<DialogContent>
				<Typography>
					¿Estás seguro que quieres eliminar la lista de chequeo{" "}
					{selected.checklist?.Nombre}? Esta acción no se
					puede deshacer.
				</Typography>
			</DialogContent>

			<DialogActions>
				<Button
					disabled={loading}
					onClick={() =>
						props.onClose?.({}, "escapeKeyDown")
					}
				>
					No
				</Button>
				<Button
					disabled={loading}
					color="primary"
					variant="contained"
					onClick={handleRemove}
				>
					{loading ? <CircularProgress size={24} /> : "Si"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
