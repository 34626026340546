import { UserValidator } from "./validator";
import { FC } from "react";
import { AccountEnabler } from "src/components/CheckPro/AccountEnabler";
import { MailChanger } from "src/components/CheckPro/MailChanger";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";

export const UserAuthPanel: FC<
	UserValidator & { onClose?: VoidFunction }
> = ({ selectedUser, onClose }) => {
	return (
		<Box>
			{selectedUser ? (
				<>
					<AccountEnabler user={selectedUser} />

					<Box height={20} />

					<MailChanger
						setEmail={onClose}
						email={selectedUser.Email}
						userId={selectedUser.id}
					/>
				</>
			) : (
				<Skeleton variant="rect" height={250} />
			)}
		</Box>
	);
};
