import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import {
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getBusiness } from "../redux/actions/businessActions";
import { getOneProject } from "../redux/actions/projectsActions";
import { IAuthState } from "../redux/reducers/authReducer";
import { IBusinessState } from "../redux/reducers/businessReducer";
import { IProjectsState } from "../redux/reducers/projectsReducer";
import { RootState } from "../redux/store/reducer";
import {
	getOneAnswers,
	getErrorTipoFromProject,
	getOneList,
	getRecintoFromProject,
	getOneSubcontratoProject,
	setSelectedAnswers,
	updateComentarioErrorTipo,
} from "../redux/actions/listsActions";
import { IListsState } from "../redux/reducers/listsReducer";
import { AnswersCard } from "../components/AnswersCard";
import { LoaderModal } from "../components/LoaderModal";
import { timesStampToDateFormatted } from "../utils/utils";
import WhitOutInformation from "../components/WhitOutInformation";
import { IRevisionsState } from "../redux/reducers/revisionsReducer";
import { getOneRevision } from "../redux/actions/revisionsActions";
import { Breadcrumbs } from "../components/CheckPro/Breadcrumbs";
import { LinkProyectos } from "../components/CheckPro/Links/Proyectos";
import { LinkRevisiones } from "../components/CheckPro/Links/Revisiones";
import { LinkListados } from "../components/CheckPro/Links/Listados";
import { LinkRecintos } from "../components/CheckPro/Links/Recintos";
import { LinkSubcontratos } from "../components/CheckPro/Links/Subcontratos";
import { LinkEmpresas } from "../components/CheckPro/Links/Empresas";

const ContentCard = () => {
	const dispatch = useDispatch();
	const { errtipoId } = useParams<any>();
	const {
		answersErrorType,
		answersState,
		selectedRecinto,
		selectedSubcontratoProject,
		selectedErrortipeProject,
	} = useSelector<RootState, IListsState>(
		(state) => state.listsReducer
	);
	useEffect(() => {
		if (!answersErrorType) {
			dispatch(getOneAnswers(errtipoId));
		}
	}, [errtipoId]);

	useEffect(() => {
		return () => {
			dispatch(setSelectedAnswers([]));
		};
	}, []);

	const checkAnswers = (answersErrorType: any) => {
		if (answersErrorType) {
			return answersErrorType;
		} else {
			return {};
		}
	};
	return (
		<Grid container spacing={2}>
			{Object.keys(checkAnswers(answersErrorType))
				.length === 0 && <WhitOutInformation />}
			{answersErrorType?.map((item: any, index: number) => (
				<Grid item key={item?.id} xs={12} sm={4}>
					<AnswersCard
						image={item?.Imagen}
						comentario={item?.Comentario}
						estado={item?.Estado}
						Recinto={selectedRecinto?.Nombre}
						subcontrato={selectedSubcontratoProject?.Nombre}
						errortipo={selectedErrortipeProject?.Nombre}
						imagenNumero={index + 1}
						fechacreacion={timesStampToDateFormatted(
							item?.FechaCreacion
						)}
						fechaResuelta={timesStampToDateFormatted(
							item?.FechaResuelta
						)}
						onAccept={(value) =>
							dispatch(
								updateComentarioErrorTipo(item.id, value)
							)
						}
					/>
				</Grid>
			))}
			<LoaderModal open={answersState === "Submiting"} />
		</Grid>
	);
};

export const AnswersChecklist = () => {
	const dispatch = useDispatch();
	const {
		projectId,
		revisionId,
		listadoId,
		recintoId,
		subcontratoId,
		errtipoId,
	} = useParams<any>();
	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);

	const businessId = auth.user?.empresaReference.id;

	const {
		revisions: {
			isLoading: projectIsLoading,
			selectedProject,
		},
	} = useSelector<RootState, IProjectsState>(
		(state) => state.projectsReducer
	);
	const { selectedRevision } = useSelector<
		RootState,
		IRevisionsState
	>((state) => state.revisionsReducer);
	const {
		selectedList,
		selectedRecinto,
		selectedSubcontratoProject,
		selectedErrortipeProject,
	} = useSelector<RootState, IListsState>(
		(state) => state.listsReducer
	);
	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);
	useEffect(() => {
		if (selectedProject === null) {
			dispatch(getOneProject(projectId));
		}
	}, [
		dispatch,
		businessId,
		projectId,
		revisionId,
		selectedProject,
		selectedBusiness,
	]);

	useEffect(() => {
		if (selectedBusiness === null) {
			dispatch(getBusiness(businessId!));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBusiness, businessId]);

	useEffect(() => {
		if (!selectedErrortipeProject) {
			dispatch(getOneRevision(projectId, revisionId, true));
			dispatch(
				getOneList(projectId, revisionId, listadoId)
			);
			dispatch(
				getRecintoFromProject(
					projectId,
					revisionId,
					listadoId,
					recintoId
				)
			);
			dispatch(
				getOneSubcontratoProject(
					projectId,
					revisionId,
					listadoId,
					recintoId,
					subcontratoId
				)
			);
			dispatch(
				getErrorTipoFromProject(
					projectId,
					revisionId,
					listadoId,
					recintoId,
					subcontratoId,
					errtipoId
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedErrortipeProject,
		projectId,
		revisionId,
		listadoId,
		recintoId,
		subcontratoId,
		errtipoId,
	]);

	return (
		<>
			<Helmet title="Respuesta" />
			<Grid justify="space-between" container>
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						{projectIsLoading && !selectedProject ? (
							<Skeleton variant="text" />
						) : (
							<Typography
								variant="h3"
								gutterBottom
								display="inline"
							>
								{`${selectedBusiness?.Nombre} / ${selectedProject?.NombreProyecto} / ${selectedRevision?.Nombre} / ${selectedList?.Nombre} / ${selectedRecinto?.Nombre} / ${selectedSubcontratoProject?.Nombre} / ${selectedErrortipeProject?.Nombre}`}
							</Typography>
						)}
					</Typography>
					<Breadcrumbs>
						<LinkEmpresas />
						<LinkProyectos />
						<LinkRevisiones />
						<LinkListados />
						<LinkRecintos />
						<LinkSubcontratos />
						<Typography>Respuestas</Typography>
					</Breadcrumbs>
				</Grid>
			</Grid>

			<Divider />

			<Grid container spacing={6}>
				<Grid item xs={12} style={{ marginTop: 6 }}>
					<ContentCard />
				</Grid>
			</Grid>
		</>
	);
};
