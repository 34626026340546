import {
	Button,
	Divider as MuiDivider,
	Grid,
	Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { AssigneesDialog } from "../../components/Revisiones/AssigneesDialog";
import { NewConfirmDialog } from "../../components/NewConfirmDialog";
import { RevisionesTable } from "../../components/Revisiones/Table";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useNewEditDialog } from "../../hooks/useNewEditDialog";
import {
	addProjectChecklist,
	deleteChecklistProject,
	setSelectedProject,
} from "../../redux/actions/projectsActions";
import { IBusinessState } from "../../redux/reducers/businessReducer";
import { IProjectsState } from "../../redux/reducers/projectsReducer";
import { RootState } from "../../redux/store/reducer";
import { DialogNewEdit } from "./components/DialogNewEdit";
import { getChecklists } from "../../redux/actions/projectsActions";
import { Breadcrumbs } from "../../components/CheckPro/Breadcrumbs";
import { LinkEmpresas } from "../../components/CheckPro/Links/Empresas";
import { IAuthState } from "../../redux/reducers/authReducer";
import { RevisionChecklistProject } from "../../models/Proyecto";

const Divider = styled(MuiDivider)(spacing);

export const ChecklistPage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { idEmpresa } = useParams<{ idEmpresa: string }>();

	const { user } = useSelector<RootState, IAuthState>(
		(s) => s.authReducer
	);

	const { checklists, projectChecklistState, isLoading } =
		useSelector<RootState, IProjectsState>(
			(state) => state.projectsReducer
		);

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const {
		open: openConfirmDialog,
		setIsLoading: setIsLoadingConfirmDialog,
		dialogConfirmHandleClose,
		dialogConfirmHandleConfirm,
		openDialog,
		selected: confirmDialogSelected,
	} = useConfirmDialog({
		onConfirm: (selected) => {
			dispatch(
				deleteChecklistProject(
					selected,
					selectedBusiness?.id
				)
			);
		},
	});

	const {
		open: openResponsibles,
		dialogConfirmHandleClose: dialogResponsiblesHandleClose,
		openDialog: openDialogResponsibles,
		selected: responsiblesDialogSelected,
	} = useConfirmDialog({
		onConfirm: () => {},
	});

	const {
		open,
		openEditDialog,
		openNewDialog,
		closeDialog,
		onAccept,
		action,
		selected,
	} = useNewEditDialog<RevisionChecklistProject>({
		onAccept: (values, action) => {
			if (!selectedBusiness) return;
			if (action === "New") {
				dispatch(
					addProjectChecklist(values, selectedBusiness)
				);
			} else if (action === "Edit") {
				// dispatch(
				// 	updateProject(
				// 		selected,
				// 		values,
				// 		selectedBusiness,
				// 		true
				// 	)
				// );
			}
		},
		// onOpenNewDialog: () => {
		//   // TOOD: solo funciona en vista administrador
		//   dispatch(getProjectUsers({ Empresa: selectedBusiness }));
		// },
	});

	/**
	 * Conseguir proyectos
	 */
	useEffect(() => {
		dispatch(getChecklists(idEmpresa));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idEmpresa]);

	useEffect(() => {
		setIsLoadingConfirmDialog(isLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	return (
		<>
			<Helmet title="Proyectos" />
			<Grid justify="space-between" container>
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						{selectedBusiness?.Nombre}
					</Typography>
					<Breadcrumbs>
						<LinkEmpresas />
						<Typography>
							Proyectos (Checklist Revisiones)
						</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					{user?.tipoUsuario !== "UsuarioEmpresa" && (
						<Button
							variant="contained"
							color="primary"
							onClick={() => openNewDialog()}
							disabled={
								selectedBusiness?.CheckList
									.CheckListUsados! >=
								selectedBusiness?.CheckList.LimiteCheckList!
							}
						>
							<AddIcon />
							Nuevo Proyecto
						</Button>
					)}
				</Grid>
			</Grid>
			<Divider />

			<Grid container spacing={6} style={{ marginTop: 20 }}>
				<Grid item xs={12}>
					<RevisionesTable
						canRestore={
							user?.tipoUsuario === "Administrador"
						}
						hidePercentage={
							user?.tipoUsuario === "UsuarioEmpresa"
						}
						type="revisiones-checklist"
						canEdit={user?.tipoUsuario !== "UsuarioEmpresa"}
						revisiones={checklists}
						handleOpenEditDialog={openEditDialog}
						handleOpenConfirmDialog={openDialog}
						isLoading={
							projectChecklistState === "Submiting"
						}
						onArrowClick={(projectsChecklist: any) => {
							dispatch(
								setSelectedProject(projectsChecklist)
							);
							history.push(
								`/administrador/checklist-proyectos/${projectsChecklist.id}/revisiones`
							);
						}}
						handleOpenResponsiblesDialog={
							openDialogResponsibles
						}
					/>
				</Grid>
			</Grid>
			<DialogNewEdit
				open={open}
				onClose={closeDialog}
				onAccept={onAccept}
				action={action}
				selected={selected}
				business={{}}
			/>
			<NewConfirmDialog
				open={openConfirmDialog}
				title={`¿Desea eliminar el Proyecto ${confirmDialogSelected?.NombreProyecto}?`}
				onClose={dialogConfirmHandleClose}
				isLoading={isLoading}
				onConfirm={dialogConfirmHandleConfirm}
				onConfirmText={"Eliminar"}
			/>
			<AssigneesDialog
				open={openResponsibles}
				onClose={dialogResponsiblesHandleClose}
				project={responsiblesDialogSelected}
			/>
		</>
	);
};
