import {
	Breadcrumbs,
	Divider,
	Typography,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { LinkProjectsOT } from "src/components/CheckPro/Links/NeoProjects";
import { ChecklistTableOT } from "src/components/ProjectsOT/ChecklistsOT/Table";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

export const ChecklistsPageOT = () => {
	const { selectedBusiness } = useBusiness();
	const { selected } = useProjectsOT();

	return (
		<>
			<Helmet title="Checklist" />

			<Breadcrumbs style={{ fontSize: 20 }}>
				<Typography variant="h3" style={{ color: "black" }}>
					{selectedBusiness?.Nombre ?? "Empresa"}
				</Typography>
				<Typography variant="h3" style={{ color: "black" }}>
					{selected.project?.Nombre ?? "Proyecto"}
				</Typography>
			</Breadcrumbs>

			<Breadcrumbs>
				<LinkEmpresas />
				<LinkProjectsOT />
				<Typography>Listas de chequeo</Typography>
			</Breadcrumbs>

			<Divider
				style={{ marginTop: 10, marginBottom: 10 }}
			/>

			<ChecklistTableOT />
		</>
	);
};
