import {
	Box,
	TableCell,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { FC } from "react";
import {
	AnswerOT,
	SheetAnswerTuple,
} from "src/models/OT/Sheet";
import { Help } from "@material-ui/icons";
import { ResponseIcon } from "../Misc/ResponseIcon";
import { getDefaultComment } from "src/models/OT/utils/sheets";

type Props = {
	answer: SheetAnswerTuple;
	onClick?: (answer: SheetAnswerTuple) => void;
	reviewerName: string;
};

export const MatrixResponseCell: FC<Props> = ({
	answer,
	onClick,
	reviewerName,
}) => {
	const { Respuestas } = answer;
	const [answer1, answer2] = Respuestas;

	const getAnswerTooltip = ({
		Fecha,
		Comentario,
		Estado,
	}: AnswerOT) =>
		Estado === "Bloqueado" ? (
			<Typography>No revisa</Typography>
		) : (
			<Typography>
				Contestada por <b>{reviewerName}</b> el{" "}
				{Fecha.toDate().toLocaleDateString()}.
				<br />
				Comentario:{" "}
				<i>{Comentario || getDefaultComment({ Estado })}</i>
			</Typography>
		);

	return (
		<TableCell onClick={() => onClick?.(answer)}>
			<Box
				style={{ cursor: "pointer" }}
				onClick={() => onClick?.(answer)}
				width="100%"
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				gridGap={10}
			>
				{!answer1 && <Help htmlColor="gold" />}

				{answer1 && (
					<Tooltip title={getAnswerTooltip(answer1)}>
						<Box>
							<ResponseIcon {...answer1} />
						</Box>
					</Tooltip>
				)}

				{answer2 && (
					<Tooltip title={getAnswerTooltip(answer2)}>
						<Box>
							<ResponseIcon {...answer2} />
						</Box>
					</Tooltip>
				)}
			</Box>
		</TableCell>
	);
};
