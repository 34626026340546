import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Divider,
	Fade,
	FormControlLabel,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
} from "@material-ui/core";
import {
	Eye as ViewIcon,
	RotateCcw,
	Trash2,
} from "react-feather";
import {
	Add as AddIcon,
	Assignment,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteSelectedBusiness,
	getBusinesses,
	restoreBusiness,
	setBusinessFilter,
	setSelectedBusiness,
} from "../redux/actions/businessActions";
import { IBusinessState } from "../redux/reducers/businessReducer";
import { useStyles } from "../theme/useStyles";
import { useHistory } from "react-router-dom";
import { RevisionesIcon } from "../icons/RevisionesIcon";
import Variants, {
	colorsCheckPro,
} from "../theme/variants";
import { TableSkeleton } from "../components/TableSkeleton";
import {
	getRevisions,
	getTotalDocsBusinessProjects,
} from "../redux/actions/projectsActions";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import { useTable } from "../hooks/useTable";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import { Breadcrumbs } from "../components/CheckPro/Breadcrumbs";
import { RootState } from "src/redux/store/reducer";
import { BusinessAddDialog } from "src/components/Business/Dialogs/AddBusiness";
import { BusinessModuleCell } from "src/components/Business/ModuleCell";

const ContentCard = () => {
	const classes = useStyles();
	const {
		businesses: business,
		isLoading,
		totalDocs,
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const dispatch = useDispatch();
	const history = useHistory();
	const theme = Variants[3];

	const {
		limit,
		handleLimitChange,
		handlePageChange,
		page,
	} = useTable({
		limit: TABLE_LIMIT_DEFAULT || 5,
	});

	const handleEditBtn = (business: any) => {
		dispatch(setSelectedBusiness(business));
		history.push(`empresas/${business.id}/edit`);
	};

	const [selectedBusiDelete, setSelectedBusiDelete] =
		useState({});
	const [openConfirm, setOpenConfirm] = useState(false);
	const handleDeleteSelected = () => {
		dispatch(deleteSelectedBusiness(selectedBusiDelete));
		setOpenConfirm(false);
	};

	const dataToShow = useMemo(
		() =>
			business?.slice(page * limit, page * limit + limit) ??
			[],
		[business, page, limit]
	);

	return (
		<Card style={{ marginBottom: 6 }}>
			<CardHeader
				action={
					<FormControlLabel
						control={
							<Checkbox
								// checked={state.checkedB}
								onChange={(e) => {
									dispatch(
										setBusinessFilter(!e.target.checked)
									);
									dispatch(getBusinesses());
								}}
								name="checkedB"
								color="primary"
							/>
						}
						label="Mostrar Eliminadas"
					/>
				}
			/>
			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Nombre</TableCell>
								<TableCell>Rut</TableCell>
								<TableCell align="center">
									Revisiones
								</TableCell>
								<TableCell align="center">
									Checklist Revisiones
								</TableCell>
								<TableCell align="center">
									Checklists
								</TableCell>
								<TableCell align="center">
									Acciones
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!isLoading ? (
								dataToShow.map((business) => (
									<Fade key={business.id} in={true}>
										<TableRow
											hover
											className={classes.styledRow}
										>
											<TableCell>
												{business.Nombre}
											</TableCell>
											<TableCell
												style={{ whiteSpace: "nowrap" }}
											>
												{business.Rut}
											</TableCell>
											<TableCell align="center">
												{business.Revisiones ? (
													<BusinessModuleCell
														activated={
															business.Revisiones.Activado
														}
														limitUser={
															business.Revisiones
																.LimiteUsuarios
														}
														usedUser={
															business.Revisiones
																.UsuariosUsados
														}
														limitType={
															business.Revisiones
																.LimiteRevisiones
														}
														usedType={
															business.Revisiones
																.RevisionesUsados
														}
													/>
												) : (
													<p>-</p>
												)}
											</TableCell>

											<TableCell align="center">
												{business.CheckList ? (
													<BusinessModuleCell
														activated={
															business.CheckList.Activado
														}
														limitUser={
															business.CheckList
																?.LimiteUsuarios
														}
														usedUser={
															business.CheckList
																?.UsuariosUsados
														}
														limitType={
															business.CheckList
																?.LimiteCheckList
														}
														usedType={
															business.CheckList
																?.CheckListUsados
														}
													/>
												) : (
													<p>-</p>
												)}
											</TableCell>
											<TableCell align="center">
												{business.ChecklistsOT ? (
													<BusinessModuleCell
														activated={
															business.ChecklistsOT.Activado
														}
														limitUser={
															business.ChecklistsOT
																?.LimiteUsuarios
														}
														usedUser={
															business.ChecklistsOT
																?.UsuariosUsados
														}
														limitType={
															business.ChecklistsOT
																?.LimiteProyectos
														}
														usedType={
															business.ChecklistsOT
																?.ProyectosUsados
														}
													/>
												) : (
													<p>-</p>
												)}
											</TableCell>
											<TableCell
												align="center"
												style={{ whiteSpace: "nowrap" }}
											>
												{business.Activado && (
													<Tooltip title="Ver Revisiones">
														<IconButton
															onClick={() => {
																if (
																	business?.Revisiones
																		?.Activado
																) {
																	dispatch(
																		getRevisions(
																			business.id
																		)
																	);
																	dispatch(
																		getTotalDocsBusinessProjects(
																			business
																		)
																	);
																	dispatch(
																		setSelectedBusiness(
																			business
																		)
																	);
																	history.push(
																		`empresas/${business.id}/proyectos`
																	);
																}
															}}
														>
															<RevisionesIcon
																style={{
																	fill: theme.palette
																		.primary.main,
																}}
																disabled={
																	!business?.Revisiones
																		?.Activado
																}
															/>
														</IconButton>
													</Tooltip>
												)}
												{business.Activado && (
													<Tooltip title="Ver Checklist Revisiones">
														<IconButton
															color="primary"
															disabled={
																!business?.CheckList
																	?.Activado
															}
															onClick={() => {
																dispatch(
																	setSelectedBusiness(
																		business
																	)
																);
																history.push(
																	`/administrador/empresas/${business.id}/checklist-proyectos`
																);
															}}
														>
															<Assignment />
															{/* <CheckListIcon
																	disabled={
																		!busi?.CheckList
																			?.Activado
																	}
																	style={{
																		fill: theme.palette
																			.primary.main,
																	}}
																/> */}
														</IconButton>
													</Tooltip>
												)}
												{business.Activado && (
													<Tooltip title="Ver Checklists">
														<IconButton
															disabled={
																!business.ChecklistsOT
																	?.Activado
															}
															color="primary"
															onClick={() => {
																dispatch(
																	setSelectedBusiness(
																		business
																	)
																);
																history.push(
																	`/administrador/empresas/${business.id}/neo-projects`
																);
															}}
														>
															<svg
																fill={
																	business.ChecklistsOT
																		?.Activado
																		? colorsCheckPro.primary
																		: "darkgray"
																}
																height={24}
																width={24}
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
															>
																<path d="M17,21L14.25,18L15.41,16.84L17,18.43L20.59,14.84L21.75,16.25M12.8,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19A2,2 0 0,1 21,5V12.8C20.12,12.29 19.09,12 18,12L17,12.08V11H7V13H14.69C13.07,14.07 12,15.91 12,18C12,19.09 12.29,20.12 12.8,21M12,15H7V17H12M17,7H7V9H17" />
															</svg>
														</IconButton>
													</Tooltip>
												)}
												{business.Activado && (
													<Tooltip title="Ver más">
														<IconButton
															onClick={() =>
																handleEditBtn(business)
															}
															color="primary"
														>
															<ViewIcon />
														</IconButton>
													</Tooltip>
												)}
												{!business?.Activado ? (
													<Tooltip title="Restaurar">
														<IconButton
															size="small"
															onClick={() =>
																dispatch(
																	restoreBusiness(business)
																)
															}
														>
															<RotateCcw color="#00e676" />
														</IconButton>
													</Tooltip>
												) : (
													""
												)}
												{!business?.Activado ? (
													<Tooltip title="Eliminar">
														<IconButton
															size="small"
															onClick={() => {
																setSelectedBusiDelete(
																	business
																);
																setOpenConfirm(true);
															}}
														>
															<Trash2 color="red" />
														</IconButton>
													</Tooltip>
												) : (
													""
												)}
											</TableCell>
										</TableRow>
									</Fade>
								))
							) : (
								<TableSkeleton colSpan={6} limit={limit} />
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={totalDocs}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
			</CardContent>
			<NewConfirmDialog
				open={openConfirm}
				title={`¿Eliminar empresa de forma definitiva?`}
				onClose={() => setOpenConfirm(false)}
				onConfirm={handleDeleteSelected}
				onConfirmText={"Eliminar"}
			/>
		</Card>
	);
};

export const BusinessPage = () => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		dispatch(getBusinesses());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Helmet title="Empresas" />
			<Grid container spacing={4} justify="space-between">
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						Empresas
					</Typography>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={() => setOpen(true)}
					>
						<AddIcon />
						Agregar Empresa
					</Button>
				</Grid>
			</Grid>

			<Breadcrumbs>
				<Typography>Empresas</Typography>
			</Breadcrumbs>

			<Divider />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard />
				</Grid>
			</Grid>
			<BusinessAddDialog
				open={open}
				onClose={() => setOpen(false)}
			/>
		</>
	);
};
