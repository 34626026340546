import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { IAuthState } from "../redux/reducers/authReducer";
import { RootState } from "../redux/store/reducer";

interface Props {
	children: any;
}

// For routes that can only be accessed by authenticated users
export const UserGuard: React.FC<Props> = ({
	children,
}) => {
	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const location = useLocation();

	localStorage.setItem("lastPath", location.pathname);

	if (
		!(
			auth.user?.tipoUsuario === "AdministradorEmpresa" ||
			auth.user?.tipoUsuario === "Administrador" ||
			auth.user?.tipoUsuario === "UsuarioEmpresa"
		)
	) {
		if (
			auth.user?.tipoUsuario === "Administrador" &&
			auth.user?.activado
		) {
			return <Redirect to="/administrador/empresas" />;
		} else if (
			auth.user?.tipoUsuario === "AdministradorEmpresa" &&
			auth.user?.activado
		) {
			return (
				<Redirect to="/administrador/empresa/proyectos" />
			);
		} else {
			return <Redirect to="/auth/sign-in" />;
		}
	}

	return children;
};
