import {
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	CardHeader,
	Chip as MuiChip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider as MuiDivider,
	Fade,
	FormControlLabel as MuiFormControlLabel,
	Grid,
	IconButton,
	Link,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
	ChevronRight,
	Plus,
	Trash2 as RemoveIcon,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import {
	NavLink,
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import { es } from "yup-locales";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { SimpleCrud } from "../components/SimpleCrud";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import {
	addErrorTipo,
	addSubcontrato,
	deleteErrorTipo,
	getEmpresaSubcontratos,
	getOneChecklist,
	getOneSubcontrato,
	removeSubcontrato,
	setSelectedErrorTypeChecklist,
	setSelectedTypeErrors,
} from "../redux/actions/checklistActions";
import { IChecklistState } from "../redux/reducers/checklistReducer";
import { RootState } from "../redux/store/reducer";
import { useStyles } from "../theme/useStyles";
import { AutocompleteSubcontratos } from "./Checklits/AutoCompleteSubcontratos";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { useDispatch, useSelector } from "src/redux/store";
import { useBusiness } from "src/hooks/redux/useBusiness";

yup.setLocale(es);

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(
	spacing
);

const Chip: any = styled(MuiChip)`
	${spacing};

	background: ${(props: any) =>
		props.activated && green[500]};
	background: ${(props: any) =>
		props.desactivated && red[500]};
	color: ${(props) => props.theme.palette.common.white};
	min-width: 90px;
`;

function ContentCard() {
	const {
		selectedTypeErrors,
		dialog: { selectedChecklist },
		totalDocs,
		selectedRecinto,
	} = useSelector((state) => state.checklistReducer);
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { checklisId } = useParams<any>();
	const { subcontratoId } = useParams<any>();

	const [openDialogCreate, setOpenDialogCreate] =
		useState(false);
	const [editTarget, setEditTarget] = useState<any | null>(
		null
	);
	const [limit, setLimit] = useState(TABLE_LIMIT_DEFAULT);
	const [page, setPage] = useState(0);
	const [nombreSelected, setNombreSelected] = useState("");
	const [selectedSubcontrato, setSelectedSubcontrato] =
		useState<any>({});

	const handleSaveCreate = (values: any) => {
		dispatch(addSubcontrato(values, checklisId));
		setOpenDialogCreate(false);
		setEditTarget(null);
	};
	const docTotals = selectedRecinto?.Subcontratos?.map(
		(e: any) => e
	);

	const handleLimitChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(parseInt(event.target.value, 10));

		// if (
		// 	parseInt(event.target.value, 10) > docTotals?.length
		// ) {
		// 	// dispatch(getSubcontratos2(parseInt(event.target.value, 10)));
		// }
		// setPage(0);
	};
	const handlePageChange = (_: any, newPage: number) => {
		if ([].length < totalDocs && newPage > page) {
			// dispatch(getSubcontratos2(limit));
		}
		setPage(newPage);
	};

	// Confirm Dialog
	const [dialogState, setDialogState] = useState({
		open: false,
		title: "",
		subContract: {},
	});
	const handleRemoveButton = (subContrato: any) => {
		setDialogState((dialogState) => ({
			...dialogState,
			title: `¿Desea eliminar el Subcontrato ${subContrato.Nombre}?`,
			subContrato: subContrato,
			open: true,
		}));
	};
	const handleTypeErrorButton = (subContract: any) => {
		dispatch(setSelectedTypeErrors(subContract));
		setNombreSelected(subContract.Nombre);
	};

	useEffect(() => {
		if (!selectedChecklist) {
			dispatch(
				getOneSubcontrato(checklisId, subcontratoId)
			);
			dispatch(getOneChecklist(checklisId));
		}
	}, [selectedChecklist, checklisId, subcontratoId]);
	useEffect(() => {
		dispatch(getEmpresaSubcontratos());
	}, []);

	useEffect(() => {
		return () => {
			dispatch(setSelectedErrorTypeChecklist([]));
		};
	}, []);
	return (
		<Grid container spacing={4}>
			<Grid item xs={6}>
				<Card mb={6} mt={2}>
					<CardHeader
						title="Subcontratos"
						action={
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									setEditTarget(null);
									setOpenDialogCreate(true);
								}}
							>
								<Plus size={20} />
								&nbsp; Agregar
							</Button>
						}
					/>
					<Divider />
					<CardContent>
						<TableContainer
							className={classes.tableContainer}
						>
							<Table size="small" stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>Nombre</TableCell>
										<TableCell>Errores Tipo</TableCell>
										<TableCell align="center">
											Acciones
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{selectedRecinto
										? selectedRecinto?.Subcontratos?.sort(
												(a: any, b: any) => {
													if (a.Nombre === b.Nombre) {
														return 0;
													}
													if (a.Nombre < b.Nombre) {
														return -1;
													}
													return 1;
												}
										  ).map(
												(subContract: any, index: any) => (
													<Fade key={index} in={true}>
														<TableRow
															hover
															className={classes.styledRow}
														>
															<TableCell>
																{subContract?.Nombre}
															</TableCell>
															<TableCell align="left">
																{subContract?.ErroresTipo
																	?.length === undefined
																	? 0
																	: subContract?.ErroresTipo
																			?.length}
															</TableCell>
															<TableCell
																style={{
																	whiteSpace: "nowrap",
																}}
																align="center"
															>
																<Tooltip title="Eliminar">
																	<span>
																		<IconButton
																			onClick={() => {
																				handleRemoveButton(
																					subContract
																				);
																				setSelectedSubcontrato(
																					subContract
																				);
																			}}
																		>
																			<RemoveIcon color="red" />
																		</IconButton>
																	</span>
																</Tooltip>
																<Tooltip title="Errores Tipo">
																	<span>
																		<IconButton
																			onClick={() => {
																				handleTypeErrorButton(
																					subContract
																				);
																				dispatch(
																					getEmpresaSubcontratos()
																				);
																			}}
																			color="primary"
																		>
																			<ChevronRight />
																		</IconButton>
																	</span>
																</Tooltip>
															</TableCell>
														</TableRow>
													</Fade>
												)
										  )
										: new Array(limit)
												.fill(0)
												.map((x, i) => (
													<TableRow key={i}>
														<TableCell colSpan={5}>
															<Skeleton />
														</TableCell>
													</TableRow>
												))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							component="div"
							count={docTotals ? docTotals?.length : 0}
							onChangePage={handlePageChange}
							onChangeRowsPerPage={handleLimitChange}
							page={page}
							rowsPerPage={limit}
							rowsPerPageOptions={TABLE_LIMITS}
						/>
					</CardContent>
					<ConfirmDialog
						open={dialogState.open}
						onClose={() =>
							setDialogState((dialogState) => ({
								...dialogState,
								open: false,
							}))
						}
						onConfirm={() => {
							dispatch(
								removeSubcontrato(
									selectedChecklist,
									selectedSubcontrato
								)
							);
						}}
						title={dialogState.title}
					/>
				</Card>
			</Grid>
			<Grid item xs={6}>
				{selectedTypeErrors ? (
					<SimpleCrud
						canDelete={true}
						key={"ErroresTipo"}
						array={selectedTypeErrors?.ErroresTipo || []}
						isLoading={false}
						name="ErroresTipo"
						onCreateOrUpdate={(values, selected) => {
							dispatch(
								addErrorTipo(
									values,
									checklisId,
									subcontratoId,
									selectedTypeErrors
								)
							);
						}}
						onDelete={(selected) => {
							dispatch(
								deleteErrorTipo(
									checklisId,
									subcontratoId,
									nombreSelected,
									selected
								)
							);
						}}
						cardTitle={
							"Errores Tipo - " +
							(selectedTypeErrors?.Nombre
								? selectedTypeErrors?.Nombre
								: "")
						}
						// este es el modal de errores tipo
					/>
				) : (
					<Card style={{ minHeight: 250 }}>
						<Grid
							container
							direction="column"
							alignItems="center"
							justify="center"
						>
							<Grid item>
								<img
									style={{
										marginTop: 20,
										marginBottom: 20,
									}}
									alt="imagen vacio"
									src="/static/void.svg"
									height="150"
								/>
							</Grid>
							<Grid item>
								<Typography variant="h5">
									Ningun subcontrato seleccionado
								</Typography>
							</Grid>
						</Grid>
					</Card>
				)}
			</Grid>
			<AutocompleteSubcontratos
				title="Agregar Subcontratos"
				open={openDialogCreate}
				onAccept={handleSaveCreate}
				onClose={() => setOpenDialogCreate(false)}
			/>
		</Grid>
	);
}

interface SimpleDialogProps {
	open: boolean;
	onClose: () => void;
	onSave: (data: any) => void;
	selected?: any;
}

const DialogAddSubContractSimple: React.FC<SimpleDialogProps> =
	({ open, onClose, onSave, selected = null }) => {
		const {
			handleSubmit,
			values,
			handleChange,
			touched,
			errors,
			setValues,
			setFieldValue,
			resetForm,
		} = useFormik({
			initialValues: {
				Nombre: "",
				Activado: true,
			},
			onSubmit: (values) => {
				onSave(values);
			},
			validationSchema: yup.object({
				Nombre: yup.string().required("Nombre requerido"),
			}),
		});

		useEffect(() => {
			if (selected) {
				setFieldValue("Nombre", selected?.Nombre || "");
				setFieldValue(
					"Activado",
					selected?.Activado || false
				);
			} else {
				resetForm();
			}
		}, [selected]);

		return (
			<React.Fragment>
				<Dialog
					open={open}
					onClose={() => onClose()}
					aria-labelledby="add-and-update-dialog-title"
				>
					<DialogTitle id="add-and-update-dialog-title">
						{selected
							? "Editar Subcontrato"
							: "Agregar Subcontrato"}
					</DialogTitle>

					<form noValidate onSubmit={handleSubmit}>
						<DialogContent>
							<TextField
								autoFocus
								id="Nombre"
								label="Nombre Subcontrato"
								type="text"
								value={values.Nombre}
								name="Nombre"
								onChange={handleChange}
								error={
									touched.Nombre && Boolean(errors.Nombre)
								}
								helperText={touched.Nombre && errors.Nombre}
								fullWidth
							/>
							<FormControlLabel
								control={
									<Switch
										checked={values.Activado}
										onChange={handleChange}
										name="Activado"
										color="primary"
									/>
								}
								my={4}
								label="Activado"
							/>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => onClose()}
								color="primary"
							>
								Cancelar
							</Button>
							<Button type="submit" color="primary">
								{selected ? "Guardar" : "Agregar"}
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</React.Fragment>
		);
	};

export const ChecklistSubcontratos = () => {
	const { projectId, revisionId, listId, checklisId } =
		useParams<any>();
	const location = useLocation();
	const {
		dialog: { selectedChecklist },
	} = useSelector((state) => state.checklistReducer);
	const { selectedRecinto, selectedTypeErrors } =
		useSelector((state) => state.checklistReducer);

	const user = useSelector((s) => s.authReducer.user);
	const { selectedBusiness } = useBusiness();

	return (
		<React.Fragment>
			<Helmet title="Subcontratos" />
			<Grid justify="space-between" container>
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						CheckList / {selectedChecklist?.Nombre} /{" "}
						{selectedRecinto?.Nombre} /{" "}
						{selectedTypeErrors?.Nombre}
					</Typography>

					<Breadcrumbs aria-label="Breadcrumb" mt={2}>
						<LinkEmpresas />
						{user?.tipoUsuario === "Administrador" ? (
							<Link
								component={NavLink}
								to={`/administrador/empresas/${selectedBusiness?.id}/edit`}
							>
								Checklist
							</Link>
						) : (
							<Typography>Checklist</Typography>
						)}
						<Link
							component={NavLink}
							exact
							to={`/administrador/checklist/${checklisId}/Recintos`}
						>
							Recintos
						</Link>
						<Typography>Subcontratos</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item></Grid>
			</Grid>
			<Divider my={6} />
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard />
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
