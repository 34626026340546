import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import Axios from "axios";
import { format } from "date-fns";

export const getRevisions = (
	project: any,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.PDF_GENERATOR_GET_REVISIONS_SUBMITTING,
		});
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(project.id)
				.collection("Revisiones")
				.orderBy("Nombre")
				.get();

			dispatch({
				type: types.PDF_GENERATOR_GET_REVISIONS_SUCCESS,
				payload: response.docs.map((e) => ({
					...e.data(),
					id: e.id,
				})),
			});
		} catch (error: any) {
			dispatch({
				type: types.PDF_GENERATOR_GET_REVISIONS_FAILURE,
				payload: error.toString(),
			});
		}
	};
};

export const getLists = (
	projectId: string,
	revisionIds: string[],
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.PDF_GENERATOR_GET_LISTS_SUBMITTING,
		});
		try {
			const revisionsPromises = revisionIds.map(
				(revisionId) =>
					firestore
						.collection(
							isChecklist
								? "ProyectosChecklist"
								: "Proyectos"
						)
						.doc(projectId)
						.collection("Revisiones")
						.doc(revisionId)
						.collection("Listado")
						.get()
			);

			const response = await Promise.all(revisionsPromises);

			dispatch({
				type: types.PDF_GENERATOR_GET_LISTS_SUCCESS,
				payload: response
					.map((res) => res.docs)
					.flat()
					.map((e) => ({
						...e.data(),
						id: e.id,
						ref: e.ref,
					})),
			});
		} catch (error: any) {
			dispatch({
				type: types.PDF_GENERATOR_GET_LISTS_FAILURE,
				payload: error.toString(),
			});
		}
	};
};

export const generatePDf = (
	project: {
		id: string;
		Nombre: string;
	},
	revisions: any[],
	listsCount: number,
	orderRecinto: boolean,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const user = getState().authReducer.user;
			if (!user) throw Error("No hay usuario logueado");

			dispatch({
				type: types.PDF_GENERATOR_SUBMITTING,
				payload: listsCount,
			});

			const empresaSnap = await user.empresaReference.get();
			const empresaData = empresaSnap.data();
			let index = 0;
			revisions.forEach((rev) => {
				const revToSend: any = { ...rev };
				delete revToSend.Listados;

				(rev.Listados as any[]).forEach((list: any) => {
					index++;
					Axios.post(
						"https://us-central1-checkpro-3a90a.cloudfunctions.net/generatePdf",
						{
							Proyecto: project,
							Revision: revToSend,
							Listado: list,
							Usuario: user,
							orderRecinto: orderRecinto,
							// cargo: user?.Cargo,
							// telefono: user?.Telefono,
							textoEmpresa: empresaData?.PiePagina,
						}
					)
						.then((response) =>
							Axios.get(response.data.data, {
								responseType: "blob",
							})
						)
						.then((response) => {
							
							dispatch({
								type: types.PDF_GENERATOR_SUCCESS,
								payload: { index },
							});
							downloadURI(
								window.URL.createObjectURL(
									new Blob([response.data], {
										type: response.headers["content-type"],
									})
								),
								list.Nombre.replace(" ", "_") +
									format(new Date(), "dd_MM_yyyy_HH_ss") +
									".pdf"
							);
						})
						.catch((err) =>
							dispatch({
								type: types.PDF_GENERATOR_FAILURE,
								payload: { error: err.toString(), index },
							})
						);
					index++;
				});
			});
		} catch (error: any) {
			
		}
	};
};

export const generatePDFInitial = (): Action => ({
	type: types.PDF_GENERATOR_INITIAL,
});

export const listsInitial = (): Action => ({
	type: types.PDF_GENERATOR_GET_LISTS_INITIAL,
});

export const revisionsInitial = (): Action => ({
	type: types.PDF_GENERATOR_GET_REVISIONS_INITIAL,
});

function downloadURI(uri: string, name: string) {
	let link = document.createElement("a");
	link.download = name;
	link.href = uri;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
