/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Breadcrumbs,
	Link,
	Paper,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useMemo, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import { firestore } from "../../firebase/firebase";
import "chartjs-plugin-labels";
import { ChartLoading } from "../../components/ChartLoading";
import { Bar, Pie } from "react-chartjs-2";
import { Business } from "@material-ui/icons";
import { calcularPorcentaje } from "../../utils/utils";
import { LinkProyectos } from "../../components/CheckPro/Links/Proyectos";
import { LinkEmpresas } from "../../components/CheckPro/Links/Empresas";
import { LinkListados } from "../../components/CheckPro/Links/Listados";

type UrlParams = {
	idProyecto: string;
	idRevision?: string;
	idListado?: string;
};

type Observacion = {
	NombreProyecto: string;
	NombreRevision: string;
	NombreListado: string;
	ProyectoId: string;
	ListadoId: string;
	RevisionId: string;
	Subcontrato: string;
	Estado: "Observada" | "Resuelta" | "Vencida";
	Sector: string;
};

/**
 * Este componente puede recibe la ID de un proyecto, una revision y/o un listado
 * mediante parámetros URL... contabiliza y renderiza las observaciones en gráficos de
 * barra y torta. Solo para proyectos normales.
 *
 * # Ruta:
 * /administrador/estadisticas/`:idProyecto`/`:?idRevision`/`:?idListado`
 *
 * # Niveles:
 * - Nivel 1: Proyecto
 * - Nivel 2: Revision
 * - Nivel 3: Listado
 *
 */
export const ProjectChart = () => {
	const { idProyecto, idRevision, idListado } =
		useParams<UrlParams>();

	const { level1, level2, level3 } = useMemo(
		() => ({
			level1: !!idProyecto && !idRevision && !idListado,
			level2: !!idProyecto && !!idRevision && !idListado,
			level3: !!idProyecto && !!idRevision && !!idListado,
		}),
		[idProyecto, idRevision, idListado]
	);

	const [errorMsg, setErrorMsg] = useState("");

	const [observaciones, setObservaciones] = useState<
		Observacion[]
	>([]);

	const [pageState, setPageState] = useState<
		"loading" | "ok" | "error"
	>("loading");

	const handleError = (e: any) => {
		setPageState("error");
		setErrorMsg(e.message);
		console.error(e);
	};

	/**
	 * Arreglo que contiene el recuento de observaciones
	 * según el nivel (Proyecto, Revisión o Listado).
	 */
	const {
		_metrics: observacionesPorNivel,
		_metricsWithPercentajes:
			observacionesPorNivelConPorcentajes,
	} = useMemo(() => {
		setPageState("loading");
		// Arreglo de subcontratos único
		try {
			if (observaciones.length === 0)
				throw Error("No hay observaciones");

			const _subcontratos = observaciones
				.map((o) => o.Subcontrato)
				.sort()
				.filter((s, i, a) => s !== a[i - 1]);

			// Crear métricas
			const _metrics: { [key: string]: number } = {};
			// Definir valores iniciales
			_subcontratos.forEach((s) => (_metrics[s] = 0));
			// Contar observaciones por subcontrato
			observaciones.forEach(
				({ Subcontrato }) => _metrics[Subcontrato]++
			);

			//Agregar porcentajes a las métricas
			const _metricsWithPercentajes: {
				[key: string]: number;
			} = {};
			Object.entries(_metrics).forEach(
				([key, value]) =>
					(_metricsWithPercentajes[
						`(${calcularPorcentaje(
							value,
							observaciones.length
						)}%) ${key}`
					] = value)
			);

			setPageState("ok");
			return { _metricsWithPercentajes, _metrics };
		} catch (e: any) {
			handleError(e);
			return {};
		}
	}, [observaciones]);

	/**
	 * Arreglo que contiene el recuento de observaciones
	 * por Listado. Solo disponible en el nivel 2.
	 */
	const observacionesPorListado = useMemo(() => {
		if (!level2) return;

		setPageState("loading");

		try {
			if (observaciones.length === 0)
				throw Error("No hay observaciones");

			const _metrics: { [key: string]: number } = {};

			observaciones
				.sort((a, b) =>
					a.NombreListado.localeCompare(b.NombreListado)
				)
				.forEach((o) => {
					if (!_metrics[o.NombreListado])
						_metrics[o.NombreListado] = 0;
					_metrics[o.NombreListado]++;
				});

			setPageState("ok");
			return _metrics;
		} catch (e: any) {
			handleError(e);
		}
	}, [observaciones]);

	const contadores = useMemo(() => {
		const _contadores = {
			total: 0,
			resueltas: 0,
			pendientes: 0,
			max: 0,
			maxListados: 0,
		};

		try {
			observaciones.forEach(({ Estado }) => {
				_contadores.total++;
				if (Estado === "Resuelta") _contadores.resueltas++;
				else _contadores.pendientes++;
			});

			_contadores.max = Math.max(
				...Object.values(_contadores)
			);
			_contadores.maxListados = Math.max(
				...Object.values(observacionesPorListado || 0)
			);
			return _contadores;
		} catch (e: any) {
			handleError(e);
		}
	}, [observaciones, observacionesPorListado]);

	const tortOptions = useMemo(
		() => ({
			legend: {
				position: "right",
			},
			plugins: {
				// @ts-ignore
				labels: {
					render: (col: any) => {
						const _percentage =
							(100 * col.value) / contadores!.total;
						return ``;
					},
					position: "outside",
					precision: 1,
				},
			},
		}),
		[contadores]
	);

	const barOptions = useMemo(
		() => ({
			legend: {
				display: false,
			},
			plugins: {
				labels: [
					{
						render: (col: any) => col.value,
						position: "outside",
						precision: 2,
					},
				],
			},
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
							stacked: true,
							max: Math.ceil(
								Math.max(
									...Object.values(
										observacionesPorNivel || 0
									)
								) * 1.15
							),
						},
					},
				],
			},
		}),
		[contadores]
	);

	const lvl2_barOptions = useMemo(
		() => ({
			legend: {
				display: false,
			},
			plugins: {
				labels: [
					{
						render: (col: any) => col.value,
						position: "outside",
						precision: 2,
					},
				],
			},
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
							stacked: true,
							max: Math.trunc(
								Math.max(
									...Object.values(
										observacionesPorListado || 0
									)
								) * 1.1
							),
						},
					},
				],
			},
		}),
		[contadores]
	);

	const chartData = useMemo(() => {
		try {
			return {
				labels: Object.keys(observacionesPorNivel || {}),
				datasets: [
					{
						label: "Observaciones",
						data: Object.values(
							observacionesPorNivel || {}
						),
						backgroundColor: observacionesPorNivel
							? new Array(
									Object.keys(observacionesPorNivel!).length
							  )
									.fill(null)
									.map(
										() =>
											`#${Math.floor(
												Math.random() * 16777215
											).toString(16)}`
									)
							: [],
					},
				],
			};
		} catch (e) {
			handleError(e);
		}
	}, [observacionesPorNivel]);

	const lvl2_chartData = useMemo(() => {
		if (!level2 || !observacionesPorListado) return;

		try {
			return {
				labels: Object.keys(observacionesPorListado),
				datasets: [
					{
						label: "Observaciones",
						data: Object.values(observacionesPorListado),
						backgroundColor: "blue",
					},
				],
			};
		} catch (e) {
			handleError(e);
		}
	}, [observacionesPorNivel]);

	const getObservaciones = async () => {
		setPageState("loading");

		try {
			const query = firestore
				.collectionGroup("Observaciones")
				.where(
					level1
						? "ProyectoId"
						: level2
						? "RevisionId"
						: level3
						? "ListadonId"
						: "",
					"==",
					level1
						? idProyecto
						: level2
						? idRevision
						: idListado?.replace(/\n/g, "")
				);

			const _obsSnaps = await query.get();

			if (_obsSnaps.empty)
				throw Error(
					(level1
						? "Este proyecto "
						: level2
						? "Esta revisión "
						: "Este listado ") + "no tiene observaciones"
				);

			const _revs = _obsSnaps.docs.map(
				(doc) => doc.data() as Observacion
			);

			setObservaciones(_revs);
		} catch (e) {
			handleError(e);
		}
	};

	useEffect(() => {
		getObservaciones();
	}, []);

	return (
		<Paper>
			<Breadcrumbs>
				<LinkEmpresas />
				<LinkProyectos />
				{(level2 || level3) && (
					<Link
						component={NavLink}
						exact
						to={`/administrador/empresa/proyectos/${idProyecto}/revisiones`}
					>
						Revisiones
					</Link>
				)}
				{level3 && (
					<LinkListados />
					// <Link
					// 	component={NavLink}
					// 	exact
					// 	to={`/administrador/empresa/proyectos/${idProyecto}/revisiones/${idListado}/listados`}
					// >
					// 	Listados
					// </Link>
				)}
				<Typography>Estadísticas</Typography>
			</Breadcrumbs>
			{pageState === "error" && (
				<Alert severity="error">{errorMsg}</Alert>
			)}
			{pageState === "loading" && <ChartLoading />}
			{pageState === "ok" && (
				<Box>
					<Typography variant="h1" align="center">
						Proyecto {observaciones[0].NombreProyecto}
					</Typography>
					{level2 && (
						<Typography variant="h2" align="center">
							Revisión {observaciones[0].NombreRevision}
						</Typography>
					)}
					{level3 && (
						<Typography variant="h2" align="center">
							Listado {observaciones[0].NombreListado}
						</Typography>
					)}

					<Typography variant="h3" align="center">
						{level1
							? "Observaciones del proyecto"
							: level2
							? "Observaciones de la revisión"
							: "Observaciones del listado"}
					</Typography>

					<Box
						padding={5}
						textAlign="center"
						margin="auto"
						display="grid"
						gridTemplateColumns="auto auto auto auto auto auto"
					>
						<Typography>Observaciones totales</Typography>
						<Typography>: {contadores!.total}</Typography>
						<Typography>Observaciones resueltas</Typography>
						<Typography>
							: {contadores!.resueltas}
						</Typography>
						<Typography>
							Observaciones pendientes
						</Typography>
						<Typography>
							: {contadores!.pendientes}
						</Typography>
					</Box>

					<>
						<Typography variant="h4" align="center">
							Observaciones por subcontrato
						</Typography>
						<Pie
							height={100}
							data={{
								...chartData!,
								labels: Object.keys(
									observacionesPorNivelConPorcentajes!
								),
							}}
							options={tortOptions!}
						/>
						<Bar
							height={100}
							data={chartData!}
							options={barOptions!}
						/>
					</>

					{level2 && (
						<>
							<Typography variant="h4" align="center">
								Observaciones por listado
							</Typography>
							<Bar
								height={100}
								data={lvl2_chartData!}
								options={lvl2_barOptions!}
							/>
						</>
					)}
				</Box>
			)}
		</Paper>
	);
};
