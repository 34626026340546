import {
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Divider as MuiDivider,
	Grid,
	Link,
	Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
	NavLink,
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import styled from "styled-components/macro";
import { AssigneesDialog } from "../../components/Revisiones/AssigneesDialog";
import { NewConfirmDialog } from "../../components/NewConfirmDialog";
import { RevisionesTable } from "../../components/Revisiones/Table";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useNewEditDialog } from "../../hooks/useNewEditDialog";
import {
	addProject,
	deleteProject,
	getRevisions,
	setSelectedProject,
} from "../../redux/actions/projectsActions";
import { IBusinessState } from "../../redux/reducers/businessReducer";
import { IProjectsState } from "../../redux/reducers/projectsReducer";
import { RootState } from "../../redux/store/reducer";
import { DialogNewEdit } from "./components/DialogNewEdit";
import { IAuthState } from "../../redux/reducers/authReducer";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const ProjectsPage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname } = useLocation();
	const { businessId } = useParams<any>();
	const isFromBusiness =
		pathname.indexOf("/administrador/empresas/") === 0;

	useEffect(() => {
		dispatch(getRevisions(businessId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessId]);

	const {
		projects,
		isLoading,
		revisions: { selectedProject },
	} = useSelector<RootState, IProjectsState>(
		(state) => state.projectsReducer
	);

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const {
		open: openConfirmDialog,
		dialogConfirmHandleClose,
		dialogConfirmHandleConfirm,
		openDialog,
		selected: confirmDialogSelected,
	} = useConfirmDialog({
		onConfirm: (selected) => {
			dispatch(deleteProject(selected, selectedBusiness));
		},
	});

	const [assigneeDialog, setAssigneeDialog] =
		useState(false);

	const {
		open,
		openEditDialog,
		openNewDialog,
		closeDialog,
		onAccept,
		action,
		selected,
	} = useNewEditDialog<any>({
		onAccept: async (values, action) => {
			if (action === "New") {
				dispatch(addProject(values, selectedBusiness));
			} else if (action === "Edit") {
				console.debug("todo");
				// dispatch(
				// 	updateProject(selected, values, selectedBusiness)
				// );
			}
		},
	});

	const { user } = useSelector<RootState, IAuthState>(
		(s) => s.authReducer
	);

	return (
		<>
			<Helmet title="Proyectos" />
			<Grid justify="space-between" container>
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						{selectedBusiness?.Nombre}
					</Typography>

					{isFromBusiness ? (
						<Breadcrumbs aria-label="Breadcrumb" mt={2}>
							<Link
								component={NavLink}
								exact
								to="/administrador/empresas"
							>
								Empresas
							</Link>
							<Typography>Proyectos</Typography>
						</Breadcrumbs>
					) : (
						<Breadcrumbs aria-label="Breadcrumb" mt={2}>
							<Link
								component={NavLink}
								exact
								to="/mantenedores/revisiones/proyectos"
							>
								Proyectos
							</Link>
							<Typography>Revisiones</Typography>
						</Breadcrumbs>
					)}
				</Grid>
				<Grid item>
					<div>
						<Button
							variant="contained"
							color="primary"
							onClick={() => openNewDialog()}
							disabled={
								projects.filter(
									// @ts-ignore
									(p) => p.Estado !== "Eliminado"
								).length >=
								selectedBusiness?.Revisiones
									.LimiteRevisiones!
							}
						>
							<AddIcon />
							Nuevo Proyecto
						</Button>
					</div>
				</Grid>
			</Grid>
			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<RevisionesTable
						canRestore={
							user?.tipoUsuario === "Administrador"
						}
						type="revisiones"
						canEdit={user?.tipoUsuario !== "UsuarioEmpresa"}
						handleOpenEditDialog={openEditDialog}
						handleOpenConfirmDialog={openDialog}
						isLoading={isLoading}
						revisiones={projects}
						onArrowClick={(selected: any) => {
							dispatch(setSelectedProject(selected));
							history.push(
								`proyectos/${selected.id}/revisiones`
							);
						}}
						handleOpenResponsiblesDialog={() =>
							setAssigneeDialog(true)
						}
					/>
				</Grid>
			</Grid>
			<DialogNewEdit
				open={open}
				onClose={closeDialog}
				onAccept={onAccept}
				action={action}
				selected={selected}
				business={{}}
				isRevision={true}
			/>
			<NewConfirmDialog
				open={openConfirmDialog}
				title={`¿Desea eliminar el Proyecto ${confirmDialogSelected?.NombreProyecto}?`}
				onClose={dialogConfirmHandleClose}
				isLoading={isLoading}
				onConfirm={dialogConfirmHandleConfirm}
				onConfirmText={"Eliminar"}
			/>
			<AssigneesDialog
				open={assigneeDialog}
				onClose={() => setAssigneeDialog(false)}
				project={selectedProject}
			/>
		</>
	);
};
