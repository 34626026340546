import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useCheckproLinks } from "./hooks/useCheckproLinks";

export const LinkSubcontratos = () => {
	const {
		auth,
		idProyecto,
		idRevision,
		idListado,
		idRecinto,
	} = useCheckproLinks();

	return (
		<Link
			component={NavLink}
			exact
			to={
				auth.user?.tipoUsuario === "AdministradorEmpresa" ||
				auth.user?.tipoUsuario === "Administrador"
					? `/administrador/checklist-proyectos/${idProyecto}/revisiones/${idRevision}/listados/${idListado}/recintos/${idRecinto}/subcontratos`
					: `/administrador/user/proyectos/${idProyecto}/revisiones/${idRevision}/recintos`
			}
		>
			Subcontratos
		</Link>
	);
};
