import React from "react";
import { Edit as EditIcon, X as ExitIcon } from "react-feather";
import { Button } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

interface HeaderCardActionsProps {
  onClick: () => void;
  value: boolean;
}

export const HeaderCardActions: React.FC<HeaderCardActionsProps> = ({
  onClick,
  value: clicked,
}) => {
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={() => {
        onClick();
      }}
      style={{ backgroundColor: clicked ? red[400] : "" }}
    >
      {clicked ? <ExitIcon size={20} /> : <EditIcon size={20} />}
      &nbsp;
      {clicked ? "Cancelar" : "Editar"}
    </Button>
  );
};
