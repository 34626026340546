import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { Table } from "src/utils/components/Table";
import { DropdownMenu } from "../Generic/DropdownMenu";
import {
	BarChart,
	ChevronRight,
	MoreVert,
	Settings,
} from "@material-ui/icons";
import { Edit, Plus, Trash2 } from "react-feather";
import { colorsCheckPro } from "src/theme/variants";
import { cleanString } from "src/utils/utils";
import { NeoProjectDialog } from "./Dialog";
import { useResponsiveQuery } from "src/utils/hooks/useResponsiveQuery";
import { NeoProjectDeleteDialog } from "./Dialog/Delete";
import { PercentageCell } from "./Misc/PercentageCell";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { Skeleton } from "@material-ui/lab";
import { NeoProjectPlacesDialog } from "./Dialog/Places";
import { useUsers } from "src/hooks/redux/useUsers";
import { NeoProjectStatusDialog } from "./Dialog/Status";
import { format } from "date-fns";
import { ChecklistProject } from "src/models/OT/Projects";
import { useSelector } from "src/redux/store";
import { ResponseSummaryExcelDialog } from "./Dialog/ResponseSummaryExcel";
import { ExcelSheetIcon } from "src/icons/ExcelSheet";
import { ProjectCounter } from "../CheckPro/ProjectCounter";
import { useBusiness } from "src/hooks/redux/useBusiness";

export const ProjectTableOT = () => {
	const {
		projects,
		set,
		getProjects,
		getUserProjects,
		get,
	} = useProjectsOT();
	const { users } = useUsers();
	const { user } = useSelector((s) => s.authReducer);

	const history = useHistory();

	const isSmall = useResponsiveQuery("down", "xs");

	const [dialog, setDialog] = useState(0);

	const sorted = useMemo(
		() =>
			[...(projects ?? [])].sort((a, b) =>
				a.Nombre.localeCompare(b.Nombre)
			),
		[projects]
	);

	const [showDeleted, setShowDeleted] = useState(false);

	const [filters, setFilters] = useState({
		name: "",
		responsible: "null",
		test: 0,
	});

	const updateFilters = (params: Partial<typeof filters>) =>
		setFilters((f) => ({ ...f, ...params }));

	const filtered = useMemo(() => {
		const { name, responsible } = filters;

		if (!name && responsible === "null") return null;
		let toBeFiltered = [...sorted];

		if (name)
			toBeFiltered = toBeFiltered.filter((c) =>
				cleanString(c.Nombre).includes(cleanString(name))
			);

		if (responsible !== "null")
			toBeFiltered = toBeFiltered.filter(
				(c) => c.Responsable.id === responsible
			);

		return toBeFiltered;
	}, [filters, sorted]);

	const [downloading, setDownloading] = useState([
		false,
		false,
	] as [boolean, boolean]);

	const handleDownloadResponseSummaryExcel = async (
		checklistType: "Obra Gruesa" | "Terminaciones"
	) => {
		setDownloading(
			checklistType === "Obra Gruesa"
				? [true, false]
				: [false, true]
		);
		await get.report.summaryResponseExcel(checklistType);
		setDownloading([false, false]);
		setDialog(0);
	};

	const getMenuOptions = useCallback(
		(value: ChecklistProject) => {
			const menu = [
				{
					label: "Editar",
					disabled:
						user?.tipoUsuario === "UsuarioEmpresa" ||
						value.Eliminado,
					onClick: () => {
						set.project(value);
						setDialog(2);
					},
					icon: <Edit color={colorsCheckPro.primary} />,
				},
				{
					label: "Eliminar",
					disabled:
						user?.tipoUsuario === "UsuarioEmpresa" ||
						value.Eliminado,
					onClick: () => {
						set.project(value);
						setDialog(1);
					},
					icon: <Trash2 color="red" fontSize={15} />,
				},
				{
					label: "Crear espacios",
					disabled:
						user?.tipoUsuario === "UsuarioEmpresa" ||
						value.Eliminado,
					onClick: () => {
						set.project(value);
						setDialog(3);
					},
					icon: <Settings color="primary" />,
					style: {
						display: value.ProyectoIniciado
							? "none"
							: undefined,
					},
				},
				{
					label: "Desviación por listas de chequeo",
					onClick: () => {
						set.project(value);
						history.push(
							`neo-projects/${value.id}/rechazadas-listas-chequeo`
						);
					},
					icon: <BarChart color="primary" />,
				},
				{
					label: "Desviación por espacios",
					onClick: () => {
						set.project(value);
						history.push(
							`neo-projects/${value.id}/rechazadas-parametro`
						);
					},
					icon: <BarChart color="primary" />,
				},
				{
					label: "Desviación por preguntas",
					onClick: () => {
						set.project(value);
						history.push(
							`neo-projects/${value.id}/rechazadas-pregunta`
						);
					},
					icon: <BarChart color="primary" />,
				},
				{
					label: "Metas de revisión",
					onClick: () => {
						set.project(value);
						history.push(
							`neo-projects/${value.id}/metas-revision`
						);
					},
					icon: <BarChart color="primary" />,
				},
				{
					label: "Resumen de respuestas",
					icon: (
						<ExcelSheetIcon
							style={{ fill: colorsCheckPro.primary }}
						/>
					),
					onClick: () => {
						set.project(value);
						setDialog(5);
					},
				},
			];

			return menu;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[projects]
	);

	useEffect(() => {
		if (user?.tipoUsuario === "UsuarioEmpresa")
			getUserProjects();
		else getProjects(undefined, { Eliminado: showDeleted });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showDeleted, user?.tipoUsuario]);

	useEffect(() => {
		if (!projects) return;
		get.project.users();
	}, [projects]);

	const { selectedBusiness } = useBusiness();

	return (
		<>
			{!showDeleted &&
				user?.tipoUsuario !== "UsuarioEmpresa" && (
					<ProjectCounter
						used={projects?.length ?? 0}
						limit={
							selectedBusiness?.ChecklistsOT
								?.LimiteProyectos ?? 0
						}
					/>
				)}

			<Box
				style={{
					display: "flex",
					gap: 5,
					flexWrap: "wrap",
					marginBottom: 10,
				}}
			>
				<TextField
					label="Buscar por nombre"
					variant="outlined"
					size="small"
					style={{ width: 200 }}
					value={filters.name}
					onChange={(e) =>
						updateFilters({ name: e.target.value })
					}
				/>

				<FormControl
					variant="outlined"
					size="small"
					style={{ width: 200 }}
				>
					<InputLabel>Filtrar por responsable</InputLabel>
					<Select
						label="Filtrar por responsable"
						value={filters.responsible || "null"}
						onChange={(e) => {
							const id = e.target.value as string;

							updateFilters({
								responsible: id,
							});
						}}
					>
						<MenuItem value="null">Todos</MenuItem>

						{users?.map((u) => (
							<MenuItem value={u.id} key={u.id}>
								{u.Nombre} {u.Apellido} ({u.Cargo})
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{/* <FormControlLabel
					control={
						<Checkbox
							checked={showDeleted}
							onClick={() => setShowDeleted(!showDeleted)}
						/>
					}
					label="Mostrar eliminados"
				/> */}

				<Box style={{ flexGrow: 1 }} />

				{user?.tipoUsuario !== "UsuarioEmpresa" && (
					<Button
						disabled={
							(projects?.length ?? 0) >=
								(selectedBusiness?.ChecklistsOT
									?.LimiteProyectos ?? 0) || showDeleted
						}
						color="primary"
						variant="contained"
						startIcon={<Plus />}
						onClick={() => {
							set.project(null);
							setDialog(2);
						}}
					>
						Crear proyecto
					</Button>
				)}
			</Box>

			<Table
				stripped
				loading={!projects}
				disableExporting
				elementsProps={{
					TableCellProps: { style: { padding: 5 } },
				}}
				list={filtered ?? sorted}
				columns={[
					{
						header: "Nombre",
						Cell: ({ value }) => (
							<Box
								display="flex"
								alignItems="center"
								gridGap={10}
							>
								{value.Eliminado && <Trash2 size={18} />}
								{value.Nombre}
							</Box>
						),
					},
					{
						header: "Responsable",
						Cell: ({ value }) => {
							const found = users?.find(
								(u) => u.id === value.Responsable.id
							);

							return found ? (
								<>{`${found.Nombre} ${found.Apellido}`}</>
							) : (
								<Skeleton variant="text" />
							);
						},
					},
					{
						header: "Cargo",
						Cell: ({ value }) => {
							const found = users?.find(
								(u) => u.id === value.Responsable.id
							);

							return found ? (
								<>{`${found.Cargo}`}</>
							) : (
								<Skeleton variant="text" />
							);
						},
					},
					{
						header: "Fecha de creación",
						Cell: ({ value }) => (
							<>
								{format(
									value.FechaCreacion?.toDate() ??
										new Date(),
									"dd/MM/yyyy HH:mm"
								)}
							</>
						),
					},
					{
						align: "center",
						header: "Progreso",
						Cell: ({ value }) => (
							<Tooltip
								title={
									<Typography>
										<ul>
											<li>Menor o igual del 25%: rojo </li>
											<li>
												Sobre 25% y menos 50%: naranjo
											</li>
											<li>
												Sobre 50% y menos al 75%: amarillo
											</li>
											<li>Sobre 75% y menos a 99%: azul</li>
											<li>Sobre el 99%: verde</li>
										</ul>
									</Typography>
								}
							>
								<Box>
									<PercentageCell
										value={
											((value.CantidadCompletada ?? 0) *
												100) /
											(value.Cantidad || 1)
										}
									/>
								</Box>
							</Tooltip>
						),
					},

					{
						align: "right",
						header: "Acciones",
						Cell: ({ value }) => (
							<>
								<DropdownMenu
									Opener={({ onClick }) => (
										<IconButton
											size="small"
											color="primary"
											onClick={onClick}
										>
											<MoreVert />
										</IconButton>
									)}
									options={getMenuOptions(value)}
								/>

								<Tooltip title="Revisiones">
									<IconButton
										size="small"
										color="primary"
										onClick={() => {
											set.project(value);
											history.push(
												`neo-projects/${value.id}`
											);
										}}
									>
										<ChevronRight />
									</IconButton>
								</Tooltip>
							</>
						),
					},
				]}
			/>

			<NeoProjectDeleteDialog
				open={dialog === 1}
				onClose={() => setDialog(0)}
				fullScreen={isSmall}
			/>

			<NeoProjectDialog
				open={dialog === 2}
				onClose={() => setDialog(0)}
				fullScreen={isSmall}
			/>

			<NeoProjectPlacesDialog
				open={dialog === 3}
				onClose={() => setDialog(0)}
				fullScreen
			/>

			<NeoProjectStatusDialog
				open={dialog === 4}
				onClose={() => setDialog(0)}
			/>

			<ResponseSummaryExcelDialog
				loading={downloading}
				open={dialog === 5}
				onClose={() => setDialog(0)}
				onSubmit={handleDownloadResponseSummaryExcel}
			/>
		</>
	);
};
