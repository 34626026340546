import { Action } from "../../models/action";
import * as types from "../../constants";
import { DialogAction } from "../../models/dialog-actions";

export interface IPlacesState {
  places: any[];
  isLoading: boolean;
  totalDocs: number;
  lastDoc?: any;
  error?: string;
  dialog: {
    isDialogOpen: boolean;
    isDialogLoading: boolean;
    dialogAction: DialogAction;
    selectedPlace?: any;
  };
}

const initialState = {
  places: [],
  isLoading: false,
  totalDocs: 0,
  dialog: {
    isDialogOpen: false,
    isDialogLoading: false,
    dialogAction: DialogAction.Initial,
  },
} as IPlacesState;

export const placesReducer = (
  state = initialState,
  action: Action
): IPlacesState => {
  switch (action.type) {
    case types.PLACES_GET_DOCS:
      return {
        ...state,
        places: action.payload,
      };
    case types.PLACES_UPDATE_DOC:
      return {
        ...state,
        places: state.places.map((x) =>
          x.id === action.payload.id ? { ...x, ...action.payload } : x
        ),
      };
    case types.PLACES_REMOVE_DOC:
      return {
        ...state,
        places: state.places.filter((x) => x.id !== action.payload.id),
      };
    case types.PLACES_ADD_DOCS:
      return {
        ...state,
        places: [...state.places, ...action.payload],
      };
    case types.PLACES_SET_LAST_DOC:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.PLACES_SET_TOTAL_DOCS:
      return {
        ...state,
        totalDocs: action.payload,
      };
    case types.PLACES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.PLACES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.PLACES_IS_DIALOG_OPEN:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          isDialogOpen: action.payload,
        },
      };
    case types.PLACES_IS_DIALOG_LOADING:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          isDialogLoading: action.payload,
        },
      };
    case types.PLACES_CHANGE_DIALOG_ACTION:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          dialogAction: action.payload,
        },
      };
    case types.PLACES_SET_SELECTED_SECTOR:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          selectedPlace: action.payload,
        },
      };
    default:
      return state;
  }
};
