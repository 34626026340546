import { AnswerOT, SheetOT } from "../Sheet";

export const twoOrMoreAreWrong = (
	sheet: Pick<SheetOT, "Revisores">
): boolean => {
	const responses = sheet.Revisores.flatMap((r) =>
		r.Categorias.flatMap((c) => c.Preguntas)
	);

	return responses.some((res) => {
		const [first, second] = res.Respuestas;
		const firstIsWrong = first?.Estado === "Rechazado";
		const secondIsWrong = second?.Estado === "Rechazado";
		return firstIsWrong && secondIsWrong;
	});
};

export const getDefaultComment = ({
	Estado,
}: Pick<AnswerOT, "Estado">) => {
	switch (Estado) {
		case "Aprobado":
			return "Conforme";
		case "Rechazado":
			return "N/A";
		case "No Aplica":
			return "No se considera";
		case "Pendiente":
			return "Por revisar";
		case "Bloqueado":
			return "No revisa";
	}
};

export const getReviewerStatusColor = (
	sheet: SheetOT,
	reviewer: number
) => {
	const { Firma, Categorias } = sheet.Revisores[reviewer];
	const responses = Categorias.flatMap(
		(c) => c.Preguntas
	).flatMap((c) => c.Respuestas);

	if (Firma) return "lightgreen";
	if (responses.some((r) => r?.Estado === "Bloqueado"))
		return "gold";
	return "white";
};
