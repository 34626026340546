import { Link, Typography } from "@material-ui/core";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useCheckproLinks } from "./hooks/useCheckproLinks";

export const LinkEmpresas: FC = () => {
	const { auth } = useCheckproLinks();

	if (auth.user?.tipoUsuario !== "Administrador")
		return <Typography>Empresas</Typography>;

	return (
		<Link
			component={NavLink}
			exact
			to="/administrador/empresas"
		>
			Empresas
		</Link>
	);
};
