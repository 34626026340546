import { firestore } from "../firebase/firebase";
import { Observacion } from "../models/Observacion";
import { ObservacionChecklist } from "../models/ObservacionChecklist";

export const conseguirImagenObservacion = async (
	o: Observacion | ObservacionChecklist
) => {
	let nextSrc = null as null | string;

	if ("ImagenUri" in o) nextSrc = o.ImagenUri;
	//@ts-ignore
	else if ("Imagen" in o) nextSrc = o.Imagen;
	else if (o.Version === "2.0")
		nextSrc = o.Imagen2 ?? o.Imagen1 ?? "";

	if (nextSrc) return nextSrc;

	const { ProyectoId, RevisionId, ListadonId, id } =
		o as Observacion;

	try {
		const snap = await firestore
			.doc(
				`/Proyectos/${ProyectoId}/Revisiones/${RevisionId}/Listado/${ListadonId}/Observaciones/${id}/Imagenes/Antes`
			)
			.get();

		if (!snap.exists) throw Error("No existe la imagen");

		const { Image } = snap.data() as {
			Image: string;
		};

		return `data:image/jpeg;base64,${Image}`;
	} catch (error) {
		console.error(error);
	}
};
