import firebase from "firebase/app";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { v4 as uuidv4 } from "uuid";
import { cleanString } from "../../utils/utils";
import { openSnack } from "./uiActions";
import { SnackState } from "../../models/snack-state";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";
import naturalSort from "natural-sort";
import { InnerChecklist } from "src/models/Checklist";
import { ToggableProyecto } from "src/models/Empresa";

const listener = FirebaseListener.addListener(
	"inner-checklists"
);

export const getChecklist = (
	businessId?: string
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const _businessId =
				businessId ??
				getState().authReducer.user?.empresaReference.id;

			if (!_businessId)
				throw Error("No hay una empresa seleccionada.");

			listener.close();
			listener.set(
				firestore
					.collection("Checklists")
					.where("EmpresaId", "==", _businessId)
					.orderBy("Nombre")
					.onSnapshot((snap) => {
						const data = snap.docs
							.map(
								(x) =>
									({
										...x.data(),
										id: x.id,
										_ref: x.ref,
									} as any)
							)
							.sort((a, b) =>
								naturalSort()(a.Nombre, b.Nombre)
							);

						dispatch({
							type: types.CHECKLISTS_GET_SUCCESS,
							payload: {
								checklist: data,
								totalDocs: snap.size,
								lastDoc: snap.docs[snap.docs.length - 1],
							},
						});
					})
			);
		} catch (error: any) {
			dispatch({
				type: types.CHECKLIST_GET_FAILURE,
				payload:
					"Ha ocurrido un error al cargar las Solicitudes",
			});
		}
	};
};
export function updateChecklist(
	id: string,
	nombre: string,
	checklist: any
): AppThunk {
	return async (dispatch) => {
		dispatch({
			type: types.CHECKLIST_UPDATE_ONE_SUBMITTING,
		});
		try {
			await firestore
				.collection("Checklists")
				.doc(id)
				.update({
					Nombre: nombre,
					ItemsRecintos: checklist,
				});
		} catch (error: any) {
			dispatch({
				type: types.CHECKLIST_UPDATE_ONE_FAILURE,
				payload: "Error Inesperado",
			});
		}
	};
}

export const getOneChecklist = (id: any): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.CHECKLIST_GET_ONE_SUBMITTING,
		});
		try {
			const response = await firestore
				.collection("Checklists")
				.doc(id)
				.get();
			dispatch({
				type: types.CHECKLIST_GET_ONE_SUCCESS,
				payload: { ...response.data(), id: response.id },
			});
		} catch (error: any) {
			dispatch({
				type: types.CHECKLIST_GET_ONE_FAILURE,
				payload:
					"Ha ocurrido un error al obtener el checklists",
			});
		}
	};
};
// obtener Un Recito(mostrar sus Subcontratos)
export const getOneSubcontrato = (
	checklistId: any,
	recintoId: any
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.SUBCONTRATO_GET_ONE_SUBMMITING,
		});
		try {
			const response = await firestore
				.collection("Checklists")
				.doc(checklistId)
				.get();
			if (response.exists) {
				let thisRecinto: any;
				response.data()?.ItemsRecintos?.map((sel: any) => {
					if (sel?.id === recintoId) {
						thisRecinto = sel;
					}
				});
				const subcontractList =
					thisRecinto.Subcontratos.map((item: any) => ({
						...item,
					}));
				dispatch({
					type: types.SUBCONTRATO_GET_ONE_SUCCESS,
					payload: {
						...thisRecinto,
						Subcontratos: subcontractList,
					},
				});
			}
		} catch (error: any) {
			dispatch({
				type: types.SUBCONTRATO_GET_ONE_FAILURE,
				payload:
					"Ha ocurrido un error al obtener el checklists",
			});
		}
	};
};
// actualizar nombre y recintos checklist
export function updateInfoChecklist(
	id: string,
	nombre: string,
	checklist: any
): AppThunk {
	return async (dispatch) => {
		dispatch({
			type: types.CHECKLIST_EDIT_SUBMMITING,
		});
		try {
			await firestore
				.collection("Checklists")
				.doc(id)
				.update({
					Nombre: nombre,
					Nombre_lower: cleanString(nombre),
					ItemsRecintos: checklist,
				});
		} catch (error: any) {
			dispatch({
				type: types.CHECKLIST_EDIT_FAILURE,
				payload: "Error Inesperado",
			});
		}
	};
}

export const addChecklist = (
	checklist: any,
	precincts?: any[]
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		try {
			const { user } = getState().authReducer;

			if (!user) throw Error("No hay usuario autenticado.");

			const empresaId =
				getState().businessReducer.selectedBusiness?.id ??
				user.empresaReference.id;

			const checklistWithSameName = await firestore
				.collection("Checklists")
				.where("EmpresaId", "==", empresaId)
				.where(
					"Nombre_lower",
					"==",
					cleanString(checklist.Nombre)
				)
				.limit(1)
				.get();

			if (!checklistWithSameName.empty)
				throw Error(
					"Ya existe un checklist con ese nombre"
				);

			await firestore.collection("Checklists").add({
				Activado: true,
				EmpresaId: empresaId,
				Nombre: checklist.Nombre,
				Nombre_lower: cleanString(checklist.Nombre),
				ItemsRecintos: precincts ?? checklist.ItemsRecintos,
				FechaCreacion:
					firebase.firestore.FieldValue.serverTimestamp(),
			});

			dispatch(
				openSnack(
					"Hemos creado el checklist.",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			console.error(error);
			dispatch(openSnack(error.message, SnackState.ERROR));
		}
		dispatch(isLoading(false));
	};
};
// obtener los subcontratos de la empresa loggeada
export const getEmpresaSubcontratos = (): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const { user } = getState().authReducer;
			if (!user) throw Error("No hay usuario logueado");

			dispatch({
				type: types.SUBCONTRATOS_GET_SUBMMITING,
			});
			const userData = await firestore
				.collection("Usuarios")
				.doc(user.localId)
				.get();
			const empresaId =
				userData?.data()?.EmpresaReference?.id;
			const empresaResponse = await firestore
				.collection("Empresas")
				.doc(empresaId)
				.get();
			const empresaData = empresaResponse?.data();
			dispatch({
				type: types.SUBCONTRATOS_GET_SUCCESS,
				payload: empresaData?.Subcontratos,
			});
		} catch (error: any) {
			dispatch({
				type: types.SUBCONTRATOS_GET_FAILURE,
			});
		}
	};
};
export const removeChecklist = (
	checklist: any
): AppThunk => {
	return async (dispatch) => {
		dispatch(isLoading(true));
		try {
			const response = await firestore
				.collection("Checklists")
				.doc(checklist.id)
				.delete();
		} catch (error: any) {
			dispatch(isLoading(false));
		}
	};
};
export const removeRecinto = (
	checklisId: any,
	recinto: any
): AppThunk => {
	return async (dispatch, getState) => {
		const { dialog, recintos } =
			getState().checklistReducer;

		dispatch({
			type: types.RECINTO_REMOVE_SUBMMITING,
		});
		try {
			const response = await firestore
				.collection("Checklists")
				.doc(checklisId.id)
				.update({
					ItemsRecintos:
						firebase.firestore.FieldValue.arrayRemove(
							recinto
						),
				});
			dialog.selectedChecklist.ItemsRecintos =
				dialog.selectedChecklist.ItemsRecintos.filter(
					(x: any) => x.id !== recinto.id
				);

			dispatch({
				type: types.RECINTO_REMOVE_SUCCESS,
			});
		} catch (error: any) {
			dispatch({
				type: types.RECINTO_REMOVE_FAILURE,
				payload: "Ha ocurrido un Error...",
			});
		}
	};
};
export const removeSubcontrato = (
	checklisId: any,
	subcontrato: any
): AppThunk => {
	return async (dispatch, getState) => {
		const { dialog, selectedRecinto } =
			getState().checklistReducer;

		dispatch({
			type: types.SUBCONTRATO_REMOVE_SUBMMITING,
		});
		try {
			const subcontratos =
				selectedRecinto?.Subcontratos.filter(
					(x: any) => x !== subcontrato
				);
			dialog.selectedChecklist.ItemsRecintos.map(
				(ele: any) => {
					if (ele.id === selectedRecinto?.id) {
						ele.Subcontratos = subcontratos;
					}
				}
			);
			const editedData =
				dialog.selectedChecklist.ItemsRecintos;

			const response = await firestore
				.collection("Checklists")
				.doc(checklisId.id)
				.update({
					ItemsRecintos: editedData,
				});
			dispatch({
				type: types.SUBCONTRATO_REMOVE_SUCCESS,
			});
		} catch (error: any) {
			dispatch({
				type: types.SUBCONTRATO_REMOVE_FAILURE,
				payload: "Ha ocurrido un Error...",
			});
		}
	};
};
export const getPlaces2 = (): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.RECINTOS_GET_SUBMMITING,
		});
		try {
			const response = await firestore
				.collection("Recintos")
				.orderBy("Nombre")
				.get();

			const responseTotal = await firestore
				.collection("Recintos")
				.get();
			const responseRecintos = response.docs.map((x) => ({
				...x.data(),
				id: x.id,
			}));
			dispatch({
				type: types.RECINTOS_GET_SUCCESS,
				payload: {
					recintos: responseRecintos,
					totalDocs: responseTotal.size,
					lastDoc: response.docs[response.docs.length - 1],
				},
			});
		} catch (error: any) {
			dispatch({
				type: types.RECINTOS_GET_FAILURE,
				payload:
					"Ha ocurrido un error al cargar las Solicitudes",
			});
		}
	};
};

export const addRecinto = (
	values: any,
	recinto: any
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const { user } = getState().authReducer;
			if (!user) throw Error("No hay usuario logueado");

			dispatch({
				type: types.RECINTO_ADD_SUBMMITING,
			});
			const { dialog } = getState().checklistReducer;
			const userData = await firestore
				.collection("Usuarios")
				.doc(user.localId)
				.get();
			const empresaId =
				userData?.data()?.EmpresaReference?.id;
			const empresaResponse = await firestore
				.collection("Empresas")
				.doc(empresaId)
				.get();
			const empresaData = empresaResponse?.data();
			await firestore
				.collection("Checklists")
				.doc(recinto)
				.update({
					ItemsRecintos:
						firebase.firestore.FieldValue.arrayUnion({
							Nombre: values.Nombre,
							Activado: values.Activado,
							id: uuidv4(),
						}),
				});
			dialog.selectedChecklist.ItemsRecintos = [
				...(dialog.selectedChecklist?.ItemsRecintos || []),
				{
					Nombre: values.Nombre,
					Activado: values.Activado,
					id: uuidv4(),
				},
			];
			dispatch({
				type: types.RECINTO_ADD_SUCCESS,
			});
		} catch (error: any) {
			dispatch({
				type: types.RECINTO_ADD_FAILURE,
				payload:
					"Ha ocurrido un error al cargar las Solicitudes",
			});
		}
	};
};
// AGREGAR SUBCONTRATOS
export const addSubcontrato = (
	values: ToggableProyecto[],
	checklistId: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({
			type: types.SUBCONTRATO_ADD_SUBMMITING,
		});
		const { dialog, selectedRecinto } =
			getState().checklistReducer;
		try {
			// const previousChecklist = await firestore
			// 	.doc(`Checklists/${checklistId}`)
			// 	.get();

			// const { ItemsRecintos } =
			// 	previousChecklist.data() as InnerChecklist;

			// const previousSubcontratos = ItemsRecintos.find(
			// 	(r) => r.Nombre === selectedRecinto?.Nombre
			// )?.Subcontratos;

			// Se mapea El selectedRecinto y se comprueba si es el que se selecciona
			// es igual entonces se le pasa la data(valores enviados)

			const selectedSubcontratos: any[] =
				selectedRecinto?.Subcontratos || [];

			selectedSubcontratos.push(
				...values.filter(
					(newValue) =>
						!selectedRecinto?.Subcontratos?.some(
							(previousValue) =>
								previousValue.Nombre === newValue.Nombre
						)
				)
			);
			let copiedData =
				dialog.selectedChecklist.ItemsRecintos;
			copiedData.map((x: any) => {
				if (x === selectedRecinto) {
					x.Subcontratos = selectedSubcontratos;
				}
			});
			await firestore
				.collection("Checklists")
				.doc(checklistId)
				.update({
					ItemsRecintos: copiedData,
				});

			dispatch({
				type: types.SUBCONTRATO_ADD_SUCCESS,
				payload: {
					...selectedRecinto,
					Subcontratos: selectedSubcontratos,
				},
			});
		} catch (error: any) {
			console.error(error);
			dispatch({
				type: types.SUBCONTRATO_ADD_FAILURE,
				payload:
					"Ha ocurrido un error al cargar las Solicitudes",
			});
		}
	};
};

export const addErrorTipo = (
	values: any,
	checklistId: any,
	recintoId: any,
	selected: any
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({
			type: types.ERRORTIPO_ADD_SUBMMITING,
		});

		const { dialog, selectedTypeErrors } =
			getState().checklistReducer;
		try {
			let errorestipo: any = [];

			dialog.selectedChecklist?.ItemsRecintos?.map(
				(e: any) => {
					if (e.id === recintoId) {
						e.Subcontratos.map((sub: any) => {
							if (sub.Nombre === selected.Nombre) {
								if (sub.ErroresTipo) {
									sub.ErroresTipo = [
										...sub?.ErroresTipo,
										values,
									];
								} else {
									sub.ErroresTipo = [values];
								}
								errorestipo = sub.ErroresTipo;
							}
						});
					}
				}
			);
			const editedData =
				dialog.selectedChecklist.ItemsRecintos;
			await firestore
				.collection("Checklists")
				.doc(checklistId)
				.update({
					ItemsRecintos: editedData,
				});

			dispatch({
				type: types.ERRORTIPO_ADD_SUCCESS,
				payload: {
					...selectedTypeErrors,
					ErroresTipo: errorestipo,
				},
			});
		} catch (error: any) {
			dispatch({
				type: types.ERRORTIPO_ADD_FAILURE,
				payload:
					"Ha ocurrido un error al Agregar el error tipo",
			});
		}
	};
};
export const deleteErrorTipo = (
	checklistId: string,
	recintoId: string,
	subcontrato: string,
	selected: any
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({
			type: types.ERRORTIPO_DELETED_SUBMITING,
		});
		const { dialog, selectedTypeErrors } =
			getState().checklistReducer;
		try {
			let newlist: any = [];
			dialog.selectedChecklist?.ItemsRecintos?.map(
				(e: any) => {
					if (e.id === recintoId) {
						e.Subcontratos.map((sub: any) => {
							if (sub.Nombre === subcontrato) {
								newlist = sub.ErroresTipo.filter(
									(x: any) => x.Nombre !== selected.Nombre
								);
								sub.ErroresTipo = newlist;
							}
						});
					}
				}
			);
			const newData =
				dialog.selectedChecklist.ItemsRecintos;
			await firestore
				.collection("Checklists")
				.doc(checklistId)
				.update({
					ItemsRecintos: newData,
				});
			dispatch({
				type: types.ERRORTIPO_DELETED_SUCCESS,
				payload: {
					...selectedTypeErrors,
					ErroresTipo: newlist,
				},
			});
		} catch (error: any) {
			dispatch({
				type: types.ERRORTIPO_DELETED_FAILURE,
				payload:
					"Ha ocurrido un error al Agregar el error tipo",
			});
		}
	};
};

const isLoading = (isloading: boolean): Action => ({
	type: types.CHECKLIST_LOADING,
	payload: isloading,
});

export const setSelectedChecklist = (
	setSelected?: any
): Action => ({
	type: types.CHECKLIST_SET_SELECTED,
	payload: setSelected,
});
export const setSelectedRecinto = (
	setSelected?: any
): Action => ({
	type: types.SET_SELECTED_RECINTO,
	payload: setSelected,
});
export const setSelectedSubContracto = (
	setSelected?: any
): Action => ({
	type: types.SUBCONTRACTO_SET_SELECTED,
	payload: setSelected,
});

export const setSelectedTypeErrors = (
	setSelected?: any
): Action => ({
	type: types.ERROR_TYPES_SET_SELECTED,
	payload: setSelected,
});
export const setSelectedErrorTypeChecklist = (
	errorType?: any[]
): Action => ({
	type: types.ERRORSTIPO_CHECKLIST_SET_SELECTED,
	payload: errorType,
});
