import * as React from "react";
import { Fade, TableCell, TableRow, Typography } from "@material-ui/core";

interface Props {
  colSpan: number;
}

export const TableNoContent: React.FC<Props> = ({ colSpan }) => {
  return (
    <Fade in={true}>
      <TableRow>
        <TableCell align={"center"} colSpan={colSpan}>
          <img alt="Nada imagen" src="/static/no_data.svg" height="100" />
          <Typography variant="h5">Nada Aun</Typography>
        </TableCell>
      </TableRow>
    </Fade>
  );
};
