import { Link } from "@material-ui/core";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useCheckproLinks } from "./hooks/useCheckproLinks";

type Props = {};

export const LinkListados = () => {
	const {
		auth,
		idProyecto,
		idRevision,
		isCheckList,
		idEmpresa,
	} = useCheckproLinks();

	const tipoUsuario = auth.user?.tipoUsuario;

	const nextLink = useMemo(() => {
		if (isCheckList) {
			if (tipoUsuario === "Administrador")
				return `/administrador/checklist-proyectos/${idProyecto}/revisiones/${idRevision}/listados`;
			else
				return `/administrador/checklist-proyectos/${idProyecto}/revisiones/${idRevision}/listados`;
		} else {
			if (tipoUsuario === "Administrador")
				return `/administrador/empresas/${idEmpresa}/proyectos/${idProyecto}/revisiones/${idRevision}/listados`;
			else if (tipoUsuario === "AdministradorEmpresa")
				return `/administrador/empresa/proyectos/${idProyecto}/revisiones/${idRevision}/listados`;
			else
				return `/administrador/user/proyectos/${idProyecto}/revisiones/${idRevision}/listados`;
		}
	}, [
		isCheckList,
		tipoUsuario,
		idProyecto,
		idRevision,
		idEmpresa,
	]);

	return (
		<Link component={NavLink} exact to={nextLink}>
			Listados
		</Link>
	);
};
