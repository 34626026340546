import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC } from "react";
import { v4 } from "uuid";

interface Props {
	rows: number;
	colSpan: number;
	animation?: "wave" | "pulse";
}

export const TableLoading: FC<Props> = ({
	rows,
	colSpan,
	animation = "wave",
}) => {
	return (
		<>
			{new Array(rows).fill(0).map((_, i) => (
				<TableRow hover key={v4()}>
					<TableCell colSpan={colSpan} align="center">
						<Skeleton animation={animation} />
					</TableCell>
				</TableRow>
			))}
		</>
	);
};
