import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel as MuiFormControlLabel,
	Switch,
	TextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { DialogAction } from "../../../models/dialog_state";

const FormControlLabel = styled(MuiFormControlLabel)(
	spacing
);

type ObjectType = {
	Nombre: string;
	Activado: boolean;
};

interface DialogProps {
	open: boolean;
	onClose: () => void;
	onAccept: (values: any, action: DialogAction) => void;
	isLoading: boolean;
	action: DialogAction;
	object?: ObjectType;
	title?: string;
	canToggle?: boolean;
}

export const DialogEditAndNew: React.FC<DialogProps> = ({
	open,
	onClose,
	onAccept,
	isLoading,
	action,
	object,
	title = "",
	canToggle = true,
}) => {
	const {
		handleSubmit,
		values,
		handleChange,
		touched,
		errors,
		resetForm,
		setValues,
	} = useFormik({
		initialValues: {
			Nombre: "",
			Activado: true,
		},
		onSubmit: (values) => {
			onAccept(values, action);
		},
		validationSchema: yup.object({
			Nombre: yup.string().required("Nombre requerido"),
		}),
	});

	useEffect(() => {
		if (action === "New") {
			resetForm();
		} else if (action === "Edit" && object) {
			setValues({
				Nombre: object.Nombre.trim(),
				Activado: object.Activado,
			});
		}
	}, [open, resetForm, setValues, object, action]);

	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={(_, reason) => {
					if (reason !== "backdropClick") {
						onClose();
					}
				}}
				aria-labelledby="add-and-update-dialog-title"
			>
				<DialogTitle id="add-and-update-dialog-title">
					{action === "Edit"
						? `Editar ${title}`
						: `Nuevo/a ${title}`}
				</DialogTitle>

				<form noValidate onSubmit={handleSubmit}>
					<DialogContent>
						<TextField
							autoFocus
							id="Nombre"
							label={`Nombre ${title}`}
							autoComplete="off"
							type="text"
							value={values.Nombre}
							name="Nombre"
							onChange={handleChange}
							error={
								touched.Nombre && Boolean(errors.Nombre)
							}
							helperText={touched.Nombre && errors.Nombre}
							fullWidth
						/>
						{canToggle && (
							<FormControlLabel
								control={
									<Switch
										checked={values.Activado}
										onChange={handleChange}
										name="Activado"
										color="primary"
									/>
								}
								mt={4}
								label="Activado"
							/>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} color="primary">
							Cancelar
						</Button>
						<Button
							type="submit"
							color="primary"
							disabled={isLoading}
						>
							Guardar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
};
