import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
} from "@material-ui/core";
import { FC } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

type Props = {
	loading: [boolean, boolean];
	onSubmit: (
		checklistType: "Obra Gruesa" | "Terminaciones"
	) => Promise<void>;
} & Omit<DialogProps, "onSubmit">;

export const ResponseSummaryExcelDialog: FC<Props> = ({
	loading,
	onSubmit,
	...dialog
}) => {
	const { selected } = useProjectsOT();

	const anyIsLoading = loading.some((l) => l);

	return (
		<Dialog {...dialog}>
			<DialogTitle
				bigText={selected.project?.Nombre ?? "Proyecto"}
				smallText="Selecciona el tipo de lista de chequeo:"
			/>

			<DialogContent
				style={{
					display: "flex",
					flexDirection: "row",
					gap: 10,
				}}
			>
				<Button
					disabled={anyIsLoading}
					fullWidth
					color="primary"
					variant="contained"
					onClick={() => onSubmit("Obra Gruesa")}
				>
					{loading[0] ? (
						<CircularProgress size={24} />
					) : (
						"OBRA GRUESA"
					)}
				</Button>
				<Button
					fullWidth
					disabled={anyIsLoading}
					color="primary"
					variant="contained"
					onClick={() => onSubmit("Terminaciones")}
				>
					{loading[1] ? (
						<CircularProgress size={24} />
					) : (
						"TERMINACIONES"
					)}
				</Button>
			</DialogContent>

			<DialogActions>
				<Button
					onClick={() =>
						dialog.onClose?.({}, "backdropClick")
					}
					color="primary"
				>
					Cancelar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
