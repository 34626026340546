import { FC, ReactNode, useEffect } from "react";
import { useStyles } from "../theme/useStyles";
import {
	Card as MuiCard,
	CardContent,
	CardHeader,
	Divider as MuiDivider,
	Fade,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { DialogEditAndNew } from "./Business/Dialogs/DialogEditAndNew";
import { NewConfirmDialog } from "./NewConfirmDialog";
import { useTable } from "../hooks/useTable";
import { ChevronRight } from "react-feather";
import { useNewEditDialog } from "../hooks/useNewEditDialog";
import { useConfirmDialog } from "../hooks/useConfirmDialog";
import { DialogAction } from "../models/dialog_state";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

interface Props {
	isLoading: boolean;
	name: string;
	nombreSelected?: string;
	goToAnotherPage: (selected: any) => void;
	/**
	 * @param values Item seleccionado por las acciones
	 * @param action Nuevos datos a crear o actualizar
	 * @param selected Action que realiza el Dialogo
	 */
	onAcceptNewEditDialog: (
		values: any,
		action: DialogAction,
		selected: any
	) => void;
	onConfirmDeleteDialog: (selected: any) => void;
	cardTitle?: string;
	array: any[];
	extraActions?: {
		tooltipText: string;
		handleAction: (selected: any) => void;
		icon: ReactNode;
	}[];
	openNewDialog?: boolean;
	onClose?: () => void;
	tableLimit?: number;
}

export const SimpleCrudProjectChecklist: FC<Props> =
	({
		isLoading,
		name,
		goToAnotherPage,
		onAcceptNewEditDialog,
		onConfirmDeleteDialog,
		cardTitle = "",
		array,
		openNewDialog: openNewEditDialog = null,
		onClose = () => {},
		tableLimit,
	}) => {
		const classes = useStyles();
		const {
			limit,
			page,
			handleLimitChange,
			handlePageChange,
		} = useTable({
			limit: tableLimit ?? TABLE_LIMIT_DEFAULT,
		});
		const {
			open: newEditDialogOpen,
			setIsLoading: setIsLoadingNewEditDialog,
			selected: newEditDialogSelected,
			action,
			closeDialog,
			openNewDialog,
			onAccept,
		} = useNewEditDialog<any>({
			onAccept: (values, action) =>
				onAcceptNewEditDialog(
					values,
					action,
					newEditDialogSelected
				),
			onClose: onClose,
		});

		const {
			open: confirmDialogOpen,
			dialogConfirmHandleClose,
			dialogConfirmHandleConfirm,
			setIsLoading,
			selected: confirmDialogSelected,
		} = useConfirmDialog({
			onConfirm: onConfirmDeleteDialog,
			onClose: onClose,
		});

		useEffect(() => {
			setIsLoading(isLoading);
			setIsLoadingNewEditDialog(isLoading);
		}, [
			isLoading,
			setIsLoading,
			setIsLoadingNewEditDialog,
		]);

		useEffect(() => {
			if (openNewEditDialog) {
				openNewDialog();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [openNewEditDialog]);

		return (
			<Card mb={6} mt={0}>
				{openNewEditDialog === null ? (
					<CardHeader title={cardTitle} />
				) : null}
				<Divider />
				<CardContent>
					<TableContainer
						className={classes.tableContainer}
					>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>Nombre</TableCell>
									<TableCell align="center">
										Observaciones
									</TableCell>
									<TableCell>Acciones</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{array ? (
									array
										.slice(
											page * limit,
											page * limit + limit
										)
										.map((arr: any, i: any) => (
											<Fade key={i} in={true}>
												<TableRow
													hover
													className={classes.styledRow}
												>
													<TableCell>
														{arr.Nombre}
													</TableCell>

													<TableCell align="center">
														{`${arr?.Resueltas || 0} de ${
															arr?.TotalRespuestas || 0
														}`}
													</TableCell>
													<TableCell align="center">
														<Tooltip title="Respuestas">
															<IconButton
																onClick={() => {
																	goToAnotherPage(arr);
																}}
																color="primary"
															>
																<ChevronRight />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											</Fade>
										))
								) : (
									<Fade in={true}>
										<TableRow>
											<TableCell
												align={"center"}
												colSpan={3}
											>
												<Typography variant="h5">
													Nada Aun
												</Typography>
											</TableCell>
										</TableRow>
									</Fade>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={array?.length || 0}
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handleLimitChange}
						page={page}
						rowsPerPage={limit}
						rowsPerPageOptions={TABLE_LIMITS}
					/>
				</CardContent>
				<DialogEditAndNew
					open={newEditDialogOpen}
					onClose={closeDialog}
					onAccept={onAccept}
					isLoading={isLoading}
					action={action}
					object={newEditDialogSelected}
					title={name}
				/>
				<NewConfirmDialog
					open={confirmDialogOpen}
					title={`¿Desea eliminar el ${name} ${confirmDialogSelected?.Nombre}?`}
					onClose={dialogConfirmHandleClose}
					isLoading={isLoading}
					onConfirm={dialogConfirmHandleConfirm}
					onConfirmText={"Eliminar"}
				/>
			</Card>
		);
	};
