import { firestore } from "src/firebase/firebase";
import { Empresa } from "src/models/Empresa";
import { RootState } from "../store/reducer";
import Firebase from "firebase";
import { ChecklistProject } from "src/models/OT/Projects";
import { SheetOT } from "src/models/OT/Sheet";

export type StateGetter<T = RootState> = () => T;

export const getSelectedBusinessRef = (
	stateGetterOrBusiness: StateGetter | Empresa
) => {
	if (typeof stateGetterOrBusiness === "function") {
		const {
			authReducer: { user },
			businessReducer: {
				edit: { selectedBusiness },
			},
		} = stateGetterOrBusiness();

		if (user?.tipoUsuario === "UsuarioEmpresa")
			return user.empresaReference;
		else {
			if (!selectedBusiness)
				throw Error("No hay una empresa seleccionada.");
			return firestore.doc(
				`Empresas/${selectedBusiness?.id}`
			);
		}
	} else {
		return firestore.doc(
			`Empresas/${stateGetterOrBusiness.id}`
		);
	}
};

export const getSelectedNeoProjectRef = (
	stateGetterOrProject: StateGetter | ChecklistProject
): Firebase.firestore.DocumentReference => {
	if (typeof stateGetterOrProject === "function") {
		const ref =
			stateGetterOrProject().neoChecklistReducer.selected
				.project?._ref;
		if (!ref)
			throw Error("No hay un proyecto seleccionado.");

		return ref;
	} else {
		if (!stateGetterOrProject._ref)
			throw Error("No hay un proyecto seleccionado.");
		return stateGetterOrProject._ref;
	}
};

export const deleteBusinessReference = <
	T extends { [key: string]: any }
>(
	obj: T
) => {
	const newObj = { ...obj };
	if (newObj.EmpresaReference)
		delete newObj.EmpresaReference;
	return newObj;
};

export const countWrongAnswersOfSpace = async (
	ref: Firebase.firestore.DocumentReference
) => {
	const responsesSnap = await ref
		.collection("RespuestasOT")
		.get();

	let count = 0;

	responsesSnap.forEach((snap) => {
		const { Revisores } = snap.data() as SheetOT;

		Revisores.forEach((revision) => {
			revision.Categorias.forEach((categoria) => {
				categoria.Preguntas.forEach((pregunta) => {
					pregunta.Respuestas.forEach((respuesta) => {
						if (respuesta?.Estado === "Rechazado") count++;
					});
				});
			});
		});
	});

	return count;
};
