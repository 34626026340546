import Axios from "axios";
import { auth } from "../firebase/firebase";

export const bloquearUsuario = async (mail: string) => {
	const token = await auth.currentUser?.getIdToken();
	if (!token)
		throw Error(
			"No se pudo obtener el token de autenticación"
		);

	let response;

	try {
		response = await Axios.post<string>(
			`https://us-central1-checkpro-3a90a.cloudfunctions.net/app/users/disable/${mail}`,
			{},
			{ headers: { token } }
		);
	} catch (error) {
		if (Axios.isAxiosError(error)) {
			throw Error(error.response?.data);
		} else throw error;
	}

	return response.data;
};

export const activarUsuario = async (mail: string) => {
	const token = await auth.currentUser?.getIdToken();
	if (!token)
		throw Error(
			"No se pudo obtener el token de autenticación"
		);
	let response;

	try {
		response = await Axios.post<string>(
			`https://us-central1-checkpro-3a90a.cloudfunctions.net/app/users/enable/${mail}`,
			{},
			{ headers: { token } }
		);
	} catch (error) {
		if (Axios.isAxiosError(error)) {
			throw Error(error.response?.data);
		} else throw error;
	}

	return response.data;
};
