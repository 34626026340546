import * as React from "react";
import { Icon } from "@material-ui/core";
import { ReactComponent as ExcelSheetSvg } from "./excel-sheet.svg";
import { IconProps } from "./IconProps";
import { grey } from "@material-ui/core/colors";

export const ExcelSheetIcon: React.FC<IconProps> = (props) => {
  const fill = !props?.disabled ? props?.style?.fill : grey[400];
  return (
    <Icon>
      <ExcelSheetSvg style={{ ...props.style, fill }} />
    </Icon>
  );
};
