import {
	SimpleCrud,
	SimpleCrudProps,
} from "src/components/SimpleCrud";
import { useBusiness } from "src/hooks/redux/useBusiness";
import {
	actualizarSectorEmpresa,
	agregarSectorEmpresa,
	quitarSectorEmpresa,
} from "src/redux/actions/businessActions";
import { useDispatch } from "src/redux/store";

export const BusinessSectorTab = () => {
	const dispatch = useDispatch();
	const { selectedBusiness, loading } = useBusiness();

	const handleSubmit: SimpleCrudProps["onCreateOrUpdate"] =
		(value, action, selected) =>
			dispatch(
				action === "New"
					? agregarSectorEmpresa(value)
					: actualizarSectorEmpresa(selected.Nombre, value)
			);

	const handleDelete: SimpleCrudProps["onDelete"] = (
		value
	) => dispatch(quitarSectorEmpresa(value.Nombre));

	return (
		<SimpleCrud
			key={"Sector"}
			array={[...new Set(selectedBusiness?.Sectores)]}
			isLoading={loading}
			name="Sector"
			onCreateOrUpdate={handleSubmit}
			onDelete={handleDelete}
		/>
	);
};
