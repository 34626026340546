/* eslint-disable react-hooks/exhaustive-deps */
import { MinusSquare as MinusSquareIcon } from "react-feather";
import {
  Box,
  MenuItem,
  TextField,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { endOfDay, startOfDay } from "date-fns";
import { Observacion } from "../../models/Observacion";
import { ExcelSheetIcon } from "../../icons/ExcelSheet";

export type FilterKeys = keyof Observacion;

export type FilterListKeys = keyof Observacion;

/**
 * Primera fecha debe ser la desde y la segunda hasta
 */
export type RangeDate = [Date, Date];

type Props = {
  observations: Observacion[];
  onChangeFilter: (value: string | RangeDate, filterName: FilterKeys) => void;
  onClickGenerateExcel: VoidFunction;
  isLoadingExcel: boolean;
};

export const LegacyFilters: FC<Props> = ({
  observations,
  onChangeFilter,
  onClickGenerateExcel: handleGenerateExcel,
  isLoadingExcel,
}) => {
  const generateList = useCallback(
    (observations: Observacion[], property: FilterListKeys) => {
      const dataSet = new Set<string>();
      observations.forEach((o) => dataSet.add(o[property] as any));
      return Array.from(dataSet).sort((a, b) => a.localeCompare(b));
    },
    []
  );
  const filters: {
    list: string[];
    name: string;
    id: FilterKeys;
  }[] = useMemo(
    () => [
      {
        list: generateList(observations, "NombreRevision"),
        name: "Revisiones",
        id: "NombreRevision",
      },
      {
        list: generateList(observations, "NombreListado"),
        name: "Listados",
        id: "NombreListado",
      },
      {
        list: generateList(observations, "Sector"),
        name: "Sectores",
        id: "Sector",
      },
      {
        list: generateList(observations, "Recinto"),
        name: "Recintos",
        id: "Recinto",
      },
      {
        list: generateList(observations, "Subcontrato"),
        name: "Subcontratos",
        id: "Subcontrato",
      },
      {
        list: generateList(observations, "ErrorTipo"),
        name: "Errores tipo",
        id: "ErrorTipo",
      },
      {
        list: generateList(observations, "Prioridad"),
        name: "Prioridades",
        id: "Prioridad",
      },
      {
        list: generateList(observations, "Estado"),
        name: "Estados",
        id: "Estado",
      },
    ],
    [observations]
  );

  const [selectedInitDate, setSelectedInitDate] = useState<Date | null>(null);

  const handleInitDateChange = (date: Date | null) => {
    setSelectedInitDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const handleEndDateChange = (date: Date | null) => {
    setSelectedEndDate(date);
  };

  const handleDeleteDates = () => {
    setSelectedEndDate(null);
    setSelectedInitDate(null);
  };

  useEffect(() => {
    if (selectedEndDate && selectedInitDate) {
      onChangeFilter(
        [startOfDay(selectedInitDate), endOfDay(selectedEndDate)],
        "CreatedAt"
      );
    }
    if (selectedEndDate === null && selectedInitDate === null) {
      onChangeFilter("todo", "CreatedAt");
    }
  }, [selectedInitDate, selectedEndDate]);

  return (
    <Box px={4} pt={4} display="flex" flexDirection="row">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {filters.map((data) => (
            <TextField
              key={data.name}
              size="small"
              margin="none"
              label={data.name}
              name={data.name}
              variant="outlined"
              select
              style={{
                minWidth: 150,
                marginRight: 10,
                marginTop: 10,
              }}
              onChange={(e) => {
                onChangeFilter(e.target.value, data.id);
              }}
              defaultValue={"todo"}
            >
              <MenuItem value="todo" divider>
                Todo
              </MenuItem>
              {data.list.map((option) => (
                <MenuItem key={option} value={option}>
                  {option === "Vencida"
                    ? "Por Resolver"
                    : option === ""
                    ? "N/A"
                    : option}
                </MenuItem>
              ))}
            </TextField>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ marginBottom: 5 }} variant="subtitle1">
            Rango de fechas
          </Typography>
          <Box display="flex" alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                disableToolbar
                id="start-date-pcicker"
                variant="inline"
                format="dd/MM/yyyy"
                margin="none"
                label="Fecha observación - Desde"
                value={selectedInitDate}
                onChange={handleInitDateChange}
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    size="small"
                    style={{ ...props.style }}
                  />
                )}
                cancelLabel="Cancelar"
                showTodayButton
              />
              <KeyboardDatePicker
                disableToolbar
                id="end-date-pcicker"
                variant="inline"
                format="dd/MM/yyyy"
                margin="none"
                label="Fecha observación - Hasta"
                cancelLabel="Cancelar"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    variant="outlined"
                    size="small"
                    style={{
                      ...props.style,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
            <Tooltip title="Borrar campos de fecha">
              <IconButton size="small" onClick={handleDeleteDates}>
                <MinusSquareIcon size={24} />
              </IconButton>
            </Tooltip>

            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateExcel}
              style={{ marginLeft: "auto" }}
              disabled={isLoadingExcel}
            >
              {!isLoadingExcel ? (
                <>
                  <ExcelSheetIcon style={{ fill: "white" }} />
                  &nbsp; Generar Excel
                </>
              ) : (
                "Generando Documento...."
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
