import {
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider as MuiDivider,
	Fade,
	FormControlLabel as MuiFormControlLabel,
	Grid,
	IconButton,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
	ChevronRight,
	Edit as EditIcon,
	Trash2 as RemoveIcon,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import { es } from "yup-locales";
import { ConfirmDialog } from "../components/ConfirmDialog";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import { DialogAction } from "../models/dialog-actions";
import {
	addChecklist,
	getChecklist,
	removeChecklist,
	setSelectedChecklist,
	updateInfoChecklist,
} from "../redux/actions/checklistActions";
import {
	addSector,
	getSectors,
	getTotalDocs,
	setSectorDialogOpen,
	updateSector,
} from "../redux/actions/sectorsActions";
import { IChecklistState } from "../redux/reducers/checklistReducer";
import { RootState } from "../redux/store/reducer";
import { ISectorsState } from "../redux/reducers/sectorsReducer";
import { useStyles } from "../theme/useStyles";
import { ChecklistEdit } from "./Checklits/ChecklistEdit";
import { DialogCreateEditChecklist } from "./Checklits/DialogCreateEditChecklist";
import { DialogEditChecklist } from "./Checklits/DialogEditChecklist";

yup.setLocale(es);
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(
	spacing
);

function ContentCard() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [openEdit, setOpenEdit] = useState(false);
	const [limit, setLimit] = useState(TABLE_LIMIT_DEFAULT);
	const [page, setPage] = useState(0);
	const [open, setOpen] = useState(false);

	const { checklist, totalDocs, checklistState } =
		useSelector<RootState, IChecklistState>(
			(state) => state.checklistReducer
		);

	const handleGoToViewRecintos = (data: any) => {
		dispatch(setSelectedChecklist(data));
		history.push(
			`/administrador/checklist/${data.id}/Recintos`
		);
	};
	const handleLimitChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(parseInt(event.target.value, 10));

		// if (
		// 	parseInt(event.target.value, 10) > checklist.length
		// ) {
		// 	dispatch(getChecklist());
		// }
		setPage(0);
	};
	const {
		dialog: { selectedChecklist },
	} = useSelector<RootState, IChecklistState>(
		(state) => state.checklistReducer
	);
	const showModalEdit = () => {
		setOpenEdit(!openEdit);
	};
	const handlePageChange = (_: any, newPage: number) => {
		// if (checklist.length < totalDocs && newPage > page) {
		// 	dispatch(getChecklist());
		// }
		setPage(newPage);
	};
	//  data edited
	const onAcept = (nombre: string, checklist: any) => {
		dispatch(
			updateInfoChecklist(
				selectedChecklist.id,
				nombre,
				checklist
			)
		);
	};
	// Confirm Dialog
	const [dialogState, setDialogState] = useState({
		open: false,
		title: "",
		checklist: {},
	});
	const openClose = () => {
		setOpen(!open);
	};
	const handleRemoveButton = (checklist: any) => {
		setDialogState((dialogState) => ({
			...dialogState,
			title: `¿Desea eliminar el Checklist ${checklist.Nombre}?`,
			checklist: checklist,
			open: true,
		}));
	};

	useEffect(() => {
		dispatch(getChecklist());
	}, []);

	const validateZeroSubcontratos = (checklist: any) => {
		let sumaSubcontratos = 0;

		checklist?.ItemsRecintos.map((item: any) => {
			if (
				item?.Subcontratos !== undefined &&
				item.Subcontratos?.length > 0
			) {
				sumaSubcontratos += 1;
			}
		});
		if (sumaSubcontratos > 0) {
			return true;
		} else {
			return false;
		}
	};
	return (
		<Card mb={6}>
			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell align="left">Nombre</TableCell>
								<TableCell align="left">Recintos</TableCell>
								<TableCell align="center">
									Acciones
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{checklistState === "Success"
								? checklist
										?.slice(
											page * limit,
											page * limit + limit
										)
										.map((checklist: any) => (
											<Fade key={checklist.id} in={true}>
												<TableRow
													hover
													className={classes.styledRow}
												>
													<TableCell align="left">
														{validateZeroSubcontratos(
															checklist
														) ? (
															<Typography>
																{checklist?.Nombre}
															</Typography>
														) : (
															<Typography color="error">
																{checklist?.Nombre}
															</Typography>
														)}
													</TableCell>
													<TableCell align="left">
														{checklist?.ItemsRecintos
															?.length === undefined
															? 0
															: checklist?.ItemsRecintos
																	?.length}
													</TableCell>
													<TableCell align="center">
														<Tooltip title="Editar">
															<IconButton
																onClick={() => {
																	openClose();
																	dispatch(
																		setSelectedChecklist(
																			checklist
																		)
																	);
																}}
																color="primary"
															>
																<EditIcon />
															</IconButton>
														</Tooltip>
														<Tooltip title="Eliminar">
															<IconButton
																onClick={() =>
																	handleRemoveButton(
																		checklist
																	)
																}
															>
																<RemoveIcon color="red" />
															</IconButton>
														</Tooltip>

														<Tooltip title="Sub-contratos">
															<span>
																<IconButton
																	onClick={() => {
																		handleGoToViewRecintos(
																			checklist
																		);
																	}}
																	color="primary"
																>
																	<ChevronRight />
																</IconButton>
															</span>
														</Tooltip>
													</TableCell>
												</TableRow>
											</Fade>
										))
								: new Array(limit).fill(0).map((x, i) => (
										<TableRow key={i}>
											<TableCell colSpan={5}>
												<Skeleton />
											</TableCell>
										</TableRow>
								  ))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={totalDocs}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
				<DialogEditChecklist
					title="Editar Checklist"
					open={openEdit}
					onClose={showModalEdit}
					onAccept={() => {}}
				/>
			</CardContent>
			<ConfirmDialog
				open={dialogState.open}
				onClose={() =>
					setDialogState((dialogState) => ({
						...dialogState,
						open: false,
					}))
				}
				onConfirm={() => {
					dispatch(removeChecklist(dialogState.checklist));
				}}
				title={dialogState.title}
			/>
			<ChecklistEdit
				title="Crear Cheklist"
				open={open}
				onAccept={(values, checklist) => {
					onAcept(values, checklist);
				}}
				onClose={() => openClose()}
			/>
		</Card>
	);
}

const DialogAddSector = () => {
	const {
		dialog: {
			isDialogLoading,
			isDialogOpen,
			dialogAction,
			selectedSector,
		},
	} = useSelector<RootState, ISectorsState>(
		(state) => state.sectorsReducer
	);

	const dispatch = useDispatch();

	const {
		handleSubmit,
		values,
		handleChange,
		touched,
		errors,
		setValues,
		resetForm,
	} = useFormik({
		initialValues: {
			Nombre: "",
			Activado: true,
		},
		onSubmit: (values) => {
			if (dialogAction === DialogAction.Edit) {
				dispatch(
					updateSector({ ...values, id: selectedSector.id })
				);
			}
			if (dialogAction === DialogAction.New) {
				dispatch(addSector(values));
			}
		},
		validationSchema: yup.object({
			Nombre: yup.string().required("Nombre requerido"),
		}),
	});

	useEffect(() => {
		setValues({
			Nombre:
				dialogAction === DialogAction.Edit
					? selectedSector?.Nombre
					: "",
			Activado:
				dialogAction === DialogAction.Edit
					? selectedSector?.Activado
					: false,
		});
		if (dialogAction === DialogAction.New) resetForm();
	}, [dialogAction, selectedSector, resetForm, setValues]);

	return (
		<React.Fragment>
			<Dialog
				open={isDialogOpen}
				onClose={() => dispatch(setSectorDialogOpen(false))}
				aria-labelledby="add-and-update-dialog-title"
			>
				<DialogTitle id="add-and-update-dialog-title">
					{dialogAction === DialogAction.Edit
						? "Editar Sector"
						: "Agregar Sector"}
				</DialogTitle>

				<form noValidate onSubmit={handleSubmit}>
					<DialogContent>
						<TextField
							autoFocus
							id="Nombre"
							label="Nombre Sector"
							type="text"
							value={values.Nombre}
							name="Nombre"
							onChange={handleChange}
							error={
								touched.Nombre && Boolean(errors.Nombre)
							}
							helperText={touched.Nombre && errors.Nombre}
							fullWidth
						/>
						<FormControlLabel
							control={
								<Switch
									checked={values.Activado}
									onChange={handleChange}
									name="Activado"
									color="primary"
								/>
							}
							mt={4}
							label="Activado"
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() =>
								dispatch(setSectorDialogOpen(false))
							}
							color="primary"
						>
							Cancelar
						</Button>
						<Button
							type="submit"
							color="primary"
							disabled={isDialogLoading}
						>
							{dialogAction === DialogAction.Edit
								? "Editar"
								: "Agregar"}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
};

export const CheckListPage = () => {
	const [openModal, setOpenModal] = useState(false);

	const dispatch = useDispatch();
	const showModal = () => {
		setOpenModal(!openModal);
	};
	const openAcept = (nombre: string, checklist: any) => {
		dispatch(addChecklist(nombre, checklist));
	};
	useEffect(() => {
		dispatch(getSectors());
		dispatch(getTotalDocs());
	}, [dispatch]);
	return (
		<React.Fragment>
			<Helmet title="CheckList" />
			<Grid justify="space-between" container>
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						CheckList
					</Typography>

					<Breadcrumbs aria-label="Breadcrumb" mt={2}>
						<Typography>CheckList</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<div>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								showModal();
							}}
						>
							<AddIcon />
							Nuevo CheckList
						</Button>
					</div>
				</Grid>
			</Grid>
			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard />
				</Grid>
			</Grid>
			<DialogAddSector />
			<DialogCreateEditChecklist
				title="Crear Cheklist"
				open={openModal}
				onAccept={openAcept}
				onClose={showModal}
			/>
		</React.Fragment>
	);
};
