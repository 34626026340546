import firebase from "firebase";
import {
	ChecklistProject,
	isChecklist,
} from "./OT/Projects";

export type ProjectStatus = "Activo" | "Eliminado";

export type BaseProjectLegacy = {
	id: string;
	ComandoOpciones: {};
	DiasPorVencer: number;
	DiasVencidos: number;
	EmpresaReference: firebase.firestore.DocumentReference;
	Estado: ProjectStatus;
	FechaCreacion: firebase.firestore.Timestamp;
	FechaCreacionString: string;
	FirebaseId: string;
	NombreProyecto: string;
	NombreProyecto_lower: string;
	Responsable: ResponsableProyecto;
	Responsables: string[];
	Tipo: "" | "CheckList" | "Revision";
	AlertaEliminacion: boolean;
	/**
	 * Este campo cuenta la cantidad de revisiones de un proyecto.
	 * ¿Por qué se llama Carpetas? Solo Dios lo sabrá.
	 * ¿Por qué podría ser string? Porque el sistema es un desastre.
	 */
	Carpetas: string | number;
	Resueltas: number;
	TotalRespuestas: number;
};

export type RevisionProject = BaseProjectLegacy & {
	Tipo: "" | "Revision";
};

export type RevisionChecklistProject = BaseProjectLegacy & {
	Tipo: "CheckList";
};

export type AnyProject =
	| RevisionProject
	| RevisionChecklistProject
	| ChecklistProject;

export type LegacyProject =
	| RevisionProject
	| RevisionChecklistProject;

export type ResponsableProyecto = {
	Cargo: null | string;
	FechaAsignado: firebase.firestore.Timestamp;
	FirebaseId: string;
	NombreCompleto: string;
	NombreCompleto_lower: string;
};

export type { ChecklistProject as Checklist };

export const isRevision = (
	p: AnyProject
): p is RevisionProject =>
	p.Tipo === "Revision" || p.Tipo === "";

export const isChecklistRevision = (
	p: AnyProject
): p is RevisionChecklistProject => p.Tipo === "CheckList";

export const getProjectName = (p: AnyProject) => {
	if (isChecklist(p)) return p.Nombre;
	else return p.NombreProyecto;
};

export { isChecklist };
