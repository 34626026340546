import { Action } from "../../models/action";
import * as types from "../../constants";
import { Empresa } from "../../models/Empresa";

export interface IBusinessState {
	selectedBusiness: Empresa | null;
	businesses: Empresa[] | null;
	isLoading: boolean;
	totalDocs: number;
	lastDoc?: any;
	error?: string;
	edit: {
		selectedBusiness?: Empresa | null;
		/**
		 * Loading para carga inicial
		 */
		isLoading: boolean;
		/**
		 * Loading para carga al enviar cambios de informacion basica
		 */
		updateLoading: boolean;
	};
	charges: {
		isLoading: boolean;
	};
	places: {
		isLoading: boolean;
	};
	sectors: {
		isLoading: boolean;
	};
	subContracts: {
		isLoading: boolean;
	};
	typeErrors: {
		isLoading: boolean;
	};
	priorities: {
		isLoading: boolean;
	};
	businessFilter: boolean;
	restore: {
		state: string;
		error?: any;
	};
}

const initialState: IBusinessState = {
	selectedBusiness: null,
	businesses: [],
	isLoading: false,
	totalDocs: 0,
	edit: {
		selectedBusiness: null,
		isLoading: false,
		updateLoading: false,
	},
	charges: {
		isLoading: false,
	},
	places: {
		isLoading: false,
	},
	sectors: {
		isLoading: false,
	},
	subContracts: {
		isLoading: false,
	},
	typeErrors: {
		isLoading: false,
	},
	priorities: {
		isLoading: false,
	},
	businessFilter: true,
	restore: {
		state: "Initial",
	},
};

export const businessReducer = (
	state = initialState,
	action: Action
): IBusinessState => {
	switch (action.type) {
		case types.BUSINESS_GET_DOCS:
			return {
				...state,
				businesses: action.payload.business,
				totalDocs: action.payload.totalDocs,
				lastDoc: action.payload.lastDoc,
			};
		case types.BUSINESS_GET_CHARGES:
			return {
				...state,
				charges: action.payload,
			};
		case types.BUSINESS_ADD_DOCS:
			return {
				...state,
				businesses: [
					...(state.businesses ?? []),
					...action.payload,
				],
			};
		case types.BUSINESS_SET_LAST_DOC:
			return {
				...state,
				lastDoc: action.payload,
			};
		case types.BUSINESS_SET_TOTAL_DOCS:
			return {
				...state,
				totalDocs: action.payload,
			};
		case types.BUSINESS_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case types.BUSINESS_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		//edit
		case types.BUSINESS_SET_SELECTED:
			return {
				...state,
				selectedBusiness: action.payload,
				edit: {
					...state.edit,
					selectedBusiness: action.payload,
				},
			};
		case types.BUSINESS_EDIT_LOADING:
			return {
				...state,
				edit: { ...state.edit, isLoading: action.payload },
			};
		case types.BUSINESS_UPDATE_LOADING:
			return {
				...state,
				edit: {
					...state.edit,
					updateLoading: action.payload,
				},
			};
		case types.BUSINESS_UPDATE_SELECTED:
			return {
				...state,
				edit: {
					...state.edit,
					selectedBusiness: {
						...state.edit.selectedBusiness,
						...action.payload,
					},
				},
			};
		case types.BUSINESS_LOADING_CHARGES:
			return {
				...state,
				charges: {
					...state.charges,
					isLoading: action.payload,
				},
			};
		case types.BUSINESS_LOADING_SECTORS:
			return {
				...state,
				sectors: {
					...state.sectors,
					isLoading: action.payload,
				},
			};
		case types.BUSINESS_LOADING_PLACES:
			return {
				...state,
				places: {
					...state.places,
					isLoading: action.payload,
				},
			};
		case types.BUSINESS_LOADING_SUBCONTRACTS:
			return {
				...state,
				subContracts: {
					...state.subContracts,
					isLoading: action.payload,
				},
			};
		case types.BUSINESS_LOADING_TYPE_ERRORS:
			return {
				...state,
				typeErrors: {
					...state.subContracts,
					isLoading: action.payload,
				},
			};
		case types.BUSINESS_LOADING_PRIORITIES:
			return {
				...state,
				priorities: {
					...state.subContracts,
					isLoading: action.payload,
				},
			};
		case types.BUSINESS_FILTER_ACTIVE:
			return {
				...state,
				businessFilter: action.payload,
			};
		case types.BUSINESS_RESTORE_SUBMITTING:
			return {
				...state,
				restore: {
					state: "Submitting",
					error: undefined,
				},
			};
		case types.BUSINESS_RESTORE_SUCCESS:
			return {
				...state,
				restore: {
					state: "Success",
				},
			};
		case types.BUSINESS_RESTORE_FAILURE:
			return {
				...state,
				restore: {
					state: "Failure",
					error: action.payload,
				},
			};
		default:
			return state;
	}
};
