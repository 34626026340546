import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	Divider,
	Typography,
} from "@material-ui/core";
import { FC, useCallback } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { Alert } from "@material-ui/lab";
import { SheetAnswerTuple } from "src/models/OT/Sheet";
import { ResponseIcon } from "../Misc/ResponseIcon";
import { getDefaultComment } from "src/models/OT/utils/sheets";

type Props = {
	cursor?: number;
	selected: SheetAnswerTuple;
} & DialogProps;

export const SheetEntryDialogOT: FC<Props> = ({
	cursor = 0,
	selected,
	...dialog
}) => {
	const { Pregunta, Respuestas } = selected;

	const getAnswerColor = useCallback(
		(index: number) => {
			const answer = Respuestas[index];

			switch (answer?.Estado) {
				case "Aprobado":
					return "lightgreen";
				case "Rechazado":
					return "lightcoral";
				case "No Aplica":
				case "Bloqueado":
					return "lightblue";
				case "Pendiente":
					return "plum";
				default:
					return "lightyellow";
			}
		},
		[Respuestas]
	);

	return (
		<Dialog {...dialog}>
			<DialogTitle
				bigText={Pregunta}
				onClose={dialog.onClose}
			/>

			<DialogContent
				style={{
					display: "flex",
					flexDirection: "column",
					gap: 10,
				}}
			>
				{Respuestas.map(
					(answer, i) =>
						answer && (
							<Alert
								key={`answer-${i}`}
								icon={false}
								style={{
									backgroundColor: getAnswerColor(i),
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Box
									display="flex"
									alignItems="center"
									gridGap={10}
								>
									<ResponseIcon {...answer} />

									<Typography variant="h4">
										Respuesta {i + 1}
									</Typography>
								</Box>

								<Divider
									style={{
										marginTop: 5,
										marginBottom: 5,
									}}
								/>

								<Box
									width="100%"
									display="grid"
									gridTemplateColumns="1fr 1fr"
								>
									<Typography>
										<b>Estado</b>
									</Typography>
									<Typography>: {answer.Estado}</Typography>
									<Typography>
										<b>Fecha</b>
									</Typography>
									<Typography>
										:{" "}
										{answer.Fecha.toDate().toLocaleDateString()}
									</Typography>
									<Typography>
										<b>Por</b>
									</Typography>
									<Typography>
										: {answer.NombreRevisor}
									</Typography>

									<Typography>
										<b>Comentario</b>
									</Typography>
									<Typography>
										:{" "}
										{answer.Comentario ||
											getDefaultComment(answer)}
									</Typography>
								</Box>

								{answer.Foto && (
									<>
										<Divider
											style={{
												marginTop: 5,
												marginBottom: 5,
											}}
										/>

										{answer.Foto.Uri.startsWith(
											"file://"
										) ? (
											<Alert severity="info">
												Esta fotografía aún no se ha subido.
											</Alert>
										) : (
											<img
												style={{
													margin: "auto",
													backgroundColor: "black",
													width: "auto",
													height: "50vh",
												}}
												width="100%"
												src={answer.Foto.Url}
												alt={
													answer.Comentario ??
													`Fotografía ${i + 1}`
												}
											/>
										)}
									</>
								)}
							</Alert>
						)
				)}
			</DialogContent>

			<DialogActions>
				<Button
					color="primary"
					variant="contained"
					onClick={() =>
						dialog.onClose?.({}, "escapeKeyDown")
					}
				>
					Listo
				</Button>
			</DialogActions>
		</Dialog>
	);
};
