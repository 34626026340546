import {
	createBusinessNeoChecklist,
	removeBusinessNeoChecklist,
	updateBusinessNeoChecklist,
} from "src/redux/businesses/actions/neo-checklists";
import { useDispatch, useSelector } from "src/redux/store";
import {
	addParameterToBusiness,
	removeParameterFromBusiness,
	updateParameterFromBusiness,
} from "src/redux/businesses/actions/neo-params";
import { ChecklistOT } from "src/models/OT/Checklist";
import { useEffect } from "react";
import { getBusiness } from "src/redux/actions/businessActions";
import { useCheckproLinks } from "src/components/CheckPro/Links/hooks/useCheckproLinks";

interface BusinessApi {
	fetchIfNeeded?: boolean;
}

export const useBusiness = (params?: BusinessApi) => {
	const dispatch = useDispatch();
	const { idEmpresa } = useCheckproLinks();
	const { user } = useSelector((s) => s.authReducer);

	const { fetchIfNeeded } = params ?? {};

	const {
		businesses,
		edit: { selectedBusiness, updateLoading },
	} = useSelector((s) => s.businessReducer);

	useEffect(() => {
		if (fetchIfNeeded) {
			if (idEmpresa) dispatch(getBusiness(idEmpresa));
			if (user?.empresaReference.id)
				dispatch(getBusiness(user?.empresaReference.id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchIfNeeded, idEmpresa, user]);

	return {
		businesses,
		selectedBusiness,
		loading: updateLoading,

		/**
		 * Business's checklists.
		 */
		checklists: {
			create: (
				checklist: Pick<
					ChecklistOT,
					"Nombre" | "Tipo" | "Preguntas"
				>
			) =>
				dispatch(createBusinessNeoChecklist({ checklist })),

			update: (
				checklist: Pick<
					ChecklistOT,
					"Id" | "Nombre" | "Preguntas" | "Tipo"
				>
			) =>
				dispatch(updateBusinessNeoChecklist({ checklist })),

			remove: (checklist: Pick<ChecklistOT, "Id">) =>
				dispatch(removeBusinessNeoChecklist({ checklist })),
		},

		neoVariables: {
			/**
			 * Selected business's units.
			 */
			units: {
				current: selectedBusiness?.UnidadesOT,

				add: (unit: string) =>
					dispatch(
						addParameterToBusiness({
							param: unit,
							type: "UnidadesOT",
						})
					),

				remove: (unit: string) =>
					dispatch(
						removeParameterFromBusiness({
							param: unit,
							type: "UnidadesOT",
						})
					),

				update: (unit: string, index: number) =>
					dispatch(
						updateParameterFromBusiness({
							param: unit,
							type: "UnidadesOT",
							index,
						})
					),
			},

			/**
			 * Selected business's corridors.
			 */
			corridors: {
				current: selectedBusiness?.PasillosOT,

				add: (corridor: string) =>
					dispatch(
						addParameterToBusiness({
							param: corridor,
							type: "PasillosOT",
						})
					),

				remove: (corridor: string) =>
					dispatch(
						removeParameterFromBusiness({
							param: corridor,
							type: "PasillosOT",
						})
					),

				update: (corridor: string, index: number) =>
					dispatch(
						updateParameterFromBusiness({
							param: corridor,
							type: "PasillosOT",
							index,
						})
					),
			},

			/**
			 * Selected business's sectors.
			 */
			sectors: {
				current: selectedBusiness?.SectoresOT,

				add: (sector: string) =>
					dispatch(
						addParameterToBusiness({
							param: sector,
							type: "SectoresOT",
						})
					),

				remove: (sector: string) =>
					dispatch(
						removeParameterFromBusiness({
							param: sector,
							type: "SectoresOT",
						})
					),

				update: (sector: string, index: number) =>
					dispatch(
						updateParameterFromBusiness({
							param: sector,
							type: "SectoresOT",
							index,
						})
					),
			},

			/**
			 * Selected business's staircases.
			 */
			precincts: {
				current: selectedBusiness?.RecintosOT,

				add: (staircase: string) =>
					dispatch(
						addParameterToBusiness({
							param: staircase,
							type: "RecintosOT",
						})
					),

				remove: (staircase: string) =>
					dispatch(
						removeParameterFromBusiness({
							param: staircase,
							type: "RecintosOT",
						})
					),

				update: (staircase: string, index: number) =>
					dispatch(
						updateParameterFromBusiness({
							param: staircase,
							type: "RecintosOT",
							index,
						})
					),
			},

			/**
			 * Selected business's others.
			 */
			others: {
				current: selectedBusiness?.OtrosOT,

				add: (other: string) =>
					dispatch(
						addParameterToBusiness({
							param: other,
							type: "OtrosOT",
						})
					),

				remove: (other: string) =>
					dispatch(
						removeParameterFromBusiness({
							param: other,
							type: "OtrosOT",
						})
					),

				update: (other: string, index: number) =>
					dispatch(
						updateParameterFromBusiness({
							param: other,
							type: "OtrosOT",
							index,
						})
					),
			},

			/**
			 * Selected business's elements.
			 */
			elements: {
				current: selectedBusiness?.ElementosOT,

				add: (element: string) =>
					dispatch(
						addParameterToBusiness({
							param: element,
							type: "ElementosOT",
						})
					),

				remove: (element: string) =>
					dispatch(
						removeParameterFromBusiness({
							param: element,
							type: "ElementosOT",
						})
					),

				update: (element: string, index: number) =>
					dispatch(
						updateParameterFromBusiness({
							param: element,
							type: "ElementosOT",
							index,
						})
					),
			},
		},
	};
};
