import { Action } from "../../models/action";
import * as types from "../../constants";

export interface IMasivePDFGeneratorState {
  revisions: {
    data: any[];
    isLoading: boolean;
    error?: string;
  };
  lists: {
    data: any[];
    isLoading: boolean;
    error?: string;
  };
  generate: {
    links: (string | undefined)[];
    loadings: boolean[];
    errors: (string | undefined)[];
  };
}

const initialState = {
  revisions: {
    data: [],
    isLoading: false,
  },
  lists: {
    data: [],
    isLoading: false,
  },
  generate: {
    loadings: [],
    links: [],
    errors: [],
  },
} as IMasivePDFGeneratorState;

export const masivePdfGeneratorReducer = (
  state = initialState,
  action: Action
): IMasivePDFGeneratorState => {
  switch (action.type) {
    // send to generate pdf
    case types.PDF_GENERATOR_INITIAL:
      return {
        ...state,
        generate: initialState.generate,
      };
    case types.PDF_GENERATOR_SUBMITTING:
      return {
        ...state,
        generate: {
          ...state.generate,
          loadings: new Array(action.payload).fill(true),
          errors: new Array(action.payload).fill(undefined),
        },
      };
    case types.PDF_GENERATOR_FAILURE:
      return {
        ...state,
        generate: {
          ...state.generate,
          loadings: state.generate.loadings.map((e, i) =>
            i === action.payload.index ? false : e
          ),
          errors: state.generate.errors.map((e, i) =>
            i === action.payload.index ? action.payload.error : e
          ),
        },
      };
    case types.PDF_GENERATOR_SUCCESS:
      return {
        ...state,
        generate: {
          ...state.generate,
          loadings: state.generate.loadings.map((e, i) =>
            i === action.payload.index ? false : e
          ),
          links: state.generate.loadings.map((e, i) =>
            i === action.payload.index ? action.payload.link : e
          ),
        },
      };

    // get revisions
    case types.PDF_GENERATOR_GET_REVISIONS_INITIAL:
      return {
        ...state,
        revisions: initialState.revisions,
      };
    case types.PDF_GENERATOR_GET_REVISIONS_SUBMITTING:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          isLoading: true,
          error: undefined,
        },
      };
    case types.PDF_GENERATOR_GET_REVISIONS_FAILURE:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.PDF_GENERATOR_GET_REVISIONS_SUCCESS:
      return {
        ...state,
        revisions: {
          isLoading: false,
          data: action.payload,
        },
      };

    // get lists
    case types.PDF_GENERATOR_GET_LISTS_INITIAL:
      return {
        ...state,
        lists: initialState.lists,
      };
    case types.PDF_GENERATOR_GET_LISTS_SUBMITTING:
      return {
        ...state,
        lists: {
          ...state.lists,
          isLoading: true,
          error: undefined,
        },
      };
    case types.PDF_GENERATOR_GET_LISTS_FAILURE:
      return {
        ...state,
        lists: {
          ...state.lists,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.PDF_GENERATOR_GET_LISTS_SUCCESS:
      return {
        ...state,
        lists: {
          isLoading: false,
          data: action.payload,
        },
      };

    default:
      return state;
  }
};
