import { Action } from "../../models/action";
import * as types from "../../constants";
import { DialogAction } from "../../models/dialog-actions";
import { ToggableProyecto } from "src/models/Empresa";
import { InnerChecklist } from "src/models/Checklist";

export interface IChecklistState {
	checklist: any[];
	checklistState:
		| "Initial"
		| "Submiting"
		| "Success"
		| "Failure";
	recintos: any[];
	subcontratos: any[];
	subcontratosState:
		| "Initial"
		| "Submiting"
		| "Success"
		| "Failure";
	selectedRecinto: InnerChecklist["ItemsRecintos"][number] | null;
	selectedSubContracto?: any;
	selectedTypeErrors?: any;
	isLoading: boolean;
	totalDocs: number;
	lastDoc?: any;
	error?: string;
	dialog: {
		isDialogOpen: boolean;
		isDialogLoading: boolean;
		dialogAction: DialogAction;
		selectedChecklist?: any;
	};
}

const initialState = {
	checklist: [],
	recintos: [],
	subcontratos: [],
	subcontratosState: "Initial",
	checklistState: "Initial",
	selectedRecinto: null,
	isLoading: false,
	totalDocs: 0,
	dialog: {
		isDialogOpen: false,
		isDialogLoading: false,
		dialogAction: DialogAction.Initial,
	},
} as IChecklistState;

export const checklistReducer = (
	state = initialState,
	action: Action
): IChecklistState => {
	switch (action.type) {
		case types.CHECKLISTS_GET_SUCCESS:
			return {
				...state,
				checklistState: "Success",
				checklist: action.payload.checklist,
				totalDocs: action.payload.totalDocs,
				lastDoc: action.payload.lastDoc,
			};
		case types.CHECKLIST_GET_SUBMITTING:
			return {
				...state,
				checklistState: "Submiting",
			};
		case types.CHECKLIST_GET_FAILURE:
			return {
				...state,
				checklistState: "Failure",
			};
		case types.CHECKLIST_UPDATE_ONE_SUBMITTING:
			return {
				...state,
				error: undefined,
			};
		case types.CHECKLIST_UPDATE_ONE_SUCCESS:
			return {
				...state,
			};
		case types.CHECKLIST_UPDATE_ONE_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		case types.CHECKLIST_GET_ONE_SUBMITTING:
			return {
				...state,
				error: undefined,
			};
		case types.CHECKLIST_GET_ONE_SUCCESS:
			return {
				...state,
				dialog: {
					...state.dialog,
					selectedChecklist: action.payload,
				},
			};
		case types.CHECKLIST_GET_ONE_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		case types.CHECKLIST_REMOVE_SUCCESS:
			return {
				...state,
				checklist: state.checklist.filter(
					(x) => x.id !== action.payload.id
				),
			};
		case types.CHECKLIST_REMOVE_SUBMITTING:
			return {
				...state,
				error: undefined,
			};
		case types.CHECKLIST_REMOVE_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		//get recintos
		case types.RECINTOS_GET_SUCCESS:
			return {
				...state,
				recintos: action.payload.recintos,
				totalDocs: action.payload.totalDocs,
				lastDoc: action.payload.lastDoc,
			};
		case types.RECINTOS_GET_SUBMMITING:
			return {
				...state,
				error: undefined,
			};
		case types.RECINTOS_GET_FAILURE:
			return {
				...state,
				error: action.payload,
			};

		case types.CHECKLIST_SET_LAST_DOC:
			return {
				...state,
				lastDoc: action.payload,
			};
		case types.CHECKLIST_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case types.CHECKLIST_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		case types.CHECKLIST_IS_DIALOG_OPEN:
			return {
				...state,
				dialog: {
					...state.dialog,
					isDialogOpen: action.payload,
				},
			};
		case types.CHECKLIST_IS_DIALOG_LOADING:
			return {
				...state,
				dialog: {
					...state.dialog,
					isDialogLoading: action.payload,
				},
			};
		case types.CHECKLIST_CHANGE_DIALOG_ACTION:
			return {
				...state,
				dialog: {
					...state.dialog,
					dialogAction: action.payload,
				},
			};
		case types.CHECKLIST_SET_SELECTED:
			return {
				...state,
				dialog: {
					...state.dialog,
					selectedChecklist: action.payload,
				},
			};
		case types.SET_SELECTED_RECINTO:
			return {
				...state,
				selectedRecinto: action.payload,
			};
		case types.SUBCONTRACT_SET_SELECTED:
			return {
				...state,
				selectedSubContracto: action.payload,
			};
		// agregar recinto
		case types.RECINTO_ADD_SUBMMITING:
			return {
				...state,
				error: undefined,
			};
		case types.RECINTO_ADD_SUCCESS:
			return {
				...state,
				recintos:
					state.dialog.selectedChecklist.ItemsRecintos,
			};
		case types.RECINTO_ADD_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		// remove Recinto

		case types.RECINTO_REMOVE_SUBMMITING:
			return {
				...state,
				error: undefined,
			};
		case types.RECINTO_REMOVE_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		// remove subcontrato
		case types.SUBCONTRATO_REMOVE_SUBMMITING:
			return {
				...state,
				subcontratosState: "Submiting",
			};
		case types.SUBCONTRATO_REMOVE_FAILURE:
			return {
				...state,
				subcontratosState: "Failure",
			};
		case types.SUBCONTRATO_REMOVE_SUCCESS:
			return {
				...state,
				subcontratosState: "Success",
			};
		// add subcontrato

		case types.SUBCONTRATO_ADD_SUBMMITING:
			return {
				...state,
				subcontratosState: "Submiting",
			};
		case types.SUBCONTRATO_ADD_FAILURE:
			return {
				...state,
				subcontratosState: "Failure",
			};
		case types.SUBCONTRATO_ADD_SUCCESS:
			return {
				...state,
				subcontratosState: "Success",
				selectedRecinto: action.payload,
			};
		// Update list Subcontratos

		case types.SUBCONTRATO_UPDATE_SUBMMITING:
			return {
				...state,
				subcontratosState: "Submiting",
			};
		case types.SUBCONTRATO_UPDATE_FAILURE:
			return {
				...state,
				subcontratosState: "Failure",
			};
		case types.SUBCONTRATO_UPDATE_SUCCESS:
			return {
				...state,
				subcontratosState: "Success",
				selectedRecinto: action.payload,
			};
		// get one subcontrato
		case types.SUBCONTRATO_GET_ONE_SUBMMITING:
			return {
				...state,
				error: undefined,
			};
		case types.SUBCONTRATO_GET_ONE_SUCCESS:
			return {
				...state,
				selectedRecinto: action.payload,
			};
		case types.SUBCONTRATO_GET_ONE_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		// selected type Errors
		case types.ERROR_TYPES_SET_SELECTED:
			return {
				...state,
				selectedTypeErrors: action.payload,
			};
		// add errores tipo

		case types.ERRORTIPO_ADD_SUBMMITING:
			return {
				...state,
			};
		case types.ERRORTIPO_ADD_FAILURE:
			return {
				...state,
			};
		case types.ERRORTIPO_ADD_SUCCESS:
			return {
				...state,
				selectedTypeErrors: action.payload,
			};
		// deleted error type
		case types.ERRORTIPO_DELETED_SUBMITING:
			return {
				...state,
			};
		case types.ERRORTIPO_DELETED_FAILURE:
			return {
				...state,
			};
		case types.ERRORTIPO_DELETED_SUCCESS:
			return {
				...state,
				selectedTypeErrors: action.payload,
			};
		// get all subcontratos por empresa
		case types.SUBCONTRATOS_GET_SUBMMITING:
			return {
				...state,
			};
		case types.SUBCONTRATOS_GET_FAILURE:
			return {
				...state,
			};
		case types.SUBCONTRATOS_GET_SUCCESS:
			return {
				...state,
				subcontratos: action.payload,
			};
		// set selected error type checklist
		case types.ERRORSTIPO_CHECKLIST_SET_SELECTED:
			return {
				...state,
				selectedTypeErrors: action.payload,
			};
		default:
			return state;
	}
};
