import { memo } from "react";
import { CoolTableProps, TableProvider } from "./Context";
import { Table as InnerTable } from "./Table";

type TableProps = <T>(
	props: CoolTableProps<T>
) => JSX.Element;

const _Table = memo<CoolTableProps>((props) => {
	return (
		<TableProvider {...props}>
			<InnerTable />
		</TableProvider>
	);
});
_Table.displayName = "CoolTable";

export const Table = _Table as unknown as TableProps;
