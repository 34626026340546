import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
	jssPreset,
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";

import createTheme from "./theme";
import Routes from "./routes/Routes";
import { IThemeState } from "./redux/reducers/themeReducer";
import { RootState } from "./redux/store/reducer";
import { validateSession } from "./redux/actions/authActions";
import { GlobalStyles } from "./theme/useStyles";
import { auth } from "./firebase/firebase";

const jss = create({
	...jssPreset(),
	insertionPoint: document.getElementById(
		"jss-insertion-point"
	) as any,
});

export function App() {
	const theme = useSelector<RootState, IThemeState>(
		(state) => state.themeReducer
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(validateSession());
		if (!localStorage.getItem("lastPath")) {
			localStorage.setItem(
				"lastPath",
				"/administrador/empresas"
			);
		}
	}, [dispatch]);

	return (
		<React.StrictMode>
			<React.Fragment>
				<HelmetProvider>
					<Helmet
						titleTemplate="%s | Check Pro"
						defaultTitle="Check Pro - React Admin"
					/>
					<StylesProvider jss={jss}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<MuiThemeProvider
								theme={createTheme(theme.currentTheme)}
							>
								<ThemeProvider
									theme={createTheme(theme.currentTheme)}
								>
									<GlobalStyles />
									<Routes />
								</ThemeProvider>
							</MuiThemeProvider>
						</MuiPickersUtilsProvider>
					</StylesProvider>
				</HelmetProvider>
			</React.Fragment>
		</React.StrictMode>
	);
}
