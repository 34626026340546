import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useStyles } from "../theme/useStyles";
import { Skeleton } from "@material-ui/lab";
import { Add as AddIcon } from "@material-ui/icons";

import { Helmet } from "react-helmet-async";

import {
	Breadcrumbs as MuiBreadcrumbs,
	Card as MuiCard,
	CardContent,
	Divider as MuiDivider,
	Fade,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	TableHead,
	Chip as MuiChip,
	IconButton,
	Tooltip,
	TablePagination,
	Button,
} from "@material-ui/core";

import {
	Edit as EditIcon,
	Trash2 as RemoveIcon,
} from "react-feather";

import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { IAuthState } from "../redux/reducers/authReducer";
import { RootState } from "../redux/store/reducer";
import { IBusinessState } from "../redux/reducers/businessReducer";
import { useTable } from "../hooks/useTable";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import { getBusiness } from "../redux/actions/businessActions";
import { green, red } from "@material-ui/core/colors";
import { EditActivateDesactivate } from "../components/EditActivateDesactivate";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Chip: any = styled(MuiChip)`
	${spacing};

	background: ${(props: any) =>
		props.activated && green[500]};
	background: ${(props: any) =>
		props.desactivated && red[500]};
	color: ${(props) => props.theme.palette.common.white};
	min-width: 90px;
`;

interface Props {
	businessId: string;
}

const ContentCard: React.FC<Props> = ({ businessId }) => {
	const {
		edit: { selectedBusiness, isLoading },
		totalDocs,
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const {
		limit,
		handleLimitChange,
		handlePageChange,
		page,
	} = useTable({
		limit: TABLE_LIMIT_DEFAULT || 5,
		onLimitChange: (e, newLimit) => {},
		onPageChange: (newPage, oldPage, limit) => {},
	});

	const classes = useStyles();
	const dispatch = useDispatch();

	const [openDataDialog, setOpenDataDialog] =
		useState(false);
	const [typeDialog, setTypeDialog] = useState<
		"new" | "edit"
	>("new");
	const [selectedData, setSelectedData] = useState<any>([]);

	const handleAccept = (myData: any) => {};

	useEffect(() => {
		if (!selectedBusiness) {
			dispatch(getBusiness(businessId));
		}
	}, []);
	return (
		<Card mb={6}>
			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Nombre</TableCell>
								<TableCell>Estado</TableCell>
								<TableCell>Acciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!isLoading
								? selectedBusiness!.Prioridades.slice(
										page * limit,
										page * limit + limit
								  ).map((data: any) => (
										<Fade key={data.id} in={true}>
											<TableRow
												hover
												className={classes.styledRow}
											>
												<TableCell>{data.Nombre}</TableCell>
												<TableCell>
													{data.Activado ? (
														<Chip
															size="small"
															mr={1}
															mb={1}
															label="Activado"
															activated="true"
														/>
													) : (
														<Chip
															size="small"
															mr={1}
															mb={1}
															label="Desactivado"
															desactivated="true"
														/>
													)}
												</TableCell>
												<TableCell>
													<Tooltip title="Eliminar">
														<IconButton>
															<RemoveIcon color="red" />
														</IconButton>
													</Tooltip>
													<Tooltip title="Editar">
														<IconButton
															onClick={() => {
																setSelectedData(data);
																setTypeDialog("edit");
																setOpenDataDialog(true);
															}}
															color="primary"
														>
															<EditIcon />
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										</Fade>
								  ))
								: new Array(limit).fill(0).map((x, i) => (
										<TableRow key={i}>
											<TableCell colSpan={5}>
												<Skeleton />
											</TableCell>
										</TableRow>
								  ))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={totalDocs}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
				<EditActivateDesactivate
					open={openDataDialog}
					data={selectedData}
					onClose={() => {
						setOpenDataDialog(false);
					}}
					type={typeDialog}
					onAccept={(values) => {
						handleAccept(values);
						setOpenDataDialog(false);
					}}
					title="Prioridad"
				/>
			</CardContent>
		</Card>
	);
};

export const PrioritiesBusiness = () => {
	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const [currentBusiness, setCurrrentBusiness] =
		useState<any>(auth.user?.empresaReference);

	const [openDataDialog, setOpenDataDialog] =
		useState(false);
	const [typeDialog, setTypeDialog] = useState<
		"new" | "edit"
	>("new");

	const handleAccept = (myData: any) => {};

	return (
		<React.Fragment>
			<Helmet title="Blank" />
			<Typography
				variant="h3"
				gutterBottom
				display="inline"
			>
				Prioridades
			</Typography>
			<Grid container justify="space-between">
				<Grid item>
					<Breadcrumbs aria-label="Breadcrumb" mt={2}>
						<Typography>Prioridades</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<Grid item>
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									setOpenDataDialog(true);
								}}
							>
								<AddIcon />
								Nueva Prioridad
							</Button>
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard businessId={currentBusiness.id} />
				</Grid>
			</Grid>
			<EditActivateDesactivate
				open={openDataDialog}
				onClose={() => {
					setOpenDataDialog(false);
				}}
				type={typeDialog}
				onAccept={(values) => {
					handleAccept(values);
					setOpenDataDialog(false);
				}}
				title="Prioridad"
			/>
		</React.Fragment>
	);
};
