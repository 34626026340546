import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
} from "@material-ui/core";
import { FC } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { ChecklistProject } from "src/models/OT/Projects";
import { WithRef, getRef } from "src/utils/firebase";

export const NeoProjectStatusDialog: FC<DialogProps> = (
	props
) => {
	const { selected, updateProject, loading } =
		useProjectsOT();

	const handleSubmit = async () => {
		if (!selected.project) return;
		const data: WithRef<Partial<ChecklistProject>> = {
			_ref: getRef(selected.project),
		};

		if (selected.project.ProyectoIniciado) {
			data.ProyectoIniciado = false;
			data.ProyectoFinalizado = true;
		} else if (
			!selected.project.ProyectoIniciado ||
			selected.project.ProyectoFinalizado
		) {
			data.ProyectoIniciado = true;
			data.ProyectoFinalizado = false;
		}

		props.onClose?.({}, "backdropClick");
		await updateProject(data);
	};

	return (
		<Dialog {...props}>
			<DialogTitle
				bigText={(() => {
					if (selected.project?.ProyectoFinalizado)
						return "Reiniciar proyecto";
					else if (!selected.project?.ProyectoIniciado)
						return "Iniciar proyecto";
					else if (selected.project?.ProyectoIniciado)
						return "Finalizar proyecto";
				})()}
				smallText={selected.project?.Nombre}
			/>
			<DialogContent>
				{(() => {
					if (selected.project?.ProyectoFinalizado)
						return `Al reiniciar el proyecto, este podrá ser respondido por los 
							revisores, pero no será posible modificar sus espacios.`;
					else if (!selected.project?.ProyectoIniciado)
						return `Al iniciar el proyecto este podrá ser respondido por
							los revisores, pero no será posible modificar sus
							espacios. ¿Deseas continuar?`;
					else if (selected.project?.ProyectoIniciado)
						return `Al finalizar el proyecto, este no podrá ser respondido por
							los revisores.`;
				})()}
			</DialogContent>
			<DialogActions>
				<Button
					disabled={loading}
					onClick={() =>
						props.onClose?.({}, "backdropClick")
					}
				>
					Cancelar
				</Button>

				<Button
					disabled={loading}
					color="primary"
					variant="contained"
					onClick={handleSubmit}
				>
					{loading ? (
						<CircularProgress size={24} />
					) : (
						(() => {
							if (selected.project?.ProyectoFinalizado)
								return "Reiniciar";
							else if (!selected.project?.ProyectoIniciado)
								return "Iniciar";
							else if (selected.project?.ProyectoIniciado)
								return "Finalizar";
						})()
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
