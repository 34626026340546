import {
	Breadcrumbs,
	Divider,
	Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { useCheckproLinks } from "src/components/CheckPro/Links/hooks/useCheckproLinks";
import { ProjectTableOT } from "src/components/ProjectsOT/Table";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { getBusiness } from "src/redux/actions/businessActions";
import { useDispatch, useSelector } from "src/redux/store";

export const ProjectsPageOT = () => {
	const dispatch = useDispatch();
	const { idEmpresa } = useCheckproLinks();

	const { user } = useSelector((s) => s.authReducer);

	const { selectedBusiness } = useBusiness();

	useEffect(() => {
		if (!selectedBusiness) {
			const id = idEmpresa ?? user?.empresaReference.id;
			if (!id) return;
			dispatch(getBusiness(id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idEmpresa, user]);

	return (
		<>
			<Helmet title="Checklists" />

			<Breadcrumbs style={{ fontSize: 20 }}>
				<Typography variant="h3" style={{ color: "black" }}>
					{selectedBusiness?.Nombre ?? "Empresa"}
				</Typography>
			</Breadcrumbs>

			<Breadcrumbs>
				<LinkEmpresas />
				<Typography>Proyectos (Checklists)</Typography>
			</Breadcrumbs>

			<Divider
				style={{ marginTop: 10, marginBottom: 10 }}
			/>

			<ProjectTableOT />
		</>
	);
};
