import {
	CircularProgress,
	unstable_createMuiStrictModeTheme,
	TableRow,
	TableCell,
	Checkbox,
	IconButton,
	Tooltip,
	MenuItem,
	Typography,
	Menu,
	MenuItemProps,
} from "@material-ui/core";
import { MouseEvent, ReactNode, useState } from "react";
import {
	ChevronRight,
	Edit,
	FileText,
	RotateCcw,
	Trash2,
	Users,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";
import {
	BaseProjectLegacy,
	isChecklistRevision,
} from "src/models/Proyecto";
import {
	restoreProject,
	setSelectedProject,
} from "src/redux/actions/projectsActions";
import { IAuthState } from "src/redux/reducers/authReducer";
import { IBusinessState } from "src/redux/reducers/businessReducer";
import { RootState } from "src/redux/store/reducer";
import { generarPendientesVsResueltas } from "src/services/documents";
import { useStyles } from "src/theme/useStyles";
import { ExcelSheetIcon } from "src/icons/ExcelSheet";
import { colorsCheckPro } from "src/theme/variants";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PieChart } from "@material-ui/icons";
import { AssigneesDialog } from "./AssigneesDialog";
import { EditRevisionDialog } from "./EditDialog";
import { setSelectedRevision } from "src/redux/actions/revisionsActions";

interface RowProps {
	project: BaseProjectLegacy;
	selected: boolean;
	handleOpenEditDialog: (value: any) => void;
	handleOpenConfirmDialog: (value: any) => void;
	handleOpenResponsiblesDialog: (value: any) => void;
	handleChecksButton: (value: any) => void;
	openPdfDialog: (project: any) => void;
	onSelection?: (
		projectId: string,
		checkedStatus: boolean
	) => void;
	renderPdf?: boolean;
	renderExcelSumary?: boolean;
}

export const RevisionTableRow = (props: RowProps) => {
	const history = useHistory();

	const { pathname } = useLocation();
	const {
		project,
		selected,
		handleOpenConfirmDialog,
		handleChecksButton,
		onSelection,
	} = props;

	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [isGenerating, setIsGenerating] = useState(false);

	// Menu desplegable
	const [anchorEl, setAnchorEl] =
		useState<null | HTMLElement>(null);
	const handleClickMenu = (
		event: MouseEvent<HTMLButtonElement>
	) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	const theme = unstable_createMuiStrictModeTheme();

	const handlePendientesVsResueltas = async (
		tipo: "checklist" | "revision"
	) => {
		if (isGenerating) {
			alert(
				"Un documento ya se está generando. Espera un minuto, por favor."
			);
			handleCloseMenu();
			return;
		}

		setIsGenerating(true);
		try {
			const res = await generarPendientesVsResueltas(
				project.id,
				project.Responsable.FirebaseId,
				tipo
			);

			// Download the thing
			const _link = document.createElement("a");
			_link.href = URL.createObjectURL(res);
			_link.setAttribute(
				"download",
				`resumen-${
					project.NombreProyecto
				}-${Date.now().toLocaleString()}.pdf`
			);
			_link.click();
		} catch (error: any) {
			console.error(error);
			alert("Ha ocurrido un error: " + error.message);
		}
		setIsGenerating(false);
	};

	const getMenuOptions = (project: BaseProjectLegacy) => {
		const menuToRender: (MenuItemProps & {
			label: string;
			icon?: ReactNode;
		})[] = [];

		if (project.Estado === "Eliminado") {
			menuToRender.push({
				label: "Restaurar",
				icon: (
					<RotateCcw
						size={22}
						color={
							selectedBusiness?.Revisiones
								?.RevisionesUsados! <
							selectedBusiness?.Revisiones
								?.LimiteRevisiones!
								? "#00e676"
								: "#808080"
						}
					/>
				),
				onClick: () => {
					dispatch(restoreProject(project));
					handleCloseMenu();
				},
			});
		} else {
			menuToRender.push(
				{
					disabled:
						auth.user?.tipoUsuario === "UsuarioEmpresa",
					label: "Editar",
					icon: (
						<Edit
							size={22}
							color={theme.palette.primary.main}
						/>
					),
					onClick: () => {
						setDialog(2);
						dispatch(setSelectedProject(project));
						handleCloseMenu();
					},
				},
				{
					label: "Eliminar",
					disabled:
						auth.user?.tipoUsuario === "UsuarioEmpresa",
					icon: <Trash2 color="red" size={22} />,
					onClick: () => {
						handleOpenConfirmDialog(project);
						dispatch(setSelectedProject(project));
						handleCloseMenu();
					},
				},
				{
					label: "Encargados",
					disabled:
						auth.user?.tipoUsuario === "UsuarioEmpresa",
					icon: (
						<Users
							size={22}
							color={theme.palette.primary.main}
						/>
					),
					onClick: () => {
						setDialog(1);
						dispatch(setSelectedProject(project));
						handleCloseMenu();
					},
				},
				{
					label: "Estadísticas",
					icon: <PieChart color="primary" />,
					onClick: () => {
						history.push(
							isChecklistRevision(project)
								? `/administrador/checklist-proyectos/estadisticas/${project.id}`
								: `/administrador/estadisticas/${project.id}`
						);
						handleCloseMenu();
					},
				},
				{
					label: isGenerating
						? "Generando documento..."
						: "Pendientes vs. Resueltas",
					icon: isGenerating ? (
						<CircularProgress size={20} />
					) : (
						<FileText color={theme.palette.primary.main} />
					),
					onClick: () =>
						handlePendientesVsResueltas(
							isChecklistRevision(project)
								? "checklist"
								: "revision"
						),
				},
				{
					label: "Observaciones",
					icon: (
						<ExcelSheetIcon
							style={{
								fill: colorsCheckPro.primary,
								height: "22px",
							}}
						/>
					),
					onClick: () => {
						dispatch(setSelectedRevision(project));
						history.push(
							isChecklistRevision(project)
								? `/administrador/checklist-proyectos/${project.id}/observaciones`
								: `/administrador/proyectos/${project.id}/observaciones`,
							{
								NombreProyecto: project.NombreProyecto,
							}
						);
					},
				}
			);

			// ageregar mas menus
		}

		return menuToRender;
	};

	const [dialog, setDialog] = useState(0);

	return (
		<>
			<TableRow
				hover
				className={classes.styledRow}
				style={{
					backgroundColor: project.AlertaEliminacion
						? "#fff8e1"
						: "",
				}}
			>
				{auth.user?.tipoUsuario !== "UsuarioEmpresa" &&
					project.Estado !== "Eliminado" && (
						<TableCell>
							<Checkbox
								id={project.id}
								checked={selected}
								onChange={(_, c) =>
									onSelection?.(project.id, c)
								}
								color="primary"
								disabled={project.Estado !== "Activo"}
								inputProps={{
									"aria-label": "secondary checkbox",
								}}
							/>
						</TableCell>
					)}

				<TableCell>{project.NombreProyecto}</TableCell>
				<TableCell>
					{typeof project.Carpetas === "string"
						? project?.Carpetas?.replace("(", "")?.replace(
								")",
								""
						  )
						: project.Carpetas}
				</TableCell>
				{pathname.includes("checklist-proyectos") && (
					<TableCell>
						{(project?.Resueltas || "0") +
							" de " +
							(project?.TotalRespuestas || "0")}
					</TableCell>
				)}
				<TableCell>
					{project.Responsable
						? project.Responsable.NombreCompleto
						: "Sin Responsable"}
				</TableCell>
				<TableCell>{project.Responsable?.Cargo}</TableCell>
				<TableCell>
					{project.FechaCreacion
						? format(
								project.FechaCreacion.toDate(),
								"dd/MM/yyyy HH:mm:ss"
						  )
						: ""}
				</TableCell>
				<TableCell style={{ whiteSpace: "nowrap" }}>
					{/* Menu usuarioEmpresa */}
					<>
						<IconButton
							size="small"
							color="primary"
							onClick={handleClickMenu}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleCloseMenu}
						>
							{getMenuOptions(project).map(
								(
									{ label, icon, button, ...props },
									index
								) => (
									<MenuItem
										key={`menu-${project.id}-${index}`}
										{...props}
									>
										{icon}
										&nbsp;
										<Typography>{label}</Typography>
									</MenuItem>
								)
							)}
						</Menu>
					</>
					{project.Estado !== "Eliminado" ? (
						<>
							<Tooltip title="Revisiones">
								<IconButton
									size="small"
									color="primary"
									onClick={() =>
										handleChecksButton(project)
									}
								>
									<ChevronRight />
								</IconButton>
							</Tooltip>
						</>
					) : null}
				</TableCell>
			</TableRow>

			<AssigneesDialog
				project={project}
				open={dialog === 1}
				onClose={() => setDialog(0)}
			/>

			<EditRevisionDialog
				project={project}
				open={dialog === 2}
				onClose={() => setDialog(0)}
			/>
		</>
	);
};
