import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

const WhitOutInformation = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{color:"#d1cbcb", fontSize:40}}>No hay informacion disponible...</h1>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhitOutInformation;
