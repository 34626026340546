import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Fade,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
} from "@material-ui/core";
import {
	BusinessCenter,
	PlaylistAddCheck,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import {
	Edit as EditIcon,
	Key as KeyIcon,
	Plus,
	Search,
	Trash2 as DeleteIcon,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "src/components/Chip";
import { NewConfirmDialog } from "src/components/NewConfirmDialog";
import { TABLE_LIMITS } from "src/constants";
import {
	getProjectsUserIn,
	setGettingProjectsUserIn,
	setProjectsUser,
} from "src/redux/actions/projectsActions";
import {
	deleteUser,
	getUsers,
	sendRecoveryMail,
	setSelectedUser,
} from "src/redux/actions/usersActions";
import { IAuthState } from "src/redux/reducers/authReducer";
import { RootState } from "src/redux/store/reducer";
import { IUsersState } from "src/redux/reducers/usersReducer";
import { useStyles } from "src/theme/useStyles";
import { DialogCreateEditUser } from "src/components/Business/Dialogs/UserDialog";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { Usuario } from "src/models/Usuario";
import { ChecklistsPageOT } from "../../../pages/NeoProjects/[neoId]/index";

export const BusinessUsersTab = () => {
	const { selectedBusiness: business } = useBusiness();

	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const classes = useStyles();
	const dispatch = useDispatch();

	const [openUsersDialog, setOpenUsersDialog] =
		useState(false);
	const [selectedEditUser, setSelectedEditUser] =
		useState<any>("");
	const [openConfirm, setOpenConfirm] = useState(false);

	const { users, isLoading } = useSelector<
		RootState,
		IUsersState
	>((state) => state.usersReducer);

	useEffect(() => {
		if (!business) return;
		dispatch(getUsers(business));
	}, [business, dispatch]);

	const [limit, setLimit] = useState(80);
	const [page, setPage] = useState(0);

	const totalAvailableUsers =
		(business?.CheckList.Activado
			? business?.CheckList?.LimiteUsuarios
			: 0) +
		(business?.Revisiones.Activado
			? business?.Revisiones?.LimiteUsuarios
			: 0);

	const txtRevisiones = business?.Revisiones.Activado
		? "  -  Revisiones: " +
		  business?.Revisiones?.UsuariosUsados +
		  "/" +
		  business?.Revisiones?.LimiteUsuarios
		: "";
	const txtChecklist = business?.CheckList.Activado
		? "  -  Check List: " +
		  business?.CheckList?.UsuariosUsados +
		  "/" +
		  business?.CheckList?.LimiteUsuarios
		: "";

	const handleDeleteUser = (user: any) => {
		dispatch(deleteUser(user));
		setOpenConfirm(false);
	};

	const handleLimitChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handlePageChange = (_: any, newPage: number) => {
		setPage(newPage);
	};

	const handleOpenUserDialog = (user: Usuario) => {
		dispatch(setSelectedUser(user));
		setOpenUsersDialog(true);
		dispatch(setGettingProjectsUserIn("Submitting"));
		dispatch(getProjectsUserIn(user?.id));
	};

	return (
		<>
			<Card>
				<CardHeader
					title={
						"Usuarios: " +
						users?.length +
						"/" +
						totalAvailableUsers +
						txtRevisiones +
						txtChecklist
					}
					action={
						auth.user?.tipoUsuario !== "UsuarioEmpresa" && (
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									dispatch(setSelectedUser(null));
									setOpenUsersDialog(true);
								}}
							>
								<Plus size={20} />
								&nbsp; Agregar
							</Button>
						)
					}
				/>

				<Divider />

				<CardContent>
					<TableContainer
						className={classes.tableContainer}
					>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>Nombre</TableCell>
									<TableCell>Cargo</TableCell>
									<TableCell align="left">
										Tipo Usuario
									</TableCell>
									<TableCell align="center">
										Asignación
									</TableCell>
									<TableCell align="center">
										Estado
									</TableCell>
									<TableCell align="center">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!isLoading
									? (users ?? [])
											.slice(
												page * limit,
												page * limit + limit
											)
											.sort((a, b) =>
												(
													a.Nombre +
													" " +
													a.Apellido
												).localeCompare(
													b.Nombre + " " + b.Apellido
												)
											)
											.map((user) => (
												<Fade key={user.id} in={true}>
													<TableRow
														hover
														className={classes.styledRow}
													>
														<TableCell>
															{user.Nombre +
																" " +
																user.Apellido}
														</TableCell>
														<TableCell>
															{user.Cargo}
														</TableCell>
														<TableCell align="left">
															{user?.TipoUsuario ===
																"UsuarioEmpresa" &&
																"Encargado"}
															{user?.TipoUsuario ===
																"AdministradorEmpresa" &&
																"Director"}
															{user?.TipoUsuario ===
																"Administrador" &&
																"SuperAdmin"}
															{!user?.TipoUsuario &&
																"Encargado"}
														</TableCell>
														<TableCell
															style={{
																whiteSpace: "nowrap",
															}}
															align="center"
														>
															<Tooltip title="Revisiones">
																<span>
																	<IconButton
																		disabled={
																			!user?.Revisiones
																		}
																		size="small"
																		color="primary"
																	>
																		<Search size={20} />
																	</IconButton>
																</span>
															</Tooltip>
															<Tooltip title="Checklist Revisiones">
																<span>
																	<IconButton
																		color="primary"
																		disabled={
																			!user?.Checklist
																		}
																		size="small"
																	>
																		<PlaylistAddCheck />
																	</IconButton>
																</span>
															</Tooltip>
															<Tooltip title="Checklists">
																<span>
																	<IconButton
																		color="primary"
																		disabled={
																			!user?.ChecklistsOT
																		}
																		size="small"
																	>
																		<BusinessCenter />
																	</IconButton>
																</span>
															</Tooltip>
														</TableCell>
														<TableCell align="center">
															{user.Activado ? (
																<Chip
																	size="small"
																	mr={1}
																	mb={1}
																	label="Activado"
																	activated="true"
																/>
															) : (
																<Chip
																	size="small"
																	mr={1}
																	mb={1}
																	label="Desactivado"
																	desactivated="true"
																/>
															)}
														</TableCell>
														<TableCell
															style={{
																whiteSpace: "nowrap",
															}}
															align="center"
														>
															<Tooltip title="Editar">
																<span>
																	<IconButton
																		disabled={
																			user.TipoUsuario ===
																				"Administrador" &&
																			auth.user
																				?.tipoUsuario !==
																				"Administrador"
																		}
																		onClick={() =>
																			handleOpenUserDialog(
																				user
																			)
																		}
																		size="small"
																		color="primary"
																	>
																		<EditIcon size={20} />
																	</IconButton>
																</span>
															</Tooltip>
															<Tooltip title="Restablecer contraseña">
																<span>
																	<IconButton
																		disabled={
																			user.TipoUsuario ===
																				"Administrador" &&
																			auth.user
																				?.tipoUsuario !==
																				"Administrador"
																		}
																		onClick={() =>
																			dispatch(
																				sendRecoveryMail(
																					user
																				)
																			)
																		}
																		size="small"
																		color="primary"
																	>
																		<KeyIcon size={20} />
																	</IconButton>
																</span>
															</Tooltip>
															<Tooltip title="Eliminar">
																<span>
																	<IconButton
																		disabled={
																			user.TipoUsuario ===
																				"Administrador" &&
																			auth.user
																				?.tipoUsuario !==
																				"Administrador"
																		}
																		size="small"
																		onClick={() => {
																			setSelectedEditUser(
																				user
																			);
																			setOpenConfirm(true);
																		}}
																	>
																		<DeleteIcon
																			color={
																				user.TipoUsuario ===
																					"Administrador" &&
																				auth.user
																					?.tipoUsuario !==
																					"Administrador"
																					? "#A9A9A9"
																					: "red"
																			}
																		/>
																	</IconButton>
																</span>
															</Tooltip>
														</TableCell>
													</TableRow>
												</Fade>
											))
									: new Array(limit).fill(0).map((x, i) => (
											<TableRow key={i}>
												<TableCell colSpan={6}>
													<Skeleton />
												</TableCell>
											</TableRow>
									  ))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={users?.length ?? 0}
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handleLimitChange}
						page={page}
						rowsPerPage={limit}
						rowsPerPageOptions={TABLE_LIMITS}
					/>
				</CardContent>
			</Card>

			<DialogCreateEditUser
				open={openUsersDialog}
				onClose={() => {
					setOpenUsersDialog(false);
					dispatch(setSelectedUser(null));
					dispatch(setProjectsUser([]));
					dispatch(setGettingProjectsUserIn("Initial"));
				}}
			/>

			<NewConfirmDialog
				title={"¿Desea Eliminar al Usuario?"}
				onConfirmText={"Eliminar"}
				onConfirm={() => {
					handleDeleteUser(selectedEditUser);
					setSelectedEditUser("");
				}}
				open={openConfirm}
				onClose={() => {
					setOpenConfirm(false);
					setSelectedEditUser("");
				}}
			/>
		</>
	);
};
