import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
	getPlaces2,
	updateChecklist,
} from "../../redux/actions/checklistActions";
import { IChecklistState } from "../../redux/reducers/checklistReducer";
import { RootState } from "../../redux/store/reducer";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface Props {
	user?: any;
	open: boolean;
	onClose: () => void;
	onAccept: (values: any, projects: any) => void;
	title?: string;
	type?: string;
	Nombre?: string;
}

export const DialogEditChecklist: React.FC<Props> =
	React.memo(
		({ open, onClose, title = "", Nombre = "" }) => {
			const dispatch = useDispatch();
			const {
				recintos,
				dialog: { selectedChecklist },
				isLoading,
			} = useSelector<RootState, IChecklistState>(
				(state) => state.checklistReducer
			);

			const [checklist, setChecklist] = React.useState<
				any[]
			>([]);
			// const [checklistStatic, setChecklistStatic] =
			// 	React.useState<any[]>([]);

			const {
				handleSubmit,
				handleChange,
				values,
				touched,
				errors,
				setValues,
				resetForm,
			} = useFormik({
				initialValues: {
					Nombre: "",
				},
				onSubmit: (values) => {
					dispatch(
						updateChecklist(
							selectedChecklist.id,
							values.Nombre,
							checklist
						)
					);
					setChecklist([]);
					resetForm();
					onClose();
				},

				validationSchema: yup.object({
					Nombre: yup.string().required("Nombre requerido"),
				}),
			});

			useEffect(() => {
				setValues({
					Nombre: selectedChecklist?.Nombre,
				});
				setChecklist(selectedChecklist?.ItemsRecintos);
				// setChecklistStatic(
				// 	selectedChecklist?.ItemsRecintos
				// );
			// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [Nombre, selectedChecklist]);

			useEffect(() => {
				dispatch(getPlaces2());
			}, [dispatch]);

			return (
				<React.Fragment>
					<Dialog
						open={open}
						onClose={(_, reason) => {
							if (reason !== "backdropClick") {
								onClose();
							}
						}}
						aria-labelledby="add-and-update-dialog-title"
					>
						<DialogTitle id="add-and-update-dialog-title">
							{title}
						</DialogTitle>

						<form noValidate onSubmit={handleSubmit}>
							<DialogContent
								style={{ minHeight: 300, minWidth: 600 }}
							>
								{!isLoading ? (
									<Grid
										container
										spacing={3}
										justify="space-between"
									>
										<Grid
											style={{ marginBottom: 20 }}
											item
											sm={6}
											xs={12}
										>
											<TextField
												autoFocus
												id="Nombre"
												label="Nombre"
												type="text"
												value={values?.Nombre}
												name="Nombre"
												onChange={handleChange}
												error={
													touched.Nombre &&
													Boolean(errors.Nombre)
												}
												helperText={
													touched.Nombre && errors.Nombre
												}
												fullWidth
												variant="outlined"
												autoComplete="off"
											/>
										</Grid>
										<Grid item sm={12} xs={12}>
											<Autocomplete
												multiple
												id="checkboxes-tags-demo"
												value={recintos?.filter(function (
													obj: any
												) {
													return checklist?.find(function (
														obj2: any
													) {
														return obj.id === obj2.id;
													});
												})}
												options={recintos}
												disableCloseOnSelect
												getOptionLabel={(option) =>
													option.Nombre
												}
												onChange={(_, value: any) => {
													setChecklist(value);
												}}
												renderOption={(
													option,
													{ selected }
												) => (
													<React.Fragment>
														<Checkbox
															icon={icon}
															checkedIcon={checkedIcon}
															style={{ marginRight: 8 }}
															checked={selected}
														/>
														{option.Nombre}
														{option.Responsable &&
															" - [" +
																option.Responsable
																	?.NombreCompleto +
																"]"}
													</React.Fragment>
												)}
												fullWidth
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														label="Agregar Recinto"
														placeholder="Recintos"
														disabled
													/>
												)}
											/>
										</Grid>
									</Grid>
								) : (
									<CircularProgress
										disableShrink
										style={{
											marginTop: "20%",
											marginLeft: "45%",
										}}
									/>
								)}
							</DialogContent>
							<DialogActions>
								<Box
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
										width: "100%",
									}}
								>
									{/* <Box m={2} display="flex">
                  <Box mt={3} order={1}>
                    <Typography>Estado:</Typography>
                  </Box>
                  <Box m={1} order={2}>
                    <Switch
                      checked={true}
                      onChange={handleChange}
                      color="primary"
                      name="Activado"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Box>
                </Box> */}
									<Box>
										<Button
											onClick={onClose}
											color="primary"
										>
											Cancelar
										</Button>
										<Button type="submit" color="primary">
											Guardar
										</Button>
									</Box>
								</Box>
							</DialogActions>
						</form>
					</Dialog>
				</React.Fragment>
			);
		}
	);
