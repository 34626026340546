import {
	Box,
	Button,
	Card as MuiCard,
	CardContent,
	Checkbox,
	Chip,
	Divider,
	Fade,
	FormControlLabel,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { FC, useRef, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../../constants";
import {
	GenerateProyectsPdf,
	GenerateProjectsPdfRef,
} from "../GenerateProyectsPdf";
import { AnyProject } from "../../models/Proyecto";
import { RevisionTableRow } from "./TableRow";
import { Usuario } from "src/models/Usuario";
import Firebase from "firebase";
import { cleanString } from "src/utils/utils";
import { actualizarResponsableRevisiones } from "../../services/empresa/revisiones";
import { ResponsibleDialog } from "./ResponsibleDialog";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducer";
import { IBusinessState } from "../../redux/reducers/businessReducer";
import { Info } from "@material-ui/icons";
import { ProjectCounter } from "../CheckPro/ProjectCounter";

const Card = styled(MuiCard)(spacing);

interface RevisionesTableProps {
	type: "revisiones" | "revisiones-checklist";
	enableSelection?: boolean;
	handleOpenEditDialog: (selected: any) => void;
	handleOpenConfirmDialog: (selected: any) => void;
	handleOpenResponsiblesDialog: (selected: any) => void;
	openNewDialog?: () => void;
	revisiones: AnyProject[];
	isLoading: boolean;
	canEdit?: boolean;
	onArrowClick: (selected: AnyProject) => void;
	renderPdf?: boolean;
	renderExcelSumary?: boolean;
	hidePercentage?: boolean;
	canRestore?: boolean;
}

export const RevisionesTable: FC<RevisionesTableProps> = ({
	type,
	canEdit = false,
	handleOpenEditDialog,
	revisiones,
	isLoading,
	handleOpenConfirmDialog,
	handleOpenResponsiblesDialog,
	onArrowClick,
	renderPdf = false,
	renderExcelSumary = false,
	hidePercentage,
	canRestore = false,
}) => {
	const [showDeleted, setShowDeleted] = useState(false);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(TABLE_LIMIT_DEFAULT);
	const [selectedRevisiones, setSelectedRevisiones] =
		useState<AnyProject[]>([]);

	const [openDialog, setOpenDialog] = useState(false);

	const pdfDialogref =
		useRef<null | GenerateProjectsPdfRef>(null);

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	/**
	 * Filtra las revisiones según la visibilidad,
	 * es decir mostrar activas o eliminadas.
	 */
	const revisionesToShow = useMemo(
		() =>
			revisiones?.filter((c) =>
				showDeleted
					? c.Estado === "Eliminado"
					: c.Estado === "Activo"
			) || [],
		[revisiones, showDeleted]
	);

	//////////////////////////////////////////////
	// 	Lógica de selección múltiple
	//////////////////////////////////////////////

	const allSelected = useMemo(
		() =>
			revisiones
				?.filter((e) => e.Estado === "Activo")
				.every((e) =>
					selectedRevisiones.find((e2) => e2.id === e.id)
				),
		[selectedRevisiones, revisiones]
	);

	const handleToggleAll = () =>
		setSelectedRevisiones(
			allSelected
				? []
				: revisiones.filter((c) => c.Estado === "Activo")
		);

	const handleToggleSelected = (id: string) => {
		const clone = [...selectedRevisiones];
		const found = clone.find((e) => e.id === id);
		if (found) clone.splice(clone.indexOf(found), 1);
		else clone.push(revisiones.find((e) => e.id === id)!);
		setSelectedRevisiones(clone);
	};

	//////////////////////////////////////////////
	// 	Lógica de conteo de revisiones
	//////////////////////////////////////////////

	const utilizados = useMemo(
		() =>
			revisiones?.filter((r) => r.Estado === "Activo")
				.length,
		[revisiones]
	);

	const projectLimit = useMemo(() => {
		if (!selectedBusiness) return NaN;
		const { Revisiones, CheckList } = selectedBusiness;
		if (type === "revisiones")
			return Revisiones.LimiteRevisiones;
		else if (type === "revisiones-checklist")
			return CheckList.LimiteCheckList;
		else return NaN;
	}, [selectedBusiness, type]);

	//////////////////////////////////////////////

	const handleSetResponsable = async (
		user: Usuario | null
	) => {
		if (!user || !user.id) return;
		try {
			await actualizarResponsableRevisiones(
				user,
				...selectedRevisiones
			);
		} catch (error: any) {
			console.error(error);
			alert(error.message);
		}
	};

	return (
		<>
			<Card
				mb={6}
				style={{ display: "flex", flexDirection: "column" }}
			>
				{!hidePercentage && (
					<>
						{/* barra de uso */}
						<ProjectCounter
							limit={projectLimit}
							used={utilizados}
						/>

						<Divider />
					</>
				)}

				{canEdit && (
					<>
						<CardContent>
							<Grid
								item
								container
								justify="space-between"
								sm={12}
							>
								<Grid item>
									<Button
										disabled={
											selectedRevisiones.length === 0
										}
										variant="contained"
										color="primary"
										onClick={() => {
											setOpenDialog(true);
										}}
									>
										Designar Responsable
									</Button>
								</Grid>

								{canRestore && (
									<Grid item>
										<FormControlLabel
											control={
												<Checkbox
													id="mostrar-eliminados-checkbox"
													checked={showDeleted}
													onChange={() =>
														setShowDeleted(!showDeleted)
													}
													color="primary"
												/>
											}
											label="Mostrar Eliminados"
										/>
									</Grid>
								)}
							</Grid>
						</CardContent>
					</>
				)}
				<CardContent>
					<TableContainer>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{canEdit && !showDeleted && (
										<TableCell>
											<Checkbox
												color="primary"
												checked={allSelected}
												onChange={handleToggleAll}
												inputProps={{
													"aria-label":
														"secondary checkbox",
												}}
											/>
										</TableCell>
									)}
									<TableCell>Nombre</TableCell>
									<TableCell>Revisiones</TableCell>
									{type === "revisiones-checklist" && (
										<TableCell>Observaciones</TableCell>
									)}
									<TableCell>Responsable</TableCell>
									<TableCell>Cargo</TableCell>
									<TableCell>Fecha Creación</TableCell>
									<TableCell align="center">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!isLoading
									? revisionesToShow
											.sort((a, b) =>
												a.NombreProyecto.localeCompare(
													b.NombreProyecto
												)
											)
											.slice(
												page * limit,
												page * limit + limit
											)
											.map((revision) => (
												<Fade key={revision.id} in={true}>
													<RevisionTableRow
														renderExcelSumary={
															renderExcelSumary
														}
														renderPdf={renderPdf}
														project={revision}
														selected={
															!!selectedRevisiones.find(
																(r) => r.id === revision.id
															)
														}
														handleOpenEditDialog={(
															project
														) =>
															handleOpenEditDialog(project)
														}
														handleOpenConfirmDialog={(
															project
														) =>
															handleOpenConfirmDialog(
																project
															)
														}
														handleOpenResponsiblesDialog={(
															project
														) =>
															handleOpenResponsiblesDialog(
																project
															)
														}
														handleChecksButton={(project) =>
															onArrowClick(project)
														}
														onSelection={
															handleToggleSelected
														}
														openPdfDialog={(project) => {
															pdfDialogref.current?.openDialog(
																project
															);
														}}
													/>
												</Fade>
											))
									: new Array(limit).fill(0).map((x, i) => (
											<TableRow key={i}>
												<TableCell colSpan={10}>
													<Skeleton />
												</TableCell>
											</TableRow>
									  ))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={revisionesToShow.length}
						onChangePage={(_, i) => setPage(i)}
						onChangeRowsPerPage={(e) =>
							setLimit(+e.target.value)
						}
						page={page}
						rowsPerPage={limit}
						rowsPerPageOptions={TABLE_LIMITS}
					/>
				</CardContent>
			</Card>

			<ResponsibleDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onSubmit={handleSetResponsable}
			/>
			{renderPdf ? (
				<GenerateProyectsPdf ref={pdfDialogref} />
			) : null}
		</>
	);
};
