import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	generatePDf,
	generatePDFInitial,
	getLists,
	getRevisions,
	listsInitial,
	revisionsInitial,
} from "../redux/actions/masivePdfGeneratorAction";
import { IMasivePDFGeneratorState } from "../redux/reducers/masivePdfGeneratorReducer";
import { RootState } from "../redux/store/reducer";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useSelectRows } from "../hooks/useSelectRows";
import { grey } from "@material-ui/core/colors";

export interface GenerateProjectsPdfRef {
	openDialog: (project: any) => void;
	closeDialog: VoidFunction;
}

interface Props {}

export const GenerateProyectsPdf = forwardRef<
	GenerateProjectsPdfRef,
	Props
>(({}, ref) => {
	const { generate, lists, revisions } = useSelector<
		RootState,
		IMasivePDFGeneratorState
	>((state) => state.masivePdfGeneratorReducer);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [selectedProject, setSelectedProject] = useState<
		undefined | any
	>();

	const openDialog = (project: any) => {
		setOpen(true);
		setSelectedProject(project);
		dispatch(getRevisions(project, true));
	};

	const closeDialog = () => {
		setSelectedProject(undefined);
		revisionsReset();
		listReset();
		// Dispatch de initial data
		dispatch(generatePDFInitial());
		dispatch(listsInitial());
		dispatch(revisionsInitial());
		setOpen(false);
	};

	useImperativeHandle(
		ref,
		() => ({
			openDialog,
			closeDialog,
		}),
		[]
	);

	const theme = useTheme();
	const fullScreen = useMediaQuery(
		theme.breakpoints.down("lg")
	);

	const {
		handleClickSelect: handleClickSelectRevision,
		isSelected: isSelectedRevision,
		reset: revisionsReset,
		selected: selectedRevisions,
	} = useSelectRows(10);

	const {
		handleClickSelect: handleClickSelectList,
		isSelected: isSelectedList,
		reset: listReset,
		selected: selectedLists,
	} = useSelectRows(10);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			selectedProject &&
				selectedRevisions.length > 0 &&
				dispatch(
					getLists(
						selectedProject.id,
						selectedRevisions,
						true
					)
				);
		}, 600);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [selectedRevisions]);

	const handleSubmit = () => {
		if (selectedLists.length === 0) return;
		const revisionsToSend = selectedRevisions
			.map((revId) => {
				const newRev: any = {
					...revisions.data.find((e) => e.id === revId),
				};
				if (!newRev) throw new Error("No existe revision");

				newRev.Listados = lists.data
					.filter((list) =>
						list.ref.path.includes(newRev.id)
					)
					.filter((list) =>
						selectedLists.includes(list.id)
					);

				delete newRev.ref;
				return newRev;
			})
			.filter((newRev) => newRev.Listados.length !== 0);
		dispatch(
			generatePDf(
				selectedProject,
				revisionsToSend,
				selectedLists.length,
				true,
				true
			)
		);
	};

	return (
		<Dialog
			open={open}
			onClose={closeDialog}
			fullScreen={fullScreen}
			maxWidth="lg"
			fullWidth
			style={{ maxHeight: "90%", marginTop: "auto" }}
		>
			<DialogTitle>
				Generar PDF proyecto{" "}
				{selectedProject?.NombreProyecto}
			</DialogTitle>
			<DialogContent
				style={{
					overflow: "hidden",
					maxHeight: "100%",
					display: "flex",
				}}
			>
				<Grid
					container
					spacing={4}
					style={{ maxHeight: "100%" }}
				>
					<Grid
						item
						xs={12}
						md={6}
						style={{
							maxHeight: "100%",
						}}
					>
						<Typography variant="subtitle1">
							Revisiones
						</Typography>
						<List
							style={{
								backgroundColor: grey[200],
								marginTop: theme.spacing(4),
								overflow: "auto",
								maxHeight: "90%",
							}}
						>
							{!revisions.isLoading && !revisions.error
								? revisions.data.map((rev, index) => (
										<Fade in={true} key={rev.id}>
											<ListItem
												button
												onClick={() =>
													handleClickSelectRevision(rev.id)
												}
												selected={isSelectedRevision(
													rev.id
												)}
											>
												<ListItemIcon>
													{isSelectedRevision(rev.id) ? (
														<CheckBoxIcon color="primary" />
													) : (
														<CheckBoxOutlineBlankIcon color="primary" />
													)}
												</ListItemIcon>
												<ListItemText
													primary={rev.Nombre}
												/>
											</ListItem>
										</Fade>
								  ))
								: null}
						</List>
						{revisions.isLoading && (
							<Box
								display="flex"
								width="100%"
								justifyContent="center"
								alignItems="center"
							>
								<CircularProgress />
							</Box>
						)}
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="subtitle1">
							Listados
						</Typography>
						<List
							style={{
								backgroundColor: grey[200],
								marginTop: theme.spacing(4),
								overflow: "auto",
								maxHeight: "90%",
							}}
						>
							{!lists.isLoading && !lists.error
								? lists.data.map((list, index) => (
										<Fade in={true} key={list.id}>
											<ListItem
												button
												onClick={() =>
													handleClickSelectList(list.id)
												}
												selected={isSelectedList(list.id)}
											>
												<ListItemIcon>
													{isSelectedList(list.id) ? (
														<CheckBoxIcon color="primary" />
													) : (
														<CheckBoxOutlineBlankIcon color="primary" />
													)}
												</ListItemIcon>
												<ListItemText
													primary={list.Nombre}
													secondary={
														revisions.data.find((rev) =>
															(
																list.ref.path as string
															).includes(rev.id)
														)?.Nombre
													}
												/>
											</ListItem>
										</Fade>
								  ))
								: null}
						</List>
						{lists.isLoading && (
							<Box
								display="flex"
								width="100%"
								justifyContent="center"
								alignItems="center"
							>
								<CircularProgress />
							</Box>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSubmit}
				>
					Generar
				</Button>
			</DialogActions>
		</Dialog>
	);
});
