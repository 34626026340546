import { useEffect, useState } from "react";
import {
	DialogAction,
	DialogAdd,
	DialogState,
} from "../models/dialog_state";

interface Params<T> {
	onAccept: (value: T, action: DialogAction) => void;
	onClose?: () => void;
	onOpenNewDialog?: () => void;
}

export const useNewEditDialog = <T = any>(
	params: Params<T>
) => {
	const {
		onAccept: onAcceptParam,
		onClose,
		onOpenNewDialog,
	} = params;
	const [isLoading, setIsLoading] = useState(false);
	const initialState: DialogAdd<T> = {
		open: false,
		action: "New",
		state: "Initial",
	};

	const [dialog, setDialog] =
		useState<DialogAdd<T>>(initialState);

	useEffect(() => {
		if (!isLoading && dialog.state === "Submitting") {
			setDialog(initialState);
			if (onClose) {
				onClose();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialog.state, isLoading]);

	const openNewDialog = () => {
		setDialog({
			...dialog,
			open: true,
			action: "New",
			selected: undefined,
		});
		if (onOpenNewDialog) onOpenNewDialog();
	};

	const openEditDialog = (selected: T) => {
		setDialog({
			...dialog,
			open: true,
			action: "Edit",
			selected,
		});
	};

	const closeDialog = () => {
		setDialog(initialState);
		if (onClose) onClose();
	};

	const setState = (state: DialogState) => {
		setDialog({ ...dialog, state });
	};

	const onAccept = (values: T, action: DialogAction) => {
		onAcceptParam(values, action);
		setDialog({ ...dialog, state: "Submitting" });
	};

	return {
		setIsLoading,
		...dialog,
		openNewDialog,
		openEditDialog,
		closeDialog,
		setState,
		onAccept,
	};
};
