import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	InputAdornment,
	TextField,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Usuario } from "src/models/Usuario";
import {
	getUsers,
	setUsers,
} from "src/redux/actions/usersActions";
import { RootState } from "src/redux/store/reducer";
import { IUsersState } from "src/redux/reducers/usersReducer";
import { conseguirUsuariosEmpresa } from "src/services/empresa";
import { IBusinessState } from "../../redux/reducers/businessReducer";

export type ResponsibleDialogProps = Omit<
	DialogProps,
	"onSubmit"
> & {
	onSubmit: (responsable: Usuario | null) => Promise<void>;
};

export const ResponsibleDialog = ({
	onSubmit,
	...props
}: ResponsibleDialogProps) => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const { users } = useSelector<RootState, IUsersState>(
		(s) => s.usersReducer
	);

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(s) => s.businessReducer
	);

	const [responsable, setResponsable] =
		useState<Usuario | null>(null);

	const { idEmpresa } = useParams<{ idEmpresa?: string }>();

	useEffect(() => {
		if (!props.open) return;
		const id = idEmpresa ?? selectedBusiness?.id;
		if (!id) return;
		dispatch(getUsers({ id }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idEmpresa, props.open]);

	const handleSubmit = async () => {
		if (!onSubmit) return;
		setLoading(true);
		await onSubmit(responsable);
		setLoading(false);
		props.onClose?.({}, "escapeKeyDown");
	};

	return (
		<Dialog {...props}>
			<DialogTitle>
				{users
					? "Elige al responsable"
					: "Cargando responsables..."}
			</DialogTitle>

			<DialogContent>
				<Autocomplete<Usuario>
					options={users || []}
					getOptionLabel={({ Nombre, Apellido, Cargo }) =>
						`${Nombre} ${Apellido} [${Cargo || "N/A"}]`
					}
					onChange={(_, value) => setResponsable(value)}
					fullWidth
					renderInput={(params) => (
						<TextField
							{...params}
							label="Nombre del responsable"
							variant="outlined"
							style={{ minWidth: 300 }}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<InputAdornment position="start">
										{users ? (
											<Person color="primary" />
										) : (
											<CircularProgress
												size={24}
												color="primary"
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={loading}
					onClick={(ev) =>
						props.onClose?.(ev, "backdropClick")
					}
					color="primary"
				>
					Cancelar
				</Button>
				<Button
					disabled={!responsable || loading}
					onClick={handleSubmit}
					color="primary"
					autoFocus
				>
					{loading ? (
						<CircularProgress size={24} color="primary" />
					) : (
						"Definir responsable"
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
