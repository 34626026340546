import { format } from "date-fns";
import firebase from "firebase";

export const removeUndefinedKeys = (obj: any) => {
	Object.keys(obj).forEach(
		(key) => obj[key] === undefined && delete obj[key]
	);
};

export const sortByName = (a: any, b: any) =>
	a.Nombre < b.Nombre ? -1 : a.Nombre > b.Nombre ? 1 : 0;

export const timesStampToDateFormatted = (
	timestamp: any
) => {
	try {
		return format(
			timestamp.toDate(),
			"dd/MM/yyyy HH:mm:ss"
		);
	} catch (e) {
		return "Sin resolver.";
	}
};

export const dateToTimeStamp = (selectedDate: any) => {
	return (new Date(selectedDate).getTime() / 1000).toFixed(
		0
	);
};

export const capitalizeFirstLetter = (str: string) => {
	const str2 = str.charAt(0).toUpperCase() + str.slice(1);
	return str2;
};

export const capitalizeAllFirstLetters = (str: string) => {
	const arr = str.split(" ");
	for (var i = 0; i < arr.length; i++) {
		arr[i] =
			arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
	}
	const str2 = arr.join(" ");
	return str2;
};

export const timesStampToDateFormattedDate = (
	timestamp: firebase.firestore.Timestamp
) => {
	try {
		return format(timestamp.toDate(), "dd/MM/yyyy");
	} catch (e) {
		return "Sin resolver.";
	}
};

export const cleanString = (value: string): string => {
	let newValue = `${value}`;
	newValue = newValue.toLowerCase();
	newValue = newValue.replace(/á/gi, "a");
	newValue = newValue.replace(/é/gi, "e");
	newValue = newValue.replace(/í/gi, "i");
	newValue = newValue.replace(/ó/gi, "o");
	newValue = newValue.replace(/ú/gi, "u");
	return newValue;
};

export const exportToCsv = (
	filename: string,
	rows: object[],
	headers: string[],
	headersAs?: string[]
): void => {
	if (!rows || !rows.length) {
		return;
	}
	const separator: string = ",";

	if (headersAs) {
		if (headers.length !== headersAs.length) {
			throw new Error(
				"headers and headersAs must be equal length"
			);
		}
	}
	const columHearders = headers;

	const csvContent =
		"sep=,\n" +
		(headersAs
			? headersAs.join(separator)
			: columHearders.join(separator)) +
		"\n" +
		rows
			.map((row: any, i) => {
				return columHearders
					.map((k) => {
						let cell = k.includes(".")
							? resolve(k, row)
							: row[k] === null || row[k] === undefined
							? ""
							: row[k];
						if (!cell) {
							cell = "";
						}
						if (
							cell instanceof firebase.firestore.Timestamp
						) {
							cell = (cell as firebase.firestore.Timestamp)
								.toDate()
								.toLocaleString();
						} else if (cell instanceof Date) {
							cell = cell.toLocaleString();
						} else {
							cell = cell.toString().replace(/"/g, '""');
						}
						if (cell.search(/("|,|\n)/g) >= 0) {
							cell = `"${cell}"`;
						}
						cell = cell.replace(/á/gi, "a");
						cell = cell.replace(/é/gi, "e");
						cell = cell.replace(/í/gi, "i");
						cell = cell.replace(/ó/gi, "o");
						cell = cell.replace(/ú/gi, "u");
						cell = cell.replace(/ñ/gi, "n");

						return cell;
					})
					.join(separator);
			})
			.join("\n");

	const blob = new Blob([csvContent], {
		type: "text/csv;charset=utf-8;",
	});
	if ((navigator as any).msSaveBlob) {
		// In case of IE 10+
		(navigator as any).msSaveBlob(blob, filename);
	} else {
		const link = document.createElement("a");
		if (link.download !== undefined) {
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filename);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

export function resolve(path: string, obj: any) {
	return path.split(".").reduce(function (prev, curr) {
		return prev ? prev[curr] : null;
	}, obj);
}

export const formatDate = (date: Date) => {
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();

	let formatted_date = `${day <= 9 ? `0${day}` : day}/${
		month <= 9 ? `0${month}` : month
	}/${year}`;
	// date.getDate() +
	// "-" +
	// (date.getMonth() + 1) +
	// "-" +
	// date.getFullYear();
	return formatted_date;
};

export const findDeleted = (
	objeto: any,
	arrayExclude: any[]
) => {
	const res = arrayExclude.find(
		(dato) => objeto.id === dato.id
	);
	if (res === undefined) {
		return true;
	} else {
		return false;
	}
};

export const findNew = (
	objeto: any,
	arrayExclude: any[]
) => {
	const res = arrayExclude.find(
		(dato) => objeto.id !== dato.id
	);
	if (res === undefined) {
		return true;
	} else {
		return false;
	}
};
export const toAddArrayData = (
	myStaticArray: any[],
	myDynamicArray: any[]
) => {
	let toAdd: any[] = [];

	if (myStaticArray.length === 0) {
		if (myDynamicArray.length !== 0) {
			toAdd = myDynamicArray;
		}
	} else {
		if (myStaticArray.length === myDynamicArray.length) {
			// toAdd = myStaticArray.filter(function (obj: any) {
			//   return !myDynamicArray.find(function (obj2: any) {
			//     return obj == obj2;
			//   });
			// });

			toAdd = myStaticArray?.filter(function (obj: any) {
				return myDynamicArray?.find(function (obj2: any) {
					return obj.id !== obj2.id;
				});
			});
		} else if (
			myDynamicArray.length > myStaticArray.length
		) {
			toAdd = myDynamicArray.filter(function (obj: any) {
				return !myStaticArray.find(function (obj2: any) {
					return obj === obj2;
				});
			});
		}
	}
	// else {
	//   if (userProjects.length !== 0) {
	//     userProjects.forEach(async (element: any) => {
	//       await firestore
	//         .collection("Proyectos")
	//         .doc(element.id)
	//         .update({
	//           // Responsable: {
	//           //   NombreCompleto: "",
	//           //   NombreCompleto_lower: "",
	//           //   FirebaseId: "",
	//           //   FechaAsignado:
	//           //     firebase.firestore.FieldValue.serverTimestamp(),
	//           //   Cargo: "",
	//           // },
	//           Responsables: firebase.firestore.FieldValue.arrayRemove(
	//             user.id
	//           ),
	//         });
	//     });
	//   }
	// }

	// else if (project !== userProjects) {
	//   if (project.length !== 0) {
	//     if (userProjects.length !== 0) {
	//       let result = [];
	//       if (userProjects.length > project.length) {
	//         const uniqueResultOne = userProjects.filter(function (obj) {
	//           return !project.find(function (obj2: any) {
	//             return obj == obj2;
	//           });
	//         });
	//         result = uniqueResultOne;

	//         result.forEach(async (element: any) => {
	//           await firestore
	//             .collection("Proyectos")
	//             .doc(element.id)
	//             .update({
	//               Responsables: firebase.firestore.FieldValue.arrayRemove(
	//                 user.id
	//               ),
	//             });
	//         });
	//       } else if (project.length > userProjects.length) {
	//         const uniqueResultOne = project.filter(function (obj: any) {
	//           return !userProjects.find(function (obj2: any) {
	//             return obj == obj2;
	//           });
	//         });
	//         result = uniqueResultOne;
	//         result.forEach(async (element: any) => {
	//           await firestore
	//             .collection("Proyectos")
	//             .doc(element.id)
	//             .update({
	//               Responsables: firebase.firestore.FieldValue.arrayUnion(
	//                 user.id
	//               ),
	//             });
	//         });
	//       } else if (project.length === userProjects.length) {
	//         const uniqueResultOne = project.filter(function (obj: any) {
	//           return !userProjects.find(function (obj2: any) {
	//             return obj == obj2;
	//           });
	//         });
	//         // To add

	//         uniqueResultOne.forEach(async (element: any) => {
	//           await firestore
	//             .collection("Proyectos")
	//             .doc(element.id)
	//             .update({
	//               Responsables: firebase.firestore.FieldValue.arrayUnion(
	//                 user.id
	//               ),
	//             });
	//         });

	//         const uniqueResultTwo = userProjects.filter(function (obj: any) {
	//           return !project.find(function (obj2: any) {
	//             return obj == obj2;
	//           });
	//         });
	//         // To remove

	//         uniqueResultTwo.forEach(async (element: any) => {
	//           await firestore
	//             .collection("Proyectos")
	//             .doc(element.id)
	//             .update({
	//               Responsables: firebase.firestore.FieldValue.arrayRemove(
	//                 user.id
	//               ),
	//             });
	//         });
	//       }
	//     } else {
	//       if (project.length !== 0) {
	//         project.forEach(async (element: any) => {
	//           await firestore
	//             .collection("Proyectos")
	//             .doc(element.id)
	//             .update({
	//               Responsables: firebase.firestore.FieldValue.arrayUnion(
	//                 user.id
	//               ),
	//             });
	//         });
	//       }
	//     }
	//   } else if (project.length === 0) {
	//     if (userProjects.length !== 0) {
	//       userProjects.forEach(async (element: any) => {
	//         await firestore
	//           .collection("Proyectos")
	//           .doc(element.id)
	//           .update({
	//             // Responsable: {
	//             //   NombreCompleto: "",
	//             //   NombreCompleto_lower: "",
	//             //   FirebaseId: "",
	//             //   FechaAsignado:
	//             //     firebase.firestore.FieldValue.serverTimestamp(),
	//             //   Cargo: "",
	//             // },
	//             Responsables: firebase.firestore.FieldValue.arrayRemove(
	//               user.id
	//             ),
	//           });
	//       });< G
	//     }
	//   }
	// }

	return toAdd;
};

export function downloadURI(uri: string, name: string) {
	let link = document.createElement("a");
	link.download = name;
	link.href = uri;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

/**
 * @param cantidad El x% que se quiere calcular
 * @param total 100%
 * @param decimales cantidad de decimales del redondeo.
 * Por defecto 2.
 */
export const calcularPorcentaje = (
	cantidad: number,
	total: number,
	decimales = 2
) => Number(((cantidad * 100) / total).toFixed(decimales));

export function delay(milliseconds: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, milliseconds);
	});
}
