import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { Add as AddIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addPriority,
  deletePriority,
  getPriorities,
  updatePriority,
} from "../redux/actions/prioritiesActions";
import { SimpleCrud } from "../components/SimpleCrud";
import { RootState } from "../redux/store/reducer";
import { IPrioritiesState } from "../redux/reducers/prioritiesReducer";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Priorities = () => {
  const dispatch = useDispatch();

  const { isLoading, priorities } = useSelector<RootState, IPrioritiesState>(
    (state) => state.prioritiesReducer
  );

  useEffect(() => {
    dispatch(getPriorities());
  }, [dispatch]);

  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Helmet title="Prioridades" />
      <Grid container spacing={4} justify="space-between">
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Prioridades
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Prioridades</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            <AddIcon />
            Agregar Prioridad
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SimpleCrud
            key="Prioridades"
            isLoading={isLoading}
            name="Prioridad"
            onCreateOrUpdate={(values, action, selected) => {
              if (action === "New") {
                dispatch(addPriority(values));
              } else if (action === "Edit") {
                dispatch(updatePriority(values, selected.id));
              }
            }}
            onDelete={(selected) => {
              dispatch(deletePriority(selected.id));
            }}
            array={priorities}
            openNewDialog={open}
            onClose={() => setOpen(false)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
