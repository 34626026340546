import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

/**
 * Los Breakpoints son puntos de específicos en los cuales el diseño de la
 * aplicación puede reaccionar. Estos puntos equivalen a largos específicos
 * de la pantalla, y son los siguientes:
 * - `xs`: entre 0px y 600px
 * - `sm`: entre 600px y 960px
 * - `md`: entre 960px y 1280px
 * - `lg`: entre 1280px y 1920px
 * - `xl`: mayor a 1920px
 *
 * Esto quiere decir que cuando el largo de la pantalla pasa a estar por
 * debajo de 600 píxeles de ancho, el layout podría reaccionar y cambiar
 * su estructura para adaptarse a la vista del móvil.
 */
type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

/**
 * Los queries son las formas en las que se puede consultar el estado de
 * la pantalla. Estos son los siguientes:
 * - `up`: mayor o igual a un punto específico
 * - `down`: menor o igual a un punto específico
 * - `between`: entre dos puntos específicos
 * - `only`: igual a un punto específico
 * - `not`: distinto a un punto específico
 */
type Query = "up" | "down" | "between" | "only" | "not";

/**
 * Estructura de una consulta responsiva.
 * Ejemplo:
 * ```tsx
 * ["down", "sm"] // es menor o igual a 600px
 * ["between", "sm", "md"] // es mayor a 600px y menor a 960px
 * ```
 */
export type ResponsiveQuery = [Query, Breakpoint, Breakpoint?];

/**
 * Permite hacer consultas respecto al estado de la pantalla.
 * Ejemplo de uso:
 * ```tsx
 * const isMobile = useResponsiveQuery("down", "sm");
 * const isDesktop = useResponsiveQuery("up", "md");
 * const isBetween = useResponsiveQuery("between", "sm", "md");
 * ```
 */
export const useResponsiveQuery = (
	...query: ResponsiveQuery
): boolean => {
	const [constraint, breakpoint1, breakpoint2] = query;
	const theme = useTheme();

	if (constraint === "between" && !breakpoint2)
		console.error(
			"You need a second breakpoint to perform a 'between' query. " +
				`The '[${constraint}, ${breakpoint1}]' query might not be accurate.`
		);

	return useMediaQuery(
		// @ts-ignore
		theme.breakpoints[constraint](
			breakpoint1,
			breakpoint2 ?? breakpoint1
		)
	);
};
