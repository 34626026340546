import { useMemo, useState } from "react";
import {
	Box,
	IconButton,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { PercentageCell } from "../Misc/PercentageCell";
import { ChevronRight, Edit, Trash2 } from "react-feather";
import { NeoPlaceAssignDialog } from "./Dialog/NeoPlaceAssign";
import { useHistory } from "react-router-dom";
import { getRef } from "src/utils/firebase";
import { DropdownMenu } from "../../Generic/DropdownMenu";
import { Opener } from "../../CheckPro/Opener";
import { colorsCheckPro } from "src/theme/variants";
import naturalSort from "natural-sort";
import { NeoPlaceDisableDialog } from "./Dialog/NeoPlaceDisable";
import { AssignedPlaceOT } from "src/models/OT/Place";
import { useSelector } from "src/redux/store";
import { Table } from "src/utils/components/Table";
import { roundWithDecimals } from "src/utils/numbers";

export const NeoPlacesTable = () => {
	const history = useHistory();

	const user = useSelector((s) => s.authReducer.user);

	const [dialog, setDialog] = useState(0);

	const { selected, checklist, set, toggle } =
		useProjectsOT();

	const dataToShow = useMemo(() => {
		if (!checklist.places) return [];
		return checklist.places
			.filter((p) => p.Asignado)
			.sort((a, b) => naturalSort()(a.Nombre, b.Nombre));
	}, [checklist.places]);

	const calculateProgress = (ofPlace: AssignedPlaceOT) => {
		const { Cantidad, CantidadCompletada = 0 } = ofPlace;

		const percentage =
			(CantidadCompletada / Cantidad) * 100;

		if (isNaN(percentage)) return 0;
		return percentage;
	};

	const handleEdit = (place: AssignedPlaceOT) => {
		toggle.assignedPlace([place]);
		setDialog(1);
	};

	const handleDelete = (place: AssignedPlaceOT) => {
		toggle.assignedPlace([place]);
		setDialog(2);
	};

	const handleRedirectToAnswers = (
		ofPlace: AssignedPlaceOT
	) => {
		const placeRef = getRef(ofPlace);
		const checklistRef = getRef(selected.checklist);

		set.assignedPlace(ofPlace);
		history.push(`${checklistRef.id}/${placeRef.id}`);
	};

	return (
		<>
			<Table
				stripped
				elementsProps={{
					TableCellProps: { style: { padding: 5 } },
				}}
				disableExporting
				loading={!checklist.places}
				list={dataToShow}
				emptyMessage="Debe asignar espacios a la lista de chequeo"
				columns={[
					{
						header: "Nombre",
						Cell: ({ value }) => <>{value.Nombre}</>,
					},
					{
						align: "center",
						header: "Cantidad",
						Cell: ({ value }) => (
							<>
								{value.CantidadCompletada ?? 0} /{" "}
								{value.Cantidad}
							</>
						),
					},
					{
						align: "center",
						header: "Unidad",
						Cell: () => (
							<>{selected.checklist?.Unidad ?? "Unidad"}</>
						),
					},
					{
						align: "center",
						header: "Progreso",
						Cell: ({ value }) => (
							<Tooltip
								title={
									<Typography>
										<ul>
											<li>Menor o igual del 25%: rojo </li>
											<li>
												Sobre 25% y menos 50%: naranjo
											</li>
											<li>
												Sobre 50% y menos al 75%: amarillo
											</li>
											<li>Sobre 75% y menos a 99%: azul</li>
											<li>Sobre el 99%: verde</li>
										</ul>
										Si la meta es 50%, y has completado un
										51%, se vería en verde porque superaría
										el 99% RESPECTO A LA META.
									</Typography>
								}
							>
								<Box>
									<PercentageCell
										value={calculateProgress(value)}
										aim={selected.checklist?.MetaRevision}
									/>
								</Box>
							</Tooltip>
						),
					},
					{
						align: "right",
						Cell: ({ value }) => (
							<>
								{user?.tipoUsuario !== "UsuarioEmpresa" &&
									!selected.project?.Eliminado && (
										<DropdownMenu
											Opener={Opener}
											options={[
												{
													label: "Editar",
													onClick: () => handleEdit(value),
													icon: (
														<Edit
															color={colorsCheckPro.primary}
														/>
													),
												},
												{
													label: "Eliminar",
													onClick: () =>
														handleDelete(value),
													icon: <Trash2 color="red" />,
												},
											]}
										/>
									)}

								<Tooltip title="Ver respuestas">
									<IconButton
										size="small"
										color="primary"
										onClick={() =>
											handleRedirectToAnswers(value)
										}
									>
										<ChevronRight />
									</IconButton>
								</Tooltip>
							</>
						),
					},
				]}
			/>

			<NeoPlaceAssignDialog
				mode="edit"
				open={dialog === 1}
				onClose={() => setDialog(0)}
			/>

			<NeoPlaceDisableDialog
				open={dialog === 2}
				onClose={() => setDialog(0)}
			/>
		</>
	);
};
