import { otActions } from "src/redux/neo-checklists/slice";
import {
	getUserProjectsOT,
	updateProjectOT,
	removeProjectOT,
	createProjectOT,
	getProjectsOT,
	GetProjectsApi,
	getProjectUsers,
} from "src/redux/neo-checklists/actions";
import { Empresa } from "src/models/Empresa";

import { WithRef } from "src/utils/firebase";
import {
	updateChecklistFromProject,
	getChecklistsFromProject,
	addChecklistToProject,
	removeChecklistFromProjectOT,
} from "src/redux/neo-checklists/actions/checklists";
import { useDispatch, useSelector } from "src/redux/store";
import {
	updatePlacesFromChecklistOT,
	removePlaceFromProjectOT,
	updatePlaceFromProjectOT,
	getPlacesFromChecklistOT,
	getProjectPlacesOT,
	addPlacesToChecklistOT,
	addPlaceToProjectOT,
} from "src/redux/neo-checklists/actions/places";
import { useCallback, useMemo } from "react";
import {
	AssignedPlaceOT,
	PlaceOT,
} from "src/models/OT/Place";
import { ChecklistProject } from "src/models/OT/Projects";
import { ChecklistOT } from "src/models/OT/Checklist";
import naturalSort from "natural-sort";
import {
	getDeviationByParameter,
	downloadPhotographicReport,
	downloadResponseSummaryExcel,
	downloadSignaturesSummaryPdf,
	DeviationByParameterApi,
	SignatureSummaryApi,
} from "../../redux/neo-checklists/actions/reports";
import {
	getChecklistDeviationByQuestion,
	getProjectDeviationByChecklist,
	getChecklistDeviationBySector,
	getProjectRevisionGoals,
} from "src/redux/neo-checklists/actions/reports";
import {
	getPlaceSheetsOT,
	getSheetUsers,
	removeSheetFromProjectSpaceOT,
} from "src/redux/neo-checklists/actions/sheets";
import { SheetOT } from "src/models/OT/Sheet";

export const useProjectsOT = () => {
	const dispatch = useDispatch();
	const {
		checklist,
		projects,
		selected,
		loading,
		project,
	} = useSelector((s) => s.neoChecklistReducer);

	const users = useSelector((s) => s.usersReducer.users);

	const getSheetReviewers = useCallback(
		({ Revisores = [] }: SheetOT) => {
			const initialNames = Revisores?.map(
				(revisor) =>
					revisor.Categorias[0].Preguntas[0]?.Respuestas[0]
						?.NombreRevisor ?? revisor.NombreRevisor
			);

			initialNames.forEach((reviewer, i) => {
				if (reviewer) return;

				const userId =
					selected.assignedPlace?.Revisores[i]?.id;
				const userData = users?.find(
					(u) => u.id === userId
				);

				initialNames[i] = userData
					? `${userData.Nombre} ${userData.Apellido}`
					: `Revisor ${i + 1}`;
			});

			return initialNames as string[];
		},
		[users, selected.assignedPlace?.Revisores]
	);

	return {
		projects,
		selected,
		project,
		loading,

		getSheetReviewers,

		isSelected: {
			place: useCallback(
				(place: PlaceOT) =>
					selected.places.some(
						(p) => p._ref!.id === place._ref!.id
					),
				[selected.places]
			),

			assignedPlace: useCallback(
				(place: AssignedPlaceOT) =>
					selected.assignedPlaces.some(
						(p) => p._ref!.id === place._ref!.id
					),
				[selected.assignedPlaces]
			),
		},

		checklist: {
			...checklist,

			availablePlaces: useMemo(
				() =>
					(
						project.places?.filter(
							(projectPlace) =>
								!!checklist.places?.every(
									(checklistPlace) =>
										checklistPlace._ref?.id !==
											projectPlace._ref?.id ||
										checklistPlace.Nombre !==
											projectPlace.Nombre
								)
						) ?? []
					).sort((a, b) =>
						naturalSort()(a.Nombre, b.Nombre)
					),
				[project.places, checklist.places]
			),
		},

		getProjects: async (
			business?: Empresa,
			filters?: GetProjectsApi["filters"]
		) => dispatch(getProjectsOT({ business, filters })),

		getUserProjects: async () =>
			dispatch(getUserProjectsOT()),

		createProject: async (
			project: Pick<
				ChecklistProject,
				| "Nombre"
				| "Espacios"
				| "Responsable"
				| "Responsables"
				| "Tipo"
			>
		) => dispatch(createProjectOT({ project })),

		updateProject: async (
			project: WithRef<Partial<ChecklistProject>>
		) => dispatch(updateProjectOT(project)),

		removeProject: async (project?: ChecklistProject) =>
			dispatch(removeProjectOT(project)),

		set: {
			project: (project: ChecklistProject | null) =>
				dispatch(otActions.setSelectedProject(project)),

			checklist: (checklist: ChecklistOT | null) =>
				dispatch(otActions.setSelectedChecklist(checklist)),

			place: (place: PlaceOT | null) =>
				dispatch(otActions.setSelectedPlace(place)),

			assignedPlace: (
				assignedPlace: AssignedPlaceOT | null
			) =>
				dispatch(
					otActions.setSelectedAssignedPlace(assignedPlace)
				),

			graphData: (graphData: any) =>
				dispatch(otActions.assignChecklist({ graphData })),

			sheet: (sheet: SheetOT | null) =>
				dispatch(otActions.assignSelected({ sheet })),
		},

		toggle: {
			place: (place: PlaceOT | PlaceOT[]) =>
				dispatch(otActions.togglePlace(place)),

			assignedPlace: (
				assignedPlace: AssignedPlaceOT | AssignedPlaceOT[]
			) =>
				dispatch(
					otActions.toggleAssignedPlace(assignedPlace)
				),
		},

		get: {
			report: {
				projectRevisionGoals: async () =>
					dispatch(getProjectRevisionGoals()),

				deviationByChecklist: async () =>
					dispatch(getProjectDeviationByChecklist()),

				deviationByPlace: async () =>
					dispatch(getChecklistDeviationBySector()),

				deviationByQuestion: async () =>
					dispatch(getChecklistDeviationByQuestion()),

				deviationByParameter: async (
					parameters: DeviationByParameterApi
				) => dispatch(getDeviationByParameter(parameters)),

				sheetPhotoPdf: async (
					responsePath: string,
					responseNumber: number
				) =>
					dispatch(
						downloadPhotographicReport({
							responsePath,
							responseNumber,
						})
					),

				summaryResponseExcel: async (
					checklistType: "Obra Gruesa" | "Terminaciones"
				) =>
					dispatch(
						downloadResponseSummaryExcel(checklistType)
					),

				sheetSignaturePdf: async (
					params: SignatureSummaryApi
				) => dispatch(downloadSignaturesSummaryPdf(params)),
			},

			project: {
				checklists: async (type?: ChecklistOT["Tipo"]) =>
					dispatch(getChecklistsFromProject({ type })),

				places: async (project?: ChecklistProject) =>
					dispatch(getProjectPlacesOT(project)),

				users: async () => dispatch(getProjectUsers()),
			},

			place: {
				sheets: () => dispatch(getPlaceSheetsOT()),
			},

			checklist: {
				places: async (checklist?: ChecklistOT) =>
					dispatch(getPlacesFromChecklistOT(checklist)),
			},

			sheet: {
				users: async () => dispatch(getSheetUsers()),
			},
		},

		update: {
			project: {
				checklist: async (
					checklist: WithRef<WithRef<Partial<ChecklistOT>>>
				) =>
					dispatch(updateChecklistFromProject(checklist)),

				place: (place: WithRef<Partial<PlaceOT>>) =>
					dispatch(updatePlaceFromProjectOT(place)),
			},

			checklist: {
				places: (
					...places: WithRef<
						Partial<
							Pick<
								AssignedPlaceOT,
								| "Asignado"
								| "FechaInicio"
								| "FechaTermino"
								| "Cantidad"
								| "Revisores"
							>
						>
					>[]
				) => dispatch(updatePlacesFromChecklistOT(places)),
			},
		},

		remove: {
			project: {
				checklist: async (checklist: ChecklistOT) =>
					dispatch(removeChecklistFromProjectOT(checklist)),

				place: async (place: WithRef<PlaceOT>) =>
					dispatch(removePlaceFromProjectOT(place)),
			},

			place: {
				sheet: async (sheet: SheetOT) =>
					dispatch(removeSheetFromProjectSpaceOT(sheet)),
			},
		},

		create: {
			project: {
				checklist: async (
					baseChecklist: ChecklistOT,
					checklist: Pick<
						ChecklistOT,
						"MetaRevision" | "Unidad"
					>
				) =>
					dispatch(
						addChecklistToProject({
							baseChecklist,
							checklist,
						})
					),

				place: async (
					name: string,
					project?: ChecklistProject
				) =>
					dispatch(addPlaceToProjectOT({ project, name })),
			},

			checklist: {
				places: async (
					...data: {
						basePlace: PlaceOT;
						assignedPlace: Pick<
							AssignedPlaceOT,
							"Asignado" | "Cantidad" | "Revisores"
						>;
					}[]
				) =>
					dispatch(
						addPlacesToChecklistOT({
							data,
						})
					),
			},
		},
	};
};
