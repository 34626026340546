import { Action } from "../../models/action";
import * as types from "../../constants";
import { sortByName } from "../../utils/utils";
import { InnerRevision } from "src/models/Revision";

export interface IRevisionsState {
	revisions: any[];
	selectedRevision?: InnerRevision;
	isLoading: boolean;
	error?: string;
	updateLoading: boolean;
	totalDocs: number;
	lastDoc?: any;
}

const initialState = {
	revisions: [],
	isLoading: false,
	updateLoading: false,
	totalDocs: 0,
} as IRevisionsState;

export const revisionsReducer = (
	state = initialState,
	action: Action
): IRevisionsState => {
	switch (action.type) {
		case types.REVISIONS_GET_DOCS:
			return {
				...state,
				revisions: action.payload,
			};
		case types.REVISIONS_ADD_DOCS:
			return {
				...state,
				revisions: [
					...state.revisions,
					...action.payload,
				].sort(sortByName),
			};
		case types.REVISIONS_UPDATE_DOC:
			return {
				...state,
				revisions: state.revisions
					.map((x) =>
						x.id === action.payload.id
							? { ...x, ...action.payload }
							: x
					)
					.sort(sortByName),
			};
		case types.REVISIONS_REMOVE_DOC:
			return {
				...state,
				revisions: state.revisions.filter(
					(x) => x.id !== action.payload.id
				),
			};
		case types.REVISIONS_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case types.REVISIONS_UPDATE_LOADING:
			return {
				...state,
				updateLoading: action.payload,
			};
		case types.REVISIONS_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		case types.REVISIONS_SET_TOTAL_DOCS:
			return {
				...state,
				totalDocs: action.payload,
			};
		case types.REVISIONS_SET_SELECTED:
			return {
				...state,
				selectedRevision: action.payload,
			};
		case types.REVISIONS_SET_LAST_DOC:
			return {
				...state,
				lastDoc: action.payload,
			};
		// get one revision
		case types.GET_ONE_REVISION_SUBMITTING:
			return {
				...state,
			};
		case types.GET_ONE_REVISION_SUCCESS:
			return {
				...state,
				selectedRevision: action.payload,
			};
		case types.GET_ONE_REVISION_FAILURE:
			return {
				...state,
			};
		default:
			return state;
	}
};
