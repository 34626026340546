/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton } from "@material-ui/lab";
import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

export const RevisionGoalGraphOT = () => {
	const { checklist, project } = useProjectsOT();

	const goals = useMemo(
		() =>
			project.checklists?.map((p) => p.MetaRevision ?? 0) ??
			[],
		[project.checklists]
	);

	const max = useMemo(() => {
		const values = [
			...Object.values(checklist.graphData ?? []),
			...goals,
		];

		return Math.max(...values) * 1.15;
	}, [checklist.graphData, goals]);

	if (!checklist.graphData)
		return <Skeleton variant="rect" height={400} />;

	return (
		<Bar
			options={{
				legend: {
					display: true,
				},
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
								max,
							},
						},
					],
				},
				plugins: {
					labels: [
						{
							render: (col: any) => col.value,
							position: "outside",
							precision: 2,
						},
					],
				},
			}}
			data={{
				labels: Object.keys(checklist.graphData),
				datasets: [
					{
						label: "Real",
						data: Object.values(checklist.graphData),
						backgroundColor: "orange",
					},
					{
						label: "Meta",
						data: goals,
						backgroundColor: "cadetblue",
					},
				],
			}}
		/>
	);
};
