import { firestore } from "../firebase/firebase";
import firebase from "firebase/app";

/**
 * Elimina datos en masa de de un listado. Entre la cosas que elimina,
 * se encuentran:
 * - Errores tipo
 * - Subcontratos
 * - Recintos
 * - //TODO Observaciones!!
 */
export const eliminarDatosListado = async (
	idListado: string
) => {
	let batch = firestore.batch();

	// Get all the weas!
	const [
		erroresResponse,
		subcontractResponse,
		recintosResponse,
	] = await Promise.all([
		firestore
			.collectionGroup("ErroresTipo")
			.where("IdReferenceList", "==", idListado)
			.get(),

		firestore
			.collectionGroup("Subcontratos")
			.where("IdReferenceList", "==", idListado)
			.get(),

		firestore
			.collectionGroup("Recintos")
			.where("IdReferenceList", "==", idListado)
			.get(),
	]);

	const docs = [
		...erroresResponse.docs,
		...subcontractResponse.docs,
		...recintosResponse.docs,
	];

	// Elimina los documentos (máximo 498 por batch)
	for (let i = 0; i < docs.length; i++) {
		const element = docs[i];
		batch.delete(element.ref);
		if (i % 498 === 0) {
			batch.commit();
			batch = firestore.batch();
		}
	}

	batch.commit();
};

type UpdateProps = {
	isChecklist: boolean;
	checklist: any;
	projectId: string;
	revisionId: string;
	listId: string;
};

export const actualizarListados = async ({
	isChecklist,
	checklist,
	projectId,
	revisionId,
	listId,
}: UpdateProps) => {
	const checlistItemsRecintos: any[] =
		checklist?.ItemsRecintos || [];
	const recintosIds = [];
	let batch = firestore.batch();

	for (let j = 0; j < checlistItemsRecintos.length; j++) {
		const recintoRef = firestore
			.collection(
				isChecklist ? "ProyectosChecklist" : "Proyectos"
			)
			.doc(projectId)
			.collection("Revisiones")
			.doc(revisionId)
			.collection("Listado")
			.doc(listId)
			.collection("Recintos")
			.doc();
		recintosIds.push(recintoRef.id);
		const recinto = checklist.ItemsRecintos[j];
		batch.set(recintoRef, {
			Activado: recinto.Activado || false,
			Nombre: recinto.Nombre,
			FechaCreacion:
				firebase.firestore.FieldValue.serverTimestamp(),
			IdReferenceList: listId,
			TotalSubcontratos: recinto?.Subcontratos?.length || 0,
		});
	}
	await batch.commit();
	batch = firestore.batch();

	for (let j = 0; j < checklist.ItemsRecintos.length; j++) {
		const itemRecinto = checklist.ItemsRecintos[j];
		const itemRecintoSubcontratos: any[] =
			itemRecinto?.Subcontratos || [];
		for (
			let k = 0;
			k < itemRecintoSubcontratos.length;
			k++
		) {
			const subcontrato = itemRecinto.Subcontratos[k];
			const subcontratosRef = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.doc(recintosIds[j])
				.collection("Subcontratos")
				.add({
					Activado: subcontrato.Activado || false,
					Nombre: subcontrato.Nombre,
					FechaCreacion:
						firebase.firestore.FieldValue.serverTimestamp(),
					IdReferenceList: listId,
					TotalErroresTipo:
						subcontrato?.ErroresTipo?.length || 0,
				});

			if (!subcontrato.ErroresTipo) break;
			let erroresTipoCount = 0;
			for (
				let l = 0;
				l < subcontrato.ErroresTipo.length;
				l++
			) {
				const errorTipo = subcontrato.ErroresTipo[l];
				const erroresTipoRef = firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(projectId)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.doc(listId)
					.collection("Recintos")
					.doc(recintosIds[j])
					.collection("Subcontratos")
					.doc(subcontratosRef.id)
					.collection("ErroresTipo")
					.doc();
				batch.set(erroresTipoRef, {
					Activado: errorTipo.Activado || false,
					Nombre: errorTipo.Nombre,
					FechaCreacion:
						firebase.firestore.FieldValue.serverTimestamp(),
					IdReferenceList: listId,
					TotalRespuestas: 0,
					Resueltas: 0,
				});
				erroresTipoCount++;
				if (erroresTipoCount === 500) {
					await batch.commit();
					
					batch = firestore.batch();
					erroresTipoCount = 0;
				}
			}
			await batch.commit();
			batch = firestore.batch();
		}
	}
	await batch.commit();
	batch = firestore.batch();
};


export const actualizarNombreListado = async ({
	isChecklist,
	projectId,
	revisionId,
	listId,
	checklist,
}: UpdateProps) => {
	// se hace la referencia del proyecto que se quiere act

	const projectReff = await firestore
		.collection(
			isChecklist ? "ProyectosChecklist" : "Proyectos"
		)
		.doc(projectId)
		.collection("Revisiones")
		.doc(revisionId)
		.collection("Listado")
		.doc(listId)
		.get();

	// se hace la llamada, se le pasa una copia del proyecto
	// que se quiere act, mas el campo que se quiere agregar
	const recintosNumber = checklist.ItemsRecintos.map(
		(e: any) => e
	);
	await firestore
		.collection(
			isChecklist ? "ProyectosChecklist" : "Proyectos"
		)
		.doc(projectId)
		.collection("Revisiones")
		.doc(revisionId)
		.collection("Listado")
		.doc(listId)
		.update({
			...projectReff.data(),
			ChecklistName: checklist.Nombre,
			Carpetas: recintosNumber?.length,
		});
};
