import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import naturalSort from "natural-sort";
import { otActions } from "../slice";
import { RootState } from "src/redux/store/reducer";
import { getRef } from "src/utils/firebase";
import { SheetOT } from "src/models/OT/Sheet";
import { countWrongAnswersOfSpace } from "../utils";
import { firestore } from "src/firebase/firebase";
import { openSnack } from "src/redux/actions/uiActions";
import { SnackState } from "src/models/snack-state";
import { PlaceOT } from "src/models/OT/Place";
import { delay } from "src/utils/utils";

export const getChecklistDeviationByQuestion =
	createAsyncThunk(
		"checklists-ot/reports/checklist-deviation-by-question",
		async (_, { getState, dispatch }) => {
			try {
				dispatch(
					otActions.assignChecklist({ graphData: null })
				);

				const project = (getState() as RootState)
					.neoChecklistReducer.selected.project!;
				const checklist = (getState() as RootState)
					.neoChecklistReducer.selected.checklist!;

				const snaps = await firestore
					.collectionGroup("RespuestasOT")
					.where("ProyectoRef", "==", project._ref!)
					.where("ChecklistRef", "==", checklist._ref!)
					.get();

				const graphData: { [key: string]: number } = {};

				snaps.docs
					.map((d) => d.data() as SheetOT)
					.forEach((sheet) => {
						const { Revisores } = sheet;
						Revisores.forEach((reviewer) => {
							const { Categorias } = reviewer;

							Categorias.forEach((category) => {
								const { Preguntas } = category;

								Preguntas.forEach((question) => {
									const { Respuestas, Pregunta } = question;
									const [r0] = Respuestas;

									if (!graphData[Pregunta])
										graphData[Pregunta] = 0;

									if (r0?.Estado === "Rechazado")
										graphData[Pregunta]++;
								});
							});
						});
					});

				dispatch(otActions.assignChecklist({ graphData }));
			} catch (error) {
				console.error(error);
				dispatch(
					openSnack(
						"No fue posible traer los datos.",
						SnackState.ERROR
					) as any
				);
			}
		}
	);

export const getChecklistDeviationBySector =
	createAsyncThunk(
		"checklists-ot/reports/checklist-deviation-by-sector",
		async (_, { dispatch, getState }) => {
			dispatch(
				otActions.assignChecklist({ graphData: null })
			);

			const places = (
				getState() as RootState
			).neoChecklistReducer.checklist.places!.filter(
				(p) => p.Asignado
			);

			const promises = places.map(async (place) => {
				const count = await countWrongAnswersOfSpace(
					place._ref!
				);
				return [place.Nombre, count];
			});

			const results = await Promise.all(promises);

			const graphData = Object.fromEntries(
				[...results].sort((a, b) =>
					naturalSort()(a[0], b[0])
				)
			);

			dispatch(otActions.assignChecklist({ graphData }));
		}
	);

export const getProjectRevisionGoals = createAsyncThunk(
	"checklists-ot/reports/project-revision-goals",
	async (_, { getState, dispatch }) => {
		dispatch(
			otActions.assignChecklist({ graphData: null })
		);

		const checklists = (getState() as RootState)
			.neoChecklistReducer.project.checklists!;

		const graphData: { [key: string]: number } = {};

		checklists.forEach((checklist) => {
			const {
				Nombre,
				CantidadCompletada = 0,
				Cantidad = 1,
			} = checklist;

			//get percentage
			graphData[Nombre] = Math.round(
				(CantidadCompletada / (Cantidad || 1)) * 100
			);
		});

		dispatch(otActions.assignChecklist({ graphData }));
	}
);

export const getProjectDeviationByChecklist =
	createAsyncThunk(
		"checklists-ot/reports/project-deviation-by-checklist",
		async (_, { getState, dispatch }) => {
			dispatch(
				otActions.assignChecklist({ graphData: null })
			);

			const checklists = (getState() as RootState)
				.neoChecklistReducer.project.checklists!;

			/**
			 * Get all places assigned to checklists
			 */
			const places = await Promise.all(
				checklists.map(async (checklist) => {
					const snap = await getRef(checklist)
						.collection("EspaciosAsignadosOT")
						.where("Asignado", "==", true)
						.get();

					return [checklist.Nombre, snap] as const;
				})
			);

			const dataArray = await Promise.all(
				places.map(async ([name, placeSnaps]) => {
					let total = 0;

					const responses = await Promise.all(
						placeSnaps.docs.map(async (snap) =>
							snap.ref.collection("RespuestasOT").get()
						)
					);

					responses
						.flatMap((snap) => snap.docs)
						.forEach((snap) => {
							const { Revisores } = snap.data() as SheetOT;

							Revisores.forEach((revisor) => {
								const { Categorias } = revisor;

								Categorias.forEach((categoria) => {
									const { Preguntas } = categoria;

									Preguntas.forEach((pregunta) => {
										const { Respuestas } = pregunta;

										Respuestas.forEach((respuesta) => {
											if (respuesta?.Estado === "Rechazado")
												total++;
										});
									});
								});
							});
						});

					return [name, total] as const;
				})
			);

			const graphData = Object.fromEntries(dataArray);

			dispatch(otActions.assignChecklist({ graphData }));
		}
	);

export type DeviationByParameterApi = {
	place?: PlaceOT | null;
	sector?: string | null;
	checklistType?: "Obra Gruesa" | "Terminaciones";
};

export const getDeviationByParameter = createAsyncThunk(
	"checklists-ot/reports/deviation-by-parameter",
	async (
		{
			place,
			sector,
			checklistType,
		}: DeviationByParameterApi,
		{ getState, dispatch }
	) => {
		try {
			dispatch(
				otActions.assignChecklist({ graphData: null })
			);

			const projectRef = getRef(
				(getState() as RootState).neoChecklistReducer
					.selected.project
			);

			let checklists =
				(getState() as RootState).neoChecklistReducer
					.project.checklists ?? [];

			if (checklistType) {
				checklists = checklists.filter(
					(c) => c.Tipo === checklistType
				);
			}

			const query = await firestore
				.collectionGroup("RespuestasOT")
				.where("ProyectoRef", "==", projectRef)
				.get();

			let responses = query.docs.map(
				(d) => d.data() as SheetOT
			);

			if (place) {
				responses = responses.filter(
					(d) => d.NombreEspacio === place.Nombre
				);
			}

			if (sector) {
				responses = responses.filter(
					(d) => d.Sector === sector
				);
			}

			const paramKeys = checklists.map((c) => c.Nombre);
			const availableSectors = Array.from(
				new Set(responses.map((r) => r.Sector ?? ""))
			).sort((a, b) => naturalSort()(a, b));

			const graphData = Object.fromEntries(
				paramKeys.map((key) => [
					key,
					responses.reduce((acc, current) => {
						console.log(key, current.NombreChecklist);
						const { NombreChecklist, PreguntasMalas } =
							current;
						if (key !== NombreChecklist) return acc;

						console.log(
							sector,
							"=",
							current.Sector,
							sector === current.Sector
						);

						return acc + PreguntasMalas;
					}, 0),
				])
			);

			dispatch(
				otActions.assignChecklist({
					graphData,
					availableSectors,
				})
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				otActions.assignChecklist({ graphData: {} })
			);
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
	}
);

type PhotographicReportApi = {
	responsePath: string;
	responseNumber: number;
};

export const downloadPhotographicReport = createAsyncThunk(
	"checklists-ot/reports/photographic-report",
	async (params: PhotographicReportApi) => {
		const link = document.createElement("a");
		link.download = `Reporte Fotográfico.pdf`;
		link.target = "_blank";

		console.log(params.responsePath);

		const getFile = async () => {
			const response = await Axios.post(
				"https://us-central1-checkpro-3a90a.cloudfunctions.net/reports-ot-pdfFoto",
				params
			);
			link.href = response.data;
		};

		let wasSuccessful = false;
		for (let i = 0; i < 3; i++) {
			try {
				await getFile();
				wasSuccessful = true;
				break;
			} catch (error) {
				await delay(3000);
				wasSuccessful = false;
			}
		}

		if (wasSuccessful) link.click();
	}
);

export const downloadResponseSummaryExcel =
	createAsyncThunk(
		"checklists-ot/reports/response-summary-pdf",
		async (
			checklistType: "Obra Gruesa" | "Terminaciones",
			{ getState }
		) => {
			const project = (getState() as RootState)
				.neoChecklistReducer.selected.project!;

			const { data } = await Axios.post(
				"https://us-central1-checkpro-3a90a.cloudfunctions.net/reports-ot-excelResponse",
				{ projectPath: getRef(project).path, checklistType }
			);

			console.log(getRef(project).path);

			const link = document.createElement("a");
			link.href = data;
			link.download = `Resumen de Respuestas - ${project.Nombre} - ${checklistType}.xlsx`;
			link.target = "_blank";
			link.click();
		}
	);

export type SignatureSummaryApi = {
	responsePath: string;
	responseNumber: number;
};

export const downloadSignaturesSummaryPdf =
	createAsyncThunk(
		"checklists-ot/reports/signatures-summary-pdf",
		async (params: SignatureSummaryApi) => {
			const link = document.createElement("a");
			link.download = `Reporte respuestas con firmas.pdf`;
			link.target = "_blank";

			const getFile = async () => {
				const { data } = await Axios.post(
					"https://us-central1-checkpro-3a90a.cloudfunctions.net/reports-ot-pdfFirma",
					params
				);

				link.href = data;
			};
			let wasSuccessful = false;

			for (let i = 0; i < 3; i++) {
				try {
					await getFile();
					wasSuccessful = true;
					break;
				} catch (error) {
					await delay(3000);
					wasSuccessful = false;
				}
			}

			if (wasSuccessful) link.click();
		}
	);
