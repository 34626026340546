import {
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Fade,
	FormControlLabel,
	Grid,
	IconButton,
	Link,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
	NavLink,
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import * as yup from "yup";
import { es } from "yup-locales";
import { Breadcrumbs } from "../components/CheckPro/Breadcrumbs";
import { ConfirmDialog } from "../components/ConfirmDialog";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import { DialogAction } from "../models/dialog-actions";
import { getBusiness } from "../redux/actions/businessActions";
import {
	addChecklist,
	removeChecklist,
} from "../redux/actions/checklistActions";
import {
	getOneList,
	getOneRecinto,
	getSubcontratosProject,
	setSelectedRecintoProject,
} from "../redux/actions/listsActions";
import { getOneProject } from "../redux/actions/projectsActions";
import { getOneRevision } from "../redux/actions/revisionsActions";
import {
	addSector,
	setSectorDialogOpen,
	updateSector,
} from "../redux/actions/sectorsActions";
import { IAuthState } from "../redux/reducers/authReducer";
import { IBusinessState } from "../redux/reducers/businessReducer";
import { IListsState } from "../redux/reducers/listsReducer";
import { IProjectsState } from "../redux/reducers/projectsReducer";
import { IRevisionsState } from "../redux/reducers/revisionsReducer";
import { RootState } from "../redux/store/reducer";
import { ISectorsState } from "../redux/reducers/sectorsReducer";
import { useStyles } from "../theme/useStyles";
import { DialogCreateEditChecklist } from "./Checklits/DialogCreateEditChecklist";
import { DialogEditChecklist } from "./Checklits/DialogEditChecklist";
import { LinkEmpresas } from "../components/CheckPro/Links/Empresas";
import { LinkProyectos } from "../components/CheckPro/Links/Proyectos";
import { LinkRevisiones } from "../components/CheckPro/Links/Revisiones";
import { LinkListados } from "../components/CheckPro/Links/Listados";

yup.setLocale(es);

function ContentCard() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [openEdit, setOpenEdit] = useState(false);
	const { projectId, revisionId, listadoId, recintoId } =
		useParams<any>();
	const [limit, setLimit] = useState(TABLE_LIMIT_DEFAULT);
	const [page, setPage] = useState(0);
	const [totaldocs, setTotaldocs] = useState(0);
	const { pathname } = useLocation();

	const { recintosProject } = useSelector<
		RootState,
		IListsState
	>((state) => state.listsReducer);
	const handleGoToViewSubcontratos = (recinto: any) => {
		dispatch(setSelectedRecintoProject(recinto));
		dispatch(
			getSubcontratosProject(
				projectId,
				revisionId,
				listadoId,
				recinto.id
			)
		);
		history.push(
			`/administrador/checklist-proyectos/${projectId}/revisiones/${revisionId}/listados/${listadoId}/recintos/${recinto.id}/subcontratos`
		);
	};
	const handleLimitChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(parseInt(event.target.value, 10));

		if (
			parseInt(event.target.value, 10) >
			recintosProject.length
		) {
			// dispatch(getRecintosProject(parseInt(event.target.value, 10)));
		}
		setPage(0);
	};

	const showModalEdit = () => {
		setOpenEdit(!openEdit);
	};
	const handlePageChange = (_: any, newPage: number) => {
		if (
			recintosProject.length < recintosProject &&
			newPage > page
		) {
			// dispatch(getChecklist(limit));
		}
		setPage(newPage);
	};

	// Confirm Dialog
	const [dialogState, setDialogState] = useState({
		open: false,
		title: "",
		checklist: {},
	});

	const handleRemoveButton = (checklist: any) => {
		setDialogState((dialogState) => ({
			...dialogState,
			title: `¿Desea eliminar el Checklist ${checklist.Nombre}?`,
			checklist: checklist,
			open: true,
		}));
	};

	useEffect(() => {
		if (!recintosProject) {
			dispatch(
				getOneRecinto(
					projectId,
					revisionId,
					listadoId,
					true
				)
			);
		}
	}, [recintosProject, projectId, revisionId, listadoId]);

	return (
		<Card style={{ marginBottom: 6 }}>
			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell align="left">Nombre</TableCell>
								<TableCell align="left">
									Subcontratos
								</TableCell>
								{pathname.includes(
									"checklist-proyectos"
								) && (
									<TableCell>{"Observaciones"}</TableCell>
								)}
								<TableCell align="center">
									Acciones
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{recintosProject
								? recintosProject
										.sort((a: any, b: any) =>
											(a.Nombre as string).localeCompare(
												b.Nombre as string
											)
										)
										.slice(
											page * limit,
											page * limit + limit
										)
										.map((recinto: any) => (
											<Fade key={recinto.id} in={true}>
												<TableRow
													hover
													className={classes.styledRow}
												>
													<TableCell align="left">
														{recinto?.Nombre}
													</TableCell>
													<TableCell align="left">
														{recinto?.TotalSubcontratos}
													</TableCell>
													{pathname.includes(
														"checklist-proyectos"
													) && (
														<TableCell>
															{(recinto?.Resueltas || "0") +
																" de " +
																(recinto?.TotalRespuestas ||
																	"0")}
														</TableCell>
													)}
													<TableCell align="center">
														<Tooltip title="Sub-Contratos">
															<IconButton
																onClick={() => {
																	handleGoToViewSubcontratos(
																		recinto
																	);
																}}
																color="primary"
															>
																<ChevronRight />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											</Fade>
										))
								: new Array(limit).fill(0).map((x, i) => (
										<TableRow key={i}>
											<TableCell colSpan={5}>
												<Skeleton />
											</TableCell>
										</TableRow>
								  ))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={recintosProject?.length}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
				<DialogEditChecklist
					title="Editar Checklist"
					open={openEdit}
					onClose={showModalEdit}
					onAccept={() => {}}
				/>
			</CardContent>
			<ConfirmDialog
				open={dialogState.open}
				onClose={() =>
					setDialogState((dialogState) => ({
						...dialogState,
						open: false,
					}))
				}
				onConfirm={() => {
					dispatch(removeChecklist(dialogState.checklist));
				}}
				title={dialogState.title}
			/>
		</Card>
	);
}

const DialogAddSector = () => {
	const {
		dialog: {
			isDialogLoading,
			isDialogOpen,
			dialogAction,
			selectedSector,
		},
	} = useSelector<RootState, ISectorsState>(
		(state) => state.sectorsReducer
	);

	const dispatch = useDispatch();

	const {
		handleSubmit,
		values,
		handleChange,
		touched,
		errors,
		setValues,
		resetForm,
	} = useFormik({
		initialValues: {
			Nombre: "",
			Activado: true,
		},
		onSubmit: (values) => {
			if (dialogAction === DialogAction.Edit) {
				dispatch(
					updateSector({ ...values, id: selectedSector.id })
				);
			}
			if (dialogAction === DialogAction.New) {
				dispatch(addSector(values));
			}
		},
		validationSchema: yup.object({
			Nombre: yup.string().required("Nombre requerido"),
		}),
	});

	useEffect(() => {
		setValues({
			Nombre:
				dialogAction === DialogAction.Edit
					? selectedSector?.Nombre
					: "",
			Activado:
				dialogAction === DialogAction.Edit
					? selectedSector?.Activado
					: false,
		});
		if (dialogAction === DialogAction.New) resetForm();
	}, [dialogAction, selectedSector, resetForm, setValues]);

	return (
		<React.Fragment>
			<Dialog
				open={isDialogOpen}
				onClose={() => dispatch(setSectorDialogOpen(false))}
				aria-labelledby="add-and-update-dialog-title"
			>
				<DialogTitle id="add-and-update-dialog-title">
					{dialogAction === DialogAction.Edit
						? "Editar Sector"
						: "Agregar Sector"}
				</DialogTitle>

				<form noValidate onSubmit={handleSubmit}>
					<DialogContent>
						<TextField
							autoFocus
							id="Nombre"
							label="Nombre Sector"
							type="text"
							value={values.Nombre}
							name="Nombre"
							onChange={handleChange}
							error={
								touched.Nombre && Boolean(errors.Nombre)
							}
							helperText={touched.Nombre && errors.Nombre}
							fullWidth
						/>
						<FormControlLabel
							control={
								<Switch
									checked={values.Activado}
									onChange={handleChange}
									name="Activado"
									color="primary"
								/>
							}
							style={{ marginTop: 4 }}
							label="Activado"
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() =>
								dispatch(setSectorDialogOpen(false))
							}
							color="primary"
						>
							Cancelar
						</Button>
						<Button
							type="submit"
							color="primary"
							disabled={isDialogLoading}
						>
							{dialogAction === DialogAction.Edit
								? "Editar"
								: "Agregar"}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
};
export const ProjectRecintos = () => {
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useDispatch();
	const { projectId, revisionId, listadoId } =
		useParams<any>();
	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);

	const idEmpresa = auth.user?.empresaReference.id;

	const { selectedList } = useSelector<
		RootState,
		IListsState
	>((state) => state.listsReducer);
	const { selectedRevision } = useSelector<
		RootState,
		IRevisionsState
	>((state) => state.revisionsReducer);

	const {
		revisions: {
			isLoading: projectIsLoading,
			selectedProject,
		},
	} = useSelector<RootState, IProjectsState>(
		(state) => state.projectsReducer
	);
	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const showModal = () => {
		setOpenModal(!openModal);
	};
	const openAcept = (nombre: string, checklist: any) => {
		dispatch(addChecklist(nombre, checklist));
	};
	useEffect(() => {
		if (selectedProject === null) {
			dispatch(getOneProject(projectId));
		}
	}, [
		dispatch,
		idEmpresa,
		projectId,
		revisionId,
		selectedProject,
		selectedBusiness,
	]);

	useEffect(() => {
		if (selectedBusiness === null) {
			dispatch(getBusiness(idEmpresa));
		}
	}, [selectedBusiness]);

	useEffect(() => {
		if (!selectedRevision) {
			dispatch(getOneRevision(projectId, revisionId));
			dispatch(
				getOneList(projectId, revisionId, listadoId)
			);
		}
	}, [selectedRevision, projectId, revisionId, listadoId]);
	return (
		<React.Fragment>
			<Helmet title="CheckList" />
			<Grid justify="space-between" container>
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						{projectIsLoading && !selectedProject ? (
							<Skeleton variant="text" />
						) : (
							<Typography
								variant="h3"
								gutterBottom
								display="inline"
							>
								{`${selectedBusiness?.Nombre} / ${selectedProject?.NombreProyecto} / ${selectedRevision?.Nombre} / ${selectedList?.Nombre}`}
							</Typography>
						)}
					</Typography>

					<Breadcrumbs>
						<LinkEmpresas />
						<LinkProyectos />
						<LinkRevisiones />
						<LinkListados />
						<Typography>Recintos</Typography>
					</Breadcrumbs>
				</Grid>
			</Grid>
			<Divider />

			<Grid container spacing={6} style={{ marginTop: 5 }}>
				<Grid item xs={12}>
					<ContentCard />
				</Grid>
			</Grid>
			<DialogAddSector />
			<DialogCreateEditChecklist
				title="Crear Cheklist"
				open={openModal}
				onAccept={openAcept}
				onClose={showModal}
			/>
		</React.Fragment>
	);
};
