import * as React from "react";
import { useEffect } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from "@material-ui/core";
import { DialogAction } from "../../../models/dialog_state";
import { useFormik } from "formik";
import * as yup from "yup";
import { ResponsibleAutocomplete } from "../../../components/ResponsibleAutocomplete";
import { getUsersInProject } from "../../../redux/actions/projectsActions";
import { useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";

interface Props {
	open: boolean;
	onClose: () => void;
	onAccept: (values: any, action: DialogAction) => void;
	isLoading: boolean;
	action: DialogAction;
	selected?: any;
	title: string;
	project: any;
	error?: string | null;
	inProjects?: boolean;
}

export const DialogNewEdit: React.FC<Props> = ({
	open,
	onClose,
	title,
	onAccept,
	isLoading,
	action,
	selected,
	project,
	error = null,
	inProjects = false,
}) => {
	const dispatch = useDispatch();

	const {
		handleSubmit,
		values,
		handleChange,
		touched,
		errors,
		resetForm,
		setValues,
		setFieldValue,
	} = useFormik({
		initialValues: {
			Nombre: "",
			Responsable: null,
		},
		onSubmit: (values) => {
			if (!error) {
				onAccept(values, action);
			}
		},
		validationSchema: yup.object().shape({
			Nombre: yup.string().required("Nombre requerido"),
		}),
	});

	useEffect(() => {
		if (action === "New") {
			resetForm();
			dispatch(getUsersInProject(project));
		} else if (action === "Edit") {
			dispatch(getUsersInProject(project));
			setValues({
				Nombre: selected.Nombre.trim(),
				Responsable: selected.Responsable,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, selected]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="dialog-new-edit-revisions"
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle id="dialog-new-edit-revisions">
				{action === "Edit"
					? `Editar ${title}`
					: `Nueva ${title}`}
			</DialogTitle>
			<form onSubmit={handleSubmit}>
				<DialogContent>
					<Grid container spacing={2}>
						{error && (
							<Grid item xs={12}>
								<Alert severity="warning">{error}</Alert>
							</Grid>
						)}
						<Grid item xs={12}>
							<TextField
								autoFocus
								id="Nombre"
								label={`Nombre ${title}`}
								autoComplete="off"
								type="text"
								value={values.Nombre}
								name="Nombre"
								onChange={handleChange}
								error={
									touched.Nombre && Boolean(errors.Nombre)
								}
								helperText={touched.Nombre && errors.Nombre}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<ResponsibleAutocomplete
								onChange={(responsible) => {
									setFieldValue(
										"Responsable",
										responsible,
										true
									);
								}}
								error={!!errors.Responsable}
								helperText={errors.Responsable}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						Cancelar
					</Button>
					<Button
						type="submit"
						color="primary"
						disabled={isLoading}
					>
						Guardar
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
